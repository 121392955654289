import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { colors } from '../../theme';

export const WeightIcon = ({ size = 16, style = {} }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ ...style }}
    >
      <Path
        d='M14.8113 13.0855L12.8551 5.2604C12.7533 4.85352 12.4148 4.57146 12.0283 4.57146H10.4144C10.51 4.30226 10.5719 4.01645 10.5719 3.7143C10.5719 2.29409 9.42066 1.14282 8.00048 1.14282C6.58029 1.14282 5.42904 2.29409 5.42904 3.7143C5.42904 4.01645 5.49065 4.30226 5.58654 4.57146H3.9727C3.58618 4.57146 3.24734 4.85379 3.14582 5.2604L1.18912 13.0855C0.96599 13.9777 1.5818 14.8571 2.42957 14.8571H13.5711C14.4186 14.8571 15.0344 13.9777 14.8113 13.0855ZM8.00021 4.57119C7.52771 4.57119 7.14306 4.18654 7.14306 3.71403C7.14306 3.24152 7.52771 2.85687 8.00021 2.85687C8.47271 2.85687 8.85735 3.24152 8.85735 3.71403C8.85735 4.18654 8.47271 4.57119 8.00021 4.57119Z'
        fill={colors.gray.default}
      />
    </Svg>
  );
};

WeightIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
