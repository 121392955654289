import { StyleSheet } from 'react-native';

import { colors } from '../../../../theme';

export const styles = StyleSheet.create({
  divider: {
    borderColor: colors.gray.border,
    borderStyle: 'solid',
    borderWidth: 1,
    marginBottom: 24,
    marginTop: 24,
  },
  text: {
    color: colors.gray.main,
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: 1.17,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
});
