import { Box } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { IS_DESKTOP_SCREEN } from '../../constants';

export const BoxWrapper = ({ children, zIndex = -1, ...props }) => (
  <Box
    flexGrow={IS_DESKTOP_SCREEN ? 0 : 1}
    p={{ base: 'md', sm: 'lg' }}
    pb={{ base: 'md', sm: '32px' }}
    bg='white'
    rounded='lg'
    mb='lg'
    // boxSizing='border-box'
    zIndex={zIndex}
    shadow={2}
    {...props}
  >
    {children}
  </Box>
);

BoxWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  zIndex: PropTypes.number,
};
