import { StyleSheet } from 'react-native';

import { theme } from '../constants';

export const styles = StyleSheet.create({
  inner: {
    flex: 1,
    position: 'relative',
  },
  wrapper: {
    backgroundColor: theme.colors.lightGray,
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
});
