import { MaterialIcons } from '@expo/vector-icons';
import { Box, Icon, Input } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { Keyboard } from 'react-native';

import { useIntl } from '../../hooks';

export const AddModalInput = ({ value, onChange }) => {
  const intl = useIntl();

  const handleClear = () => {
    onChange('');
    Keyboard.dismiss();
  };

  return (
    <Box
      bgColor={{ base: 'secondary', lg: 'white' }}
      paddingX={{ base: '8px', lg: 0 }}
      paddingY={{ base: '10px', lg: 0 }}
      marginX={{ base: '0px', lg: 'lg' }}
      mb={{ base: '8px', lg: 'lg' }}
    >
      <Input
        value={value}
        onChangeText={onChange}
        height={{ base: '35px', lg: 'auto' }}
        bgColor={{ base: 'secondary', lg: 'white' }}
        color={{ base: 'white', lg: 'text.default' }}
        backgroundColor={{ base: 'secondary', lg: 'white' }}
        borderBottomColor={{ base: 'white', lg: 'text.default' }}
        placeholder={intl.formatMessage({ id: 'app.search' })}
        InputLeftElement={
          <Icon
            as={<MaterialIcons name='search' />}
            size='20px'
            color={{ base: 'white', lg: 'text.default' }}
            ml='8px'
          />
        }
        InputRightElement={
          value ? (
            <Icon
              as={<MaterialIcons name='close' />}
              size='20px'
              color={{ base: 'white', lg: 'text.default' }}
              mr='8px'
              onPress={handleClear}
            />
          ) : null
        }
      />
    </Box>
  );
};

AddModalInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
