import React, { Component, createRef } from 'react';
import {
  View,
  Text,
  SafeAreaView,
  TextInput,
  FlatList,
  TouchableHighlight,
  Dimensions,
} from 'react-native';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { MaterialIcons } from '@expo/vector-icons';
import { displayWidth } from '../../../../constants/index';
import client from '../../../../client';
import findEmailsQuery from '../../../../containers/Customers/graphql/queries/findEmails';
import emailsQuery from '../../../../containers/Customers/graphql/queries/emails';
import styles from '../Style';
import { isWeb } from '../../../../constants';

class NameField extends Component {
  UNSAFE_componentWillMount() {
    isWeb && document.addEventListener('click', this.handleClickOutside, false);

    const { emails, value } = this.props;
    if (value.split(',').length) {
      client
        .query({
          query: emailsQuery,
          variables: { ids: value.split(',') },
        })
        .then((result) => {
          this.setState({
            emails: _.uniqBy([...emails, ...result.data.emails], 'id'),
          });
        });
    } else {
      this.setState({ emails });
    }
  }
  componentWillUnmount() {
    isWeb && document.removeEventListener('click', this.handleClickOutside, false);
  }
  state = {
    search: this.props.phone ? this.props.phone : '',
    emails: [],
    isOpen: false,
  };

  myRef = createRef();

  handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this.myRef.current);
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  };

  render() {
    const { value, label } = this.props;
    const { search, isOpen, emails } = this.state;

    const options = emails
      .map((i) => ({
        value: i.id,
        label: i.email,
      }))
      .filter((item) => item.label.includes(search));
    const width = Dimensions.get('window').width >= displayWidth;
    return (
      <>
        <View ref={this.myRef} style={styles.input}>
          <View style={styles.items}>
            <Text style={{ color: '#4F4F4F' }}>{label}</Text>
            {options.map((item) =>
              value.split(',').includes(item.value) ? (
                <View style={styles.user} key={item.value}>
                  <Text>{item.label}</Text>
                  <MaterialIcons
                    onPress={() => this.selecting(item.value, false)}
                    name='delete-forever'
                    color='#333333'
                    size={25}
                  />
                </View>
              ) : null,
            )}
          </View>
          <TextInput
            style={[{ marginTop: 3, marginBottom: 3 }, isWeb && { outline: 'none' }]}
            onChangeText={(text) => this.handleInputChange(text)}
            value={search}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                this.selecting(
                  options.filter((item) => !value.includes(item.value))[0].value,
                  true,
                );
              }
            }}
            onFocus={() => this.setState({ isOpen: true })}
          />
          <MaterialIcons
            name={`arrow-drop-${isOpen ? 'up' : 'down'}`}
            size={25}
            color='black'
            onPress={() => this.setState({ isOpen: !isOpen })}
            style={styles.iconOpen}
          />
        </View>
        {isOpen && (
          <SafeAreaView
            style={{ flex: 1 }}
            horizontal
            showsHorizontalScrollIndicator={false}
            style={[styles.listCity, width && { position: 'absolute', bottom: -205 }]}
          >
            <FlatList
              data={options.filter((item) => !value.includes(item.value))}
              renderItem={({ item }) => (
                <TouchableHighlight
                  style={styles.city}
                  onPress={() => this.selecting(item.value, true)}
                >
                  <Text>{item.label}</Text>
                </TouchableHighlight>
              )}
              keyExtractor={(item) => item.value}
            />
          </SafeAreaView>
        )}
      </>
    );
  }

  selecting = (id, type) => {
    const { value, name, handleChange } = this.props;
    const filter = !!value ? value.split(',') : [];
    if (type) {
      filter.push(id);
      handleChange(name, filter.join(','));
      this.setState({ search: '' });
      this.setState({ isOpen: false });
    } else {
      filter.splice(filter.indexOf(id), 1);
      handleChange(name, filter.join(','));
    }
  };

  handleInputChange = async (value) => {
    this.setState({ search: value });

    const emails = await client
      .query({
        query: findEmailsQuery,
        variables: { search: value },
      })
      .then((result) => {
        return result.data.findEmails;
      });
    this.setState({
      emails: _.uniqBy([...emails, ...this.state.emails], 'id'),
    });
  };
}

export default NameField;
