import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const OrderCustomerIcon = ({ size = 16, fill = 'gray', style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 16 16' style={{ ...style }}>
      <Path
        d='M5 4.33337C5 5.98737 6.346 7.33337 8 7.33337C9.654 7.33337 11 5.98737 11 4.33337C11 2.67937 9.654 1.33337 8 1.33337C6.346 1.33337 5 2.67937 5 4.33337ZM13.3333 14H14V13.3334C14 10.7607 11.906 8.66671 9.33333 8.66671H6.66667C4.09333 8.66671 2 10.7607 2 13.3334V14H13.3333Z'
        fill={fill}
      />
    </Svg>
  );
};

OrderCustomerIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
  fill: PropTypes.string,
};
