import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { Box, CheckIcon, Flex, WarningTwoIcon } from 'native-base';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  BoxWrapper,
  FormRow,
  FormWrapper,
  Layout,
  RequestHandler,
  TabNavigation,
  TextFieldHookFrom,
  Typography,
} from '../../../components';
import CurrencyIdSelect from '../../../components/CurrencyIdSelect/CurrencyIdSelect';
import { CustomParamTypesEnum, IS_DESKTOP_SCREEN } from '../../../constants';
import stylesGlobal from '../../../globalStyles';
import {
  useAddProductMutation,
  useDeleteProductCustomParam,
  useGetAlloProduct,
  useGetCurrenciesQuery,
  useGetProduct,
  useGetRozetkaProduct,
  useHasScopesQuery,
  useParams,
  useProductCustomParam,
  useUpdateAlloProduct,
  useUpdateRozetkaProduct,
} from '../../../hooks';
import {
  addAlloProductSchema,
  addProductSchema,
  addRozetkaProductSchema,
} from '../../../services/validation';
import { getVolumeWeight } from '../../../utils';
import { compareArraysById } from '../../../utils/compareArraysById';
import { Availability } from './Availability/Availability';
import Barcodes from './Barcodes/Barcodes';
import CustomParams from './CustomParams/CustomParams';
import PriceTags from './PriceTags/PriceTags';
import ProductLocales from './ProductLocales/ProductLocales';
import RozetkaInfo from './RozetkaInfo/RozetkaInfo';
import UploadImage from './UploadImage/UploadImage';
import UploadImageMobile from './UploadImage/UploadImageMobile/UploadImageMobile';
import AlloInfo from './AlloInfo/AlloInfo';

const Product = () => {
  const { data, refetch, ...productQueryProps } = useGetProduct();
  const { data: rozetkaData, ...rozetkaProductQueryProps } = useGetRozetkaProduct();
  const { data: alloData, ...alloProductQueryProps } = useGetAlloProduct();

  const { data: hasScopesData } = useHasScopesQuery(['Purchase:Read']);
  const { data: currenciesData, ...currenciesQueryProps } = useGetCurrenciesQuery();
  const [addProduct, { loading }] = useAddProductMutation();
  const [updateRozetkaProduct, { loading: updateRozetkaProductLoading }] =
    useUpdateRozetkaProduct();
  const [updateAlloProduct, { loading: updateAlloProductLoading }] =
    useUpdateAlloProduct();

  const [productCustomParamMutation, { loading: customParamLoading }] =
    useProductCustomParam();
  const [deleteProductCustomParamMutation, { loading: deleteCustomParamLoading }] =
    useDeleteProductCustomParam();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [initCustomParamOptions, setInitCustomParamOptions] = useState([]);

  const params = useParams();

  const hasScope = hasScopesData?.hasScopes[0]?.isAllowed;
  const methods = useForm({
    resolver: yupResolver(
      currentIndex === 0
        ? addProductSchema
        : currentIndex === 1
        ? addRozetkaProductSchema
        : addAlloProductSchema,
    ),
    reValidateMode: 'onBlur',
    defaultValues: {
      name: data?.product?.name || '',
      sku: data?.product?.sku || '',
      weight: data?.product?.weight?.toString() || '',
      width: data?.product?.width?.toString() || '',
      height: data?.product?.height?.toString() || '',
      length: data?.product?.length?.toString() || '',
      price: data?.product?.price?.toString() || '',
      priceCurrencyId: data?.product?.priceCurrencyId?.toString() || '',
      MSRprice: data?.product?.MSRprice?.toString() || '',
      MSRpriceCurrencyId: data?.product?.MSRpriceCurrencyId?.toString() || '',
      addedBarcode: '',
      barcodes: data?.product?.barcodes?.map((item) => item.barcode) || [],
      url: data?.product?.url || '',
      productPriceTags:
        data?.product?.productPriceTags.length !== 0
          ? data?.product?.productPriceTags
          : [],
      translates: data?.product?.translates,
      photos: data?.product?.photos || [],
      productCustomParams: data?.product?.productCustomParams || [],
      rozetkaProduct: rozetkaData?.rozetkaProduct
        ? { ...rozetkaData?.rozetkaProduct }
        : initRozetkaProduct,
      alloProduct: alloData?.alloProduct ? alloData?.alloProduct : initAlloProduct,
    },
  });

  const initRozetkaProduct = {
    categoryId: '',
    categoryName: '',
    description: data?.product?.translates?.find(
      (translate) => translate.productLocaleId === 2,
    )?.description,
    descriptionUa: data?.product?.translates?.find(
      (translate) => translate.productLocaleId === 1,
    )?.description,
    name: data?.product?.translates?.find((translate) => translate.productLocaleId === 2)
      ?.name,
    nameUa: data?.product?.translates?.find(
      (translate) => translate.productLocaleId === 1,
    )?.name,
    internalId: '',
    params: [],
    sku: '',
    vendor: '',
    categories: [{ id: '', name: '' }],
  };
  const initAlloProduct = {
    categoryId: '',
    categoryName: '',
    vendor: '',
  };

  const getInitRozetkaParams = () => {
    const uniqueParams = {};
    rozetkaData?.rozetkaProduct?.params.forEach((param) => {
      const { name, value, valueId } = param;
      if (!uniqueParams[name]) {
        if (valueId !== null) {
          uniqueParams[name] = {
            ...param,
            value: value,
            valueId: [{ label: value, value: valueId }],
          };
        } else {
          uniqueParams[name] = { ...param };
        }
      } else if (valueId !== null) {
        uniqueParams[name].valueId.push({ label: value, value: valueId });
      }
    });

    const filteredParams = Object.values(uniqueParams);
    return filteredParams;
  };

  useEffect(() => {
    if (!data) return;
    const transformParamsOptions = data?.product?.productCustomParams?.reduce(
      (acc, param) => {
        if (acc.some((el) => Number(el.id) === param.customParamId)) {
          const customParam = param.customParam.customParamOptions.find(
            (option) => Number(option.id) === param.customParamOptionId,
          );
          const multiOptionsParams = acc.find(
            (el) => Number(el.id) === param.customParamId,
          );
          multiOptionsParams?.customParamOptionIds?.push({
            value: customParam?.id,
            label: customParam?.name,
            id: param.id,
          });
          return acc;
        }

        if (param.customParam?.type === CustomParamTypesEnum.Text) {
          acc?.push({
            ...param.customParam,
            productParamId: Number(param.id),
            textValue: param.textValue,
          });
          return acc;
        } else if (param.customParam?.type === CustomParamTypesEnum.Number) {
          acc?.push({
            ...param.customParam,
            productParamId: Number(param.id),
            numberValue: param.numberValue,
          });
          return acc;
        } else if (param.customParam?.type === CustomParamTypesEnum.Boolean) {
          acc?.push({
            ...param.customParam,
            productParamId: Number(param.id),
            booleanValue: param.booleanValue,
          });
          return acc;
        } else if (param.customParam.type === CustomParamTypesEnum.Range) {
          acc?.push({
            ...param.customParam,
            productParamId: Number(param.id),
            rangeFromValue: param.rangeFromValue,
            rangeToValue: param.rangeToValue,
          });
          return acc;
        } else if (param.customParam.type === CustomParamTypesEnum.SingleOption) {
          acc?.push({
            ...param.customParam,
            productParamId: Number(param.id),
            customParamOptionId: param.customParamOptionId.toString(),
          });
          return acc;
        } else if (param.customParam.type === CustomParamTypesEnum.MultiOptions) {
          const customParam = param.customParam.customParamOptions.find(
            (option) => Number(option.id) === param.customParamOptionId,
          );
          acc?.push({
            ...param.customParam,
            productParamId: Number(param.id),
            customParamOptionIds: [
              {
                value: customParam?.id,
                id: param.id,
                label: customParam?.name,
              },
            ],
          });
          return acc;
        } else {
          acc?.push({ ...param.customParam });
          return acc;
        }
      },
      [],
    );
    setInitCustomParamOptions(transformParamsOptions);
    methods.reset({
      name: data?.product?.name || '',
      sku: data?.product?.sku || '',
      weight: data?.product?.weight?.toString() || '',
      width: data?.product?.width?.toString() || '',
      height: data?.product?.height?.toString() || '',
      length: data?.product?.length?.toString() || '',
      price: data?.product?.price?.toString() || '',
      priceCurrencyId: data?.product?.priceCurrencyId?.toString() || '',
      MSRprice: data?.product?.MSRprice?.toString() || '',
      MSRpriceCurrencyId: data?.product?.MSRpriceCurrencyId?.toString() || '',
      addedBarcode: '',
      barcodes: data?.product?.barcodes?.map((item) => item.barcode) || [],
      url: data?.product?.url || '',
      productPriceTags:
        data?.product?.productPriceTags.length !== 0
          ? data?.product?.productPriceTags
          : [],
      translates: data?.product?.translates,
      photos: data?.product?.photos,
      productCustomParams: transformParamsOptions,
      alloProduct: alloData?.alloProduct ? alloData?.alloProduct : initAlloProduct,
      rozetkaProduct: rozetkaData?.rozetkaProduct
        ? {
            ...rozetkaData?.rozetkaProduct,
            ...(rozetkaData?.rozetkaProduct?.categoryId
              ? {
                  categories: [
                    {
                      value: rozetkaData?.rozetkaProduct?.categoryId,
                      label: rozetkaData?.rozetkaProduct?.categoryName,
                    },
                  ],
                }
              : {
                  categories: [
                    {
                      value: '',
                      label: '',
                    },
                  ],
                }),
            params: getInitRozetkaParams(),
            description:
              data?.product?.translates?.find(
                (translate) => translate.productLocaleId === 2,
              )?.description || '',
            descriptionUa:
              data?.product?.translates?.find(
                (translate) => translate.productLocaleId === 1,
              )?.description || '',
            name:
              data?.product?.translates?.find(
                (translate) => translate.productLocaleId === 2,
              )?.name || '',
            nameUa:
              data?.product?.translates?.find(
                (translate) => translate.productLocaleId === 1,
              )?.name || '',
          }
        : initRozetkaProduct,
    });

    setTimeout(() => {
      methods.reset({
        ...methods.getValues(),
      });
    }, 300);
  }, [data, methods.reset, params?.id, rozetkaData]);

  const handleFormSubmit = async (values) => {
    if (currentIndex === 0) {
      addProduct({
        variables: {
          input: {
            id: params.id ? +params.id : undefined,
            name: values.name,
            sku: values.sku,
            weight: Number(values.weight),
            width: Number(values.width),
            height: Number(values.height),
            length: Number(values.length),
            price: Number(values.price),
            MSRprice: Number(values.MSRprice),
            priceCurrencyId: Number(values.priceCurrencyId),
            MSRpriceCurrencyId: Number(values.MSRpriceCurrencyId),
            barcodes: values.barcodes?.map((code) => Number(code)),
            url: values.url,
            productPriceTags: values?.productPriceTags?.map(({ id, name }) => ({
              id,
              name,
            })),
            productTranslates: values?.translates?.map((translate) => ({
              id: translate.id,
              description: translate.description,
              name: translate.name,
              productLocaleId: Number(translate.productLocaleId),
            })),
          },
        },
        onCompleted: async (response) => {
          const { newElements, idsToDelete, changes } = compareArraysById(
            data?.product?.productCustomParams,
            initCustomParamOptions,
            values?.productCustomParams,
          );

          if (newElements.length || changes.length || idsToDelete.length) {
            const promises = [];
            let deletedIds = idsToDelete;
            if (newElements.length) {
              newElements.forEach((element) => {
                delete element.name;
                delete element.customParamOptionIds;
                delete element.customParamOptions;
                if (element.type === CustomParamTypesEnum.SingleOption) {
                  delete element.type;

                  promises.push(
                    productCustomParamMutation({
                      variables: {
                        input: {
                          customParamId: element.customParamId,
                          productId: Number(params.id),
                          customParamOptionId: Number(element.customParamOptionId),
                        },
                      },
                    }),
                  );
                } else {
                  delete element.type;
                  promises.push(
                    productCustomParamMutation({
                      variables: {
                        input: {
                          ...element,
                          productId: Number(params.id),
                        },
                      },
                    }),
                  );
                }
              });
            }
            if (changes.length) {
              changes.forEach((change) => {
                delete change.customParamOptions;
                delete change.name;
                delete change.productParamId;
                delete change.type;
                delete change.__typename;
                promises.push(
                  productCustomParamMutation({
                    variables: {
                      input: {
                        ...change,
                        productId: Number(params.id),
                      },
                    },
                  }),
                );
              });
            }
            if (deletedIds.length) {
              deletedIds.forEach((id) => {
                id &&
                  promises.push(
                    deleteProductCustomParamMutation({
                      variables: {
                        deleteProductCustomParamId: Number(id),
                      },
                    }),
                  );
              });
            }
            await Promise.all(promises).then(() => {
              deletedIds = [];
              refetch();
            });
          }
          const isEqualIsEqualTranslates = _.isEqual(
            data?.product?.translates,
            values?.translates,
          );
          const isEqualSku = data?.product?.sku === values.sku;
          if ((!isEqualSku || !isEqualIsEqualTranslates) && !!params.id) {
            updateRozetkaProduct({
              variables: {
                input: {
                  internalId: Number(response.product.id),
                  sku: values.sku,
                  ...(!isEqualIsEqualTranslates && {
                    description:
                      values?.translates?.find(
                        (translate) => Number(translate.productLocaleId) === 2,
                      )?.description || '',
                    descriptionUa:
                      values?.translates?.find(
                        (translate) => Number(translate.productLocaleId) === 1,
                      )?.description || '',
                    name:
                      values?.translates?.find(
                        (translate) => Number(translate.productLocaleId) === 2,
                      )?.name || '',
                    nameUa:
                      values?.translates?.find(
                        (translate) => Number(translate.productLocaleId) === 1,
                      )?.name || '',
                  }),
                },
              },
            });
          }
        },
      });
    } else if (currentIndex === 1) {
      const updatedParams = values.rozetkaProduct.params.reduce((acc, param) => {
        const { __typename, ...rest } = param;
        delete rest.isValueId;
        if (__typename) {
          delete rest.__typename;
        }

        if (Array.isArray(param.valueId)) {
          const processedValues = param.valueId.map(({ value, label }) => ({
            ...rest,
            paramId: Number(param.paramId),
            value: label,
            valueId: value,
          }));
          return [...acc, ...processedValues];
        } else {
          return [...acc, { ...rest, paramId: Number(param.paramId) }];
        }
      }, []);
      updateRozetkaProduct({
        variables: {
          input: {
            internalId: Number(params.id),
            categoryId:
              values.rozetkaProduct.categories[
                values.rozetkaProduct.categories.length - 1
              ]?.value,
            categoryName:
              values.rozetkaProduct.categories[
                values.rozetkaProduct.categories.length - 1
              ]?.label,
            params: updatedParams,
            sku: values.sku,
            vendor: values.rozetkaProduct.vendor.toString(),
          },
        },
      });
    } else {
      updateAlloProduct({
        variables: {
          input: {
            internalId: Number(params.id),
            categoryId: values.alloProduct.categoryId,
            categoryName: values.alloProduct.categoryName,
            vendor: values.alloProduct.vendor.toString(),
          },
        },
      });
    }
  };

  const values = methods.watch();

  const IsAllDataFilledInIcon = ({ index }) => {
    if (rozetkaData?.rozetkaProduct?.isAllDataFilledIn && index === 1) {
      return <CheckIcon size='4' mr={2} color='emerald.500' />;
    } else if (alloData?.alloProduct?.isAllDataFilledIn && index === 2) {
      return <CheckIcon size='4' mr={2} color='emerald.500' />;
    } else {
      return <WarningTwoIcon size='4' mr={2} color='rose.500' />;
    }
  };

  return (
    <RequestHandler
      loading={
        productQueryProps.loading ||
        rozetkaProductQueryProps.loading ||
        alloProductQueryProps.loading
      }
      error={
        productQueryProps.error ||
        rozetkaProductQueryProps.error ||
        alloProductQueryProps.error
      }
      backgroundLoading={
        loading ||
        updateRozetkaProductLoading ||
        updateAlloProductLoading ||
        customParamLoading ||
        deleteCustomParamLoading
      }
    >
      <FormWrapper>
        <FormProvider {...methods}>
          <Layout
            onActionButtonPress={methods.handleSubmit(handleFormSubmit)}
            actionButtonType='save'
            actionButtonIsLoading={loading}
          >
            <RequestHandler {...currenciesQueryProps}>
              <TabNavigation
                onChange={(index) => {
                  setCurrentIndex(index);
                }}
                items={['app.generalInfo', 'app.rozetkaInfo', 'app.alloInfo']}
                disabledIndexes={!rozetkaData ? [1, 2] : [-1]}
                isFullWidth={false}
                initialState={currentIndex}
                isIcon
                iconIndexes={[1, 2]}
                mb={'24px'}
                Icon={IsAllDataFilledInIcon}
              >
                <TabNavigation.Item>
                  <FormRow>
                    <FormRow.Left>
                      <BoxWrapper style={stylesGlobal.form}>
                        <Typography intlId='app.product' variant='heading' />
                        <Flex
                          justifyContent={'space-between'}
                          flexDirection={{ base: 'column', lg: 'row' }}
                        >
                          <TextFieldHookFrom
                            name='name'
                            label='app.title'
                            width={IS_DESKTOP_SCREEN ? '47%' : '100%'}
                          />
                          <TextFieldHookFrom
                            name='sku'
                            label='app.sku'
                            width={IS_DESKTOP_SCREEN ? '47%' : '100%'}
                            isReadOnly={params.id}
                          />
                        </Flex>
                      </BoxWrapper>

                      {!IS_DESKTOP_SCREEN ? (
                        <UploadImageMobile
                          photoFormats={data?.product?.photos}
                          loadingProduct={productQueryProps.loading}
                        />
                      ) : null}

                      <BoxWrapper style={stylesGlobal.form}>
                        <ProductLocales productId={params.id} />
                      </BoxWrapper>

                      <BoxWrapper style={stylesGlobal.form}>
                        <Typography intlId='app.prices' variant='heading' />
                        <Flex
                          justify={'space-between'}
                          align={'center'}
                          flexDirection={{ base: 'column', lg: 'row' }}
                        >
                          <Box
                            display={'flex'}
                            width={IS_DESKTOP_SCREEN ? '47%' : '100%'}
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                          >
                            <TextFieldHookFrom
                              name='MSRprice'
                              label='app.MSRprice'
                              type='number'
                              width={'65%'}
                            />
                            <CurrencyIdSelect
                              name='MSRpriceCurrencyId'
                              label='app.currency'
                              currenciesData={currenciesData}
                            />
                          </Box>
                          <Box
                            display={'flex'}
                            flexDirection={'row'}
                            width={IS_DESKTOP_SCREEN ? '47%' : '100%'}
                            justifyContent={'space-between'}
                          >
                            <TextFieldHookFrom
                              name='price'
                              type='number'
                              label='app.price'
                              width={'65%'}
                            />
                            <CurrencyIdSelect
                              name='priceCurrencyId'
                              label='app.currency'
                              currenciesData={currenciesData}
                            />
                          </Box>
                        </Flex>
                        <PriceTags />
                      </BoxWrapper>
                      <BoxWrapper style={stylesGlobal.form}>
                        <Barcodes />
                      </BoxWrapper>
                      <BoxWrapper style={stylesGlobal.form}>
                        <Typography intlId='app.dimensions' variant='heading' />
                        <Flex
                          justifyContent={'space-between'}
                          flexDirection={{ base: 'column', lg: 'row' }}
                        >
                          <TextFieldHookFrom
                            name='width'
                            label='app.widthCm'
                            type='number'
                            width={IS_DESKTOP_SCREEN ? '20%' : '100%'}
                          />
                          <TextFieldHookFrom
                            name='length'
                            label='app.lengthCm'
                            type='number'
                            width={IS_DESKTOP_SCREEN ? '20%' : '100%'}
                          />
                          <TextFieldHookFrom
                            name='height'
                            label='app.heightCm'
                            type='number'
                            width={IS_DESKTOP_SCREEN ? '20%' : '100%'}
                          />
                          <TextFieldHookFrom
                            name='weight'
                            label='app.weightKg'
                            type='number'
                            width={IS_DESKTOP_SCREEN ? '20%' : '100%'}
                          />
                        </Flex>
                        <Flex justifyContent={'flex-end'}>
                          {IS_DESKTOP_SCREEN ? (
                            <>
                              <Box width={'20%'}></Box>
                              <Box width={'20%'}></Box>
                              <Box width={'20%'}></Box>
                            </>
                          ) : (
                            <></>
                          )}
                          <TextFieldHookFrom
                            name='volumeWeight'
                            value={getVolumeWeight(
                              values.width,
                              values.height,
                              values.length,
                            )}
                            label='app.volumeWeight'
                            isReadOnly
                            width={IS_DESKTOP_SCREEN ? '20%' : '100%'}
                          />
                        </Flex>
                      </BoxWrapper>
                      <BoxWrapper style={stylesGlobal.form}>
                        <CustomParams />
                      </BoxWrapper>
                    </FormRow.Left>
                    <FormRow.Right>
                      {IS_DESKTOP_SCREEN ? (
                        <UploadImage
                          photos={data?.product?.photos}
                          loadingProduct={loading}
                          productId={params.id}
                        />
                      ) : null}
                    </FormRow.Right>
                  </FormRow>
                  {data?.product?.availabilities.length ? (
                    <Availability
                      hasScope={hasScope}
                      availabilities={data?.product?.availabilities}
                    />
                  ) : null}
                </TabNavigation.Item>
                <TabNavigation.Item disabled={!!rozetkaData}>
                  <RozetkaInfo categoryId={rozetkaData?.rozetkaProduct?.categoryId} />
                </TabNavigation.Item>
                <TabNavigation.Item disabled={!!alloData}>
                  <AlloInfo />
                </TabNavigation.Item>
              </TabNavigation>
            </RequestHandler>
          </Layout>
        </FormProvider>
      </FormWrapper>
    </RequestHandler>
  );
};

export default Product;
