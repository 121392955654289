import { useField } from 'formik';
import { Box, Input } from 'native-base';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useMultiSelect } from '../../hooks';
import { MultiSelectOptions } from '../MultiSelectOptions/MultiSelectOptions';
import { SelectWithSearch } from '../SelectWithSearch/SelectWithSearch';
import { Typography } from '../Typography/Typography';
import { SearchSelectList } from './SearchSelectList';

export const SearchSelectInFilter = ({
  name,
  label,
  placeholder = 'app.choose',
  options,
  zIndex = 1,
  ...props
}) => {
  const { onChange, onRemove } = useMultiSelect(name, options);
  const [field, meta] = useField(name);

  const handleOptionPress = (option) => {
    onChange(option?.value);
  };

  return (
    <>
      <SelectWithSearch
        name={name}
        placeholderIntlId={placeholder}
        options={options}
        onChange={handleOptionPress}
        mb='md'
        width={'100%'}
        pr={0}
        pl={0}
      />
      <MultiSelectOptions value={field?.value} onRemove={onRemove} />
    </>
  );
};

SearchSelectInFilter.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isSearchable: PropTypes.bool,
  options: PropTypes.array,
  zIndex: PropTypes.number,
};
