import { useFormikContext } from 'formik';
import { Center } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { IS_DESKTOP_SCREEN } from '../../constants';
import { useSearchContext } from '../../context';
import { useFindForwardingsQuery } from '../../hooks';
import { ShipIcon } from '../Icons';
import {
  AddModal,
  DesktopTable,
  ForwardingCard,
  LinkDescription,
  ModalButton,
  RequestHandler,
} from '../index';

export const AddForwardingModal = ({ isOpen, onClose }) => {
  const { searchValue, changeSearchValue } = useSearchContext();
  const { values, setFieldValue } = useFormikContext();

  const { data, ...queryProps } = useFindForwardingsQuery({
    searchValue,
    skip: !searchValue,
  });

  const handleSet = (item) => {
    setFieldValue('forwarding', item);
    onClose();
  };

  return (
    <AddModal
      isOpen={isOpen}
      onClose={onClose}
      searchValue={searchValue}
      onChangeSearchValue={changeSearchValue}
      withScan={false}
      queryProps={queryProps}
      title='app.addforwarding'
    >
      <RequestHandler {...queryProps}>
        {IS_DESKTOP_SCREEN ? (
          <>
            <DesktopTable inModal>
              <DesktopTable.Head>
                <DesktopTable.Title width={45} />
                <DesktopTable.Title />
                <DesktopTable.Title width={80} />
              </DesktopTable.Head>
              <DesktopTable.Body>
                {data?.purchaseForwardings?.rows?.map((item) => (
                  <DesktopTable.Row key={item?.id}>
                    <DesktopTable.Cell width={45}>
                      <Center
                        width='40px'
                        height='40px'
                        borderRadius='20px'
                        bgColor='primary.default'
                        mr='md'
                      >
                        <ShipIcon />
                      </Center>
                    </DesktopTable.Cell>
                    <DesktopTable.Cell>
                      <LinkDescription
                        intlId={item.trackingCode}
                        date={item?.date}
                        item={item}
                        withClockIcon
                        link={`/purchase/forwarding/${item?.id}`}
                        vertically
                      />
                    </DesktopTable.Cell>
                    <ModalButton
                      handleSet={handleSet}
                      onClose={onClose}
                      item={item}
                      addedItemsIds={values?.forwarding?.id}
                    />
                  </DesktopTable.Row>
                ))}
              </DesktopTable.Body>
            </DesktopTable>
          </>
        ) : (
          data?.purchaseForwardings?.rows?.map((item) => (
            <ForwardingCard key={item?.id} item={item} handleSet={handleSet} />
          ))
        )}
      </RequestHandler>
    </AddModal>
  );
};

AddForwardingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
