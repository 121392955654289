import { MaterialIcons } from '@expo/vector-icons';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { TouchableOpacity, View } from 'react-native';

import { Typography } from '../../../components/Typography/Typography';
import { theme } from '../../../constants';
import styles from './styles';

const CustomerMobileItem = ({ id, date, name, phones, emails, onItemPress }) => {
  const intl = useIntl();
  const renderEmails = () => {
    if (!emails.length) return null;

    let result = ``;

    emails.forEach(({ email }, index) => {
      result += `${index > 0 ? ', ' : ''}${email}`;
    });

    return (
      <View style={styles.info}>
        <MaterialIcons name='email' size={16} color='#4F4F4F' style={styles.infoIcon} />
        <Typography>{result}</Typography>
      </View>
    );
  };

  const renderPhones = () => {
    if (!phones.length) return null;

    let result = ``;

    phones.forEach(({ phone }, index) => {
      result += `${index > 0 ? ', ' : ''}${phone}`;
    });

    return (
      <View style={styles.info}>
        <MaterialIcons name='call' size={16} color='#4F4F4F' style={styles.infoIcon} />
        <Typography>{result}</Typography>
      </View>
    );
  };

  return (
    <TouchableOpacity onPress={() => onItemPress(id)} style={styles.wrapper}>
      <Typography marginBottom={8}>{name}</Typography>
      {date && (
        <Typography fontSize={12} marginBottom={6} color={theme.colors.darkGray}>
          {intl.formatMessage({ id: 'app.registration' })} - {date}
        </Typography>
      )}
      {renderEmails()}
      {renderPhones()}
    </TouchableOpacity>
  );
};

export default memo(CustomerMobileItem);

CustomerMobileItem.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  phones: PropTypes.array,
  emails: PropTypes.array,
  onItemPress: PropTypes.func,
};
