/* eslint-disable react-native/no-inline-styles */
import PropTypes from 'prop-types';
import React from 'react';
import { ScrollView } from 'react-native';

import { IS_DESKTOP_SCREEN } from '../../constants';

export const AddModalContent = ({ children }) => {
  return (
    <ScrollView contentContainerStyle={{ paddingHorizontal: IS_DESKTOP_SCREEN ? 24 : 8 }}>
      {children}
    </ScrollView>
  );
};

AddModalContent.propTypes = {
  children: PropTypes.node.isRequired,
};
