import { format as dateFnsFormat } from 'date-fns';
import React from 'react';

import { IS_DESKTOP_SCREEN } from '../../constants';
import { useBreadcrumbsContext } from '../../context';
import { Typography } from '../Typography/Typography';

export const TransactionBreadcrumbs = () => {
  const { breadcrumbsValues } = useBreadcrumbsContext();

  return (
    <>
      <Typography
        intlId={breadcrumbsValues?.typeName}
        fontSize={{ base: '20px', lg: '2xl' }}
        color='gray.extraLight'
        fontWeight={300}
      />
      <Typography
        color='gray.extraLight'
        fontSize={IS_DESKTOP_SCREEN ? '14px' : '13px'}
        fontWeight={IS_DESKTOP_SCREEN ? 400 : 200}
        position='relative'
        top={IS_DESKTOP_SCREEN ? '10px' : '3px'}
        ml={IS_DESKTOP_SCREEN ? '16px' : '6px'}
        intlId='app.from'
      >
        {dateFnsFormat(new Date(breadcrumbsValues?.date || new Date()), 'd.MM.y')}
      </Typography>
    </>
  );
};
