import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { DATA_REQUEST_LIMIT } from '../../constants';
import { useDebounce } from '../../hooks';
import { transformSelectOption, transformSelectOptions } from '../../utils';
import { SelectWithSearchHookForm } from '../SelectWithSearch/SelectWithSearchHookForm';

export const QuerySelectWithSearchHookForm = (props) => {
  const field = useWatch({ name: props.fieldName });

  return <QuerySelectWithSearchHookFormNested {...props} field={field} />;
};

const QuerySelectWithSearchHookFormNested = memo(
  ({
    label,
    labelProperty,
    valueProperty = 'id',
    placeholderIntlId = 'app.enterValue',
    fieldName,
    initialOption,
    graphqlName,
    graphql,
    width,
    mb,
    onChange,
    rowsInResponse = false,
    pr,
    alreadyPickedItems,
    pl,
    field,
    handleCreateOption,
    args = {},
    isCreateble = false,
    isMulti = false,
    isLoading = false,
    isStreetNovaposhta = false,
    isValChange = false,
  }) => {
    const [options, setOptions] = useState([]);

    const [searchValue, setSearchValue] = useState('');

    const { debouncedValue: debouncedSearchValue, isDebouncing } =
      useDebounce(searchValue);

    const [lazyQueryRequest, { loading, fetchMore }] = useLazyQuery(graphql, {
      variables: {
        search: debouncedSearchValue,
        offset: 0,
        limit: DATA_REQUEST_LIMIT,
        ...args,
      },
      fetchPolicy: 'cache-first',
      onCompleted: (response) => {
        const result = rowsInResponse
          ? response?.[graphqlName]?.['rows']
          : response?.[graphqlName];
        setOptions(
          transformSelectOptions({
            data: result?.filter?.((item) =>
              item?.[labelProperty] && alreadyPickedItems
                ? !alreadyPickedItems.some((pickedItem) => pickedItem.id === item.id)
                : true,
            ),
            value: valueProperty,
            label: labelProperty,
          }),
        );
      },
    });

    const fetchMoreData = async () => {
      const { data } = await fetchMore({
        variables: {
          offset: options?.length,
          limit: DATA_REQUEST_LIMIT,
          ...args,
        },
      });
      const result = rowsInResponse ? data?.[graphqlName]?.['rows'] : data?.[graphqlName];

      setOptions((prevState) => [
        ...prevState,
        ...transformSelectOptions({
          data: result?.filter?.((item) =>
            item?.[labelProperty] && alreadyPickedItems
              ? !alreadyPickedItems.some((pickedItem) => pickedItem.id === item.id)
              : true,
          ),
          value: valueProperty,
          label: labelProperty,
        }),
      ]);
    };

    const fetchQueryResponse = () => {
      lazyQueryRequest();
    };

    const initialOptionOrFind = initialOption
      ? initialOption
      : field
      ? transformSelectOption({
          item: field,
          value: valueProperty,
          label: labelProperty,
        })
      : null;

    return (
      <SelectWithSearchHookForm
        onMenuOpen={fetchQueryResponse}
        onMenuScrollToBottom={fetchMoreData}
        onChange={onChange}
        placeholderIntlId={placeholderIntlId}
        setSearchValue={setSearchValue}
        options={options}
        isLoading={loading || isDebouncing || isLoading}
        label={label}
        name={fieldName}
        initialOption={initialOptionOrFind}
        pr={pr}
        pl={pl}
        mb={mb}
        width={width}
        isStreetNovaposhta={isStreetNovaposhta}
        isValChange={isValChange}
        isMulti={isMulti}
        isCreateble={isCreateble}
        handleCreateOption={handleCreateOption}
      />
    );
  },
);

QuerySelectWithSearchHookFormNested.displayName = 'QuerySelectWithSearchHookFormNested';

const propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  fieldName: PropTypes.string.isRequired,
  graphqlName: PropTypes.string.isRequired,
  placeholderIntlId: PropTypes.string,
  graphql: PropTypes.object.isRequired,
  initialOption: PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  }),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  labelProperty: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  valueProperty: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
  ]),
  pr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rowsInResponse: PropTypes.bool,
  alreadyPickedItems: PropTypes.array,
  args: PropTypes.object,
  isLoading: PropTypes.bool,
  isStreetNovaposhta: PropTypes.bool,
  isValChange: PropTypes.bool,
  isCreateble: PropTypes.bool,
  isMulti: PropTypes.bool,
  handleCreateOption: PropTypes.func,
};

QuerySelectWithSearchHookFormNested.propTypes = { ...propTypes, field: PropTypes.string };

QuerySelectWithSearchHookForm.propTypes = propTypes;
