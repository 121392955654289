import { Formik } from 'formik';
import React from 'react';

import {
  BoxWrapper,
  FieldRow,
  FormRow,
  FormWrapper,
  Layout,
  QuerySelectWithSearch,
  Typography,
} from '../../../components';
import { useNotificationsContext } from '../../../context';
import { GET_STOCK_PLACES } from '../../../graphql';
import { useDisplacementsMutation } from '../../../hooks';
import { displacementsSchema } from '../../../services/validation';
import { DisplacementsStructure } from './DisplacementsStructure/DisplacementsStructure';

const Displacements = () => {
  const { showNotification } = useNotificationsContext();
  const [addDisplacement, { loading }] = useDisplacementsMutation();

  const handleFormSubmit = async (values, { resetForm }) => {
    const variables = [];

    values.availabilities.forEach((availability) => {
      variables.push({
        fromPlaceId: parseInt(values.fromPlace?.id),
        toPlaceId: parseInt(values.toPlace?.id),
        purchaseId: parseInt(availability.purchaseId),
        fromConditionId: parseInt(availability.fromConditionId),
        toConditionId: parseInt(availability.toConditionId),
        value: parseInt(availability.quantity),
      });
    });

    for (const item of variables) {
      await addDisplacement({ variables: item });
    }

    if (!variables.length) {
      showNotification({
        title: 'errors.networkError',
        message: 'product.needAdd',
        type: 'error',
      });
    } else {
      resetForm();
    }
  };

  const initialValues = {
    fromPlace: '',
    toPlace: '',
    availabilities: [],
  };

  return (
    <FormWrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={displacementsSchema}
      >
        {({ handleSubmit, setFieldValue }) => (
          <Layout
            onActionButtonPress={handleSubmit}
            actionButtonType='save'
            actionButtonIsLoading={loading}
          >
            <FormRow>
              <FormRow.Left>
                <BoxWrapper>
                  <Typography intlId='app.displacements' variant='heading' />
                  <FieldRow>
                    <QuerySelectWithSearch
                      graphql={GET_STOCK_PLACES}
                      graphqlName='stockPlaces'
                      fieldName='fromPlace'
                      label='app.fromTheBox'
                      labelProperty={'name'}
                      valueProperty={['id', 'name', 'availabilities']}
                      onChange={() => setFieldValue('availabilities', [])}
                    />
                    <QuerySelectWithSearch
                      graphql={GET_STOCK_PLACES}
                      graphqlName='stockPlaces'
                      fieldName='toPlace'
                      label='app.toTheBox'
                      labelProperty={'name'}
                      valueProperty={['id', 'name']}
                    />
                  </FieldRow>
                </BoxWrapper>
              </FormRow.Left>
              <FormRow.Right />
            </FormRow>
            <DisplacementsStructure />
          </Layout>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default Displacements;
