import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const SettingsUsersIcon = ({ size = '24', fill = 'white', style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' style={{ ...style }}>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.0074 18.141C9.84439 18.141 7.64689 16.857 6.36439 15.1575C0.21139 15.1575 0.0253906 23.9475 0.0253906 23.9475H23.9879C23.9879 23.9475 24.4709 15.1185 17.5514 15.1185C16.2704 16.839 14.1704 18.141 12.0074 18.141Z'
        fill={fill}
      />
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.1614 7.6035C17.1614 10.1325 14.8529 14.9685 12.0029 14.9685C9.15741 14.9685 6.84741 10.131 6.84741 7.6035C6.84741 5.076 9.15591 3.024 12.0029 3.024C14.8529 3.0255 17.1614 5.0775 17.1614 7.6035Z'
        fill={fill}
      />
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.4625 5.421C19.4625 4.947 18.8025 4.5645 17.9835 4.5615V3.585C17.9835 3.4395 18.039 0.0330048 12.021 0.0330048C6.00603 0.0330048 6.06153 3.4395 6.06153 3.585V4.596C6.04653 4.596 6.03453 4.59151 6.02103 4.59151C5.20653 4.59151 4.55103 4.968 4.55103 5.4345V9.56551C4.55103 10.029 5.20803 10.407 6.02103 10.407C6.83403 10.407 7.49403 10.029 7.49403 9.56551V5.4345C7.49403 5.37 7.45353 5.3115 7.42953 5.2515V4.125C7.42953 4.0215 6.99753 1.4505 12.021 1.4505C17.046 1.4505 16.524 4.0215 16.524 4.125V5.3115C16.515 5.349 16.4865 5.382 16.4865 5.421V9.64651C16.4865 10.122 17.151 10.5075 17.9745 10.5075C17.9925 10.5075 18.006 10.5015 18.024 10.5015V12.045H16.548V13.5H19.4775L19.4625 5.421Z'
        fill={fill}
      />
    </Svg>
  );
};

SettingsUsersIcon.propTypes = {
  size: PropTypes.string,
  style: PropTypes.object,
  fill: PropTypes.string,
};
