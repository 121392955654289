import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';

import { QuerySelectWithSearchHookForm } from '../../../../components/QuerySelectWithSearch/QuerySelectWithSearchHookForm';
import { GET_WAREHOUSES_NP } from '../../../../graphql';
import { useGetWarehouseNP } from '../../../../hooks';
import { transformSelectOption } from '../../../../utils';

const DATA_REQUEST_LIMIT = 20;

export const WarehouseAutoCompleteFieldHookForm = ({ name, cityRef, warehouseRef }) => {
  const args = { cityRef: cityRef };

  const [getInitWarehouse, { data: warehouseData, loading: initWarehouseLoading }] =
    useGetWarehouseNP();

  useEffect(() => {
    cityRef &&
      warehouseRef &&
      getInitWarehouse({
        variables: {
          warehouseRef: warehouseRef,
          offset: 0,
          limit: DATA_REQUEST_LIMIT,
        },
      });
  }, []);

  const labelProperty = useMemo(() => {
    return ['description'];
  }, []);

  return (
    <QuerySelectWithSearchHookForm
      width={'100%'}
      label='app.warehouse'
      initialOption={
        warehouseRef &&
        transformSelectOption({
          item: warehouseData?.getWarehousesNovaPoshta?.[0],
          value: 'ref',
          label: 'description',
        })
      }
      placeholderIntlId='app.enterValue'
      fieldName={name}
      labelProperty={labelProperty}
      valueProperty='ref'
      pl={0}
      pr={0}
      graphql={GET_WAREHOUSES_NP}
      graphqlName='getWarehousesNovaPoshta'
      args={args}
      isLoading={initWarehouseLoading}
    />
  );
};

WarehouseAutoCompleteFieldHookForm.propTypes = {
  name: PropTypes.string.isRequired,
  cityRef: PropTypes.string.isRequired,
  warehouseRef: PropTypes.string.isRequired,
};
