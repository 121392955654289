import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderBottomColor: '#d9d9d9',
    borderBottomWidth: 1,
    justifyContent: 'space-between',
  },
  rowActive: { backgroundColor: '#EDEDED' },
  buttonNav: {
    position: 'absolute',
    top: '50%',
    width: 40,
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    backgroundColor: '#47C8FF',
    transform: [{ translateY: -20 }],
  },
});
