import { Flex } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { TouchableOpacity } from 'react-native';

import { BoxWrapper, Typography } from '../../../../components';

const BoxMobileItem = ({ name, archival, room, shelving, onItemPress }) => {
  return (
    <TouchableOpacity onPress={onItemPress}>
      <BoxWrapper>
        <Flex justify='space-between' mb='md'>
          <Typography color='gray.main' intlId='app.title' />
          <Typography>{name}</Typography>
        </Flex>
        <Flex justify='space-between' mb='md'>
          <Typography color='gray.main' intlId='app.archival' />
          {archival}
        </Flex>
        <Flex justify='space-between' mb='md'>
          <Typography color='gray.main' intlId='app.room' />
          <Typography>{room}</Typography>
        </Flex>
        <Flex justify='space-between'>
          <Typography color='gray.main' intlId='app.shelving' />
          <Typography>{shelving}</Typography>
        </Flex>
      </BoxWrapper>
    </TouchableOpacity>
  );
};

export default memo(BoxMobileItem);

BoxMobileItem.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  archival: PropTypes.string.isRequired,
  room: PropTypes.string,
  shelving: PropTypes.string,
  onItemPress: PropTypes.func,
};
