import AsyncStorage from '@react-native-async-storage/async-storage';

class LocalStorageService {
  async setValue(key, value) {
    await AsyncStorage.setItem(key, value);
  }
  async getValue(key) {
    return await AsyncStorage.getItem(key);
  }
  async deleteValue(key) {
    return await AsyncStorage.removeItem(key);
  }
}

export const LocalStorage = new LocalStorageService();
