import React from 'react';
import { BoxWrapper, FormRow } from '../../../../components';
import stylesGlobal from '../../../../globalStyles';
import AlloVendor from './AlloVendor';
import AlloCategory from './AlloCategory';

const AlloInfo = () => {
  return (
    <FormRow>
      <FormRow.Left>
        <BoxWrapper style={stylesGlobal.form}>
          <AlloVendor />
        </BoxWrapper>
        <BoxWrapper style={stylesGlobal.form}>
          <AlloCategory />
        </BoxWrapper>
      </FormRow.Left>
      <FormRow.Right></FormRow.Right>
    </FormRow>
  );
};

export default AlloInfo;
