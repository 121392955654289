import { format } from 'date-fns';
import { Flex } from 'native-base';
import React from 'react';

import {
  DesktopTable,
  Layout,
  MobileTable,
  RequestHandler,
  Typography,
} from '../../../components';
import { DATA_REQUEST_LIMIT, IS_DESKTOP_SCREEN } from '../../../constants';
import { useSearchContext } from '../../../context';
import { useGetCustomersQuery, useHistory, useIntl } from '../../../hooks';
import useFilter from '../../../hooks/useFilter';
import CustomerMobileItem from './CustomerMobileItem';
import { CustomersFilters } from './CustomersFilters';

const COLUMNS = [
  { intlId: 'app.id', sortId: 'id' },
  { intlId: 'customer.name', sortId: 'firstName' },
  { intlId: 'app.registration', sortId: 'createdAt' },
  { intlId: 'app.email' },
  { intlId: 'app.phone' },
];

const sortableColumns = COLUMNS?.filter((col) => col.sortId);

const Customers = () => {
  const intl = useIntl();

  const navigation = useHistory();

  const { debouncedSearchValue } = useSearchContext();

  const { data, fetchMore, ...customersQueryProps } = useGetCustomersQuery();

  const { filters } = useFilter();

  const customers = data?.customers;

  const fetchMoreData = async (resolve, reject) => {
    await fetchMore({
      variables: {
        offset: customers?.length,
        limit: DATA_REQUEST_LIMIT,
        search: debouncedSearchValue,
        order: filters?.direction || 'asc',
        orderBy: filters?.field || 'id',
        filters: {
          dateFrom: filters?.dateFrom || undefined,
          dateTo: filters?.dateTo || undefined,
          emailIds: filters?.emailIds || undefined,
          phoneIds: filters?.phoneIds || undefined,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          reject && reject();
          return prev;
        }

        resolve && resolve();

        return {
          customers: [...prev?.customers, ...fetchMoreResult?.customers],
        };
      },
    });
  };

  const handleEditButtonClick = (id, breadcrumbsParam) => {
    navigation.push('/sales/customer/' + id, { breadcrumbsParam });
  };

  const handleAddButtonClick = () => {
    navigation.push('/sales/customer');
  };

  const getName = (firstName, lastName) => {
    return firstName || lastName
      ? `${firstName ? firstName : ''} ${lastName ? lastName : ''}`
      : intl.formatMessage({ id: 'app.no' });
  };

  const getDate = (createdAt) => {
    return format(new Date(parseInt(createdAt)), 'dd.MM.yyyy HH:mm');
  };

  const getPhones = (phones, id, breadcrumbsParam) => {
    return phones.length ? (
      <Flex>
        {phones[0].phone}
        {phones.length > 1 ? (
          <Typography
            underline={false}
            _hover={{
              color: 'secondary',
            }}
            color='primary.default'
            ml={{ base: '5px' }}
            onPress={() => handleEditButtonClick(id, getName(breadcrumbsParam))}
            intlId={`+${phones.length - 1}`}
          />
        ) : (
          ''
        )}
      </Flex>
    ) : (
      intl.formatMessage({ id: 'app.no' })
    );
  };

  const getEmails = (emails, id, breadcrumbsParam) => {
    return emails.length ? (
      <Flex>
        {emails[0].email}
        {emails.length > 1 ? (
          <Typography
            underline={false}
            _hover={{
              color: 'secondary',
            }}
            color='primary.default'
            ml={{ base: '5px' }}
            onPress={() => handleEditButtonClick(id, getName(breadcrumbsParam))}
            intlId={`+${emails.length - 1}`}
          />
        ) : (
          ''
        )}
      </Flex>
    ) : (
      intl.formatMessage({ id: 'app.no' })
    );
  };

  if (IS_DESKTOP_SCREEN) {
    return (
      <Layout
        isScrollable={false}
        onActionButtonPress={handleAddButtonClick}
        filters={<CustomersFilters />}
        onEndReached={fetchMoreData}
      >
        <RequestHandler {...customersQueryProps}>
          <DesktopTable fetchMoreData={fetchMoreData}>
            <DesktopTable.Head>
              {COLUMNS?.map(({ intlId, sortId }, index) => (
                <DesktopTable.Title key={index} intlId={intlId} sortId={sortId} />
              ))}
            </DesktopTable.Head>
            <DesktopTable.Body>
              {customers?.map((customer, index) => (
                <DesktopTable.Row
                  key={index}
                  onEditButtonClick={() =>
                    handleEditButtonClick(
                      customer?.id,
                      getName(customer?.firstName, customer?.lastName),
                    )
                  }
                >
                  <DesktopTable.Cell>{customer?.id}</DesktopTable.Cell>
                  <DesktopTable.Cell>
                    {getName(customer?.firstName, customer?.lastName)}
                  </DesktopTable.Cell>
                  <DesktopTable.Cell>{getDate(customer?.createdAt)}</DesktopTable.Cell>
                  <DesktopTable.Cell>
                    {getEmails(
                      customer?.emails,
                      customer?.id,
                      getName(customer?.firstName, customer?.lastName),
                    )}
                  </DesktopTable.Cell>
                  <DesktopTable.Cell>
                    {getPhones(
                      customer?.phones,
                      customer?.id,
                      getName(customer?.firstName, customer?.lastName),
                    )}
                  </DesktopTable.Cell>
                </DesktopTable.Row>
              ))}
            </DesktopTable.Body>
          </DesktopTable>
        </RequestHandler>
      </Layout>
    );
  }

  return (
    <Layout
      isScrollable={false}
      onActionButtonPress={handleAddButtonClick}
      filters={<CustomersFilters />}
    >
      <RequestHandler {...customersQueryProps}>
        <MobileTable
          data={customers}
          sortableColumns={sortableColumns}
          fetchMoreData={fetchMoreData}
          renderItem={(item) => (
            <CustomerMobileItem
              onItemPress={handleEditButtonClick}
              id={item?.id}
              name={getName(item?.firstName, item?.lastName)}
              date={getDate(item?.createdAt)}
              phones={item?.phones}
              emails={item?.emails}
            />
          )}
        />
      </RequestHandler>
    </Layout>
  );
};

export default Customers;
