import { Box, Flex, Text } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { TouchableOpacity } from 'react-native';

import {
  DeliveredIcon,
  ShippingOnUkraineIcon,
  ShippingToUkraineIcon,
} from '../../../../components/Icons';
import { WeightIcon } from '../../../../components/Icons/WeightIcon';
import { PARCEL_STATUS_IDS } from '../../../../constants';
import { useIntl } from '../../../../hooks';
import { formatDateTime } from '../../../../utils';
import styles from './styles';

const ParcelMobileItem = ({
  trackingCode,
  quantity,
  parcelTime,
  sourceName,
  productWeight,
  statusId,
  onItemPress,
  id,
}) => {
  const intl = useIntl();

  const renderIcon = () => {
    switch (statusId) {
      case PARCEL_STATUS_IDS.onTheWay:
        return <ShippingOnUkraineIcon />;
      case PARCEL_STATUS_IDS.inWarehouse:
        return <DeliveredIcon />;
      default:
        return <ShippingToUkraineIcon />;
    }
  };

  return (
    <TouchableOpacity onPress={() => onItemPress(id)}>
      <Box
        bg='white'
        borderRadius={'5px'}
        px={'md'}
        py={'18px'}
        my={'4px'}
        style={styles.card}
      >
        <Flex wrap={'wrap'} direction={'row'} align={'center'}>
          <Box mr={'sm'}>{renderIcon()}</Box>
          <Box>
            <Text color={'gray.darkness'} fontSize={15} fontFamily={'medium'} mb={'4px'}>
              {trackingCode}
            </Text>
            <Text>{`${quantity} ${intl.formatMessage({
              id: 'app.pcs',
            })}${
              parcelTime
                ? ` ${intl.formatMessage({
                    id: 'app.from',
                  })} ${formatDateTime(parcelTime, 'dd.MM.yyyy')}`
                : ''
            }`}</Text>
          </Box>
          <Flex ml={'auto'} mr={0} alignItems={'flex-end'} direction={'column'}>
            <Text mb={'4px'} fontSize={16} color={'pink'}>
              {sourceName}
            </Text>
            <Flex align={'center'} direction={'row'}>
              <Box mr={'4px'}>
                <WeightIcon />
              </Box>
              <Text fontSize={15}>{productWeight}</Text>
              <Flex direction={'row'} align={'center'} mt={'3px'}>
                <Text mx={'1px'} fontSize={11} color={'gray.default'}>
                  ({quantity})
                </Text>
                <Text fontSize={15}>
                  {intl.formatMessage({
                    id: 'app.kg',
                  })}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </TouchableOpacity>
  );
};

export default memo(ParcelMobileItem);

ParcelMobileItem.propTypes = {
  trackingCode: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  parcelTime: PropTypes.string.isRequired,
  sourceName: PropTypes.string.isRequired,
  productWeight: PropTypes.number.isRequired,
  statusId: PropTypes.string.isRequired,
  onItemPress: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};
