import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const OrderUserIcon = ({ size = 16, fill = 'gray', style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 16 16' style={{ ...style }}>
      <Path
        d='M7.99992 1.33337C4.32392 1.33337 1.33325 4.32404 1.33325 8.00004V10.762C1.33325 11.4447 1.93125 12 2.66659 12H3.33325C3.51006 12 3.67963 11.9298 3.80466 11.8048C3.92968 11.6798 3.99992 11.5102 3.99992 11.3334V7.90471C3.99992 7.7279 3.92968 7.55833 3.80466 7.4333C3.67963 7.30828 3.51006 7.23804 3.33325 7.23804H2.72792C3.09859 4.65804 5.31859 2.66671 7.99992 2.66671C10.6813 2.66671 12.9013 4.65804 13.2719 7.23804H12.6666C12.4898 7.23804 12.3202 7.30828 12.1952 7.4333C12.0702 7.55833 11.9999 7.7279 11.9999 7.90471V12C11.9999 12.7354 11.4019 13.3334 10.6666 13.3334H9.33325V12.6667H6.66659V14.6667H10.6666C12.1373 14.6667 13.3333 13.4707 13.3333 12C14.0686 12 14.6666 11.4447 14.6666 10.762V8.00004C14.6666 4.32404 11.6759 1.33337 7.99992 1.33337Z'
        fill={fill}
      />
    </Svg>
  );
};

OrderUserIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
  fill: PropTypes.string,
};
