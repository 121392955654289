import { Box, Flex } from 'native-base';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Typography } from '../../../../components';

import { styles } from './styles';
import { QuerySelectWithSearchHookForm } from '../../../../components/QuerySelectWithSearch/QuerySelectWithSearchHookForm';
import { GET_ROZETKA_VENDORS } from '../../../../graphql';

const Vendor = () => {
  const { watch, setValue } = useFormContext();
  const vendor = watch('rozetkaProduct.vendor');

  const onChangeVendorSelect = (option) => {
    setValue('rozetkaProduct.vendor', option);
  };
  return (
    <>
      <Flex justify='space-between' alignItems='baseline'>
        <Typography intlId='app.vendor' variant='heading' />
      </Flex>
      <Box style={styles.wrapper}>
        <QuerySelectWithSearchHookForm
          width={'100%'}
          label='app.vendor'
          valueProperty={'name'}
          labelProperty={'name'}
          placeholderIntlId='app.enterValue'
          fieldName='rozetkaProduct.vendor'
          initialOption={vendor ? { value: vendor, label: vendor } : null}
          graphqlName={'getRozetkaVendors'}
          graphql={GET_ROZETKA_VENDORS}
          onChange={onChangeVendorSelect}
          mb={0}
          pr={0}
          pl={0}
          isValChange
        />
      </Box>
    </>
  );
};

export default Vendor;
