import { NativeModules } from 'react-native';

import { isAndroid, isIos } from '../constants';

const appLocales = ['ru', 'en'];
const fallbackLocale = 'en';

function getDefaultLocale() {
  if (isIos) {
    const result =
      NativeModules.SettingsManager.settings.AppleLocale ||
      NativeModules.SettingsManager.settings.AppleLanguages[0];

    const index = result.indexOf('_');
    return result.slice(0, index);
  }

  if (isAndroid) {
    const result = NativeModules.I18nManager.localeIdentifier;
    const index = result.indexOf('_');
    return result.slice(0, index);
  }

  return navigator.language.split(/[-_]/)[0];
}

export function getCurrentLocale() {
  const defaultLocale = getDefaultLocale();
  return appLocales.includes(defaultLocale) ? defaultLocale : fallbackLocale;
}
