import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import onClickOutside from 'react-onclickoutside';

import { IS_WEB } from '../../constants';

const HandlerClickOutside = ({ children, onClickOutside }) => {
  HandlerClickOutside.handleClickOutside = () => onClickOutside();

  return children;
};

const clickOutsideConfig = {
  handleClickOutside: () => HandlerClickOutside.handleClickOutside,
};

const ClickOutsideHOC = onClickOutside(HandlerClickOutside, clickOutsideConfig);

const ClickOutside = ({ children, ...props }) => {
  return IS_WEB ? (
    <ClickOutsideHOC {...props}>{children}</ClickOutsideHOC>
  ) : (
    <View>{children}</View>
  );
};

ClickOutside.propTypes = {
  children: PropTypes.node.isRequired,
  onClickOutside: PropTypes.func.isRequired,
};

export default ClickOutside;
