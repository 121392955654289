import { StyleSheet } from 'react-native';

import { theme } from '../../../constants';

export const styles = StyleSheet.create({
  iconFilter: {
    borderRadius: 30,
    marginRight: theme.spaces.large - 6,
    padding: 6,
  },
  iconFilterActive: {
    backgroundColor: theme.colors.primary.default,
  },
});
