import { StyleSheet } from 'react-native';

import { IS_DESKTOP_SCREEN, IS_WEB, theme } from '../../constants';

const inputBg = 'rgba(255, 255, 255, 0.15)';

export const styles = StyleSheet.create({
  closeIcon: {
    bottom: IS_WEB ? 4 : 6,
    opacity: 0.7,
    position: 'absolute',
    right: 14,
  },
  iconMobile: {},
  iconOpen: {
    bottom: IS_WEB ? 3 : 5,
    left: 14,
    position: 'absolute',
  },
  iconWeb: {
    bottom: IS_WEB ? 3 : 5,
    left: 14,
    opacity: 0.7,
    position: 'absolute',
  },
  input: {
    backgroundColor: inputBg,
    borderRadius: 3,
    color: theme.colors.white,
    paddingLeft: 46,
    paddingVertical: 8,
  },
  inputMobile: {
    display: 'none',
  },
  inputOpen: {
    display: 'flex',
    paddingRight: IS_DESKTOP_SCREEN ? theme.spaces.default : 46,
  },
  wrapper: {
    marginHorizontal: theme.spaces.large,
  },
  wrapperOpen: {
    flex: 1,
  },
});
