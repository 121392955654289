import { useField } from 'formik';
import { ChevronDownIcon } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { IS_DESKTOP_SCREEN, IS_MOBILE_SCREEN } from '../../constants';
import { theme as appTheme } from '../../theme';
import { FieldWrapper } from '../FieldWrapper/FieldWrapper';
import { SelectWithSearchMobile } from './SelectWithSearchMobile';

export const SelectWithSearch = ({
  name,
  options,
  searchValue,
  setSearchValue,
  isLoading = false,
  label,
  onChange,
  initialOption,
  onMenuScrollToBottom,
  onMenuOpen,
  placeholderIntlId = 'app.enterValue',
  width = IS_MOBILE_SCREEN ? '100%' : '50%',
  mb,
  pr = '12px',
  pl = '12px',
  isMulti = false,
  isCreateble = false,
  handleCreateOption,
}) => {
  const intl = useIntl();

  const [field, meta, helper] = useField(name);

  const hasError = Boolean(meta.error && meta.touched);

  const fontFamily =
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif';

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: 'white',
      borderRadius: 0,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      paddingRight: '8px',
      borderBottomColor: hasError
        ? 'red'
        : state?.isFocused
        ? 'none'
        : appTheme.colors.dark,
      borderBottomWidth: '1px',
      minHeight: '33px',
      height: '33px',
      maxHeight: '33px',
      width: '100%',
      boxShadow: 'none',
      textAlign: 'left',
    }),
    container: (provided) => ({
      ...provided,
      minWidth: '100%',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 8px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '20',
      paddingLeft: '1px',
      fontFamily: fontFamily,
      fontSize: 14,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    placeholder: (provided) => ({
      ...provided,
      paddingLeft: '1px',
      fontSize: 14,
      fontFamily: fontFamily,
      color: '#a3a3a3',
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: 0,
      paddingLeft: '1px',
      fontFamily: fontFamily,
      backgroundColor: 'white',
      textAlign: 'left',
      fontSize: 14,
    }),
    menu: (provided) => ({
      ...provided,
      textAlign: 'left',
      width: '100%',
      borderRadius: 0,
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      padding: 0,
      margin: 0,
    }),
    option: (provided) => ({
      ...provided,
      position: 'relative',
      padding: '7px 5px 7px 8px',
      fontFamily: fontFamily,
      backgroundColor: 'white',
      textAlign: 'left',
      fontSize: 13,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: appTheme.colors.text.default,
      ':hover': {
        cursor: 'pointer',
        backgroundColor: appTheme.colors.gray.light,
      },
      ':active': {
        textDecorationLine: 'none',
        backgroundColor: appTheme.colors.gray.light,
      },
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontFamily: fontFamily,
      backgroundColor: 'white',
      fontSize: 13,
      textOverflow: 'ellipsis',
    }),
    loadingMessage: (provided) => ({
      ...provided,
      fontFamily: fontFamily,
      backgroundColor: 'white',
      fontSize: 13,
      textOverflow: 'ellipsis',
    }),
    multiValue: (provided) => ({
      ...provided,
      margin: 0,
      paddingLeft: '1px',
      fontFamily: fontFamily,
      backgroundColor: 'white',
      textAlign: 'left',
      fontSize: 16,
    }),
  };

  const onValueChange = async (option) => {
    if (!isCreateble) await helper.setValue(option?.value || option);
    if (typeof onChange === 'function') {
      await onChange(option);
    }
  };

  const selectedOption =
    options?.find((option) => option?.value === field?.value) || initialOption;

  const placeholderText = intl.formatMessage({ id: placeholderIntlId });

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ChevronDownIcon size={4} />
      </components.DropdownIndicator>
    );
  };

  return (
    <FieldWrapper
      isInvalid={hasError}
      label={label}
      errorMessageText={meta.error}
      mb={mb}
      pl={pl}
      pr={pr}
      width={width}
    >
      {IS_DESKTOP_SCREEN ? (
        isCreateble ? (
          <CreatableSelect
            isMulti={isMulti}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            onMenuOpen={onMenuOpen}
            components={{ DropdownIndicator }}
            styles={customStyles}
            onMenuScrollToBottom={onMenuScrollToBottom}
            isLoading={isLoading}
            isClearable
            backspaceRemovesValue
            onInputChange={setSearchValue}
            name={name}
            value={selectedOption || null}
            onCreateOption={handleCreateOption}
            onChange={onValueChange}
            options={isLoading ? [] : options}
            loadingMessage={() =>
              intl.formatMessage({
                id: 'app.loading',
              })
            }
            noOptionsMessage={() =>
              intl.formatMessage({
                id: 'app.noData',
              })
            }
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              spacing: {
                controlHeight: 50,
                baseUnit: 2,
                menuGutter: 5,
              },
              colors: {
                ...theme.colors,
                primary: appTheme.colors.primary.default,
                neutral30: appTheme.colors.primary.default,
              },
            })}
            placeholder={placeholderText}
          />
        ) : (
          <Select
            isMulti={isMulti}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            onMenuOpen={onMenuOpen}
            components={{ DropdownIndicator }}
            styles={customStyles}
            onMenuScrollToBottom={onMenuScrollToBottom}
            isLoading={isLoading}
            isClearable
            backspaceRemovesValue
            onInputChange={setSearchValue}
            name={name}
            value={selectedOption || null}
            onChange={onValueChange}
            options={isLoading ? [] : options}
            loadingMessage={() =>
              intl.formatMessage({
                id: 'app.loading',
              })
            }
            noOptionsMessage={() =>
              intl.formatMessage({
                id: 'app.noData',
              })
            }
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              spacing: {
                controlHeight: 50,
                baseUnit: 2,
                menuGutter: 5,
              },
              colors: {
                ...theme.colors,
                primary: appTheme.colors.primary.default,
                neutral30: appTheme.colors.primary.default,
              },
            })}
            placeholder={placeholderText}
          />
        )
      ) : (
        <SelectWithSearchMobile
          searchValue={searchValue}
          label={label}
          onSelectScreenOpen={onMenuOpen}
          placeholderText={placeholderText}
          onValueChange={onValueChange}
          selectedOption={selectedOption}
          setSearchValue={setSearchValue}
          options={options}
          name={name}
        />
      )}
    </FieldWrapper>
  );
};

SelectWithSearch.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  placeholderIntlId: PropTypes.string,
  initialOption: PropTypes.object,
  label: PropTypes.string,
  onMenuOpen: PropTypes.func,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  onMenuScrollToBottom: PropTypes.func,
  onChange: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isMulti: PropTypes.bool,
  isCreateble: PropTypes.bool,
  handleCreateOption: PropTypes.func,
};
