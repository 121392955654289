import { Image } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { API_HOST } from '../../constants';

export const ImageView = ({ uri, size = '40px', ...props }) => {
  return (
    <Image
      source={uri ? `${API_HOST}/${uri}` : require('../../assets/thumbnail.png')}
      width={size}
      height={size}
      resizeMode='contain'
      alt='crashed image'
      {...props}
    />
  );
};

ImageView.propTypes = {
  uri: PropTypes.string,
  size: PropTypes.string,
};
