import { Formik } from 'formik';
import { Box } from 'native-base';
import React, { useMemo } from 'react';

import {
  BoxWrapper,
  Checkbox,
  FieldRow,
  FormRow,
  FormWrapper,
  Layout,
  RequestHandler,
  TextField,
  Typography,
} from '../../../components';
import { IS_MOBILE_SCREEN } from '../../../constants';
import {
  useCreatePurchaseSources,
  useGetPurchaseSourceQuery,
  useParams,
} from '../../../hooks';
import { purchaseSourceSchema } from '../../../services/validation';

const SourcesOrder = () => {
  const { id } = useParams();

  const { data: purchaseSourceData, ...queryProps } = useGetPurchaseSourceQuery(id);

  const [createPurchaseSourcesMutation, { loading }] = useCreatePurchaseSources();

  const initialValues = useMemo(() => {
    return {
      name: purchaseSourceData?.purchaseSource?.name || '',
      link: purchaseSourceData?.purchaseSource?.link || '',
      isInternational: purchaseSourceData?.purchaseSource?.isInternational || false,
    };
  }, [purchaseSourceData]);

  const handleFormSubmit = (values) => {
    createPurchaseSourcesMutation({
      variables: {
        input: {
          ...(id && { id: Number(id) }),
          name: values.name,
          link: values.link,
          isInternational: values.isInternational,
        },
      },
    });
  };

  return (
    <RequestHandler {...queryProps}>
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={purchaseSourceSchema}
        >
          {({ handleSubmit, setFieldValue, values }) => (
            <Layout
              onActionButtonPress={handleSubmit}
              actionButtonType='save'
              actionButtonIsLoading={loading}
            >
              <FormRow>
                <FormRow.Left>
                  <BoxWrapper>
                    <Typography intlId='app.sourcesorder' variant='heading' />
                    <FieldRow withNegativeBottomMargin={false}>
                      <TextField name='name' label='app.name' />
                      <TextField name='link' label='app.link' />
                    </FieldRow>
                    <FieldRow>
                      <Box ml={2} pb={3}>
                        <Checkbox
                          mt={!IS_MOBILE_SCREEN ? '16px' : 0}
                          justify={!IS_MOBILE_SCREEN ? 'flex-start' : 'center'}
                          label={'app.international'}
                          onChange={() =>
                            setFieldValue('isInternational', !values.isInternational)
                          }
                          checked={values.isInternational}
                        />
                      </Box>
                    </FieldRow>
                  </BoxWrapper>
                </FormRow.Left>
              </FormRow>
            </Layout>
          )}
        </Formik>
      </FormWrapper>
    </RequestHandler>
  );
};

export default SourcesOrder;
