import { FieldArray, Formik } from 'formik';
import React from 'react';

import {
  BoxWrapper,
  FieldRow,
  FormRow,
  FormWrapper,
  Layout,
  RequestHandler,
  Typography,
} from '../../../components';
import { useCustomerMutation, useCustomerQuery } from '../../../hooks';
import { customerSchema } from '../../../services/validation';
import { getFormattedDate } from '../../../utils';
import { CustomerChildren } from './CustomerChildren';
import { CustomerContact } from './CustomerContact';
import { CustomerInfo } from './CustomerInfo';
import { CustomerOrders } from './CustomerOrders';

const Customer = () => {
  const { data, ...queryProps } = useCustomerQuery();

  const [mutation, { loading: mutationLoading }] = useCustomerMutation();

  const onSubmit = (values) => {
    const inputValues = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      middleName: values?.middleName,
      birthday: values?.birthday,
      sex: values?.sex,
      phones: [
        ...new Set(values?.contacts?.filter((contact) => contact.match(/^\d{12}$/))),
      ],
      emails: [
        ...new Set(
          values?.contacts?.filter((contact) =>
            contact.match(/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/),
          ),
        ),
      ],
      children: values?.children,
    };

    if (values?.id) inputValues.id = values?.id;

    mutation({
      variables: {
        input: inputValues,
      },
    });
  };

  const customer = data?.customer;
  const contacts = customer?.phones
    ?.map((phone) => phone?.phone)
    .concat(customer?.emails?.map((email) => email?.email)) || [''];

  const initialValues = {
    id: Number(customer?.id) || '',
    firstName: customer?.firstName || '',
    lastName: customer?.lastName || '',
    middleName: customer?.middleName || '',
    birthday: data?.customer?.birthday
      ? getFormattedDate(data?.customer?.birthday, 'yyyy-MM-dd')
      : '',
    sex: customer?.sex || '-1',
    children: formattedChildren(customer?.children),
    contacts: contacts,
    contactsQuantity: contacts?.length + 1 || 1,
  };

  return (
    <RequestHandler {...queryProps}>
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={customerSchema}
        >
          {({ values, handleSubmit }) => (
            <Layout
              onActionButtonPress={handleSubmit}
              actionButtonIsLoading={mutationLoading}
              actionButtonType='save'
            >
              <FormRow>
                <FormRow.Left>
                  <CustomerInfo />
                </FormRow.Left>
                <FormRow.Right>
                  <BoxWrapper>
                    <Typography intlId='customer.contacts' variant='heading' />
                    <RequestHandler loading={false}>
                      <FieldArray
                        name={'contacts'}
                        render={(arrayHelpers) => (
                          <FieldRow>
                            {[
                              ...values.contacts,
                              ...(values.contacts?.[0] === '' ? [] : [{}]),
                            ].map((_, index) => {
                              return (
                                <CustomerContact
                                  removeContact={arrayHelpers.remove}
                                  key={index}
                                  index={index}
                                  name={`contacts.${index}`}
                                />
                              );
                            })}
                          </FieldRow>
                        )}
                      />
                    </RequestHandler>
                  </BoxWrapper>
                  <CustomerChildren data={data} />
                </FormRow.Right>
              </FormRow>
              <CustomerOrders data={data} />
            </Layout>
          )}
        </Formik>
      </FormWrapper>
    </RequestHandler>
  );
};

function formattedChildren(children) {
  if (!children?.length) return [];
  return children.map((child) => {
    return {
      id: Number(child.id),
      name: child.name,
      sex: child.sex,
      birthday: getFormattedDate(child.birthday, 'yyyy-MM-dd') || '',
      relationType: child.relationType ? child.relationType.id : '',
    };
  });
}

export default Customer;
