import { Box, Spinner } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { useHasScopesQuery } from '../../hooks';

export const HasScopesHandler = ({ scopesArray, children }) => {
  const { data, ...queryProps } = useHasScopesQuery(scopesArray);

  if (!scopesArray) {
    return children;
  }

  if (queryProps?.loading) {
    return (
      <Box paddingY='lg'>
        <Spinner size='sm' />
      </Box>
    );
  }

  if (data?.hasScopes?.find((el) => el?.isAllowed === false)) {
    return null;
  }

  return children;
};

HasScopesHandler.propTypes = {
  scopesArray: PropTypes.array,
  children: PropTypes.node,
};
