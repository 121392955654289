import { StyleSheet } from 'react-native';

import { IS_DESKTOP_SCREEN, STATUS_BAR_HEIGHT } from '../../constants';

export const styles = StyleSheet.create({
  inner: {
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: IS_DESKTOP_SCREEN ? 124 : 80,
    paddingHorizontal: 15,
    paddingVertical: 20,
    position: 'relative',
  },
  wrapper: {
    marginTop: STATUS_BAR_HEIGHT,
  },
});
