/* eslint-disable react-native/no-raw-text */
import { Flex } from 'native-base';
import React from 'react';
import { StyleSheet } from 'react-native';

import {
  AccountWithAmount,
  AccountWithAmountHeader,
  DesktopTable,
  FormattedDate,
  Layout,
  MobileTable,
  RequestHandler,
  Typography,
} from '../../../components';
import { IS_DESKTOP_SCREEN } from '../../../constants';
import { useGetExchangesQuery, useHistory } from '../../../hooks';
import ExchangeMobileItem from './ExchangeMobileItem/ExchangeMobileItem';
import { ExchangesFilters } from './ExchangesFilters';

const Exchanges = () => {
  const navigation = useHistory();
  const { data, ...queryProps } = useGetExchangesQuery();

  const handleClickAddButton = () => {
    navigation.push('/bookkeeping/exchange/');
  };

  const handleEditButtonClick = (item) => {
    navigation.push(`/bookkeeping/exchange/${item?.id}`);
  };

  const getRatio = (exchange) => {
    return `${Number(exchange.fromAmount / exchange.toAmount).toFixed(5)} / ${Number(
      exchange.toAmount / exchange.fromAmount,
    ).toFixed(5)}`;
  };

  return (
    <Layout
      isScrollable={false}
      onActionButtonPress={handleClickAddButton}
      filters={<ExchangesFilters />}
    >
      <RequestHandler {...queryProps}>
        {IS_DESKTOP_SCREEN ? (
          <DesktopTable>
            <DesktopTable.Head>
              <DesktopTable.Title intlId={'app.date'} sortId={'date'} />
              <DesktopTable.Title textAlign={'right'} width={'350px'}>
                <AccountWithAmountHeader accountTitle={'app.originalCheck'} />
              </DesktopTable.Title>
              <DesktopTable.Title textAlign={'right'} width={'350px'}>
                <AccountWithAmountHeader accountTitle={'app.targetCheck'} />
              </DesktopTable.Title>
              <DesktopTable.Title
                intlId={'app.ratio&rate'}
                textAlign={'right'}
                width={'400px'}
                style={styles.ratioCell}
              />
              <DesktopTable.Title intlId={'app.comment'} />
            </DesktopTable.Head>
            <DesktopTable.Body>
              {data?.exchanges?.map((item, index) => (
                <DesktopTable.Row
                  key={index}
                  onEditButtonClick={() => handleEditButtonClick(item)}
                >
                  <DesktopTable.Cell>
                    <FormattedDate fontWeight='700' date={new Date(item?.date)} />
                  </DesktopTable.Cell>
                  <DesktopTable.Cell
                    style={styles.cell}
                    textAlign={'right'}
                    width={'350px'}
                  >
                    <AccountWithAmount
                      accountTitle={item?.fromBankAccount?.title}
                      amount={`${item?.fromAmount} ${item?.fromBankAccount?.currency?.symbol}`}
                    />
                  </DesktopTable.Cell>
                  <DesktopTable.Cell
                    style={styles.cell}
                    textAlign={'right'}
                    width={'350px'}
                  >
                    <AccountWithAmount
                      accountTitle={item?.toBankAccount?.title}
                      amount={`${item?.toAmount} ${item?.toBankAccount?.currency?.symbol}`}
                    />
                  </DesktopTable.Cell>
                  <DesktopTable.Cell
                    style={styles.ratioCell}
                    textAlign={'right'}
                    width={'400px'}
                  >
                    {getRatio(item)}
                  </DesktopTable.Cell>
                  <DesktopTable.Cell maxWidth='350px' width={'350px'}>
                    <Flex>
                      <Typography
                        intlId={item.comment ? undefined : 'app.noData'}
                        isTruncated
                        color={'#828282'}
                      >
                        {item.comment}
                      </Typography>
                    </Flex>
                  </DesktopTable.Cell>
                </DesktopTable.Row>
              ))}
            </DesktopTable.Body>
          </DesktopTable>
        ) : (
          <MobileTable
            data={data?.exchanges}
            renderItem={(item) => (
              <ExchangeMobileItem
                onItemPress={() => handleEditButtonClick(item)}
                date={<FormattedDate date={new Date(item.date)} />}
                ratio={getRatio(item)}
                fromAmount={`${item.fromAmount} ${item.fromBankAccount?.currency?.symbol}`}
                toAmount={`${item.toAmount} ${item.toBankAccount?.currency?.symbol}`}
              />
            )}
          />
        )}
      </RequestHandler>
    </Layout>
  );
};

const styles = StyleSheet.create({
  cell: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  ratioCell: {
    paddingRight: '150px',
  },
});

export default Exchanges;
