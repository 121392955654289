import { useField } from 'formik';
import { Box, Flex } from 'native-base';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { IS_DESKTOP_SCREEN } from '../../constants';
import { useSearchContext } from '../../context';
import { ConditionNumber } from '../../screens/sales/Order/Properties';
import { getDateWithNumber } from '../../utils/getDateWithNumber';
import {
  AddModal,
  Button,
  DesktopTable,
  ImageView,
  ModalButton,
  ProductCard,
  TextLink,
  Typography,
} from '../index';

export const AddAvailabilityModal = ({
  onAddProduct,
  buttonText = 'app.select',
  addedAvailabilities = [],
  hasScopes = false,
}) => {
  const intl = useIntl();
  const [field] = useField('fromPlace');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isButtonOnFocus, setIsButtonOnFocus] = useState(false);

  const { searchValue, changeSearchValue } = useSearchContext();

  const availabilities = field?.value?.availabilities?.filter((availabilitiy) =>
    availabilitiy?.purchase?.product?.name?.match(searchValue),
  );

  const addedAvailabilitiesIds = addedAvailabilities?.map((el) => el?.id);

  const isAvailabilityAdded = (id) => addedAvailabilitiesIds?.includes(id);

  const onAddAvailabilityHandler = (product) => {
    onAddProduct(product);
  };

  const handlerButtonPress = () => {
    availabilities && setIsModalOpen(true);
  };

  return (
    <Box>
      <Flex justifyContent={'flex-end'}>
        <Button
          onFocus={() => setIsButtonOnFocus(true)}
          onBlur={() => setIsButtonOnFocus(false)}
          intlId={buttonText}
          onPress={handlerButtonPress}
          minWidth={'120px'}
          size='sm'
        />
      </Flex>
      {isButtonOnFocus && !availabilities && (
        <Typography
          width={200}
          ml={'-63'}
          mt={10}
          position={'absolute'}
          fontSize='sm'
          intlId={'app.chooseBox'}
          color={'error'}
        />
      )}
      <AddModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        searchValue={searchValue}
        onChangeSearchValue={changeSearchValue}
        title='app.addPurchase'
      >
        {IS_DESKTOP_SCREEN ? (
          <DesktopTable editable={false} inModal>
            <DesktopTable.Title width={50} />
            <DesktopTable.Title intlId={'app.title'} textAlign={'left'} />
            <DesktopTable.Title intlId={'app.purchase'} />
            <DesktopTable.Title intlId={'app.condition'} />
            <DesktopTable.Title width={10} />
            <DesktopTable.Title width={80} />
            <DesktopTable.Body>
              {availabilities?.map((item, index) => (
                <DesktopTable.Row key={index}>
                  <DesktopTable.Cell width={50}>
                    <ImageView
                      uri={
                        item?.purchase?.product?.photo?.formats.find(
                          (el) => el.format === 'small',
                        ).pathWithTime
                      }
                    />
                  </DesktopTable.Cell>
                  <DesktopTable.Cell textAlign={'left'}>
                    <Box>
                      <TextLink
                        link={`/warehouse/product/${item.id}`}
                        intlId={item?.purchase?.product?.name}
                      />
                      <Typography fontSize='xs'>
                        {item?.purchase?.product?.sku}
                      </Typography>
                    </Box>
                  </DesktopTable.Cell>
                  <DesktopTable.Cell>
                    <Flex>
                      {hasScopes ? (
                        <TextLink
                          fontSize='xs'
                          link={`/purchase/purchase/${item?.purchase?.id}`}
                        >
                          {getDateWithNumber(item?.purchase, intl)}
                        </TextLink>
                      ) : (
                        <Typography fontSize='xs'>
                          {getDateWithNumber(item?.purchase, intl)}
                        </Typography>
                      )}
                    </Flex>
                  </DesktopTable.Cell>
                  <DesktopTable.Cell>
                    <ConditionNumber condition={availabilities[index].condition} />
                  </DesktopTable.Cell>
                  <DesktopTable.Cell>
                    <Typography />
                  </DesktopTable.Cell>
                  <ModalButton
                    addedItemsIds={addedAvailabilitiesIds}
                    handleSet={onAddAvailabilityHandler}
                    item={item}
                  />
                </DesktopTable.Row>
              ))}
            </DesktopTable.Body>
          </DesktopTable>
        ) : (
          <>
            {availabilities?.map((item, index) => (
              <ProductCard
                key={index}
                product={item}
                isProductAdded={isAvailabilityAdded(item?.id)}
                onPress={onAddAvailabilityHandler}
                isAnnul={true}
                hasScopes={hasScopes}
              />
            ))}
          </>
        )}
      </AddModal>
    </Box>
  );
};

AddAvailabilityModal.propTypes = {
  buttonText: PropTypes.string,
  onAddProduct: PropTypes.func.isRequired,
  addedAvailabilities: PropTypes.array.isRequired,
  isMultiSelect: PropTypes.bool,
  withQuantity: PropTypes.bool,
  withPrice: PropTypes.bool,
  inOrder: PropTypes.bool,
  source: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currencyRatio: PropTypes.object,
  hasScopes: PropTypes.boolean,
  getDateWithNumber: PropTypes.func,
};
