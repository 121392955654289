import { useEffect, useRef, useState } from 'react';

export function useFetchMore(fetchMore = null, rootMargin = '0px') {
  const [element, setElement] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const loader = useRef(fetchMore);

  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        if (fetchMore) {
          const first = entries[0];
          if (first.isIntersecting) {
            setIsLoading(true);
            loader.current().then(() => setIsLoading(false));
          }
        }
      },
      { threshold: 1, rootMargin },
    ),
  );

  useEffect(() => {
    loader.current = fetchMore;
  }, [fetchMore]);

  useEffect(() => {
    const currentElement = element;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [element]);

  return [setElement, isLoading];
}
