import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const StatisticsIcon = ({ size = 24, style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' style={{ ...style }}>
      <Path
        d='M6 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V12C2 11.7348 2.10536 11.4804 2.29289 11.2929C2.48043 11.1054 2.73478 11 3 11H6C6.26522 11 6.51957 11.1054 6.70711 11.2929C6.89464 11.4804 7 11.7348 7 12V20C7 20.2652 6.89464 20.5196 6.70711 20.7071C6.51957 20.8946 6.26522 21 6 21ZM13 21H10C9.73478 21 9.48043 20.8946 9.29289 20.7071C9.10536 20.5196 9 20.2652 9 20V3C9 2.73478 9.10536 2.48043 9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2H13C13.2652 2 13.5196 2.10536 13.7071 2.29289C13.8946 2.48043 14 2.73478 14 3V20C14 20.2652 13.8946 20.5196 13.7071 20.7071C13.5196 20.8946 13.2652 21 13 21ZM20 21H17C16.7348 21 16.4804 20.8946 16.2929 20.7071C16.1054 20.5196 16 20.2652 16 20V9C16 8.73478 16.1054 8.48043 16.2929 8.29289C16.4804 8.10536 16.7348 8 17 8H20C20.2652 8 20.5196 8.10536 20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21Z'
        fill='white'
      />
    </Svg>
  );
};

StatisticsIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
