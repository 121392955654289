import { Box, Flex, Text } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { TouchableOpacity } from 'react-native';

import {
  DeliveredIcon,
  ShippingOnUkraineIcon,
  ShippingToUkraineIcon,
} from '../../../../components/Icons';
import { WeightIcon } from '../../../../components/Icons/WeightIcon';
import { FORWARDING_STATUS_IDS } from '../../../../constants';
import { useIntl } from '../../../../hooks';
import { formatDateTime } from '../../../../utils';

const ForwardingMobileItem = ({
  trackingCode,
  quantity,
  forwardingTime,
  deliveryPrice,
  productWeight,
  statusId,
  onItemPress,
  id,
}) => {
  const intl = useIntl();

  const renderIcon = () => {
    switch (statusId) {
      case FORWARDING_STATUS_IDS.onTheWay:
        return <ShippingOnUkraineIcon />;
      case FORWARDING_STATUS_IDS.inWarehouse:
        return <DeliveredIcon />;
      default:
        return <ShippingToUkraineIcon />;
    }
  };

  return (
    <TouchableOpacity onPress={() => onItemPress(id)}>
      <Box
        borderBottomWidth='1px'
        borderColor='gray.light'
        backgroundColor='white'
        borderRadius={'5px'}
        px={'md'}
        py={'18px'}
        mb={'8px'}
      >
        <Flex wrap={'wrap'} direction={'row'} align={'center'}>
          <Box mr={'sm'}>{renderIcon()}</Box>
          <Box>
            <Text color={'gray.darkness'} fontSize={15} fontFamily={'medium'} mb={'4px'}>
              {trackingCode}
            </Text>
            <Text>{`${quantity} ${intl.formatMessage({
              id: 'app.pcs',
            })}${
              forwardingTime
                ? ` ${intl.formatMessage({
                    id: 'app.from',
                  })} ${formatDateTime(forwardingTime, 'dd.MM.yyyy')}`
                : ''
            }`}</Text>
          </Box>
          <Flex ml={'auto'} alignItems={'flex-end'} direction={'column'}>
            <Text mb={'4px'} fontSize={16} color={'pink'}>
              {`${deliveryPrice} $`}
            </Text>
            <Flex align={'center'} direction={'row'}>
              <Box mr={'4px'}>
                <WeightIcon />
              </Box>
              <Text fontSize={15}>{productWeight}</Text>
              <Flex direction={'row'} align={'center'} mt={'3px'}>
                <Text mx={'1px'} fontSize={11} color={'gray.default'}>
                  ({quantity})
                </Text>
                <Text fontSize={15}>
                  {intl.formatMessage({
                    id: 'app.kg',
                  })}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </TouchableOpacity>
  );
};

export default memo(ForwardingMobileItem);

ForwardingMobileItem.propTypes = {
  trackingCode: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  forwardingTime: PropTypes.string.isRequired,
  deliveryPrice: PropTypes.string.isRequired,
  productWeight: PropTypes.number,
  statusId: PropTypes.string,
  onItemPress: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};
