import React, { useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import RNDateTimePicker from '@react-native-community/datetimepicker';
import { HelperText } from 'react-native-paper';
import { format } from 'date-fns';
import styles from './styles';
import stylesGlobal from '../../../globalStyles';
import { useMediaQuery } from '../../../hooks';
import { isWeb, isIos } from '../../../constants';

const getFormattedDate = (date) => {
  const parseDate = new Date(date);
  return format(parseDate, 'yyyy-MM-dd');
};

const DatePicker = ({
  name,
  value,
  label,
  setFieldValue,
  error,
  appWidth = '100%',
  desktopWidth = '100%',
}) => {
  const isDesktop = useMediaQuery('isDesktop');
  const [show, setShow] = useState(false);
  const onChangeHandler = (value, selectedDate) => {
    setShow(isIos);
    if (selectedDate) {
      setDate(getFormattedDate(value));
    }

    setFieldValue(name, getFormattedDate(value));
  };
  const formattedDate = getFormattedDate(value);

  return (
    <View style={[styles.field, { isDesktop: isDesktop ? appWidth : desktopWidth }]}>
      {label ? <Text style={stylesGlobal.label}>{label}</Text> : null}
      {isWeb ? (
        <input
          style={{
            paddingBottom: 8,
            paddingRight: 5,
            paddingLeft: 5,
            borderBottomWidth: 1,
            borderTopWidth: 0,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderBottomColor: '#333333',
            fontStyle: 'normal',
            fontFamily: 'Roboto-Regular, sans-serif',
            color: '#4F4F4F',
            fontSize: 14,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            outline: 'none',
          }}
          type='date'
          onChange={(event) => onChangeHandler(event.target.value)}
          data-date-format='DD MMMM YYYY'
          value={formattedDate}
        />
      ) : (
        <>
          <TouchableOpacity
            style={[styles.datePicker, error && styles.hasError]}
            activeOpacity={1}
            onPress={() => setShow(true)}
          >
            <Text>{formattedDate}</Text>
          </TouchableOpacity>
          {show && (
            <RNDateTimePicker
              name={name}
              value={date ? new Date(date) : null}
              onChange={onChangeHandler}
              defaultValue={null}
              mode='date'
              display='default'
              locale='ru-RU'
            />
          )}
          <HelperText type='error' visible={error} style={styles.error}>
            {error}
          </HelperText>
        </>
      )}
    </View>
  );
};

export default DatePicker;
