import { MaterialIcons } from '@expo/vector-icons';
import { differenceInYears, parse } from 'date-fns';
import { FieldArray, useFormikContext } from 'formik';
import { Box, Center, Flex, Icon } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import {
  BoxWrapper,
  Button,
  DatePicker,
  Select,
  TextField,
  Typography,
} from '../../../components';
import { IS_LAPTOP_SCREEN, IS_MOBILE_SCREEN } from '../../../constants';
import { useIntl, useSexOptions } from '../../../hooks';
import { transformSelectOptions } from '../../../utils';
import { getCurrentLocale } from '../../../utils/getCurrentLocale';

export const CustomerChildren = ({ data }) => {
  const intl = useIntl();

  const { values } = useFormikContext();

  const sexOptions = useSexOptions();

  const currentLocale = getCurrentLocale();

  const isSmallScreen = IS_LAPTOP_SCREEN || IS_MOBILE_SCREEN;

  const columnWidth = isSmallScreen ? '100%' : `${100 / 4}%`;

  function getCurrentAge(date) {
    if (!date) return '';

    const parsedDate = parse(date, 'yyyy-MM-dd', new Date());

    const age = differenceInYears(new Date(), parsedDate);

    const endOfAge = (n, arr) =>
      n +
      ' ' +
      arr[n % 100 > 4 && n % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][n % 10 < 5 ? n % 10 : 5]];

    const arr1 = [
      `${intl.formatMessage({ id: 'app.year' })}`,
      `${intl.formatMessage({ id: 'app.yearsRu' })}`,
      `${intl.formatMessage({ id: 'app.years' })}`,
    ];

    const endOfAgeEng = (n) => {
      if (n <= 1) return n + ' ' + `${intl.formatMessage({ id: 'app.year' })}`;
      else return n + ' ' + `${intl.formatMessage({ id: 'app.years' })}`;
    };

    if (currentLocale === 'ru') return endOfAge(age, arr1);
    else return endOfAgeEng(age);
  }

  return (
    <BoxWrapper>
      <Typography intlId='app.children' variant='heading' />
      {isSmallScreen ? null : (
        <Flex align='flex-start' mb='md'>
          <Typography intlId='customer.name' width={isSmallScreen ? '100%' : '25%'} />
          <Typography intlId='app.sex' width={isSmallScreen ? '100%' : '18%'} />
          <Typography intlId='app.birthday' width={isSmallScreen ? '100%' : '39%'} />
          <Typography intlId='app.relation' width={isSmallScreen ? '100%' : '18%'} />
        </Flex>
      )}
      <FieldArray
        name='children'
        render={({ push, remove }) => (
          <>
            {values.children.map((child, index) => (
              <Box key={index}>
                {isSmallScreen && (
                  <TextField
                    width={columnWidth}
                    name={`children[${index}].name`}
                    label={isSmallScreen ? 'customer.name' : ''}
                    pr={{ base: '0', lg: 'sm' }}
                  />
                )}
                <Flex
                  key={index}
                  align={'flex-start'}
                  direction={{ base: 'column', lg: 'row' }}
                >
                  {!isSmallScreen && (
                    <TextField
                      width={isSmallScreen ? '100%' : '25%'}
                      name={`children[${index}].name`}
                      label={isSmallScreen ? 'customer.name' : ''}
                      pr={{ base: '0', lg: 'sm' }}
                    />
                  )}
                  <Select
                    name={`children[${index}].sex`}
                    label={isSmallScreen ? 'app.sex' : ''}
                    options={sexOptions}
                    width={isSmallScreen ? '100%' : '18%'}
                    pr={{ base: '0', lg: 'sm' }}
                  />
                  <DatePicker
                    name={`children[${index}].birthday`}
                    label={isSmallScreen ? 'app.birthday' : ''}
                    width={isSmallScreen ? '100%' : '27%'}
                    pr={{ base: '0', lg: 'sm' }}
                  />
                  <Typography
                    width={isSmallScreen ? '100%' : '12%'}
                    mt={isSmallScreen ? 0 : '12px'}
                    pr={{ base: '0', lg: 'sm' }}
                  >
                    {getCurrentAge(child?.birthday)}
                  </Typography>

                  <Select
                    name={`children[${index}].relationType`}
                    label={isSmallScreen ? 'app.relation' : ''}
                    options={transformSelectOptions({ data: data?.relationTypes })}
                    width={isSmallScreen ? '100%' : '18%'}
                    pr={{ base: '0', lg: 'md' }}
                  />
                  {!isSmallScreen && (
                    <Center>
                      <Icon
                        position={isSmallScreen ? 'relative' : 'absolute'}
                        top={isSmallScreen ? 0 : '18px'}
                        as={<MaterialIcons name='delete' />}
                        size='20px'
                        color='gray.dark'
                        onPress={() => {
                          remove(index);
                        }}
                      />
                    </Center>
                  )}
                </Flex>
                {isSmallScreen && (
                  <Flex paddingY={3} align={'center'} justify={'center'}>
                    <Icon
                      size={'sm'}
                      color={'gray.dark'}
                      as={<MaterialIcons name={'delete'} />}
                      onPress={() => {
                        remove(index);
                      }}
                    />
                  </Flex>
                )}
              </Box>
            ))}
            <Flex align='flex-start' justify='flex-end'>
              <Button
                onPress={() => {
                  push({
                    name: '',
                    sex: '-1',
                    birthday: '',
                    relationType: '1',
                  });
                }}
                intlId='app.add'
              />
            </Flex>
          </>
        )}
      />
    </BoxWrapper>
  );
};

CustomerChildren.propTypes = {
  data: PropTypes.object,
};
