import { Formik } from 'formik';
import React, { useMemo } from 'react';

import {
  BoxWrapper,
  FieldRow,
  FormRow,
  FormWrapper,
  Layout,
  RequestHandler,
  TextField,
  Typography,
} from '../../../components';
import {
  useCreateShippingType,
  useDeleteShippingType,
  useGetShippingTypeQuery,
  useParams,
} from '../../../hooks';
import { shippingTypeSchema } from '../../../services/validation';

const DeliveryMethod = () => {
  const { id } = useParams();

  const { data, ...queryProps } = useGetShippingTypeQuery(id);

  const [createShippingTypeMutation, { loading }] = useCreateShippingType();
  const [deleteShippingTypeMutation, { loading: deleteShippingTypeLoading }] =
    useDeleteShippingType();

  const initialValues = useMemo(() => {
    return {
      name: data?.shippingType?.name || '',
    };
  }, [data]);

  const handleFormSubmit = (values) => {
    createShippingTypeMutation({
      variables: {
        input: {
          ...(id && { id: Number(id) }),
          name: values.name,
        },
      },
    });
  };

  const handleDeleteShippingType = () => {
    deleteShippingTypeMutation({
      variables: {
        id: Number(id),
      },
    });
  };

  return (
    <RequestHandler {...queryProps}>
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={shippingTypeSchema}
        >
          {({ handleSubmit }) => (
            <Layout
              onActionButtonPress={handleSubmit}
              actionButtonType='save'
              actionButtonIsLoading={loading || deleteShippingTypeLoading}
              onRemoveButtonPress={handleDeleteShippingType}
            >
              <FormRow>
                <FormRow.Left>
                  <BoxWrapper>
                    <Typography intlId='app.shippingType' variant='heading' />
                    <FieldRow withNegativeBottomMargin={false}>
                      <TextField name='name' label='app.name' />
                    </FieldRow>
                  </BoxWrapper>
                </FormRow.Left>
              </FormRow>
            </Layout>
          )}
        </Formik>
      </FormWrapper>
    </RequestHandler>
  );
};

export default DeliveryMethod;
