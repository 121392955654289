import React from 'react';

import {
  BoxWrapper,
  DesktopTable,
  Layout,
  MobileTable,
  RequestHandler,
  Typography,
} from '../../../components';
import { DATA_REQUEST_LIMIT, IS_DESKTOP_SCREEN } from '../../../constants';
import { useGetPurchaseForwardingsQuery, useHistory } from '../../../hooks';
import {
  formatDateTime,
  getProductsTotalQuantity,
  getProductsVolumeWeight,
  getProductsWeight,
} from '../../../utils';
import ForwardingMobileItem from './ForwardingMobileItem/ForwardingMobileItem';

const Forwardings = () => {
  const navigation = useHistory();

  const { data, fetchMore, ...queryProps } = useGetPurchaseForwardingsQuery();

  const fetchMoreData = async (resolve, reject) => {
    await fetchMore({
      variables: {
        offset: data?.purchaseForwardings?.rows?.length,
        limit: DATA_REQUEST_LIMIT,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          reject && reject();
          return prev;
        }

        resolve && resolve();

        return {
          purchaseForwardings: {
            ...fetchMoreResult?.purchaseForwardings,
            rows: [
              ...data?.purchaseForwardings?.rows,
              ...fetchMoreResult?.purchaseForwardings?.rows,
            ],
          },
        };
      },
    });
  };

  const handleEditButtonClick = (id) => {
    navigation.push(`/purchase/forwarding/${id}`);
  };

  const handleClickAddButton = () => {
    navigation.push('/purchase/forwarding/');
  };

  return (
    <Layout isScrollable={false} onActionButtonPress={handleClickAddButton}>
      <RequestHandler {...queryProps}>
        {IS_DESKTOP_SCREEN ? (
          <BoxWrapper mb={0}>
            <Typography intlId='app.listForwarding' variant='heading' mb='xs' />
            <DesktopTable fetchMoreData={fetchMoreData}>
              <DesktopTable.Head>
                <DesktopTable.Title intlId='app.date' />
                <DesktopTable.Title intlId='app.code' />
                <DesktopTable.Title intlId='app.priceDeliveryUSD' />
                <DesktopTable.Title intlId='app.quantity' />
                <DesktopTable.Title intlId='app.fixWeight' />
                <DesktopTable.Title intlId='app.weightVolume' />
                <DesktopTable.Title intlId='app.status' />
              </DesktopTable.Head>
              <DesktopTable.Body>
                {data?.purchaseForwardings?.rows?.map((item, index) => {
                  return (
                    <DesktopTable.Row
                      key={index}
                      onEditButtonClick={() => handleEditButtonClick(item?.id)}
                    >
                      <DesktopTable.Cell>
                        {formatDateTime(item?.date, 'dd.MM.yyyy')}
                      </DesktopTable.Cell>
                      <DesktopTable.Cell>{item?.trackingCode}</DesktopTable.Cell>
                      <DesktopTable.Cell>{item?.deliveryPrice}</DesktopTable.Cell>
                      <DesktopTable.Cell>
                        {getProductsTotalQuantity(item?.purchases)}
                      </DesktopTable.Cell>
                      <DesktopTable.Cell>
                        {getProductsWeight(item?.purchases)}
                      </DesktopTable.Cell>
                      <DesktopTable.Cell>
                        {getProductsVolumeWeight(item?.purchases)}
                      </DesktopTable.Cell>
                      <DesktopTable.Cell>{item?.status?.name}</DesktopTable.Cell>
                    </DesktopTable.Row>
                  );
                })}
              </DesktopTable.Body>
            </DesktopTable>
          </BoxWrapper>
        ) : (
          <>
            <Typography
              ml={'md'}
              mb='lg'
              color='dark'
              fontSize='lg'
              intlId='app.listForwarding'
            />
            <MobileTable
              data={data?.purchaseForwardings?.rows}
              fetchMoreData={fetchMoreData}
              renderItem={(item) => (
                <ForwardingMobileItem
                  onItemPress={handleEditButtonClick}
                  statusId={item?.status?.id}
                  quantity={getProductsTotalQuantity(item?.purchases)}
                  trackingCode={item?.trackingCode}
                  productWeight={getProductsWeight(item?.purchases)}
                  forwardingTime={item?.date}
                  deliveryPrice={item?.deliveryPrice}
                  id={item?.id}
                />
              )}
            />
          </>
        )}
      </RequestHandler>
    </Layout>
  );
};

export default Forwardings;
