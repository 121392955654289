import { endOfDay, startOfDay } from 'date-fns';
import { Formik } from 'formik';
import { Box, Flex } from 'native-base';
import React from 'react';

import { Button, DatePicker, RequestHandler, Select } from '../../../components';
import { IS_MOBILE_SCREEN } from '../../../constants';
import { useFiltersContext } from '../../../context';
import {
  useFilter,
  useGetBankAccountsForField,
  useGetCurrenciesQuery,
} from '../../../hooks';
import { transformSelectOptions } from '../../../utils';

export const ExchangesFilters = () => {
  const { filters, setFilters, clearFilters } = useFilter();

  const { data: bankAccounts, ...bankAccountsQueryProps } = useGetBankAccountsForField();
  const { data: currencies, ...currenciesQueryProps } = useGetCurrenciesQuery();

  const { toggleFilter } = useFiltersContext();

  const handleFormSubmit = (values) => {
    setFilters({
      dateFrom: values?.dateFrom ? startOfDay(new Date(values?.dateFrom)) : '',
      dateTo: values?.dateTo ? endOfDay(new Date(values?.dateTo)) : '',
      fromBankAccountId: values.fromBankAccountId,
      toBankAccountId: values.toBankAccountId,
      fromCurrencyId: values.fromCurrencyId,
      toCurrencyId: values.toCurrencyId,
    });

    if (IS_MOBILE_SCREEN) {
      toggleFilter();
    }
  };

  const handleResetForm = (setFieldValue) => {
    setFieldValue('dateFrom', '');
    setFieldValue('dateTo', '');
    setFieldValue('fromBankAccountId', '');
    setFieldValue('toBankAccountId', '');
    setFieldValue('fromCurrencyId', '');
    setFieldValue('toCurrencyId', '');
    clearFilters();
  };

  const loading = currenciesQueryProps.loading || bankAccountsQueryProps.loading;
  const error = currenciesQueryProps.error || bankAccountsQueryProps.error;
  const initialValues = {
    dateFrom: filters?.dateFrom || '',
    dateTo: filters?.dateTo || '',
    fromBankAccountId: filters?.fromBankAccountId?.toString() || '',
    toBankAccountId: filters?.toBankAccountId?.toString() || '',
    fromCurrencyId: filters?.fromCurrencyId?.toString() || '',
    toCurrencyId: filters?.toCurrencyId?.toString() || '',
  };

  return (
    <RequestHandler error={error} loading={loading}>
      <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
        {({ handleSubmit, handleReset, setFieldValue }) => (
          <>
            <Flex>
              <Box flexShrink={1} mr={'12px'}>
                <DatePicker name='dateFrom' label='date.from' />
              </Box>
              <Box flexShrink={1} ml={'12px'}>
                <DatePicker name='dateTo' label='date.to' />
              </Box>
            </Flex>
            <Flex>
              <Box flexShrink={1} mr={'12px'}>
                <Select
                  name='fromCurrencyId'
                  label='app.sourceCurrency'
                  options={transformSelectOptions({
                    data: currencies?.currencies,
                    label: 'name',
                  })}
                />
              </Box>
              <Box flexShrink={1} ml={'12px'}>
                <Select
                  name='toCurrencyId'
                  label='app.targetCurrency'
                  options={transformSelectOptions({
                    data: currencies?.currencies,
                    label: 'name',
                  })}
                />
              </Box>
            </Flex>
            <Select
              name='fromBankAccountId'
              label='app.originalCheck'
              options={transformSelectOptions({
                data: bankAccounts?.bankAccounts,
                label: 'title',
              })}
            />
            <Select
              name='toBankAccountId'
              label='app.targetCheck'
              options={transformSelectOptions({
                data: bankAccounts?.bankAccounts,
                label: 'title',
              })}
            />
            <Flex justify='space-between'>
              <Button
                onPress={() => {
                  handleReset();
                  handleResetForm(setFieldValue);
                }}
                variant='secondary'
                intlId='app.clear'
              />
              <Button onPress={handleSubmit} intlId='app.apply' />
            </Flex>
          </>
        )}
      </Formik>
    </RequestHandler>
  );
};
