import { Flex } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { BoxWrapper, Typography } from '../../../../../components';

const CrmOrderStatusMobileItem = ({ id, name, reservationType }) => {
  return (
    <BoxWrapper>
      <Flex mb='md' justify='space-between'>
        <Typography intlId='crm.id' color='gray.main' />
        <Typography>{id}</Typography>
      </Flex>

      <Flex mb='md' justify='space-between'>
        <Typography intlId='app.name' color='gray.main' />
        <Typography>{name}</Typography>
      </Flex>
      <Flex mb='md' justify='space-between'>
        <Typography intlId='crm.reservationType' color='gray.main' />
        <Typography>{reservationType}</Typography>
      </Flex>
    </BoxWrapper>
  );
};

export default memo(CrmOrderStatusMobileItem);

CrmOrderStatusMobileItem.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  reservationType: PropTypes.string.isRequired,
};
