import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const LineIcon = (props) => {
  return (
    <Svg width={16} height={16} viewBox='0 0 16 16' fill='currentColor' {...props}>
      <Path
        d='M7.83325 8.83335V7.16669H8.16659V8.83335H7.83325Z'
        fill='#828282'
        stroke='#828282'
      />
    </Svg>
  );
};
