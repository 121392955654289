import React from 'react';
import { useIntl } from 'react-intl';

import {
  BoxWrapper,
  DesktopTable,
  Layout,
  MobileTable,
  RequestHandler,
  Typography,
} from '../../../components';
import { DATA_REQUEST_LIMIT, IS_DESKTOP_SCREEN } from '../../../constants';
import { useGetPurchasesQuery, useHistory } from '../../../hooks';
import { formatDateTime, getStatus } from '../../../utils';
import PurchasesMobileItem from './PurchasesMobileItem/PurchasesMobileItem';

const Purchases = () => {
  const intl = useIntl();
  const navigation = useHistory();

  const { data, fetchMore, ...queryProps } = useGetPurchasesQuery();

  const fetchMoreData = async (resolve, reject) => {
    await fetchMore({
      variables: {
        offset: data?.purchases?.length,
        limit: DATA_REQUEST_LIMIT,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          reject && reject();
          return prev;
        }

        resolve && resolve();

        return {
          purchases: [...prev?.purchases, ...fetchMoreResult?.purchases],
        };
      },
    });
  };

  const handleClickAddButton = () => {
    navigation.push('/purchase/purchase/');
  };

  const handleEditButtonClick = (item) => {
    navigation.push(`/purchase/purchase/${item?.id}`);
  };

  return (
    <Layout isScrollable={false} onActionButtonPress={handleClickAddButton}>
      <RequestHandler {...queryProps}>
        {IS_DESKTOP_SCREEN ? (
          <BoxWrapper mb={0}>
            <Typography intlId='app.listPurchase' variant='heading' mb='xs' />

            <DesktopTable fetchMoreData={fetchMoreData}>
              <DesktopTable.Head>
                <DesktopTable.Title intlId='app.nameProduct' />
                <DesktopTable.Title intlId='app.number' />
                <DesktopTable.Title intlId='app.date' />
                <DesktopTable.Title intlId='app.status' />
                <DesktopTable.Title intlId='app.parcel' />
                <DesktopTable.Title intlId='app.forwarding' />
                <DesktopTable.Title intlId='app.cost' />
                <DesktopTable.Title intlId='app.deliveryCost' />
                <DesktopTable.Title intlId='app.quantity' />
              </DesktopTable.Head>
              <DesktopTable.Body>
                {data?.purchases?.map((item, index) => (
                  <DesktopTable.Row
                    key={index}
                    onEditButtonClick={() => handleEditButtonClick(item)}
                  >
                    <DesktopTable.Cell color='primary.default'>
                      {item?.product?.name || ''}
                    </DesktopTable.Cell>
                    <DesktopTable.Cell>{item?.id}</DesktopTable.Cell>
                    <DesktopTable.Cell>
                      {formatDateTime(+item?.purchaseTime, 'dd.MM.yyyy')}
                    </DesktopTable.Cell>
                    <DesktopTable.Cell>{getStatus(item, intl)}</DesktopTable.Cell>
                    <DesktopTable.Cell>{item?.parcel?.trackingCode}</DesktopTable.Cell>
                    <DesktopTable.Cell>
                      {item?.forwarding?.trackingCode}
                    </DesktopTable.Cell>
                    <DesktopTable.Cell>{item?.price}</DesktopTable.Cell>
                    <DesktopTable.Cell>{item?.deliveryPrice}</DesktopTable.Cell>
                    <DesktopTable.Cell>{item?.quantity}</DesktopTable.Cell>
                  </DesktopTable.Row>
                ))}
              </DesktopTable.Body>
            </DesktopTable>
          </BoxWrapper>
        ) : (
          <>
            <Typography mb='lg' color='dark' fontSize='lg' intlId='app.listPurchase' />
            <MobileTable
              data={data?.purchases}
              fetchMoreData={fetchMoreData}
              renderItem={(item) => (
                <PurchasesMobileItem
                  onItemPress={() => handleEditButtonClick(item)}
                  id={item?.id}
                  name={item?.product?.name}
                  date={item?.purchaseTime}
                  source={item?.source?.name}
                  parcel={item?.parcel?.trackingCode}
                  forwarding={item?.forwarding?.trackingCode}
                  price={item?.price}
                  deliveryPrice={item?.deliveryPrice}
                  quantity={item?.quantity}
                />
              )}
            />
          </>
        )}
      </RequestHandler>
    </Layout>
  );
};

export default Purchases;
