import { Flex } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import {
  BoxWrapper,
  DesktopTable,
  MobileTable,
  Price,
  TextLink,
  Typography,
} from '../../../../components';
import { IS_DESKTOP_SCREEN } from '../../../../constants';
import { getFormattedDate } from '../../../../utils';
import BoxAvailabilityMobileItem from './BoxAvailabilityMobileItem';

const COLUMNS = [
  { name: 'app.title', id: 'name' },
  { name: 'app.purchase', id: 'date' },
  { name: 'app.condition', id: 'status' },
  { name: 'app.deliveryCost', id: 'price' },
  { name: 'app.costPrice', id: 'defaultPrice' },
  { name: 'app.quantity', id: 'quantity' },
];

export const BoxAvailability = ({ availabilities, withPrice = false }) => {
  const intl = useIntl();

  const getDateWithNumber = (purchase) => {
    return `№${purchase.id} ${
      purchase.purchaseTime ? intl.formatMessage({ id: 'app.from' }) : ''
    } ${getFormattedDate(purchase.purchaseTime)}`;
  };

  const currentColumns = withPrice
    ? COLUMNS
    : COLUMNS.filter((el) => el.id !== 'price' && el.id !== 'defaultPrice');

  if (IS_DESKTOP_SCREEN) {
    return (
      <BoxWrapper>
        <Typography intlId='app.availability' variant='heading' mb='10px' />
        <DesktopTable editable={false}>
          <DesktopTable.Head>
            {currentColumns.map((column) => (
              <DesktopTable.Title key={column.id} intlId={column.name} />
            ))}
          </DesktopTable.Head>
          <DesktopTable.Body>
            {availabilities?.map((item, index) => (
              <DesktopTable.Row key={index}>
                <DesktopTable.Cell>
                  <TextLink link={`/warehouse/product/${item?.purchase?.product?.id}`}>
                    {item?.purchase?.product?.name}
                  </TextLink>
                </DesktopTable.Cell>
                <DesktopTable.Cell>
                  {withPrice ? (
                    <TextLink link={`/purchase/purchase/${item?.purchase?.id}`}>
                      {getDateWithNumber(item?.purchase)}
                    </TextLink>
                  ) : (
                    <Typography>{getDateWithNumber(item?.purchase)}</Typography>
                  )}
                </DesktopTable.Cell>
                <DesktopTable.Cell>{item?.condition?.name}</DesktopTable.Cell>
                {withPrice ? (
                  <DesktopTable.Cell>
                    <Price value={item?.purchase?.deliveryPrice} />
                  </DesktopTable.Cell>
                ) : null}
                {withPrice ? (
                  <DesktopTable.Cell>
                    <Price value={item?.purchase?.price} />
                  </DesktopTable.Cell>
                ) : null}
                <DesktopTable.Cell>
                  {withPrice && item?.reservedQuantity ? (
                    <>
                      <Flex alignItems={'flex-end'}>
                        <Typography>{`${item?.quantity}/ `}</Typography>
                        <Flex direction={'column'} alignItems={'flex-start'}>
                          <Typography fontSize={'xs'} color={'danger'}>
                            {intl.formatMessage({ id: 'app.reservation' })}
                          </Typography>
                          <Typography color={'danger'} fontWeight={'600'}>
                            {`+ ${item?.reservedQuantity}`}
                          </Typography>
                        </Flex>
                      </Flex>
                    </>
                  ) : (
                    <Typography>{item?.quantity}</Typography>
                  )}
                </DesktopTable.Cell>
              </DesktopTable.Row>
            ))}
          </DesktopTable.Body>
        </DesktopTable>
      </BoxWrapper>
    );
  }

  return (
    <>
      <Typography intlId='app.availability' variant='heading' mb='10px' />
      <MobileTable
        autoHeight
        data={availabilities}
        renderItem={(item) => (
          <BoxAvailabilityMobileItem
            dateWithNumber={() => getDateWithNumber(item?.purchase)}
            withPrice={withPrice}
            name={item?.purchase?.product?.name}
            purchaseId={item?.purchase?.id}
            condition={item?.condition?.name}
            deliveryPrice={<Price value={item?.purchase?.deliveryPrice} />}
            price={<Price value={item?.purchase?.price} />}
            quantity={item?.quantity}
            reservedQuantity={item?.reservedQuantity}
          />
        )}
      />
    </>
  );
};

BoxAvailability.propTypes = {
  availabilities: PropTypes.array,
  withPrice: PropTypes.bool,
};
