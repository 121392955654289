export const getProductsAmount = (
  amount,
  transactionType,
  originalSymbol,
  { currencySymbol, originalCurrencyRate = 1, targetCurrencyRate = 1 },
) => {
  if (originalCurrencyRate === targetCurrencyRate) {
    originalCurrencyRate = 1;
    targetCurrencyRate = 1;
  }

  if (!amount) return;

  let sign;
  switch (transactionType) {
    case 'income':
      sign = '+';
      break;
    case 'spend':
      sign = '-';
      break;
    default:
      sign = '';
  }

  switch (originalSymbol) {
    case '₴': {
      return currencySymbol === '$'
        ? `${sign}${(amount * originalCurrencyRate * targetCurrencyRate)
            .toFixed(0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ${currencySymbol}`
        : `${sign}${((amount * originalCurrencyRate) / targetCurrencyRate)
            .toFixed(0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ${currencySymbol}`;
    }

    case '£':
      return currencySymbol === '$'
        ? `${sign}${(amount * originalCurrencyRate * targetCurrencyRate)
            .toFixed(0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ${currencySymbol}`
        : `${sign}${((amount * originalCurrencyRate) / targetCurrencyRate)
            .toFixed(0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ${currencySymbol}`;

    default:
      return `${sign}${((amount * originalCurrencyRate) / targetCurrencyRate)
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ${currencySymbol}`;
  }
};
