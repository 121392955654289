import { StyleSheet } from 'react-native';

import { theme } from '../../../constants';

export default StyleSheet.create({
  info: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 10,
  },
  infoIcon: {
    marginRight: 15,
  },
  wrapper: {
    backgroundColor: theme.colors.white,
    borderRadius: 5,
    marginBottom: 8,
    paddingHorizontal: 24,
    paddingVertical: 24,
  },
});
