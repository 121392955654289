import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

const BreadcrumbsContext = createContext(null);

const useBreadcrumbsContext = () => {
  const context = useContext(BreadcrumbsContext);
  if (!context) {
    throw new Error('useAccessContext must be used within a AccessProvider');
  }
  return context;
};

const BreadcrumbsProvider = ({ children }) => {
  const [breadcrumbsValues, setBreadcrumbsValues] = useState([]);

  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbsValues, setBreadcrumbsValues }}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};

BreadcrumbsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { BreadcrumbsProvider, useBreadcrumbsContext };
