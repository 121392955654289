import { Flex } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { MultiSelectOption } from './MultiSelectOption';

export const MultiSelectOptions = ({ value, onRemove, ...props }) => {
  if (!value?.length || !Array.isArray(value)) return null;

  return (
    <Flex mt='-16px' mb='md' ml='-5px' align='flex-start' wrap='wrap' {...props}>
      {value?.map((item) => (
        <MultiSelectOption key={item?.value} item={item} onRemove={onRemove} />
      ))}
    </Flex>
  );
};

MultiSelectOptions.propTypes = {
  value: PropTypes.array,
  onRemove: PropTypes.func,
};
