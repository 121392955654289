import React from 'react';

import { ROUTES } from '../../constants';
import { useParams, useRoute } from '../../hooks';
import { Typography } from '../Typography/Typography';
import { TransactionBreadcrumbs } from './TransactionBreadcrumbs';

export const BreadcrumbsItem = () => {
  const { path, state } = useRoute();
  const params = useParams();

  const currentRoute = ROUTES.getCurrentRouteName(path);
  const paramName = 'id';
  const breadcrumbsParam = state?.breadcrumbsParam;

  if (currentRoute?.name === 'transaction') return <TransactionBreadcrumbs />;

  return (
    <>
      <Typography
        intlId={path !== 'customer' ? currentRoute?.intlId : ''}
        fontSize={{ base: '20px', lg: '2xl' }}
        color='gray.extraLight'
        fontWeight={300}
      />
      {breadcrumbsParam || params?.[paramName] ? (
        <Typography
          fontSize={{ base: '20px', lg: '2xl' }}
          color='gray.extraLight'
          fontWeight={300}
        >
          {`${path !== 'customer' ? ' ' : ''}${breadcrumbsParam || params[paramName]}`}
        </Typography>
      ) : null}
    </>
  );
};
