import { Flex } from 'native-base';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect } from 'react';
import Toast from 'react-native-toast-message';
import { ToastProvider, useToasts } from 'react-toast-notifications';

import { useErrorsStore } from '../client/client';
import { Typography } from '../components';
import { IS_WEB } from '../constants';
import { useIntl } from '../hooks';

const NotificationsContext = createContext();
const useNotificationsContext = () => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error(
      'useNotificationsContext must be used within a NotificationsProvider',
    );
  }
  return context;
};

const NotificationsProvider = ({ children }) => {
  if (IS_WEB) {
    return (
      <ToastProvider autoDismiss autoDismissTimeout={6000} placement='bottom-right'>
        <WebProvider>{children}</WebProvider>
      </ToastProvider>
    );
  }

  return <MobileProvider>{children}</MobileProvider>;
};

const MobileProvider = ({ children }) => {
  const intl = useIntl();

  const { hasError, error, date } = useErrorsStore((state) => state);

  useEffect(() => {
    if (hasError && error?.message) {
      Toast.show({
        type: 'error',
        position: 'bottom',
        visibilityTime: 5000,
        text1: intl.formatMessage({
          id: 'errors.networkError',
        }),
        text2: error?.message,
      });
    }
  }, [error?.message, hasError, intl, date]);

  const handleShowNotification = ({
    title = 'app.successful',
    message = '',
    type = 'success',
  }) => {
    Toast.show({
      type: type,
      position: 'bottom',
      visibilityTime: 5000,
      text1: intl.formatMessage({
        id: title,
      }),
      text2: intl.formatMessage({
        id: message,
      }),
    });
  };

  return (
    <NotificationsContext.Provider
      value={{
        showNotification: handleShowNotification,
      }}
    >
      {children}
      <Toast ref={(ref) => Toast.setRef(ref)} />
    </NotificationsContext.Provider>
  );
};

const WebProvider = ({ children }) => {
  const intl = useIntl();

  const { addToast } = useToasts();

  const { hasError, error, date } = useErrorsStore((state) => state);

  useEffect(() => {
    if (hasError && error?.message) {
      addToast(
        <Flex direction='column' align='flex-start'>
          <Typography bold mb='xs' intlId='errors.networkError' />
          <Typography intlId={error?.message} />
        </Flex>,
        {
          appearance: 'error',
        },
      );
    }
  }, [addToast, error?.message, hasError, date]);

  const handleShowNotification = ({
    title = 'app.successful',
    message = '',
    type = 'success',
  }) => {
    addToast(
      <Flex direction='column' align='flex-start'>
        <Typography bold mb='xs'>
          {intl.formatMessage({
            id: title,
          })}
        </Typography>
        {message ? <Typography intlId={message} /> : null}
      </Flex>,
      {
        appearance: type,
      },
    );
  };

  return (
    <NotificationsContext.Provider
      value={{
        showNotification: handleShowNotification,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
WebProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
MobileProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { NotificationsProvider, useNotificationsContext };
