import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

import { colors } from '../../theme';

export const ShippingToUkraineIcon = ({ size = 40, style = {} }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ ...style }}
    >
      <Circle cx='20' cy='20' r='20' fill={colors.blue.light} />
      <Path
        d='M20.0007 29.1667C14.9379 29.1667 10.834 25.0628 10.834 20C10.834 14.9373 14.9379 10.8334 20.0007 10.8334C25.0634 10.8334 29.1673 14.9373 29.1673 20C29.1673 25.0628 25.0634 29.1667 20.0007 29.1667ZM25.8261 23.6227V23.5265C25.8261 22.6813 25.8261 22.2954 25.2284 21.9535C25.0363 21.8446 24.839 21.7451 24.6372 21.6555C24.3007 21.5025 24.078 21.4025 23.7773 20.9625C23.7414 20.9103 23.706 20.8577 23.671 20.8049C23.3547 20.3236 23.1265 19.9771 22.2575 20.1146C20.5479 20.386 20.4214 20.6866 20.3462 21.1945L20.3343 21.2779C20.2234 22.0204 20.2032 22.2697 20.5131 22.5951C21.6727 23.8115 22.3675 24.6888 22.5783 25.2021C22.681 25.4524 22.945 26.2105 22.7635 26.9603C23.8762 26.5171 24.8666 25.814 25.6519 24.9097C25.7527 24.5669 25.8261 24.1397 25.8261 23.6227ZM20.0007 12.5136C17.8767 12.5136 15.9582 13.3991 14.596 14.82C14.7582 14.9327 14.8994 15.0913 14.9966 15.3095C15.1836 15.7284 15.1836 16.1601 15.1836 16.5424C15.1836 16.843 15.1836 17.129 15.2798 17.3353C15.4118 17.6176 15.982 17.7386 16.4852 17.8431C16.6658 17.8816 16.851 17.9201 17.0197 17.9669C17.4835 18.0952 17.8428 18.5123 18.1297 18.8469C18.2489 18.9853 18.4258 19.1897 18.5147 19.241C18.5606 19.208 18.7082 19.0476 18.7806 18.7845C18.8374 18.5829 18.8209 18.405 18.7393 18.3079C18.226 17.7029 18.2544 16.5387 18.413 16.1088C18.6623 15.4314 19.4415 15.4818 20.0117 15.5185C20.2243 15.5322 20.4242 15.546 20.5745 15.5267C21.1447 15.4552 21.3207 14.5871 21.4444 14.4175C21.7121 14.0509 22.5316 13.4981 23.0394 13.1571C22.0829 12.7318 21.0475 12.5125 20.0007 12.5136Z'
        fill='white'
      />
    </Svg>
  );
};

ShippingToUkraineIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
