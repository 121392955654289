import { useIntl } from './useIntl';

export const useSexOptions = () => {
  const intl = useIntl();

  return [
    {
      value: '-1',
      label: intl.formatMessage({
        id: 'app.no',
      }),
    },
    {
      value: '0',
      label: intl.formatMessage({
        id: 'app.female',
      }),
    },
    {
      value: '1',
      label: intl.formatMessage({
        id: 'app.male',
      }),
    },
  ];
};
