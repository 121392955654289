import { MaterialIcons } from '@expo/vector-icons';
import { Box, Flex, Text } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Button, TextFieldHookFrom, Typography } from '../../../../components';
import { SelectWithSearchHookForm } from '../../../../components/SelectWithSearch/SelectWithSearchHookForm';
import { TextInputHookForm } from '../../../../components/TextField/TextInputHookForm';
import { theme } from '../../../../constants';
import { useDebounce, useGetProductLocales } from '../../../../hooks';
import { transformSelectOptions } from '../../../../utils';
import { styles } from './styles';

const ProductLocales = ({ productId }) => {
  const { watch, setValue, control } = useFormContext();
  const values = watch();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'translates',
  });

  const [localeSearchValue, setLocaleSearchValue] = useState('');
  const [options, setOptions] = useState([]);

  const { debouncedValue: debouncedSearchValue, isDebouncing } =
    useDebounce(localeSearchValue);

  const [getLocales, { data: localesData, loading }] = useGetProductLocales({
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    getLocales({
      variables: {
        search: debouncedSearchValue,
      },
    });
  }, [debouncedSearchValue.length]);

  useEffect(() => {
    if (!localesData?.productLocales) return;
    const options = transformSelectOptions({
      data: localesData?.productLocales,
      value: 'id',
      label: 'title',
    });
    const selectedLocaleIds = values?.translates?.map((translate) =>
      Number(translate.productLocaleId),
    );
    const filteredOptions = options.filter(
      (option) => !selectedLocaleIds?.includes(Number(option.value)),
    );
    setOptions(filteredOptions);
  }, [values?.translates?.length, localesData?.productLocales]);

  const getLocalesHandler = () => {
    !localesData?.productLocales && getLocales();
  };

  const setSearchHandler = (val) => {
    return val ? setLocaleSearchValue(val) : setLocaleSearchValue('');
  };

  const handleAddLocale = () => {
    if (values.locale) {
      const { value, label } = values.locale;
      append({
        description: '',
        name: '',
        productId,
        productLocaleId: value,
        productLocale: {
          id: value,
          title: label,
        },
      });
    }
  };

  const handleChangeLocale = (option) => {
    setValue('locale', option);
  };

  const handleRemoveLocale = (index) => () => {
    remove(index);
  };

  return (
    <>
      <Flex justify='space-between' alignItems='baseline'>
        <Typography intlId='app.titleAndDescription' variant='heading' />
      </Flex>
      <Flex justify='space-between' alignItems='center'>
        <SelectWithSearchHookForm
          width='80%'
          pl={0}
          pr={0}
          setSearchValue={setSearchHandler}
          isLoading={loading || isDebouncing}
          onMenuOpen={getLocalesHandler}
          options={options}
          name={'locale.value'}
          label='app.language'
          onChange={handleChangeLocale}
          isValChange
        />
        <Button
          intlId='app.add'
          onPress={handleAddLocale}
          variant='ghost'
          isDisabled={!values?.locale?.value}
        />
      </Flex>
      {!!fields.length && <Box style={styles.divider}></Box>}
      <Box style={styles.wrapper}>
        {fields.map((field, index) => (
          <Box key={field.id}>
            <Flex justify='space-between' alignItems='center' mb={4}>
              <Text style={styles.text}>{field.productLocale.title}</Text>
              <MaterialIcons
                onPress={handleRemoveLocale(index)}
                name='delete'
                size={25}
                color={theme.colors.primary}
              />
            </Flex>

            <TextFieldHookFrom
              name={`translates.${index}.name`}
              label='app.name'
              type='text'
              width={'100%'}
            />
            <TextInputHookForm
              name={`translates.${index}.description`}
              label='app.specification'
              type='text'
              width={'100%'}
            />
          </Box>
        ))}
      </Box>
    </>
  );
};

export default ProductLocales;

ProductLocales.propTypes = {
  productId: PropTypes.string,
};
