import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';

import { styles } from './styles';

export const Container = ({ children }) => {
  return <View style={styles.wrapper}>{children}</View>;
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
};
