import { Flex } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { IS_DESKTOP_SCREEN } from '../../constants';
import { Typography } from '../Typography/Typography';

export const PurchaseDescription = ({ title, data, ...props }) => {
  return (
    <Flex
      direction='column'
      mr={IS_DESKTOP_SCREEN ? '30px' : '50px'}
      marginTop={'15px'}
      {...props}
    >
      <Typography intlId={title} />
      <Typography intlId={data || 'app.noData'} />
    </Flex>
  );
};

PurchaseDescription.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
};
