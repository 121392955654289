import React from 'react';

import { DesktopTable, Layout, MobileTable, RequestHandler } from '../../../components';
import { IS_DESKTOP_SCREEN } from '../../../constants';
import { useGetShippingTypesQuery, useHistory } from '../../../hooks';
import DeliveryMethodMobileItem from './DeliveryMethodMobileItem';

const COLUMNS = [{ intlId: 'app.name' }];

const DeliveryMethods = () => {
  const navigation = useHistory();
  const { data, ...queryProps } = useGetShippingTypesQuery();

  const handleClickAddButton = () => {
    navigation.push('/settings/deliverymethod/');
  };

  const handleEditButtonClick = (id) => {
    navigation.push(`/settings/deliverymethod/${id}`);
  };

  if (IS_DESKTOP_SCREEN) {
    return (
      <Layout isScrollable={false} onActionButtonPress={handleClickAddButton}>
        <RequestHandler {...queryProps}>
          <DesktopTable>
            <DesktopTable.Head>
              {COLUMNS?.map(({ intlId }) => (
                <DesktopTable.Title key={intlId} intlId={intlId} />
              ))}
            </DesktopTable.Head>
            <DesktopTable.Body>
              {data?.shippingTypes?.map((item) => (
                <DesktopTable.Row
                  key={item?.id}
                  onEditButtonClick={() => handleEditButtonClick(item?.id)}
                >
                  <DesktopTable.Cell>{item?.name}</DesktopTable.Cell>
                </DesktopTable.Row>
              ))}
            </DesktopTable.Body>
          </DesktopTable>
        </RequestHandler>
      </Layout>
    );
  }
  return (
    <Layout isScrollable={false} onActionButtonPress={handleClickAddButton}>
      <RequestHandler {...queryProps}>
        <MobileTable
          data={data?.shippingTypes}
          renderItem={(item) => (
            <DeliveryMethodMobileItem
              onItemPress={handleEditButtonClick}
              id={item.id}
              name={item.name}
            />
          )}
          fetchMoreData={(resolve) => resolve()}
        />
      </RequestHandler>
    </Layout>
  );
};

export default DeliveryMethods;
