import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const NewslettersIcon = ({
  size = 24,
  style = {},
  color1 = '#FF9F43',
  color2 = '#FECA57',
}) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 40 40' style={{ ...style }}>
      <Path
        d='M33.3333 6.66675H6.66659C4.83325 6.66675 3.34992 8.16675 3.34992 10.0001L3.33325 30.0001C3.33325 31.8334 4.83325 33.3334 6.66659 33.3334H33.3333C35.1666 33.3334 36.6666 31.8334 36.6666 30.0001V10.0001C36.6666 8.16675 35.1666 6.66675 33.3333 6.66675ZM33.3333 13.3334L19.9999 21.6667L6.66659 13.3334V10.0001L19.9999 18.3334L33.3333 10.0001V13.3334Z'
        fill={color1}
      />
      <Path
        d='M33.3333 6.66675H6.66659C4.83326 6.66675 3.34992 8.16675 3.34992 10.0001L3.34888 11.2559L19.9999 21.6667L36.6666 11.26V10.0001C36.6666 8.16675 35.1666 6.66675 33.3333 6.66675Z'
        fill={color2}
      />
    </Svg>
  );
};

NewslettersIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
  color1: PropTypes.string,
  color2: PropTypes.string,
};
