import { ROUTES } from '../constants';
import {
  Account,
  Currencies,
  Currency,
  Exchange,
  Exchanges,
  Reports,
  Transaction,
  Transactions,
} from '../screens/bookkeeping';
import {
  AddDocumentationSections,
  DocumentationSections,
} from '../screens/documentation';
import { Login } from '../screens/login/Login';
import { Magazine, Messages, NewslettersList, Segments } from '../screens/newsletters';
import {
  AddForwarding,
  Forwarding,
  Forwardings,
  Parcel,
  Parcels,
  Purchase,
  Purchases,
} from '../screens/purchase';
import {
  Appeals,
  Comments,
  Customer,
  Customers,
  Order,
  Orders,
  SalesHome,
} from '../screens/sales';
import {
  BoxesArrangement,
  DeliveryMethods,
  OrdersStatuses,
  PackagingTemplate,
  PackagingTemplates,
  PaymentMethods,
  SettingsMain,
  SourcesOrders,
  User,
  Users,
} from '../screens/settings';
import CustomProductParam from '../screens/settings/CustomProductParam';
import CustomProductParams from '../screens/settings/CustomProductParams/CustomProductParams';
import DeliveryMethod from '../screens/settings/DeliveryMethod/DeliveryMethod';
import AlloOrderStatus from '../screens/settings/OrderStatuses/AlloOrder/AlloOrderStatus/AlloOrderStatus';
import AlloOrderStatuses from '../screens/settings/OrderStatuses/AlloOrder/AlloOrderStatuses/AlloOrderStatuses';
import CrmOrderStatuses from '../screens/settings/OrderStatuses/CrmOrder/CrmOrderStatuses';
import FlexibleshopOrderStatus from '../screens/settings/OrderStatuses/FlexibleshopOrder/FlexibleShopOrderStatus/FlexibleShopOrderStatus';
import FlexibleshopOrderStatuses from '../screens/settings/OrderStatuses/FlexibleshopOrder/FlexibleShopOrderStatuses/FlexibleShopOrderStatuses';
import PromOrderStatus from '../screens/settings/OrderStatuses/PromOrder/PromOrderStatus';
import PromOrderStatuses from '../screens/settings/OrderStatuses/PromOrder/PromOrderStatuses/PromOrderStatuses';
import RozetkaOrderStatus from '../screens/settings/OrderStatuses/RozetkaOrder/RozetkaOrderStatus/RozetkaOrderStatus';
import RozetkaOrderStatuses from '../screens/settings/OrderStatuses/RozetkaOrder/RozetkaOrderStatuses/RozetkaOrderStatuses';
import StrapiOrderStatus from '../screens/settings/OrderStatuses/StrapiOrder/StrapiOrderStatus/StrapiOrderStatus';
import StrapiOrderStatuses from '../screens/settings/OrderStatuses/StrapiOrder/StrapiOrderStatuses/StrapiOrderStatuses';
import PaymentMethod from '../screens/settings/PaymentMethod';
import ServiceSettings from '../screens/settings/ServiceSettings';
import SourcesOrder from '../screens/settings/SourcesOrder';
import { OrdersStatistic, SavedReports, StatisticHome } from '../screens/statistic';
import {
  Acceptance,
  Annul,
  Box,
  Boxes,
  Displacements,
  HomeWarehouse,
  Packaging,
  Product,
  Products,
} from '../screens/warehouse';

export const routes = [
  {
    path: ROUTES.login.path,
    component: Login,
    isPrivate: false,
  },

  {
    path: '/sales/home',
    component: SalesHome,
  },

  {
    path: ROUTES.orders.path,
    component: Orders,
    scopes: ['Order:Read'],
  },

  {
    path: '/sales/order/:id?',
    component: Order,
    scopes: ['Order:Read', 'Order:Write', 'Product:Read', 'Currency:Read'],
  },

  {
    path: '/sales/appeals',
    component: Appeals,
  },

  {
    path: '/sales/comments',
    component: Comments,
  },

  {
    path: '/sales/customers',
    component: Customers,
    scopes: ['Customer:Read'],
  },

  {
    path: '/sales/customer/:id?',
    component: Customer,
    scopes: ['Customer:Read', 'Customer:Write'],
  },

  {
    path: '/bookkeeping/accounts',
    component: Reports,
    scopes: ['Report:Read', 'Currency:Read'],
  },

  {
    path: '/bookkeeping/account/:id?',
    component: Account,
    scopes: ['BankAccount:Read', 'BankAccount:Write', 'Currency:Read'],
  },

  {
    path: '/bookkeeping/currencies',
    component: Currencies,
    scopes: ['Currency:Read'],
  },

  {
    path: '/bookkeeping/currency/:id?',
    component: Currency,
    scopes: ['Currency:Read', 'Currency:Write'],
  },

  {
    path: '/bookkeeping/transactions',
    component: Transactions,
    scopes: ['Transaction:Read', 'Currency:Read'],
  },

  {
    path: '/bookkeeping/transaction/:id?',
    component: Transaction,
    scopes: [
      'Transaction:Read',
      'Transaction:Write',
      'Currency:Read',
      'Order:Read',
      'Purchase:Read',
      'User:Read',
    ],
  },

  {
    path: '/bookkeeping/exchanges',
    component: Exchanges,
    scopes: ['Exchange:Read'],
  },

  {
    path: '/bookkeeping/exchange/:id?',
    component: Exchange,
    scopes: ['Exchange:Read', 'Exchange:Write'],
  },

  {
    path: '/warehouse/home',
    component: HomeWarehouse,
  },

  {
    path: '/warehouse/products',
    component: Products,
    scopes: ['Product:Read', 'Currency:Read'],
  },

  {
    path: '/warehouse/product/:id?',
    component: Product,
    scopes: ['Product:Read', 'Product:Write', 'Currency:Read'],
  },

  {
    path: '/warehouse/boxes',
    component: Boxes,
    scopes: ['Stock:Read'],
  },

  {
    path: '/warehouse/box/:id?',
    component: Box,
    scopes: ['Stock:Read', 'Stock:Write', 'Availability:Read'],
  },

  {
    path: '/warehouse/acceptance',
    component: Acceptance,
    scopes: [
      'Stock:Read',
      'Stock:Write',
      'Availability:Write',
      'Condition:Read',
      'Product:Read',
      'Purchase:Read',
    ],
  },

  {
    path: '/warehouse/displacements',
    component: Displacements,
    scopes: ['Stock:Read', 'Availability:Write', 'Condition:Read', 'Product:Read'],
  },

  {
    path: '/warehouse/annul',
    component: Annul,
    scopes: ['Stock:Read', 'Availability:Write', 'Condition:Read', 'Product:Read'],
  },

  {
    path: '/warehouse/packaging',
    component: Packaging,
  },

  {
    path: '/statistic/home',
    component: StatisticHome,
  },

  {
    path: '/statistic/ordersstatistic',
    component: OrdersStatistic,
  },

  {
    path: '/statistic/savedreports',
    component: SavedReports,
  },

  {
    path: '/purchase/purchases',
    component: Purchases,
    scopes: ['Purchase:Read'],
  },

  {
    path: '/purchase/purchase/:id?',
    component: Purchase,
    scopes: ['Purchase:Read', 'Purchase:Write', 'Product:Read'],
  },

  {
    path: '/purchase/parcels',
    component: Parcels,
    scopes: ['Purchase:Read'],
  },

  {
    path: '/purchase/parcel/:id?',
    component: Parcel,
    scopes: ['Purchase:Read', 'Purchase:Write', 'Product:Read'],
  },

  {
    path: '/purchase/forwardings',
    component: Forwardings,
    scopes: ['Purchase:Read'],
  },

  {
    path: '/purchase/forwarding/:id?',
    component: Forwarding,
    scopes: ['Purchase:Read', 'Purchase:Write', 'Product:Read'],
  },

  {
    path: '/purchase/addforwarding',
    component: AddForwarding,
    scopes: ['Purchase:Write'],
  },

  {
    path: '/documentation/home',
    component: DocumentationSections,
  },

  {
    path: '/documentation/adddocumentationsection',
    component: AddDocumentationSections,
  },

  {
    path: '/newsletters/home',
    component: NewslettersList,
  },

  {
    path: '/newsletters/messages',
    component: Messages,
  },

  {
    path: '/newsletters/segments',
    component: Segments,
  },

  {
    path: '/newsletters/magazine',
    component: Magazine,
  },

  {
    path: '/settings/home',
    component: SettingsMain,
  },

  {
    path: '/settings/users',
    component: Users,
    scopes: ['User:Read'],
  },

  {
    path: '/settings/user/:id?',
    component: User,
    scopes: ['User:Read', 'User:Write', 'UserRole:Read'],
  },

  {
    path: '/settings/ordersstatuses',
    component: OrdersStatuses,
    scopes: ['Status:Read'],
  },

  {
    path: '/settings/ordersstatuses',
    component: OrdersStatuses,
    scopes: ['Status:Read'],
  },

  {
    path: '/settings/ordersstatuses/promstatuses',
    component: PromOrderStatuses,
    scopes: ['Status:Read'],
  },

  {
    path: '/settings/ordersstatuses/crmstatuses',
    component: CrmOrderStatuses,
    scopes: ['Status:Read'],
  },

  {
    path: '/settings/ordersstatuses/flexibleshopstatuses',
    component: FlexibleshopOrderStatuses,
    scopes: ['Status:Read'],
  },

  {
    path: '/settings/ordersstatuses/rozetkastatuses',
    component: RozetkaOrderStatuses,
    scopes: ['Status:Read'],
  },

  {
    path: '/settings/ordersstatuses/strapistatuses',
    component: StrapiOrderStatuses,
    scopes: ['Status:Read'],
  },

  {
    path: '/settings/ordersstatuses/allostatuses',
    component: AlloOrderStatuses,
    scopes: ['Status:Read'],
  },

  {
    path: '/settings/ordersstatuses/prom/:id?/:statusId?/:add?',
    component: PromOrderStatus,
    scopes: ['Status:Read', 'Status:Write'],
  },

  {
    path: '/settings/orderstatuses/flexibleshop/:id?/:statusId?/:add?',
    component: FlexibleshopOrderStatus,
    scopes: ['Status:Read', 'Status:Write'],
  },

  {
    path: '/settings/ordersstatuses/rozetka/:id?/:statusId?/:add?',
    component: RozetkaOrderStatus,
    scopes: ['Status:Read', 'Status:Write'],
  },

  {
    path: '/settings/ordersstatuses/strapi/:id?/:statusId?/:add?',
    component: StrapiOrderStatus,
    scopes: ['Status:Read', 'Status:Write'],
  },
  {
    path: '/settings/ordersstatuses/allo/:id?/:statusId?/:add?',
    component: AlloOrderStatus,
    scopes: ['Status:Read', 'Status:Write'],
  },

  {
    path: '/settings/paymentmethods',
    component: PaymentMethods,
  },

  {
    path: '/settings/deliverymethods',
    component: DeliveryMethods,
  },

  {
    path: '/settings/sourcesorders',
    component: SourcesOrders,
    scopes: ['User:Read'],
  },

  {
    path: '/settings/sourcesorder/:id?',
    component: SourcesOrder,
    scopes: ['User:Read', 'User:Write', 'UserRole:Read'],
  },

  {
    path: '/settings/boxesarrangement',
    component: BoxesArrangement,
  },

  {
    path: '/settings/packagingtemplates/',
    component: PackagingTemplates,
    scopes: ['PackagingTemplate:Read'],
  },

  {
    path: '/settings/packagingtemplate/:id?',
    component: PackagingTemplate,
    scopes: ['PackagingTemplate:Read', 'PackagingTemplate:Write'],
  },

  {
    path: '/settings/deliverymethod/:id?',
    component: DeliveryMethod,
    scopes: ['User:Read', 'User:Write', 'UserRole:Read'],
  },

  {
    path: '/settings/paymentmethod/:id?',
    component: PaymentMethod,
    scopes: ['User:Read', 'User:Write', 'UserRole:Read'],
  },

  {
    path: '/settings/customparams',
    component: CustomProductParams,
  },

  {
    path: '/settings/customparam/:id?',
    component: CustomProductParam,
  },

  {
    path: '/settings/servicesettings',
    component: ServiceSettings,
  },
];
