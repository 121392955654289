import { MaterialIcons } from '@expo/vector-icons';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { TouchableOpacity, View } from 'react-native';

import { theme } from '../../constants';
import { Typography } from '../Typography/Typography';
import { styles } from './styles';

const OrderMobileItem = ({
  customerName,
  sourse,
  id,
  date,
  paymentStatus,
  managerName,
  amount,
  status,
  onItemPress = () => {},
}) => {
  return (
    <TouchableOpacity onPress={() => onItemPress(id)} style={styles.wrapper}>
      <View style={styles.top}>
        <View style={styles.left}>
          <Icon status={status} />
        </View>
        <View style={styles.center}>
          <Typography marginBottom={8}>{customerName}</Typography>
          <Typography fontSize={12} color={theme.colors.darkGray}>
            {date}
          </Typography>
        </View>
        <View style={styles.right}>
          <Typography fontSize={12} color={theme.colors.darkGray}>
            {`№ ${id}`}
          </Typography>
        </View>
      </View>
      <View style={styles.bottom}>
        <View style={styles.info}>
          <Typography>{managerName}</Typography>
          <Typography>{sourse}</Typography>
        </View>
        <View style={styles.info}>
          <Typography>{paymentStatus}</Typography>
          <Typography>{amount}</Typography>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const Icon = ({ status }) => {
  switch (parseInt(status || 0)) {
    case 1:
      return (
        <View style={[styles.icon, styles.statusReceived]}>
          <MaterialIcons name='shopping-cart' size={20} color='#fff' />
        </View>
      );
    case 2:
      return (
        <View style={[styles.icon, styles.statusDelivered]}>
          <MaterialIcons name='check' size={20} color='#fff' />
        </View>
      );
    case 3:
      return (
        <View style={[styles.icon, styles.statusCanceled]}>
          <MaterialIcons name='clear' size={20} color='#fff' />
        </View>
      );
    case 4:
      return (
        <View style={[styles.icon, styles.statusSent]}>
          <MaterialIcons name='local-shipping' size={20} color='#fff' />
        </View>
      );
    case 5:
      return (
        <View style={[styles.icon, styles.statusDraft]}>
          <MaterialIcons name='remove-shopping-cart' size={20} color='#fff' />
        </View>
      );
    case 6:
      return (
        <View style={[styles.icon, styles.statusPending]}>
          <MaterialIcons name='person-add' size={20} color='#fff' />
        </View>
      );
    case 7:
      return (
        <View style={[styles.icon, styles.statusPaid]}>
          <MaterialIcons name='payment' size={20} color='#fff' />
        </View>
      );
    case 8:
      return (
        <View style={[styles.icon, styles.statusStore]}>
          <MaterialIcons name='local-shipping' size={20} color='#fff' />
        </View>
      );
    case 9:
      return (
        <View style={[styles.icon, styles.statusWaiting]}>
          <MaterialIcons name='payment' size={20} color='#fff' />
        </View>
      );
    default:
      return null;
  }
};

export default memo(OrderMobileItem);

Icon.propTypes = {
  status: PropTypes.object,
};

OrderMobileItem.propTypes = {
  customerName: PropTypes.string.isRequired,
  sourse: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  paymentStatus: PropTypes.string.isRequired,
  managerName: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  status: PropTypes.string,
  onItemPress: PropTypes.func,
};
