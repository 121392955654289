export const select = {
  Select: {
    defaultProps: {
      variant: 'unstyled',
    },
    baseStyle: () => {
      return {
        paddingLeft: '10px',
        paddingRight: '10px',
        bgColor: 'white',
        _disabled: {
          bgColor: 'white',
          color: 'text.default',
          opacity: 1,
        },
        _webSelect: {
          style: {
            appearance: 'none',
            WebkitAppearance: 'none',
            MozAppearance: 'none',
            position: 'absolute',
            width: '100%',
            height: '100%',
            opacity: 0,
            zIndex: 1,
          },
        },
      };
    },
  },
};
