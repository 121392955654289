import { Dimensions, StyleSheet } from 'react-native';

import { colors } from '../../../../../theme';

const width = Dimensions.get('window').width - 48;

export const styles = StyleSheet.create({
  download: {
    backgroundColor: colors.gray.background,
    borderColor: colors.gray.border,
    borderRadius: 3,
    borderStyle: 'solid',
    borderWidth: 1,
    flexDirection: 'column',
    height: width,
    justifyContent: 'center',
    marginBottom: 24,
    width: width,
  },
  image: { alignSelf: 'center', height: width, width: width },
});
