import PropTypes from 'prop-types';
import React from 'react';
import Svg from 'react-native-svg';

export const CalendarIcon = ({ style }) => {
  return (
    <Svg
      width='12'
      height='14'
      viewBox='0 0 12 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <path
        d='M12 12.3333V3C12 2.26467 11.402 1.66667 10.6667 1.66667H9.33333V0.333336H8V1.66667H4V0.333336H2.66667V1.66667H1.33333C0.598 1.66667 0 2.26467 0 3V12.3333C0 13.0687 0.598 13.6667 1.33333 13.6667H10.6667C11.402 13.6667 12 13.0687 12 12.3333ZM4 11H2.66667V9.66667H4V11ZM4 8.33334H2.66667V7H4V8.33334ZM6.66667 11H5.33333V9.66667H6.66667V11ZM6.66667 8.33334H5.33333V7H6.66667V8.33334ZM9.33333 11H8V9.66667H9.33333V11ZM9.33333 8.33334H8V7H9.33333V8.33334ZM10.6667 5H1.33333V3.66667H10.6667V5Z'
        fill='#47C8FF'
      />
    </Svg>
  );
};

CalendarIcon.propTypes = {
  style: PropTypes.object,
};
