import { Formik } from 'formik';
import { Box, Center } from 'native-base';
import React from 'react';
import { useIntl } from 'react-intl';
import { ImageBackground } from 'react-native';

import { Button, TextField, Typography } from '../../components';
import { useLoginQuery } from '../../hooks';
import { loginSchema } from '../../services/validation';
import { styles } from './styles';

export const Login = () => {
  const intl = useIntl();
  const [loginQuery, { loading }] = useLoginQuery();

  const onSubmit = (values) => {
    loginQuery({ variables: values });
  };

  return (
    <ImageBackground
      style={styles.backgroundImage}
      source={require('../../assets/images/login-background.jpg')}
    >
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={onSubmit}
        validationSchema={loginSchema}
      >
        {({ handleSubmit }) => (
          <Center flex={1} width='320px'>
            <Typography
              color='white'
              fontSize='2xl'
              mb='32px'
              fontWeight='400'
              intlId='app.appName'
            />
            <Box width='100%'>
              <TextField
                name='email'
                label='app.email'
                keyboardType='email-address'
                isLight
                width='100%'
                inputProps={{
                  placeholder: intl.formatMessage({ id: 'app.enterEmail' }),
                }}
              />
            </Box>
            <Box width='100%'>
              <TextField
                name='password'
                label='app.password'
                secureTextEntry={true}
                isLight
                width='100%'
                inputProps={{
                  placeholder: intl.formatMessage({ id: 'app.enterPassword' }),
                }}
                inputType='password'
              />
            </Box>
            <Button
              intlId='app.login'
              isLoading={loading}
              onPress={handleSubmit}
              minWidth='170px'
            />
          </Center>
        )}
      </Formik>
    </ImageBackground>
  );
};
