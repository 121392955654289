import { Box } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-native';

import { IS_DESKTOP_SCREEN } from '../../constants';
import { AddModalContent } from './AddModalContent';
import { AddModalFooter } from './AddModalFooter';
import { AddModalHeader } from './AddModalHeader';
import { AddModalInput } from './AddModalInput';

export const AddModal = ({
  title,
  isOpen,
  onClose,
  children,
  searchValue,
  onChangeSearchValue,
  withScan,
}) => {
  if (!isOpen) return null;

  return (
    <Modal
      animationType='none'
      visible={true}
      transparent={IS_DESKTOP_SCREEN}
      onRequestClose={onClose}
    >
      <Box
        flex={1}
        alignItems='center'
        justifyContent='center'
        bgColor={IS_DESKTOP_SCREEN ? 'rgba(0,0,0, 0.4)' : 'gray.light'}
      >
        <Box
          bgColor={IS_DESKTOP_SCREEN ? 'white' : 'gray.light'}
          elevation={5}
          width='100%'
          height='100%'
          maxWidth={{ base: '100%', lg: '770px' }}
          maxHeight={{ base: '100%', lg: '80%' }}
          borderRadius={{ base: 0, lg: '5px' }}
        >
          <AddModalHeader title={title} onClose={onClose} />
          <AddModalInput value={searchValue} onChange={onChangeSearchValue} />
          <AddModalContent>{children}</AddModalContent>
          <AddModalFooter
            withScan={withScan}
            searchValue={searchValue}
            onClose={onClose}
          />
        </Box>
      </Box>
    </Modal>
  );
};

AddModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  withScan: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  searchValue: PropTypes.string,
  onChangeSearchValue: PropTypes.func,
};
