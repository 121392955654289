import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const BookkeepingIcon = ({
  size = 24,
  style = {},
  color1 = '#EE5253',
  color2 = '#3A374B',
}) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 40 40' style={{ ...style }}>
      <Path
        d='M34.2168 11.2634H7.70592C7.38929 11.3246 7.06301 11.3151 6.75047 11.2356C6.43794 11.156 6.14687 11.0083 5.89811 10.8031C5.64936 10.5979 5.44908 10.3403 5.31161 10.0486C5.17414 9.757 5.10289 9.43857 5.10296 9.11618V9.07463L33.1231 7.98354V5.38152C33.1688 4.9933 33.1256 4.59977 32.9969 4.23067C32.8681 3.86156 32.6571 3.52652 32.3799 3.25085C32.1027 2.97518 31.7664 2.76608 31.3965 2.63936C31.0265 2.51263 30.6326 2.47158 30.2445 2.51931L5.36545 6.56227C4.52579 6.87211 3.80113 7.43148 3.28888 8.16517C2.77663 8.89887 2.50134 9.77174 2.5 10.6665L2.5 34.2201C2.5 35.09 2.84568 35.9243 3.461 36.5394C4.07631 37.1544 4.91086 37.5 5.78104 37.5H34.2168C35.087 37.5 35.9215 37.1544 36.5368 36.5394C37.1521 35.9243 37.4978 35.09 37.4978 34.2201V14.5192L37.5 14.4295C37.5 13.5887 37.1658 12.7822 36.571 12.1876C35.9762 11.593 35.1695 11.259 34.3283 11.259L34.2124 11.2612L34.2168 11.2634ZM30.9357 26.5694C30.0652 26.5691 29.2305 26.2231 28.6152 25.6076C27.9999 24.9921 27.6544 24.1575 27.6547 23.2873C27.655 22.4172 28.001 21.5828 28.6168 20.9677C29.2325 20.3526 30.0674 20.0072 30.9379 20.0075C31.3689 20.0076 31.7957 20.0926 32.1938 20.2576C32.592 20.4226 32.9537 20.6644 33.2584 20.9692C33.5631 21.274 33.8047 21.6357 33.9695 22.0339C34.1343 22.432 34.2191 22.8586 34.219 23.2895C34.2188 23.7204 34.1338 24.147 33.9687 24.545C33.8036 24.943 33.5617 25.3046 33.2569 25.6092C32.952 25.9137 32.5901 26.1553 32.1918 26.32C31.7936 26.4848 31.3667 26.5695 30.9357 26.5694Z'
        fill={color1}
      />
      <Path
        opacity='0.7'
        d='M5.10303 9.07463L33.1232 7.98354V5.38152C33.1688 4.9933 33.1257 4.59977 32.9969 4.23067C32.8682 3.86156 32.6572 3.52652 32.38 3.25085C32.1027 2.97518 31.7664 2.76608 31.3965 2.63936C31.0266 2.51263 30.6327 2.47158 30.2446 2.51931L5.36551 6.56227C5.27669 6.59504 5.18916 6.63061 5.10303 6.66888V9.07463Z'
        fill={color2}
      />
    </Svg>
  );
};

BookkeepingIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
  color1: PropTypes.string,
  color2: PropTypes.string,
};
