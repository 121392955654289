/* eslint-disable react-native/no-color-literals */
import { Dimensions, StyleSheet } from 'react-native';

import {
  displayWidth,
  IS_DESKTOP_SCREEN,
  IS_MOBILE_SCREEN,
  theme,
} from '../../../../constants';

const width = Dimensions.get('window').width >= displayWidth;

export default StyleSheet.create({
  arrow: {
    position: 'absolute',
    right: 5,
    top: '50%',
    transform: [{ translateY: 50 }],
  },
  conditionDropdownName: {
    fontFamily: theme.fonts.medium,
    marginLeft: 6,
  },
  conditionDropdownNumber: {
    height: 16,
    marginLeft: 6,
    width: 16,
  },
  conditionNumber: { height: 18, marginRight: 6, width: 18 },
  conditionSelectItem: {
    alignItems: 'center',
    borderRadius: 2,
    height: 12,
    justifyContent: 'center',
    marginLeft: 2,
    width: 12,
  },
  conditionSelectItemText: {
    color: theme.colors.white,
    fontFamily: theme.fonts.medium,
    fontSize: 9,
    lineHeight: 9,
    margin: 0,
  },
  conditionsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    margin: 0,
    width: '13%',
  },
  customerEditButton: {
    borderBottomWidth: 1,
    borderColor: '#47C8FF',
    fontFamily: theme.fonts.light,
    fontSize: 13,
    height: 13,
    justifyContent: 'flex-start',
    marginTop: 8,
  },
  deliveryItem: {
    width: (IS_DESKTOP_SCREEN && '47%') || 0,
  },
  deliveryType: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 25,
    width: '100%',
  },
  departureDate: {
    marginBottom: 17,
  },
  departureDateInput: {
    fontSize: 14,
    height: 17,
    paddingBottom: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 2,
  },
  dropdown: {
    backgroundColor: theme.colors.white,
    borderRadius: 5,
    elevation: 3,
    left: 0,
    marginTop: 6,
    padding: 10,
    position: 'absolute',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    top: 0,
  },
  dropdownItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  fieldArrayContainer: {
    alignItems: width >= displayWidth ? 'flex-end' : 'stretch',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  fields: {
    flexDirection: width ? 'row' : 'column-reverse',
    marginTop: 10,
  },
  halfWidth: {
    width: '50%',
  },
  header: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  itemConditionField: {
    display: 'flex',
    flexDirection: 'row',
    flex: -1,
    height: 28,
    justifyContent: IS_DESKTOP_SCREEN ? 'center' : 'flex-end',
    width: 85,
  },
  itemConditionFieldActive: {
    backgroundColor: theme.colors.lightGray,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    height: 48,
    justifyContent: 'center',
    marginBottom: 0,
    padding: 8,
    paddingBottom: 19,
  },
  itemLocationBar: {
    alignItems: 'center',
    backgroundColor: theme.colors.lightGray,
    borderRadius: 5,
    borderTopRightRadius: IS_DESKTOP_SCREEN ? 5 : 0,
    justifyContent: 'space-between',
    margin: 'auto',
    marginBottom: 15,
    paddingHorizontal: 6,
    paddingVertical: 11,
    width: IS_MOBILE_SCREEN ? '100%' : '80%',
  },
  locationCondition: {
    backgroundColor: theme.colors.white,
    padding: 2,
  },
  locationItem: {
    alignItems: 'flex-end',
    marginLeft: 14,
  },
  name: {
    marginTop: 10,
  },
  notAvailable: {
    color: theme.colors.danger,
  },
  number: {
    fontFamily: theme.fonts.regular,
    fontSize: 14,
    lineHeight: 14,
    margin: 0,
  },
  overlay: {
    height: '100%',
    width: '100%',
  },
  priceField: {
    alignSelf: 'flex-end',
    width: '50%',
  },
  radioLabel: {
    fontSize: 14,
    marginLeft: 16,
  },
  serviceType: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    justifyContent: 'center',
    padding: 5,
    width: '100%',
  },
  serviceTypeRadio: {
    marginLeft: 16,
  },
  unit: {
    fontSize: 10,
    margin: 0,
  },
});
