import { View } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Link as WebLink } from 'react-router-dom';

import { isWeb } from '../../constants';
import { useHistory } from '../../hooks';

export const TouchableLink = ({
  children,
  onClick = () => {},
  to,
  wrapperStyle,
  childrenStyle,
}) => {
  const navigation = useHistory();

  const handleLinkClick = () => {
    navigation.push(to);
    onClick();
  };

  const linkStyles = {
    textDecoration: 'none',
    width: '100%',
    height: '100%',
  };
  const appStyles = StyleSheet.compose(wrapperStyle, childrenStyle);

  if (isWeb) {
    return (
      <TouchableOpacity onPress={onClick} style={wrapperStyle}>
        <WebLink style={linkStyles} to={to}>
          <View style={childrenStyle}>{children}</View>
        </WebLink>
      </TouchableOpacity>
    );
  } else {
    return (
      <TouchableOpacity style={appStyles} onPress={handleLinkClick}>
        {children}
      </TouchableOpacity>
    );
  }
};

TouchableLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
    .isRequired,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  wrapperStyle: PropTypes.any,
  childrenStyle: PropTypes.any,
  onClick: PropTypes.func,
};
