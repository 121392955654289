import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const SortIcon = ({ style = {} }) => {
  return (
    <Svg width='10' height='13' viewBox='0 0 10 13' fill='none' style={{ ...style }}>
      <Path
        d='M1.28135 7.5001H8.71885C9.3876 7.5001 9.72197 8.30947 9.2501 8.78135L5.53135 12.5001C5.2376 12.7938 4.7626 12.7938 4.47197 12.5001L0.750099 8.78135C0.278224 8.30947 0.612599 7.5001 1.28135 7.5001ZM9.2501 4.21885L5.53135 0.500098C5.2376 0.206348 4.7626 0.206348 4.47197 0.500098L0.750099 4.21885C0.278224 4.69072 0.612599 5.5001 1.28135 5.5001H8.71885C9.3876 5.5001 9.72197 4.69072 9.2501 4.21885Z'
        fill='#828282'
      />
    </Svg>
  );
};

SortIcon.propTypes = {
  style: PropTypes.object,
};
