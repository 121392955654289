import { StyleSheet } from 'react-native';

import { colors } from '../../../../theme';

export const styles = StyleSheet.create({
  divider: {
    borderColor: colors.gray.border,
    borderStyle: 'solid',
    borderWidth: 1,
    marginBottom: 24,
    marginTop:24
  },
  wrapper: {
    display: 'flex',
    flexDirection:'column',
    gap:24
  },
  text: {
    color: colors.gray.main,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: 1.17
  }
});
