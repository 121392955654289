import React, { Component } from 'react';
import { View, Text } from 'react-native';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Card } from 'react-native-paper';
import { formatDateTime } from '../../../../utils';
import { MaterialIcons } from '@expo/vector-icons';
import styles from '../Style';

class OrdersRow extends Component {
  render() {
    const {
      orderItems,
      discount,
      id,
      createdAt,
      user,
      customer,
      source,
      paymentStatus,
      intl,
    } = this.props;
    const amount =
      orderItems.reduce((sum, value) => sum + value.pricePerPcs * value.quantity, 0) -
      discount;

    const userName = customer
      ? `${customer.firstName ? customer.firstName : ''} ${
          customer.lastName ? customer.lastName : ''
        }`
      : null;

    return (
      <Card style={styles.card}>
        <Card.Content>
          <View style={styles.top}>
            <View style={styles.left}>{this.getIcon()}</View>
            <View style={styles.center}>
              <Text style={styles.name}>
                {userName ? userName : intl.formatMessage({ id: 'app.no' })}
              </Text>
              <Text style={styles.date}>{formatDateTime(parseInt(createdAt))}</Text>
            </View>
            <View style={styles.right}>
              <Text style={styles.id}>{`№ ${id}`}</Text>
            </View>
          </View>
          <View style={styles.bottom}>
            <View style={styles.info}>
              <Text>
                {user
                  ? `${user.lastName} ${user.firstName}`
                  : intl.formatMessage({ id: 'app.no' })}
              </Text>
              <Text>{source.name}</Text>
            </View>
            <View style={styles.info}>
              <Text>
                {paymentStatus
                  ? paymentStatus.name
                  : intl.formatMessage({ id: 'app.no' })}
              </Text>
              <Text>
                {amount.toFixed(2)} {intl.formatMessage({ id: 'currency.uah' })}
              </Text>
            </View>
          </View>
        </Card.Content>
      </Card>
    );
  }

  getIcon = () => {
    const { status } = this.props;

    switch (parseInt(status ? status.id : 0)) {
      case 1:
        return (
          <View style={[styles.icon, styles.statusReceived]}>
            <MaterialIcons name='shopping-cart' size={25} color='#fff' />
          </View>
        );
      case 2:
        return (
          <View style={[styles.icon, styles.statusDelivered]}>
            <MaterialIcons name='check' size={25} color='#fff' />
          </View>
        );
      case 3:
        return (
          <View style={[styles.icon, styles.statusCanceled]}>
            <MaterialIcons name='clear' size={25} color='#fff' />
          </View>
        );
      case 4:
        return (
          <View style={[styles.icon, styles.statusSent]}>
            <MaterialIcons name='local-shipping' size={25} color='#fff' />
          </View>
        );
      case 5:
        return (
          <View style={[styles.icon, styles.statusDraft]}>
            <MaterialIcons name='remove-shopping-cart' size={25} color='#fff' />
          </View>
        );
      case 6:
        return (
          <View style={[styles.icon, styles.statusPending]}>
            <MaterialIcons name='person-add' size={25} color='#fff' />
          </View>
        );
      case 7:
        return (
          <View style={[styles.icon, styles.statusPaid]}>
            <MaterialIcons name='payment' size={25} color='#fff' />
          </View>
        );
      case 8:
        return (
          <View style={[styles.icon, styles.statusStore]}>
            <MaterialIcons name='local-shipping' size={25} color='#fff' />
          </View>
        );
      case 9:
        return (
          <View style={[styles.icon, styles.statusWaiting]}>
            <MaterialIcons name='payment' size={25} color='#fff' />
          </View>
        );
      default:
        return <Text>0</Text>;
    }
  };
}

OrdersRow.propTypes = {
  id: PropTypes.string.isRequired,
  discount: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  orderItems: PropTypes.array,
  user: PropTypes.object,
  customer: PropTypes.object,
  source: PropTypes.object.isRequired,
  status: PropTypes.object,
  paymentStatus: PropTypes.object,
};

export default injectIntl(OrdersRow);
