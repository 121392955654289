import { Formik } from 'formik';
import { Box } from 'native-base';
import React, { useMemo } from 'react';

import {
  BoxWrapper,
  Checkbox,
  FieldRow,
  FormRow,
  FormWrapper,
  Layout,
  RequestHandler,
  Select,
  TextField,
  Typography,
} from '../../../../../components';
import { IS_MOBILE_SCREEN } from '../../../../../constants';
import {
  useCreateRozetkaOrderStatusMap,
  useDeleteRozetkaOrderStatusMap,
  useGetRozetkaOrderStatusMapQuery,
  useGetStatusesQuery,
  useParams,
  useUpdateRozetkaOrderStatusMap,
} from '../../../../../hooks';
import { createRozetkaOrderStatusSchema } from '../../../../../services/validation';
import { transformSelectOptions } from '../../../../../utils';

const RozetkaOrderStatus = () => {
  const { id: rozetkaStatusId, statusId } = useParams();

  const isEdit = rozetkaStatusId && statusId;

  const { data, ...queryProps } = useGetRozetkaOrderStatusMapQuery({
    variables: {
      args: {
        rozetkaStatusId: Number(rozetkaStatusId),
        statusId: Number(statusId),
      },
    },
    skip: !isEdit,
    fetchPolicy: 'no-cache',
  });

  const { data: statusesData } = useGetStatusesQuery();

  const [createRozetkaOrderStatusMapMutation, { loading }] =
    useCreateRozetkaOrderStatusMap();
  const [
    updateRozetkaOrderStatusMapMutation,
    { loading: updateRozetkaOrderStatusMapLoading },
  ] = useUpdateRozetkaOrderStatusMap();
  const [
    deleteRozetkaOrderStatusMapMutation,
    { loading: deleteRozetkaOrderStatusMapLoading },
  ] = useDeleteRozetkaOrderStatusMap();

  const initialValues = useMemo(() => {
    return {
      rozetkaStatusId: data?.rozetkaOrderStatusMap?.rozetkaStatusId || '',
      statusId: data?.rozetkaOrderStatusMap?.statusId?.toString() || '',
      isRozetkaStatusDefault:
        data?.rozetkaOrderStatusMap?.isRozetkaStatusDefault || false,
      isStatusDefault: data?.rozetkaOrderStatusMap?.isStatusDefault || false,
      rozetkaStatusDesc: data?.rozetkaOrderStatusMap?.rozetkaStatusDesc || '',
    };
  }, [data]);

  const handleFormSubmit = (values) => {
    if (isEdit) {
      updateRozetkaOrderStatusMapMutation({
        variables: {
          input: {
            rozetkaStatusId: Number(rozetkaStatusId),
            statusId: Number(statusId),
            isRozetkaStatusDefault: values.isRozetkaStatusDefault,
            isStatusDefault: values.isStatusDefault,
            rozetkaStatusDesc: values.rozetkaStatusDesc,
          },
        },
      });
    } else {
      createRozetkaOrderStatusMapMutation({
        variables: {
          input: {
            rozetkaStatusId: Number(values.rozetkaStatusId),
            statusId: Number(values.statusId),
            isRozetkaStatusDefault: values.isRozetkaStatusDefault,
            isStatusDefault: values.isStatusDefault,
            rozetkaStatusDesc: values.rozetkaStatusDesc,
          },
        },
      });
    }
  };

  const handleDeleteRozetkaOrderStatusMap = () => {
    deleteRozetkaOrderStatusMapMutation({
      variables: {
        params: { rozetkaStatusId: Number(rozetkaStatusId), statusId: Number(statusId) },
      },
    });
  };

  return (
    <RequestHandler {...queryProps}>
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={createRozetkaOrderStatusSchema}
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <Layout
              onActionButtonPress={handleSubmit}
              actionButtonType='save'
              actionButtonIsLoading={
                loading ||
                updateRozetkaOrderStatusMapLoading ||
                deleteRozetkaOrderStatusMapLoading
              }
              onRemoveButtonPress={handleDeleteRozetkaOrderStatusMap}
            >
              <FormRow>
                <FormRow.Left>
                  <BoxWrapper>
                    <Typography intlId='rozetka.status' variant='heading' />
                    {!isEdit ? (
                      <FieldRow withNegativeBottomMargin={false}>
                        <TextField
                          name='rozetkaStatusId'
                          label='rozetka.status'
                          type='number'
                        />
                      </FieldRow>
                    ) : null}
                    <FieldRow withNegativeBottomMargin={false}>
                      <TextField
                        name='rozetkaStatusDesc'
                        label='rozetka.statusDescription'
                      />
                    </FieldRow>
                    {!isEdit ? (
                      <FieldRow withNegativeBottomMargin={false}>
                        <Select
                          name='statusId'
                          label='orderStatuses.statusCRM'
                          options={transformSelectOptions({
                            data: statusesData?.statuses,
                          })}
                        />
                      </FieldRow>
                    ) : null}
                    <FieldRow withNegativeBottomMargin={false}>
                      <Box ml={2} pb={3}>
                        <Checkbox
                          mt={!IS_MOBILE_SCREEN ? '16px' : 0}
                          justify={!IS_MOBILE_SCREEN ? 'flex-start' : 'center'}
                          label={'orderStatuses.statusDefaultCRM'}
                          onChange={() =>
                            setFieldValue('isStatusDefault', !values.isStatusDefault)
                          }
                          checked={values.isStatusDefault}
                        />
                      </Box>
                    </FieldRow>
                    <FieldRow withNegativeBottomMargin={false}>
                      <Box ml={2} pb={3}>
                        <Checkbox
                          mt={!IS_MOBILE_SCREEN ? '16px' : 0}
                          justify={!IS_MOBILE_SCREEN ? 'flex-start' : 'center'}
                          label={'rozetka.statusDefault'}
                          onChange={() =>
                            setFieldValue(
                              'isRozetkaStatusDefault',
                              !values.isRozetkaStatusDefault,
                            )
                          }
                          checked={values.isRozetkaStatusDefault}
                        />
                      </Box>
                    </FieldRow>
                  </BoxWrapper>
                </FormRow.Left>
              </FormRow>
            </Layout>
          )}
        </Formik>
      </FormWrapper>
    </RequestHandler>
  );
};

export default RozetkaOrderStatus;
