import React from 'react';
import { View, Text } from 'react-native';
import { Portal } from 'react-native-paper';
import styles from './styles';

function Modal({ title, children, onClose, stylesNameModal }) {
  return (
    <Portal>
      <View style={styles.wrapped}>
        <View style={[styles.modal, stylesNameModal]}>
          <View style={styles.head}>
            <Text style={styles.heading}>{title}</Text>
          </View>
          <View style={styles.content}>{children}</View>
        </View>
      </View>
    </Portal>
  );
}

export default Modal;
