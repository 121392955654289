import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const RightUpperCloud = (props) => {
  return (
    <Svg
      width='620'
      height='350'
      viewBox='0 0 620 350'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <Path
        d='M620 350C620 350 413.592 339.985 393.784 235.831C378.884 157.488 242.912 210.931 201.081 175.882C160.124 141.565 141.991 26.0076 71.7108 62.4412C1.43018 98.8749 0 0 0 0H620V350Z'
        fill='#47C8FF'
        fillOpacity='0.1'
      />
    </Svg>
  );
};
