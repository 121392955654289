import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const DeliveryUkraineIcon = ({ size = 24, style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' style={{ ...style }}>
      <Path d='M10.6339 10.3414H8.38477V12.5906H10.6339V10.3414Z' fill='white' />
      <Path d='M14.3727 10.3414H12.1235V12.5906H14.3727V10.3414Z' fill='white' />
      <Path d='M10.6339 6.60242H8.38477V8.85157H10.6339V6.60242Z' fill='white' />
      <Path d='M14.3727 6.60242H12.1235V8.85157H14.3727V6.60242Z' fill='white' />
      <Path d='M18.1119 10.3414H15.8628V11.4649H18.1119V10.3414Z' fill='white' />
      <Path
        d='M4.45829 8.0188V7.13451H5.59083V5.64468H4.45829V4.51208H2.96846V5.64468H1.83586V7.13451H2.96846V8.0188H0.629395V12.5904H2.11922H5.30752H6.79735V8.0188H4.45829Z'
        fill='white'
      />
      <Path
        d='M22.4369 12.9546H16.328H15.8313L15.4739 14.0802H14.3728H12.1236H10.6338H6.8948H6.79734H1.56314H0.629438H0L1.71717 19.4878H21.9254L24 12.9546H22.4369ZM19.0309 15.8239H17.4913V14.3341H19.0309V15.8239ZM21.4512 15.8239H19.9117V14.3341H21.4512V15.8239Z'
        fill='white'
      />
    </Svg>
  );
};

DeliveryUkraineIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
