import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const TransactionForwardingIcon = ({
  size = '16',
  fill = '#47C8FF',
  style = {},
}) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 14 14' style={{ ...style }}>
      <Path
        d='M13.2826 6.922C13.3331 6.80015 13.3462 6.66607 13.3205 6.53672C13.2947 6.40737 13.2312 6.28856 13.1379 6.19533L9.80458 2.862L8.86191 3.80467L11.0572 6H2.66658V7.33333H12.6666C12.7984 7.33337 12.9273 7.2943 13.037 7.22109C13.1466 7.14787 13.2321 7.04379 13.2826 6.922ZM2.71725 9.078C2.66678 9.19985 2.65358 9.33393 2.67934 9.46328C2.70509 9.59263 2.76863 9.71144 2.86191 9.80467L6.19525 13.138L7.13791 12.1953L4.94258 10H13.3332V8.66667H3.33325C3.20138 8.66654 3.07245 8.70557 2.96279 8.7788C2.85313 8.85202 2.76767 8.95616 2.71725 9.078Z'
        fill={fill}
      />
    </Svg>
  );
};

TransactionForwardingIcon.propTypes = {
  size: PropTypes.string,
  style: PropTypes.object,
  fill: PropTypes.string,
};
