import React from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import styles from './Style';
import { MaterialIcons } from '@expo/vector-icons';
import stylesGlobal from '../../globalStyles';
import { Select } from '../UI';
import { useMediaQuery } from '../../hooks';
import { isWeb } from '../../constants';

const WebSorting = ({ columnData, sorting, setSorting, styleWrapper }) => {
  const isDesktop = useMediaQuery('isDesktop');
  const getDirection = (id) => {
    if (sorting?.field === id) {
      return sorting?.direction === 'desc' ? 'asc' : 'desc';
    }
    return 'desc';
  };
  const handleSetSorting = (id) => {
    const values = { field: id, direction: getDirection(id) };
    setSorting(values);
  };
  return isDesktop ? (
    <View
      style={[styles.header, { marginRight: 25 }, stylesGlobal.wrapper, styleWrapper]}
    >
      {columnData.map((item) => (
        <TouchableOpacity
          key={item.id}
          onPress={() => handleSetSorting(item.id)}
          style={[styles.headerRow, { width: item.width }]}
        >
          <Text style={{ color: '#333333' }}>{item.label}</Text>
          {sorting?.field === item.id && (
            <MaterialIcons
              name={getDirection(item.id) === 'desc' ? 'arrow-upward' : 'arrow-downward'}
              size={16}
              color='black'
              style={{ marginLeft: 10 }}
            />
          )}
        </TouchableOpacity>
      ))}
    </View>
  ) : (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: isWeb ? 16 : 0,
      }}
    >
      <View style={{ width: '90%' }}>
        <Select
          options={columnData}
          onChange={(_v, id) => setSorting({ field: id, direction: 'desc' })}
        >
          {columnData.map(({ label, id }) => (
            <Select.Item id={id} key={id}>
              {label}
            </Select.Item>
          ))}
        </Select>
      </View>
      <TouchableOpacity
        onPress={() =>
          setSorting({
            field: sorting?.field,
            direction: sorting?.direction === 'desc' ? 'asc' : 'desc',
          })
        }
      >
        <MaterialIcons
          name={sorting?.direction === 'desc' ? 'arrow-upward' : 'arrow-downward'}
          size={16}
          color='black'
          style={{ padding: 10 }}
        />
      </TouchableOpacity>
    </View>
  );
};

export default WebSorting;
