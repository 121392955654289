import { useIntl } from 'react-intl';

export const useSlides = () => {
  const intl = useIntl();

  const slides = [
    {
      title: intl.formatMessage({ id: 'slides.titleStep1' }),
      description: intl.formatMessage({ id: 'slides.descriptionStep1' }),
    },
    {
      title: intl.formatMessage({ id: 'slides.titleStep2' }),
      description: intl.formatMessage({ id: 'slides.descriptionStep2' }),
    },
    {
      title: intl.formatMessage({ id: 'slides.titleStep3' }),
      description: intl.formatMessage({ id: 'slides.descriptionStep3' }),
    },
    {
      title: intl.formatMessage({ id: 'slides.titleStep4' }),
      description: intl.formatMessage({ id: 'slides.descriptionStep4' }),
    },
  ];
  return slides;
};
