import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const ReportsIcon = ({ size = 24, style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' style={{ ...style }}>
      <Path
        d='M20 8L14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8ZM9 19H7V10H9V19ZM13 19H11V13H13V19ZM17 19H15V16H17V19ZM14 9H13V4L18 9H14Z'
        fill='white'
      />
    </Svg>
  );
};

ReportsIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
