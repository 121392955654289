import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const ArrowUp = (props) => {
  return (
    <Svg width={16} height={16} viewBox='0 0 16 16' fill='none' {...props}>
      <Path
        d='M2.66663 10L7.99996 4.66669L13.3333 10'
        stroke='#222222'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  );
};
