import { StyleSheet } from 'react-native';

export const stylesSorting = StyleSheet.create({
  select: {
    width: '90%',
  },
  sortButton: {
    marginLeft: 10,
    position: 'relative',
    top: -5,
  },
  wrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
