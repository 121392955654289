import { StyleSheet } from 'react-native';

import { IS_MOBILE_SCREEN } from '../../constants';

export const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    marginHorizontal: 'auto',
    paddingHorizontal: IS_MOBILE_SCREEN ? 8 : 15,
    position: 'relative',
    width: '100%',
  },
});
