import { useEffect, useState } from 'react';

import { IS_DESKTOP_SCREEN } from '../constants';

const useIntersections = (element, rootMargin) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    if (!IS_DESKTOP_SCREEN) return setState(false);

    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
      },
      { rootMargin },
    );

    element && observer.observe(element);

    return () => element && observer.unobserve(element);
  }, [element, rootMargin]);

  return isVisible;
};

export default useIntersections;
