import { MaterialIcons } from '@expo/vector-icons';
import { FieldArray, useFormikContext } from 'formik';
import { Flex, Icon, Text } from 'native-base';
import React, { useMemo } from 'react';
import { TouchableOpacity } from 'react-native';

import {
  AddForwardingProductModal,
  BoxWrapper,
  CalculateCostDeliveryButton,
  DesktopTable,
  Select,
  TextField,
  TextLink,
  Typography,
} from '../../../../components';
import { CopyIcon } from '../../../../components/Icons';
import { IS_DESKTOP_SCREEN } from '../../../../constants';
import { useDeletePurchase, useIntl } from '../../../../hooks';
import { transformSelectOptions } from '../../../../utils';
import ForwardingStructureMobileItem from './ForwardingStructureMobileItem';

const ForwardingStructure = () => {
  const { values, setFieldValue } = useFormikContext();

  const intl = useIntl();

  const [deletePurchase] = useDeletePurchase();

  const purchasesQuantity = values.purchases.reduce(
    (accumulator, { quantity }) => accumulator + Number(quantity),
    0,
  );
  const purchasesTotalPrice = values.purchases.reduce(
    (accumulator, { price, quantity }) => accumulator + Number(price) * Number(quantity),
    +values.deliveryPrice ?? 0,
  );

  const handleDuplicateProduct = (product, addField, index) => {
    addField(index, {
      ...product,
      isDuplicate: true,
      purchaseId: '',
      conditionId: '',
      quantity: '',
    });
  };

  const getForwardingOptions = () => {
    return transformSelectOptions({
      data: values?.parcels,
      label: 'trackingCode',
      value: 'id',
    });
  };

  const handleAddProduct = (product, addField, values) => {
    addField({
      ...product,
      quantity: 1,
      forwarding: values.parcels[0]?.id,
      price: 1,
      deliveryPrice: undefined,
      isNew: true,
    });
  };

  const forwardingParcelsIds = useMemo(
    () => values?.parcels.map((parcel) => parcel?.id) || [],
    [values?.parcels],
  );

  if (IS_DESKTOP_SCREEN) {
    return (
      <BoxWrapper>
        <Flex direction={'row'} justify={'space-between'} align={'center'}>
          <Typography mb={0} intlId='app.structure' variant='heading' />
          <Flex>
            <CalculateCostDeliveryButton values={values} setFieldValue={setFieldValue} />
            <FieldArray
              name={'purchases'}
              render={(arrayHelpers) => (
                <AddForwardingProductModal
                  forwardingParcels={forwardingParcelsIds}
                  addedProducts={values?.purchases?.map(
                    (purchase) => purchase?.product || purchase,
                  )}
                  isMultiSelect
                  onAddProduct={(product) =>
                    handleAddProduct(product, arrayHelpers.push, values)
                  }
                  buttonText={'app.fillIn'}
                />
              )}
            />
          </Flex>
        </Flex>

        <DesktopTable editable={false}>
          <DesktopTable.Head>
            <DesktopTable.Title intlId='app.purchase' />
            {values?.parcels?.length ? <DesktopTable.Title intlId='app.parcel' /> : null}
            <DesktopTable.Title intlId='app.costUSD' />
            <DesktopTable.Title intlId='app.priceDeliveryUSD' />
            <DesktopTable.Title intlId='app.quantity' />
            <DesktopTable.Title intlId='app.sum' />
          </DesktopTable.Head>
          <DesktopTable.Body>
            <FieldArray
              name={'purchases'}
              render={(arrayHelpers) => {
                return (
                  <>
                    {values?.purchases?.map((item, index) => {
                      const handleRemove = () => {
                        arrayHelpers.remove(index);
                        !item?.isNew &&
                          deletePurchase({
                            variables: {
                              id: +item?.id,
                            },
                          });
                      };

                      return (
                        <DesktopTable.Row key={item?.id}>
                          <DesktopTable.Cell width={'800px'} maxWidth={'800px'}>
                            {item?.name ? (
                              <Text color={'primary.default'}>{item?.name}</Text>
                            ) : (
                              <>
                                <TextLink link={`/purchase/purchase/${item.id}`}>
                                  {item?.product?.name}
                                  <Text color='black'>{` (${item?.id})`}</Text>
                                </TextLink>
                              </>
                            )}
                          </DesktopTable.Cell>
                          {!!values?.parcels?.length && (
                            <DesktopTable.Cell>
                              {values?.parcels?.length === 1 ? (
                                <Text>{values?.parcels[0]?.trackingCode}</Text>
                              ) : (
                                <Select
                                  // eslint-disable-next-line react-native/no-inline-styles
                                  style={{ width: '200px' }}
                                  mb={0}
                                  name={`purchases[${index}].parcel.id`}
                                  options={getForwardingOptions()}
                                />
                              )}
                            </DesktopTable.Cell>
                          )}
                          <DesktopTable.Cell>
                            <TextField
                              inputProps={{
                                textAlign: 'right',
                              }}
                              width={'90px'}
                              mb={0}
                              type={'number'}
                              name={`purchases[${index}].price`}
                              placeholderIntlId={'app.price'}
                            />
                          </DesktopTable.Cell>
                          <DesktopTable.Cell>
                            <TextField
                              inputProps={{
                                textAlign: 'right',
                              }}
                              width={'90px'}
                              mb={0}
                              isDisabled
                              type={'number'}
                              name={`purchases[${index}].deliveryPrice`}
                              placeholderIntlId={'app.delivery'}
                            />
                          </DesktopTable.Cell>
                          <DesktopTable.Cell>
                            <TextField
                              inputProps={{
                                textAlign: 'center',
                              }}
                              width={'48px'}
                              mb={0}
                              type={'number'}
                              numberFixedCount={0}
                              name={`purchases[${index}].quantity`}
                              placeholderIntlId={'app.quantity'}
                            />
                          </DesktopTable.Cell>
                          <DesktopTable.Cell>
                            <Flex
                              direction={'row'}
                              align={'center'}
                              justify={'space-between'}
                            >
                              <Text>{(item?.price * item?.quantity).toFixed(2)} $</Text>
                              <Flex
                                cursor={'pointer'}
                                onClick={() =>
                                  handleDuplicateProduct(
                                    item,
                                    arrayHelpers.insert,
                                    index + 1,
                                  )
                                }
                              >
                                <CopyIcon />
                              </Flex>
                              <TouchableOpacity onPress={handleRemove}>
                                <Icon
                                  size={'sm'}
                                  color={'gray.dark'}
                                  as={<MaterialIcons name={'delete'} />}
                                />
                              </TouchableOpacity>
                            </Flex>
                          </DesktopTable.Cell>
                        </DesktopTable.Row>
                      );
                    })}
                  </>
                );
              }}
            />
            <DesktopTable.Row>
              <DesktopTable.Cell>
                <></>
              </DesktopTable.Cell>
              {!!values?.parcels?.length && (
                <DesktopTable.Cell>
                  <></>
                </DesktopTable.Cell>
              )}
              <DesktopTable.Cell>
                <></>
              </DesktopTable.Cell>
              <DesktopTable.Cell>
                <></>
              </DesktopTable.Cell>
              <DesktopTable.Cell>
                <Flex justify={'flex-start'}>
                  <Text>
                    {`${purchasesQuantity} ${intl.formatMessage({ id: 'app.pcs' })}`}
                  </Text>
                </Flex>
              </DesktopTable.Cell>
              <DesktopTable.Cell>
                <Flex justify={'flex-start'}>
                  <Text>{purchasesTotalPrice} $</Text>
                </Flex>
              </DesktopTable.Cell>
            </DesktopTable.Row>
          </DesktopTable.Body>
        </DesktopTable>
      </BoxWrapper>
    );
  }

  return (
    <BoxWrapper>
      <Flex direction={'row'} justify={'space-between'} align={'center'}>
        <Typography margin={'24px 0px'} intlId='app.structure' variant='heading' />
        <FieldArray
          name={'purchases'}
          render={(arrayHelpers) => (
            <AddForwardingProductModal
              forwardingParcels={forwardingParcelsIds}
              addedProducts={values?.purchases?.map(
                (purchase) => purchase?.product || purchase,
              )}
              isMultiSelect
              onAddProduct={(product) =>
                handleAddProduct(product, arrayHelpers.push, values)
              }
              buttonText={'app.fillIn'}
            />
          )}
        />
      </Flex>
      <Flex align={'center'} justify={'center'}>
        <CalculateCostDeliveryButton values={values} setFieldValue={setFieldValue} />
      </Flex>
      <FieldArray
        name={'purchases'}
        render={(arrayHelpers) => {
          return (
            <>
              {values?.purchases?.map((item, index) => {
                const handleRemove = () => {
                  arrayHelpers.remove(index);
                  !item?.isNew &&
                    deletePurchase({
                      variables: {
                        id: +item?.id,
                      },
                    });
                };

                return (
                  <ForwardingStructureMobileItem
                    key={item?.id}
                    item={item}
                    getForwardingOptions={getForwardingOptions}
                    handleRemove={handleRemove}
                    index={index}
                  />
                );
              })}
            </>
          );
        }}
      />
      <Flex mt={'lg'} justify={'space-between'} align={'center'}>
        <Text>{intl.formatMessage({ id: 'app.all' })}</Text>
        <Flex align={'center'}>
          <Text>{`${purchasesQuantity} ${intl.formatMessage({ id: 'app.pcs' })}`}</Text>
          <Text ml={'lg'}>{purchasesTotalPrice} $</Text>
        </Flex>
      </Flex>
    </BoxWrapper>
  );
};

ForwardingStructure.propTypes = {};

export default ForwardingStructure;
