import { MaterialIcons } from '@expo/vector-icons';
import PropTypes from 'prop-types';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';

import { useFilter, useIntl } from '../../hooks';
import { Select } from '../Select/Select';
import { stylesSorting } from './styles';

const MobileSorting = ({ sortableColumns }) => {
  const intl = useIntl();
  const { filters, setFilters } = useFilter();

  const handleSortDirectionClick = () => {
    setFilters({
      field: filters?.field,
      direction: filters?.direction === 'desc' ? 'asc' : 'desc',
    });
  };

  const handleSelectChange = (optionValue) => {
    setFilters({ field: optionValue, direction: 'desc' });
  };

  const options = sortableColumns.map(({ intlId, sortId }) => ({
    label: intl.formatMessage({ id: intlId }),
    value: sortId,
  }));

  return (
    <View style={stylesSorting.wrapper}>
      <View style={stylesSorting.select}>
        <Select value={filters?.field} onChange={handleSelectChange} options={options} />
      </View>
      <TouchableOpacity
        onPress={handleSortDirectionClick}
        style={stylesSorting.sortButton}
      >
        <MaterialIcons
          name={filters?.direction === 'desc' ? 'arrow-upward' : 'arrow-downward'}
          size={24}
          color='black'
        />
      </TouchableOpacity>
    </View>
  );
};

MobileSorting.propTypes = {
  sortableColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default MobileSorting;
