import {
  AppealsIcon,
  BuyersIcon,
  CommentsIcon,
  DashboardIcon,
  OrdersIcon,
} from '../../components/Icons';

export default [
  { name: 'Home', icon: DashboardIcon, path: '/home' },
  { name: 'Orders', icon: OrdersIcon, path: '/orders', scopes: ['Order:Read'] },
  { name: 'Appeals', icon: AppealsIcon, path: '/appeals' },
  { name: 'Comments', icon: CommentsIcon, path: '/comments' },
  { name: 'Customers', icon: BuyersIcon, path: '/customers', scopes: ['Customer:Read'] },
];
