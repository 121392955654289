import { Flex } from 'native-base';
import { useDrop } from 'react-dnd';
import React from 'react';
import { theme } from '../../../../../constants';

const DropZone = ({ onDrop, index, children }) => {
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: 'ITEM',
    drop: (item) => onDrop(item, index),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }));

  return (
    <Flex direction='row' flexWrap='wrap' justify='flex-start' ref={drop}>
      {children}
    </Flex>
  );
};
export default DropZone;
