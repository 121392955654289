import { MaterialIcons } from '@expo/vector-icons';
import { Flex, Icon } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { TouchableOpacity } from 'react-native';

import { Typography } from '../Typography/Typography';

export const MultiSelectOption = ({ item, onRemove }) => {
  if (!item?.value || !item?.label) return null;

  return (
    <Flex
      key={item.value}
      justify='space-between'
      bg='gray.light'
      rounded='md'
      paddingX='xs'
      paddingY='xs'
      mt='2.5px'
      mb='2.5px'
      ml='5px'
    >
      <Typography fontSize='sm'>{item.label}</Typography>
      <TouchableOpacity onPress={() => onRemove(item.value)}>
        <Icon
          as={<MaterialIcons name='delete' />}
          color='gray.main'
          size='16px'
          width='16px'
        />
      </TouchableOpacity>
    </Flex>
  );
};

MultiSelectOption.propTypes = {
  item: PropTypes.object,
  onRemove: PropTypes.func,
};
