import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  wrapper: { display: 'flex', flexDirection: 'column' },
  iconBack: {
    position: 'absolute',
    top: 0,
    right: 10,
  },
  listItem: {
    display: 'flex',
    height: 200,
    paddingHorizontal: 10,
    marginBottom: 5,
  },
  item: {
    padding: 5,
  },
});
