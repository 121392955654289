import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const BookkeepingMainIcon = ({ size = 21, style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 21 20' style={{ ...style }}>
      <Path
        d='M19.743 10.3309L10.743 0.330904C10.364 -0.0910957 9.63598 -0.0910957 9.25698 0.330904L0.256984 10.3309C0.12744 10.4745 0.0424085 10.6526 0.0122281 10.8437C-0.0179524 11.0347 0.00801875 11.2304 0.0869837 11.4069C0.246984 11.7679 0.604984 11.9999 0.999984 11.9999H2.99998V18.9999C2.99998 19.2651 3.10534 19.5195 3.29288 19.707C3.48041 19.8945 3.73477 19.9999 3.99998 19.9999H6.99998C7.2652 19.9999 7.51955 19.8945 7.70709 19.707C7.89463 19.5195 7.99998 19.2651 7.99998 18.9999V14.9999H12V18.9999C12 19.2651 12.1053 19.5195 12.2929 19.707C12.4804 19.8945 12.7348 19.9999 13 19.9999H16C16.2652 19.9999 16.5196 19.8945 16.7071 19.707C16.8946 19.5195 17 19.2651 17 18.9999V11.9999H19C19.1937 12.0007 19.3834 11.9452 19.546 11.8401C19.7087 11.7349 19.8372 11.5847 19.916 11.4078C19.9947 11.2309 20.0203 11.0348 19.9896 10.8436C19.9589 10.6524 19.8732 10.4743 19.743 10.3309Z'
        fill='white'
      />
    </Svg>
  );
};

BookkeepingMainIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
