import { MaterialIcons } from '@expo/vector-icons';
import { FieldArray, useFormikContext } from 'formik';
import { Center, Flex, Icon } from 'native-base';
import React from 'react';

import {
  AddAvailabilityModal,
  BoxWrapper,
  DesktopTable,
  RequestHandler,
  Select,
  TextField,
  TextLink,
  Typography,
} from '../../../../components';
import { IS_DESKTOP_SCREEN, theme } from '../../../../constants';
import { useGetConditionsQuery, useHasScopesQuery, useIntl } from '../../../../hooks';
import { getProductsTotalQuantity, transformSelectOptions } from '../../../../utils';
import { getDateWithNumber } from '../../../../utils/getDateWithNumber';

export const DisplacementsStructure = () => {
  const intl = useIntl();
  const { data, ...queryProps } = useGetConditionsQuery();
  const { values } = useFormikContext();
  const { data: hasScopesData } = useHasScopesQuery(['Section:Purchase:Access']);

  const availabilities = values?.availabilities;

  const handleAddProduct = (availability, addField) => {
    addField({
      ...availability,
      fromConditionId: availability?.condition?.id,
      purchaseId: availability?.purchase?.id,
      toConditionId: '',
      quantity: availability?.quantity,
    });
  };

  if (IS_DESKTOP_SCREEN) {
    return (
      <RequestHandler {...queryProps}>
        <BoxWrapper>
          <FieldArray
            name='availabilities'
            render={(arrayHelpers) => {
              return (
                <>
                  <Flex justify='space-between' mb='xs'>
                    <Typography intlId='app.structure' variant='heading' mb={0} />
                    <AddAvailabilityModal
                      buttonText={'app.addPurchase'}
                      addedAvailabilities={availabilities}
                      onAddProduct={(availability) =>
                        handleAddProduct(availability, arrayHelpers.push)
                      }
                      hasScopes={hasScopesData?.hasScopes[0]?.isAllowed}
                    />
                  </Flex>
                  <DesktopTable editable={false}>
                    <DesktopTable.Head>
                      {COLUMNS.map((column) => (
                        <DesktopTable.Title key={column.id} intlId={column.name} />
                      ))}
                      <DesktopTable.Title />
                    </DesktopTable.Head>
                    <DesktopTable.Body>
                      {availabilities?.map((item, index) => (
                        <DesktopTable.Row key={index}>
                          <DesktopTable.Cell>
                            <TextLink
                              link={`/warehouse/product/${item?.purchase?.product?.id}`}
                              intlId={item?.purchase?.product?.name}
                            />
                          </DesktopTable.Cell>
                          <DesktopTable.Cell>
                            {hasScopesData?.hasScopes[0]?.isAllowed ? (
                              <TextLink link={`/purchase/purchase/${item?.purchase?.id}`}>
                                {getDateWithNumber(item?.purchase, intl)}
                              </TextLink>
                            ) : (
                              <Typography>
                                {getDateWithNumber(item?.purchase, intl)}
                              </Typography>
                            )}
                          </DesktopTable.Cell>
                          <DesktopTable.Cell>
                            <Typography>{item?.condition?.name}</Typography>
                          </DesktopTable.Cell>
                          <DesktopTable.Cell>
                            <Select
                              name={`availabilities.${index}.toConditionId`}
                              options={transformSelectOptions({
                                data: data?.conditions,
                              })}
                              mb={0}
                              mr={6}
                            />
                          </DesktopTable.Cell>
                          <DesktopTable.Cell>
                            <Typography textAlign={'center'}>
                              {
                                values?.fromPlace?.availabilities?.find(
                                  (el) => el.id === item.id,
                                )?.quantity
                              }
                            </Typography>
                          </DesktopTable.Cell>
                          <DesktopTable.Cell>
                            <TextField
                              name={`availabilities.${index}.quantity`}
                              mb={0}
                              type='number'
                              isInvalid={
                                values?.availabilities[index]?.quantity >
                                values?.fromPlace?.availabilities?.find(
                                  (el) => el.id === item.id,
                                )?.quantity
                              }
                              errorMessageText={intl.formatMessage({
                                id: 'app.maxQuantity',
                              })}
                            />
                          </DesktopTable.Cell>
                          <DesktopTable.Cell>
                            <Center>
                              <Icon
                                as={<MaterialIcons name='delete' />}
                                size='sm'
                                color='gray.dark'
                                onPress={() => {
                                  arrayHelpers.remove(index);
                                }}
                              />
                            </Center>
                          </DesktopTable.Cell>
                        </DesktopTable.Row>
                      ))}
                    </DesktopTable.Body>
                  </DesktopTable>
                  <Flex justify='flex-end' mt='lg'>
                    <Typography>{`${getProductsTotalQuantity(
                      availabilities,
                    )} ${intl.formatMessage({ id: 'app.pcs' })}`}</Typography>
                  </Flex>
                </>
              );
            }}
          />
        </BoxWrapper>
      </RequestHandler>
    );
  }

  return (
    <RequestHandler {...queryProps}>
      <FieldArray
        name='availabilities'
        render={(arrayHelpers) => {
          return (
            <>
              <Flex justifyContent='space-between' mb='lg'>
                <Typography intlId='app.structure' variant='heading' mb={0} />
                <AddAvailabilityModal
                  buttonText={'app.addPurchase'}
                  addedAvailabilities={availabilities}
                  onAddProduct={(availability) =>
                    handleAddProduct(availability, arrayHelpers.push)
                  }
                  hasScopes={hasScopesData?.hasScopes[0]?.isAllowed}
                />
              </Flex>
              {availabilities?.map((item, index) => (
                <BoxWrapper key={index}>
                  <TextLink
                    link={`/warehouse/product/${item.id}`}
                    intlId={item?.purchase?.product?.name}
                    mb={4}
                  />
                  <Flex justify={'flex-start'} mb={4}>
                    <Typography
                      intlId='app.purchase'
                      w={'40%'}
                      color={theme.colors.gray}
                    />
                    {hasScopesData?.hasScopes[0]?.isAllowed ? (
                      <TextLink link={`/purchase/purchase/${item?.purchase?.id}`}>
                        {getDateWithNumber(item?.purchase, intl)}
                      </TextLink>
                    ) : (
                      <Typography>{getDateWithNumber(item?.purchase, intl)}</Typography>
                    )}
                  </Flex>
                  <Flex justify={'flex-start'} mb={4}>
                    <Typography
                      intlId='app.condition'
                      w={'40%'}
                      color={theme.colors.gray}
                    />
                    <Typography>{item?.condition?.name}</Typography>
                  </Flex>
                  <Select
                    label='app.after'
                    name={`availabilities.${index}.toConditionId`}
                    options={transformSelectOptions({
                      data: data?.conditions,
                    })}
                  />
                  <Flex justify={'flex-start'} mb={4}>
                    <Typography
                      intlId='app.available'
                      w={'40%'}
                      color={theme.colors.gray}
                    />
                    <Typography>
                      {values?.fromPlace?.availabilities[index]?.quantity}
                    </Typography>
                  </Flex>
                  <TextField
                    label='app.quantity'
                    name={`availabilities.${index}.quantity`}
                    type='number'
                    isInvalid={true}
                    errorMessageText={'ssadadsads'}
                  />
                  <Center>
                    <Icon
                      as={<MaterialIcons name='delete' />}
                      size='sm'
                      color='gray.dark'
                      onPress={() => {
                        arrayHelpers.remove(index);
                      }}
                    />
                  </Center>
                </BoxWrapper>
              ))}
            </>
          );
        }}
      />
    </RequestHandler>
  );
};

const COLUMNS = [
  { name: 'app.title' },
  { name: 'app.purchase' },
  { name: 'app.conditionBefore' },
  { name: 'app.after' },
  { name: 'app.available' },
  { name: 'app.quantity' },
];
