import { StyleSheet } from 'react-native';

import {
  IS_DESKTOP_SCREEN,
  IS_LAPTOP_SCREEN,
  IS_WEB,
  STATUS_BAR_HEIGHT,
  theme,
} from '../../../constants';

export const styles = StyleSheet.create({
  inner: {
    borderRadius: 5,
    width: 260,
    ...(IS_WEB ? { boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)' } : null),
    backgroundColor: theme.colors.white,
    paddingBottom: 16.5,
    paddingHorizontal: IS_LAPTOP_SCREEN || IS_DESKTOP_SCREEN ? 16 : 10,
    paddingTop: 24,
    position: 'absolute',
    right: IS_LAPTOP_SCREEN || IS_DESKTOP_SCREEN ? 190 : 10,
    top: IS_LAPTOP_SCREEN || IS_DESKTOP_SCREEN ? 93 : STATUS_BAR_HEIGHT + 65,
  },
});
