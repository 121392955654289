import { Box, Center, Spinner } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { ErrorIndicator } from '../ErrorIndicator/ErrorIndicator';

export const RequestHandler = ({ loading, error, children, backgroundLoading }) => {
  if (loading) {
    return (
      <Box paddingY='lg'>
        <Spinner />
      </Box>
    );
  }
  if (error) {
    return (
      <Box paddingY='lg'>
        <ErrorIndicator />
      </Box>
    );
  }
  if (backgroundLoading) {
    return (
      <>
        <Box
          bgColor='white'
          opacity={0.4}
          position={'absolute'}
          width='100%'
          height='100%'
          zIndex={10}
        >
          <Center width='100%' height='100%'>
            <Spinner zIndex={1} />
          </Center>
        </Box>
        {children}
      </>
    );
  }
  return children;
};

RequestHandler.propTypes = {
  loading: PropTypes.bool,
  backgroundLoading: PropTypes.bool,
  error: PropTypes.any,
  children: PropTypes.node,
};
