import React from 'react';
import { useIntl } from 'react-intl';

import {
  DesktopTable,
  Layout,
  MobileTable,
  RequestHandler,
  TextLink,
} from '../../../components';
import { DATA_REQUEST_LIMIT, IS_DESKTOP_SCREEN } from '../../../constants';
import { useGetPurchaseSourcesQuery, useHistory } from '../../../hooks';
import SourcesOrderMobileItem from './SourcesOrderMobileItem';

const COLUMNS = [
  { intlId: 'app.name' },
  { intlId: 'app.link' },
  { intlId: 'app.international' },
];

const SourcesOrders = () => {
  const intl = useIntl();

  const navigation = useHistory();
  const { data, fetchMore, ...queryProps } = useGetPurchaseSourcesQuery();

  const fetchMoreData = async (resolve, reject) => {
    await fetchMore({
      variables: {
        offset: data.purchaseSources?.length,
        limit: DATA_REQUEST_LIMIT,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          reject && reject();
          return prev;
        }

        resolve && resolve();

        return {
          purchaseSources: [
            ...prev?.purchaseSources,
            ...fetchMoreResult?.purchaseSources,
          ],
        };
      },
    });
  };

  const handleClickAddButton = () => {
    navigation.push('/settings/sourcesorder/');
  };

  const handleEditButtonClick = (id) => {
    navigation.push(`/settings/sourcesorder/${id}`);
  };

  const getIsInternationalTitle = (isInternational) => {
    if (isInternational) return intl.formatMessage({ id: 'app.yes' });
    else return intl.formatMessage({ id: 'app.no' });
  };

  if (IS_DESKTOP_SCREEN) {
    return (
      <Layout isScrollable={false} onActionButtonPress={handleClickAddButton}>
        <RequestHandler {...queryProps}>
          <DesktopTable fetchMoreData={fetchMoreData}>
            <DesktopTable.Head>
              {COLUMNS?.map(({ intlId }) => (
                <DesktopTable.Title key={intlId} intlId={intlId} />
              ))}
            </DesktopTable.Head>
            <DesktopTable.Body>
              {data?.purchaseSources?.map((item) => (
                <DesktopTable.Row
                  key={item?.id}
                  onEditButtonClick={() => handleEditButtonClick(item?.id)}
                >
                  <DesktopTable.Cell>{item?.name}</DesktopTable.Cell>
                  <DesktopTable.Cell>
                    <TextLink link={item?.link} intlId={item?.link} />
                  </DesktopTable.Cell>
                  <DesktopTable.Cell>
                    {getIsInternationalTitle(item?.isInternational)}
                  </DesktopTable.Cell>
                </DesktopTable.Row>
              ))}
            </DesktopTable.Body>
          </DesktopTable>
        </RequestHandler>
      </Layout>
    );
  }
  return (
    <Layout isScrollable={false} onActionButtonPress={handleClickAddButton}>
      <RequestHandler {...queryProps}>
        <MobileTable
          data={data?.purchaseSources}
          renderItem={(item) => (
            <SourcesOrderMobileItem
              onItemPress={handleEditButtonClick}
              id={item.id}
              name={item.name}
              link={item.link}
              internationalTitle={getIsInternationalTitle(item?.isInternational)}
            />
          )}
          fetchMoreData={(resolve) => resolve()}
        />
      </RequestHandler>
    </Layout>
  );
};

export default SourcesOrders;
