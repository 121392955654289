import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { TextInput } from 'react-native';

import { FieldWrapper } from '../../components';
import { IS_MOBILE_SCREEN, theme } from '../../constants';
import { getValueFromPath } from '../../utils';
import createStyles from './styles';

export const TextInputHookForm = (props) => {
  const { control, formState } = useFormContext();

  const errorMessageText = getValueFromPath(formState.errors, props.name)?.message;

  const hasError = Boolean(errorMessageText);

  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <TextInputHookFormNested
      {...props}
      control={control}
      hasError={hasError}
      errorMessageText={errorMessageText}
      isFocused={isFocused}
      setIsFocused={setIsFocused}
      isHovered={isHovered}
      setIsHovered={setIsHovered}
    />
  );
};

const TextInputHookFormNested = memo(
  ({
    hasError,
    width,
    label,
    control,
    name,
    errorMessageText,
    placeholderIntlId = 'app.enterValue',
    isReadOnly,
    isFocused,
    setIsFocused,
    isHovered,
    setIsHovered,
  }) => {
    const intl = useIntl();
    const [inputHeight, setInputHeight] = useState(66);

    const styles = createStyles(hasError, inputHeight);

    return (
      <FieldWrapper
        isInvalid={hasError}
        label={label}
        errorMessageText={errorMessageText}
        width={width || (IS_MOBILE_SCREEN ? '100%' : '50%')}
      >
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => {
            return (
              <TextInput
                style={[
                  styles.textInput,
                  isFocused && styles.textInputFocus,
                  !isFocused && isHovered && styles.textInputHover,
                ]}
                multiline
                isReadOnly={isReadOnly}
                value={value}
                onChangeText={onChange}
                placeholder={
                  intl.messages[placeholderIntlId]
                    ? intl.formatMessage({ id: placeholderIntlId })
                    : placeholderIntlId
                }
                placeholderTextColor={theme.colors.gray}
                onContentSizeChange={(event) => {
                  setInputHeight(event.nativeEvent.contentSize.height);
                }}
                numberOfLines={2}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              />
            );
          }}
        />
      </FieldWrapper>
    );
  },
);

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholderIntlId: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isReadOnly: PropTypes.bool,
};

TextInputHookFormNested.displayName = 'TextFieldHookFormNested';
TextInputHookForm.propTypes = propTypes;

TextInputHookFormNested.propTypes = {
  ...propTypes,
  hasError: PropTypes.bool,
  control: PropTypes.object,
  errorMessageText: PropTypes.string,
};
