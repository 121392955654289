import AsyncStorage from '@react-native-async-storage/async-storage';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
// import Constants from 'expo-constants';
import create from 'zustand';

// function getCurrentHost() {
//   const host = Constants?.manifest?.debuggerHost?.split(':')[0] || 'localhost';
//   return `http://${host}/graphql`;
// }

export const setToken = async (token) => {
  // eslint-disable-next-line no-useless-catch
  try {
    await AsyncStorage.setItem('authToken', token);
    return true;
  } catch (e) {
    throw e;
  }
};
export const useErrorsStore = create(() => ({
  hasError: false,
  error: null,
  operation: null,
}));
// for relise
// const httpLink = new BatchHttpLink({ uri: '/graphql' });

// for demo server
// const httpLink = new BatchHttpLink({ uri: 'http://crm.demo.flexibleshop.net/graphql' });

// for testing
const httpLink = new BatchHttpLink({ uri: 'http://test.crm.flexibleshop.net/graphql' });
// const httpLink = new BatchHttpLink({ uri: 'http://localhost/graphql' });

let token;

const withToken = setContext(async () => {
  if (!token) {
    token = await AsyncStorage.getItem('authToken');
  }
  return {
    headers: {
      ...(token ? { authorization: `Bearer ${token}` } : null),
      'content-type': 'application/json',
    },
  };
});

const resetToken = onError(({ networkError, graphQLErrors, operation }) => {
  if (networkError || graphQLErrors[0]) {
    useErrorsStore.setState({
      hasError: true,
      error: networkError || graphQLErrors[0],
      operation: operation.operationName,
    });
  }
  if (networkError && networkError.statusCode === 401) {
    token = undefined;
  }
});

const authFlowLink = withToken.concat(resetToken);

const link = authFlowLink.concat(httpLink);

const cache = new InMemoryCache();

export default new ApolloClient({
  link,
  cache,
});
