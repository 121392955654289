import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const DeleteIcon = ({ size = 11, style = {} }) => {
  return (
    <Svg
      width={size}
      height={size + 3}
      viewBox='0 0 11 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ ...style }}
    >
      <Path
        d='M10.7383 0.988281V2.5H0.261719V0.988281H2.86328L3.63672 0.25H7.36328L8.13672 0.988281H10.7383ZM1 12.2383V3.23828H10V12.2383C10 12.6367 9.84766 12.9883 9.54297 13.293C9.23828 13.5977 8.88672 13.75 8.48828 13.75H2.51172C2.11328 13.75 1.76172 13.5977 1.45703 13.293C1.15234 12.9883 1 12.6367 1 12.2383Z'
        fill='#BDBDBD'
      />
    </Svg>
  );
};

DeleteIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
