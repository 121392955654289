import { useField, useFormikContext } from 'formik';
import { Input } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import { FLOAT_NUMBER_REGEX } from '../../constants';
import { getCourseValue, getReverseCourseValue } from '../../utils';
import { FieldWrapper } from '../FieldWrapper/FieldWrapper';

export const TextField = memo(
  ({
    name,
    label,
    value,
    type = 'text',
    inputType = 'text',
    keyboardType = 'default',
    placeholderIntlId = 'app.enterValue',
    mb,
    onChange,
    isLight,
    isDisabled,
    width,
    style,
    isRatioToReverseCourse,
    isReverseCourseToRatio,
    inputProps,
    numberFixedCount,
    maxValue,
    height = '18px',
    ...props
  }) => {
    const [field, meta] = useField(name);

    const { setFieldValue } = useFormikContext();
    const intl = useIntl();

    const getNumberTypeValue = (value, numberFixedCount) => {
      const valueArray = value.replace(FLOAT_NUMBER_REGEX, '').split('.');
      if (valueArray.length > 1) {
        if (valueArray[1].length >= numberFixedCount) {
          setFieldValue(
            name,
            Number(value.replace(FLOAT_NUMBER_REGEX, '')).toFixed(numberFixedCount),
          );
        } else {
          setFieldValue(name, value.replace(FLOAT_NUMBER_REGEX, ''));
        }
      } else {
        setFieldValue(name, value.replace(FLOAT_NUMBER_REGEX, ''));
      }
    };

    const handleChangeInput = (currentValue) => {
      if (isRatioToReverseCourse) {
        setFieldValue('reverseCourse', getReverseCourseValue(currentValue));
        setFieldValue(name, currentValue.replace(FLOAT_NUMBER_REGEX, ''));
      } else if (isReverseCourseToRatio) {
        setFieldValue('ratio', getCourseValue(currentValue));
        setFieldValue(name, currentValue.replace(FLOAT_NUMBER_REGEX, ''));
      } else {
        if (type === 'number') {
          if (numberFixedCount === 0 || numberFixedCount) {
            getNumberTypeValue(currentValue, numberFixedCount);
          } else {
            setFieldValue(name, currentValue.replace(FLOAT_NUMBER_REGEX, ''));
          }
          if (
            (maxValue || maxValue === 0) &&
            (currentValue > maxValue || currentValue === '0')
          ) {
            setFieldValue(name, maxValue || '');
          }
        } else {
          if (onChange) {
            onChange(currentValue);
          }
          setFieldValue(name, currentValue);
        }
      }
    };

    const hasError = Boolean(meta.error && meta.touched);

    return (
      <FieldWrapper
        width={width}
        mb={mb}
        style={style}
        isLight={isLight}
        isInvalid={hasError}
        label={label}
        errorMessageText={meta.error}
        height={height}
        {...props}
      >
        <Input
          value={value || field?.value}
          onChangeText={handleChangeInput}
          placeholder={
            intl.messages[placeholderIntlId]
              ? intl.formatMessage({ id: placeholderIntlId })
              : placeholderIntlId
          }
          height={'33px'}
          isDisabled={isDisabled}
          maxLength={255}
          keyboardType={type === 'number' ? 'numeric' : keyboardType}
          type={inputType}
          {...inputProps}
        />
      </FieldWrapper>
    );
  },
);

TextField.displayName = 'TextField';

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  numberFixedCount: PropTypes.number,
  placeholderIntlId: PropTypes.string,
  onChange: PropTypes.func,
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isDisabled: PropTypes.bool,
  keyboardType: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  inputProps: PropTypes.object,
  isLight: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.oneOf(['text', 'number']),
  inputType: PropTypes.oneOf(['text', 'password']),
  isRatioToReverseCourse: PropTypes.bool,
  isReverseCourseToRatio: PropTypes.bool,
  maxValue: PropTypes.number,
};
