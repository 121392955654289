import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const DocumentationIcon = ({
  size = 24,
  style = {},
  color1 = '#5F27CD',
  color2 = '#956CE7',
}) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 40 40' style={{ ...style }}>
      <Path
        d='M33.4375 17.5H22.5C21.5054 17.5 20.5516 17.1049 19.8483 16.4017C19.1451 15.6984 18.75 14.7446 18.75 13.75V2.8125C18.75 2.72962 18.7171 2.65013 18.6585 2.59153C18.5999 2.53292 18.5204 2.5 18.4375 2.5H11.25C9.92392 2.5 8.65215 3.02678 7.71447 3.96447C6.77678 4.90215 6.25 6.17392 6.25 7.5V32.5C6.25 33.8261 6.77678 35.0979 7.71447 36.0355C8.65215 36.9732 9.92392 37.5 11.25 37.5H28.75C30.0761 37.5 31.3479 36.9732 32.2855 36.0355C33.2232 35.0979 33.75 33.8261 33.75 32.5V17.8125C33.75 17.7296 33.7171 17.6501 33.6585 17.5915C33.5999 17.5329 33.5204 17.5 33.4375 17.5ZM26.25 30H13.75C13.4185 30 13.1005 29.8683 12.8661 29.6339C12.6317 29.3995 12.5 29.0815 12.5 28.75C12.5 28.4185 12.6317 28.1005 12.8661 27.8661C13.1005 27.6317 13.4185 27.5 13.75 27.5H26.25C26.5815 27.5 26.8995 27.6317 27.1339 27.8661C27.3683 28.1005 27.5 28.4185 27.5 28.75C27.5 29.0815 27.3683 29.3995 27.1339 29.6339C26.8995 29.8683 26.5815 30 26.25 30ZM26.25 23.75H13.75C13.4185 23.75 13.1005 23.6183 12.8661 23.3839C12.6317 23.1495 12.5 22.8315 12.5 22.5C12.5 22.1685 12.6317 21.8505 12.8661 21.6161C13.1005 21.3817 13.4185 21.25 13.75 21.25H26.25C26.5815 21.25 26.8995 21.3817 27.1339 21.6161C27.3683 21.8505 27.5 22.1685 27.5 22.5C27.5 22.8315 27.3683 23.1495 27.1339 23.3839C26.8995 23.6183 26.5815 23.75 26.25 23.75Z'
        fill={color1}
      />
      <Path
        d='M32.7516 14.7337L21.5164 3.49856C21.4946 3.47684 21.4668 3.46207 21.4366 3.45609C21.4063 3.45012 21.375 3.45321 21.3465 3.46498C21.3181 3.47675 21.2937 3.49668 21.2765 3.52225C21.2594 3.54783 21.2501 3.57791 21.25 3.60872V13.7501C21.25 14.0816 21.3817 14.3996 21.6161 14.634C21.8505 14.8684 22.1685 15.0001 22.5 15.0001H32.6414C32.6722 15 32.7023 14.9908 32.7279 14.9736C32.7534 14.9564 32.7734 14.9321 32.7851 14.9036C32.7969 14.8751 32.8 14.8438 32.794 14.8136C32.7881 14.7834 32.7733 14.7556 32.7516 14.7337Z'
        fill={color2}
      />
    </Svg>
  );
};

DocumentationIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
  color1: PropTypes.string,
  color2: PropTypes.string,
};
