import PropTypes from 'prop-types';
import React from 'react';

import { getFormattedDate } from '../../utils';
import { Typography } from '../Typography/Typography';

export const FormattedDate = ({ date, format = 'd.MM.y', ...props }) => {
  if (!date) return null;
  return <Typography {...props}>{getFormattedDate(date, format)}</Typography>;
};

FormattedDate.propTypes = {
  date: PropTypes.any,
  format: PropTypes.string,
};
