import _ from 'lodash';

const compareByKeys = (obj1, obj2, keys) => {
  for (let key of keys) {
    const keysPath = key.split('.');
    const value1 = _.get(obj1, keysPath);
    const value2 = _.get(obj2, keysPath);
    if (!_.isEqual(value1, value2)) {
      return false;
    }
  }
  return true;
};

export const checkIsArraysAreEqualByKeys = (arr1, arr2, keys) => {
  if (arr1?.length !== arr2?.length) {
    return false;
  }
  for (let item1 of arr1) {
    const found = arr2?.some((item2) => compareByKeys(item1, item2, keys));
    if (!found) {
      return false;
    }
  }

  return true;
};
