import { StyleSheet, Dimensions } from 'react-native';
import { displayWidth } from '../../../constants/index';

const width = Dimensions.get('window').width >= displayWidth;

export default StyleSheet.create({
  item: {
    backgroundColor: '#fff',
    marginBottom: 25,
    marginLeft: 10,
    marginRight: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
    paddingTop: 25,
    paddingBottom: 25,
    paddingRight: 20,
    paddingLeft: 20,
  },
  top: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  name: {
    fontSize: 14,
    color: '#4F4F4F',
    marginBottom: 8,
  },
  role: {
    fontSize: 11,
    color: '#828282',
  },
  contact: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  contactText: {
    fontSize: 14,
    marginLeft: 15,
  },
  input: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderBottomWidth: 1,
    borderColor: 'black',
    marginBottom: width ? 0 : 5,
    width: '97%',
  },
  inputFilter: {
    width: '97%',
    marginBottom: 5,
  },
  listCity: {
    display: 'flex',
    height: 200,
    paddingHorizontal: 10,
    marginBottom: 5,
    backgroundColor: '#fff',
  },
  city: {
    padding: 5,
  },
  user: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#C4C4C4',
    borderRadius: 10,
    marginTop: 5,
    paddingLeft: 7,
    paddingRight: 7,
  },
  iconOpen: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  multiSelectHead: {
    height: 17,
    width: '95%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  items: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexWrap: width ? 'nowrap' : 'wrap',
  },
  inputName: {
    width: 150,
    backgroundColor: '#f2f2f2',
    paddingVertical: 3,
    borderBottomWidth: 1,
    borderColor: '#333333',
  },
});
