import { MaterialIcons } from '@expo/vector-icons';
import { Box } from 'native-base';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Avatar, LogOutModal } from '../../../components';
import { ROUTES } from '../../../constants';
import { useFiltersContext } from '../../../context';
import { useRoute, useUser } from '../../../hooks';
import { CurrencySelect } from '../CurrencySelect/CurrencySelect';
import { NavigationPanel } from '../NavigationPanel/NavigationPanel';
import { styles } from './styles';

export const HeaderRight = ({ isSingleScreen }) => {
  const { path, pathname } = useRoute();
  const { toggleFilter, isFilterOpen } = useFiltersContext();
  const user = useUser();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {ROUTES.isChoiceCurrencyScreen(path) || ROUTES.isChoiceCurrencyScreen(pathname) ? (
        <Box mr='12px'>
          <CurrencySelect />
        </Box>
      ) : null}
      {ROUTES.isFilteredScreen(path) ||
      (ROUTES.isFilteredScreen(pathname) && !isSingleScreen) ? (
        <MaterialIcons
          style={[styles.iconFilter, isFilterOpen && styles.iconFilterActive]}
          onPress={toggleFilter}
          name='filter-list'
          size={24}
          color='#fff'
        />
      ) : null}
      {isSingleScreen ? null : (
        <Box mr='lg'>
          <NavigationPanel />
        </Box>
      )}
      <TouchableOpacity onPress={() => setIsModalOpen(true)}>
        <Avatar
          uri={user?.avatar}
          firstName={user?.firstName}
          lastName={user?.lastName}
          bgColor='primary.default'
        />
      </TouchableOpacity>
      {isModalOpen ? <LogOutModal user={user} setIsModalOpen={setIsModalOpen} /> : null}
    </>
  );
};

HeaderRight.propTypes = {
  isSingleScreen: PropTypes.bool,
};
