import { StyleSheet } from 'react-native';

import { colors } from '../../../../theme';

export default StyleSheet.create({
  card: {
    elevation: 5,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,

    shadowRadius: 3.84,
  },
});
