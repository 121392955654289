/* eslint-disable react/prop-types */
import { useQuery } from '@apollo/client';
import { Formik } from 'formik';
import React from 'react';
import { View } from 'react-native';

import { ErrorIndicator, Modal, Preloader } from '../../../components/UI';
import globalStyles from '../../../globalStyles';
import { CUSTOMERS_FILTER } from '../../../graphql';
import { useFilter } from '../../../hooks';
import FilterFieldsDesktop from './desktop/FilterFieldsDesktop';
import FilterFieldsMobile from './mobile/FilterFieldsMobile';
import validationSchema from './validationSchema';

function Filter({ withModal, onClose }) {
  const { filters, setFilters } = useFilter();
  const { error, data, loading } = useQuery(CUSTOMERS_FILTER);

  if (withModal) {
    if (loading)
      return (
        <Modal onClose={onClose}>
          <Preloader />
        </Modal>
      );
    if (error)
      return (
        <Modal onClose={onClose}>
          <ErrorIndicator />
        </Modal>
      );
  } else {
    if (loading)
      return (
        <View style={globalStyles.filterWeb}>
          <Preloader />
        </View>
      );
    if (error)
      return (
        <View style={globalStyles.filterWeb}>
          <ErrorIndicator />
        </View>
      );
  }

  return (
    <>
      <Formik
        initialValues={{
          dateFrom: filters?.dateFrom || '',
          dateTo: filters?.dateTo || '',
          name: filters?.name || '',
          phone: filters?.phone || '',
          email: filters?.email || '',
        }}
        onSubmit={(values) => setFilters(values)}
        validationSchema={validationSchema}
      >
        {({ values, handleSubmit, setFieldValue }) => {
          const setValue = (name, value) => {
            setFieldValue(name, value);
            handleSubmit();
          };
          return withModal ? (
            <FilterFieldsMobile
              data={data}
              setFieldValue={setFieldValue}
              values={values}
              handleSubmit={handleSubmit}
              sorting={filters}
              setSorting={setFilters}
              onClose={onClose}
            />
          ) : (
            <FilterFieldsDesktop
              data={data}
              onChange={setValue}
              values={values}
              setFieldValue={setFieldValue}
              handleSubmit={handleSubmit}
            />
          );
        }}
      </Formik>
    </>
  );
}

Filter.propTypes = {};

export default Filter;
