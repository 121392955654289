/* eslint-disable */
import React from 'react';

const webTableStyles = ({ inModal }) => `
  .desktop-table {
  border-spacing: 0;
    width: 100%;
  }

  .desktop-table.editable tbody:not(.is-empty) tr:hover {
    background-color: ${inModal ? 'transparent' : 'rgba(0, 0, 0, 0.02)'};
  }

  .desktop-table.editable tr td:last-child {
    padding-left: 0;
    padding-right: 0;
  }

  .desktop-table.editable tr td:last-child:not(:first-child) > div {
    display: none;
  }

  .desktop-table.editable th:last-child,
  .desktop-table.editable td:nth-last-child(+2) {
    padding-right: ${inModal ? '5px' : '50px'};
  }

  .desktop-table.editable tr:hover td:last-child:not(:first-child) > div {
    display: flex;
  }

  .desktop-table th,
  .desktop-table td {
    text-align: left;
    padding: 15px 5px;
  }
  
  .desktop-table th  div {
  color: #222222;
  font-size: 15.5px;
  }
  
  .desktop-table ${inModal ? 'td' : 'th, td'} {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  .desktop-table tr:last-child td {
    border-bottom: none;
  }

  .desktop-table th > button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
  }

  .desktop-table-spinner {
    bottom: 15px;
    left: 50%;
    margin-left: -15px;
    position: absolute;
  }
`;

export const sortIconStyles = {
  marginLeft: 12,
  position: 'relative',
  bottom: -2,
};

export const TableStyles = ({ inModal }) => {
  return <style jsx=''>{webTableStyles({ inModal })}</style>;
};
