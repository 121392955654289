import { Formik } from 'formik';
import { Box } from 'native-base';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  BoxWrapper,
  DatePicker,
  FieldRow,
  FormRow,
  FormWrapper,
  Layout,
  RequestHandler,
  Select,
  TextField,
  Typography,
} from '../../../components';
import { TextareaInput } from '../../../components/TextField/TextareaInput';
import { useBreadcrumbsContext } from '../../../context';
import {
  useAddTransactionMutation,
  useDeleteTransactionMutation,
  useGetBankAccounts,
  useGetTransactionQuery,
  useGetTransactionsTypesQuery,
  useParams,
} from '../../../hooks';
import { transactionSchema } from '../../../services/validation';
import { transformSelectOptions } from '../../../utils';
import { TransactionForwarding } from './TransactionForwarding';
import { TransactionOrder } from './TransactionOrder';
import { TransactionPurchase } from './TransactionPurchase';
import { TransactionUser } from './TransactionUser';

const DEFAULT_TYPE_ID = '11';

const Transaction = () => {
  const { data: bankAccounts, ...bankAccountsQueryProps } = useGetBankAccounts();
  const { data: transactionsTypes, ...typesQueryProps } = useGetTransactionsTypesQuery();
  const [addTransaction, ...addTransactionProps] = useAddTransactionMutation();
  const { data: transactionData, ...transactionQueryProps } = useGetTransactionQuery();
  const [remove] = useDeleteTransactionMutation();
  const { setBreadcrumbsValues } = useBreadcrumbsContext();

  const params = useParams();

  const [isParish, setIsParish] = useState(false);
  const [currentType, setCurrentType] = useState(DEFAULT_TYPE_ID);

  const defaultType = transactionsTypes?.transactionTypes?.find(
    (el) => el.id === DEFAULT_TYPE_ID,
  );

  const transaction = transactionData?.transaction;

  const onSubmit = (values) => {
    addTransaction({
      variables: {
        input: {
          id: params.id ? +params.id : undefined,
          date: values.date,
          amount: values.amount,
          typeId: +values.typeId,
          bankAccountId: +values.bankAccountId,
          comment: values.comment,
          ...(+values.typeId === 7
            ? { forwardingId: +values.forwarding?.id }
            : { forwardingId: null }),
          ...(+values.typeId === 6
            ? { purchaseId: +values.purchase?.id }
            : { purchaseId: null }),
          ...(+values.typeId === 1 || +values.typeId === 3 || +values.typeId === 8
            ? {
                orderId: +values.order.id,
              }
            : { orderId: null }),
          ...(+values.typeId === 4 || +values.typeId === 5
            ? {
                userId: +values.user.id,
              }
            : { userId: null }),
        },
      },
    });
  };

  const handleChangeType = (typeId, setFieldValue) => {
    setIsParish(typeId === '1' || typeId === '2');
    setCurrentType(typeId);
    setFieldValue('typeId', typeId);
    const type = transactionsTypes?.transactionTypes?.find((el) => el.id === typeId);
    setFieldValue('typeName', type?.name);
  };

  const onRemove = () => remove({ variables: { id: +params.id } });

  //typeName field value exists and set in only for TransactionBreadcrumbs

  const initialValues = {
    date: transaction?.date || new Date(),
    typeId: transaction?.type?.id || DEFAULT_TYPE_ID,
    typeName: transaction?.type?.name || defaultType?.name,
    amount: transaction?.amount || '',
    bankAccountId: transaction?.bankAccount?.id.toString() || '',
    comment: transaction?.comment || '',
    forwarding: transaction?.forwarding || '',
    purchase: transaction?.purchase || '',
    user: transaction?.user || '',
    order: transaction?.order || '',
  };

  return (
    <RequestHandler
      loading={
        typesQueryProps.loading ||
        bankAccountsQueryProps.loading ||
        transactionQueryProps.loading
      }
      error={typesQueryProps.error || bankAccountsQueryProps.error}
      backgroundLoading={addTransactionProps.loading}
    >
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={transactionSchema(currentType)}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <Layout
              onActionButtonPress={handleSubmit}
              actionButtonType='save'
              actionButtonIsLoading={addTransactionProps.loading}
              onRemoveButtonPress={onRemove}
            >
              <FormRow>
                {setBreadcrumbsValues(values)}
                <FormRow.Left>
                  <BoxWrapper>
                    <Typography
                      intlId={isParish ? 'app.parish' : 'app.consumption'}
                      variant='heading'
                    />
                    <FieldRow withNegativeBottomMargin={false}>
                      <Box>
                        <DatePicker name='date' label='app.date' />
                        <TextField
                          inputProps={{ pb: '9px' }}
                          name='amount'
                          label='app.sum'
                          type='number'
                        />
                      </Box>
                      <Box>
                        <Select
                          name='typeId'
                          label='app.type'
                          options={transformSelectOptions({
                            data: transactionsTypes?.transactionTypes,
                          })}
                          onChange={(typeId) => handleChangeType(typeId, setFieldValue)}
                        />
                        <Select
                          name='bankAccountId'
                          label='app.account'
                          options={transformSelectOptions({
                            data: bankAccounts?.bankAccounts,
                            label: 'title',
                          })}
                        />
                      </Box>
                    </FieldRow>
                  </BoxWrapper>
                  <BoxWrapper>
                    <Typography intlId='app.comment' variant='heading' />
                    <FieldRow>
                      <TextareaInput
                        placeholderIntlId={'app.enterComment'}
                        name='comment'
                        type='text'
                      />
                    </FieldRow>
                  </BoxWrapper>
                </FormRow.Left>
                <FormRow.Right>
                  <FormController typeId={values.typeId} />
                </FormRow.Right>
              </FormRow>
            </Layout>
          )}
        </Formik>
      </FormWrapper>
    </RequestHandler>
  );
};

const FormController = ({ typeId }) => {
  if (['1', '3', '8'].includes(typeId)) {
    return <TransactionOrder />;
  }
  if (['6'].includes(typeId)) {
    return <TransactionPurchase />;
  }
  if (['7'].includes(typeId)) {
    return <TransactionForwarding />;
  }
  if (['4', '5'].includes(typeId)) {
    return <TransactionUser />;
  }
  return null;
};

FormController.propTypes = {
  typeId: PropTypes.string.isRequired,
};

export default Transaction;
