import PropTypes from 'prop-types';
import React from 'react';
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';

export const BoxesIcon = ({ size = 24, style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' style={{ ...style }}>
      <G clipPath='url(#clip0)'>
        <Path
          d='M15.9639 12.6C15.3301 12.6 14.7339 12.2625 14.4114 11.7225L12.0001 7.72499L9.59262 11.7225C9.26637 12.2662 8.67012 12.6037 8.03637 12.6037C7.86762 12.6037 7.69887 12.5812 7.53762 12.5325L2.40012 11.0625V17.7375C2.40012 18.2887 2.77512 18.7687 3.30762 18.9L11.4151 20.9287C11.7976 21.0225 12.1989 21.0225 12.5776 20.9287L20.6926 18.9C21.2251 18.765 21.6001 18.285 21.6001 17.7375V11.0625L16.4626 12.5287C16.3014 12.5775 16.1326 12.6 15.9639 12.6ZM23.9364 8.39249L22.0051 4.53749C21.8889 4.30499 21.6376 4.16999 21.3789 4.20374L12.0001 5.39999L15.4389 11.1037C15.5814 11.34 15.8664 11.4525 16.1326 11.3775L23.5539 9.25874C23.9251 9.14999 24.1051 8.73749 23.9364 8.39249ZM1.99512 4.53749L0.0638724 8.39249C-0.108628 8.73749 0.0751224 9.14999 0.442622 9.25499L7.86387 11.3737C8.13012 11.4487 8.41512 11.3362 8.55762 11.1L12.0001 5.39999L2.61762 4.20374C2.35887 4.17374 2.11137 4.30499 1.99512 4.53749Z'
          fill='white'
        />
      </G>
      <Defs>
        <ClipPath id='clip0'>
          <Rect width='24' height='24' fill='white' />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

BoxesIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
