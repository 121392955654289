import { getFormattedDate } from '../utils';
import { useIntl } from './useIntl';

export const useOrderData = () => {
  const intl = useIntl();

  const getDate = (createdAt) => getFormattedDate(createdAt, 'd.MM.y HH:mm');

  const getManagerName = (user) => {
    return user
      ? `${user?.lastName} ${user?.firstName}`
      : intl.formatMessage({ id: 'app.no' });
  };

  const getCustomerName = (customer) => {
    const userName = customer
      ? `${customer?.firstName ? customer?.firstName : ''} ${
          customer?.lastName ? customer?.lastName : ''
        }`
      : null;
    return userName ? userName : intl.formatMessage({ id: 'app.no' });
  };

  const getPaymentStatus = (paymentStatus) => {
    return paymentStatus?.name || intl.formatMessage({ id: 'app.no' });
  };

  const getStatus = (status) => {
    return status?.name || intl.formatMessage({ id: 'app.no' });
  };

  const getAmount = (orderItems, discount) => {
    const amount =
      orderItems?.reduce((sum, value) => sum + value?.pricePerPcs * value?.quantity, 0) -
      discount;

    return `${amount?.toFixed(2)} ${intl.formatMessage({
      id: 'currency.uah',
    })}`;
  };

  const getStatusColor = (id) => {
    switch (parseInt(id || 0)) {
      case 1:
        return { color: '#38761d', weight: 'medium' };
      case 2:
        return { color: '#fbbc04', weight: 'medium' };
      case 3:
        return { color: '#EB5757', weight: 'medium' };
      case 4:
        return { color: '#4a86e8', weight: 'medium' };
      case 5:
        return { color: 'rgba(0, 0, 0, 0.87)', weight: 'medium' };
      case 6:
        return { color: '#666', weight: 'medium' };
      case 7:
        return { color: '#666', weight: 'medium' };
      case 8:
        return { color: '#b45f06', weight: 'medium' };
      case 9:
        return { color: '#cc0000', weight: 'medium' };
      default:
        return {};
    }
  };

  return {
    getOrderData: (type, order) => {
      if (type === 'id') return order?.id;
      if (type === 'date') return getDate(order?.createdAt);
      if (type === 'manager') return getManagerName(order?.user);
      if (type === 'customer') return getCustomerName(order?.customer);
      if (type === 'source') return order?.source?.name;
      if (type === 'paymentStatus') return getPaymentStatus(order?.paymentStatus);
      if (type === 'status') return getStatus(order?.status, intl);
      if (type === 'statusId') return order?.status?.id;
      if (type === 'amount') return getAmount(order?.orderItems, order?.discount);
      return null;
    },
    getOrderStatusColor: (order) => getStatusColor(order?.status?.id),
  };
};
