import { Flex } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { TouchableOpacity } from 'react-native';

import { getVolumeWeightProduct, getWeightProduct } from '../../utils';
import { BoxWrapper, LinkDescription, PurchaseDescription } from '../index';

export const PurchaseCard = ({ item, handleSet }) => {
  const intl = useIntl();
  return (
    <TouchableOpacity onPress={() => handleSet(item)}>
      <BoxWrapper mb={'8px'}>
        <LinkDescription
          link={`/purchase/purchase/${item?.id}`}
          date={item?.purchaseTime}
          intlId={`№ ${item?.id}`}
        />
        <Flex justify={'space-between'}>
          <Flex direction={'column'} pl={'15px'}>
            <PurchaseDescription
              title={'app.cost'}
              data={item?.price ? `${item?.price} $` : 'app.noData'}
            />
            <PurchaseDescription
              title={'app.deliveryCost'}
              data={item?.deliveryPrice ? `${item?.deliveryPrice} $` : 'app.noData'}
            />
          </Flex>
          <Flex direction={'column'}>
            <PurchaseDescription
              title={'app.volumeWeight'}
              data={getVolumeWeightProduct(item, intl) || 'app.noData'}
            />
            <PurchaseDescription
              title={'app.weight'}
              data={getWeightProduct(item) || 'app.noData'}
            />
          </Flex>
        </Flex>
      </BoxWrapper>
    </TouchableOpacity>
  );
};

PurchaseCard.propTypes = {
  item: PropTypes.object.isRequired,
  handleSet: PropTypes.func.isRequired,
};
