import { StyleSheet } from 'react-native';

import { theme } from '../../constants';

export const styles = StyleSheet.create({
  actions: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  backIcon: {
    left: 10,
    position: 'absolute',
    top: 14,
    zIndex: 1,
  },
  centerView: {
    flex: 1,
    justifyContent: 'center',
  },
  footer: {
    bottom: 0,
    left: 8,
    position: 'absolute',
    right: 8,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 16,
  },
  inner: {
    backgroundColor: theme?.colors?.gray,
    height: '100%',
    position: 'relative',
  },
});
