import { formatDate } from './formatDate';

export const getDateWithNumber = (purchase, intl) => {
  if (purchase?.id) {
    return purchase?.purchaseTime
      ? `№ ${purchase.id} ${intl.formatMessage({ id: 'app.from' })} ${formatDate(
          Number(+purchase?.purchaseTime),
        )}`
      : `№ ${purchase.id}`;
  }
};
