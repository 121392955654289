/* eslint-disable react-native/no-inline-styles */
import { MaterialIcons } from '@expo/vector-icons';
import PropTypes from 'prop-types';
import React from 'react';
import { FAB, Portal } from 'react-native-paper';

import { useMediaQuery } from '../../../hooks';
import styles from './styles';

export const DeleteButton = ({ onPress }) => {
  const isDesktop = useMediaQuery('isDesktop');

  return (
    <Portal>
      <FAB
        icon={() => <MaterialIcons name='delete-forever' size={25} color='#fff' />}
        onPress={onPress}
        small
        style={isDesktop ? styles.removeButtonWeb : styles.removeButton}
      />
    </Portal>
  );
};

DeleteButton.propTypes = {
  onPress: PropTypes.func,
};
