import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { QuerySelectWithSearchHookForm } from '../../../../components/QuerySelectWithSearch/QuerySelectWithSearchHookForm';
import { GET_STREETS_NP } from '../../../../graphql';

export const StreetAutoCompleteFieldHookForm = ({
  name,
  cityRef,
  streetRef,
  street,
  onChange,
}) => {
  const args = { cityRef: cityRef };

  const labelProperty = useMemo(() => {
    return ['description', 'type'];
  }, []);
  const initOption =
    cityRef && streetRef && street ? { value: streetRef, label: street } : null;

  return (
    <QuerySelectWithSearchHookForm
      width={'100%'}
      label='app.street'
      initialOption={initOption}
      placeholderIntlId='app.enterValue'
      fieldName={name}
      labelProperty={labelProperty}
      valueProperty='ref'
      pl={0}
      pr={0}
      graphql={GET_STREETS_NP}
      graphqlName='getStreetNovaPoshta'
      args={args}
      onChange={onChange}
      isStreetNovaposhta
    />
  );
};

StreetAutoCompleteFieldHookForm.propTypes = {
  name: PropTypes.string.isRequired,
  cityRef: PropTypes.string.isRequired,
  streetRef: PropTypes.string,
  street: PropTypes.string,
  onChange: PropTypes.func,
};
