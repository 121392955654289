import queryString from 'query-string';

import { useSearchContext } from '../context';
import { convertToInt } from '../utils';
import useHistory from './useHistory';
import useRoute from './useRoute';

const useFilter = () => {
  const { search, pathname } = useRoute();
  const { changeSearchValue } = useSearchContext();
  const history = useHistory();

  const parsedFilters = queryString.parse(search);
  delete parsedFilters.search;

  return {
    filters: convertToInt(parsedFilters) || {},
    setFilters: (values) => {
      const newFilters = { ...parsedFilters, ...values };
      delete newFilters.search;

      history.push(pathname + '?' + queryString.stringify(newFilters));
    },
    clearFilters: () => {
      history.push(pathname);
      changeSearchValue('');
    },
  };
};

export default useFilter;
