const columnData = [
  { id: 'id', label: 'ID', width: '10%' },
  { id: 'datetime', label: 'Дата и время', width: '15%' },
  { id: 'manager', label: 'Менеджер', width: '15%' },
  { id: 'customer', label: 'Клиент', width: '15%' },
  { id: 'source', label: 'Источник', width: '10%' },
  { id: 'payment', label: 'Оплата', width: '10%' },
  { id: 'status', label: 'Статус', width: '15%' },
  { id: 'amount', label: 'Сумма', width: '9%' },
];

export default columnData;
