import React, { useRef } from 'react';
import { TouchableOpacity, Text, Clipboard, Animated, View } from 'react-native';
import { useIntl } from '../../../hooks';

const TextCopy = ({ title, style, styleText }) => {
  const intl = useIntl();
  const anim = useRef(new Animated.Value(0)).current;

  const setFade = (value, time) => {
    Animated.timing(anim, {
      toValue: value,
      duration: time,
    }).start(() => setFade(0, 1000));
  };

  return (
    <View style={[style, { alignSelf: 'flex-start', position: 'relative' }]}>
      <Animated.View
        style={{
          position: 'absolute',
          bottom: 15,
          left: '50%',
          // transform: [{ translateX: '-50%' }],
          opacity: anim,
        }}
      >
        <Text
          style={{
            color: '#47C8FF',
            fontWeight: 'bold',
          }}
        >
          {intl.formatMessage({
            id: 'app.copied',
          })}
        </Text>
      </Animated.View>
      <Text
        onDoubleClick={() => {
          Clipboard.setString(title);
          setFade(1, 150);
        }}
        style={[{ color: '#4F4F4F' }, styleText]}
      >
        {title}
      </Text>
    </View>
  );
};

export default TextCopy;
