export const getProductsVolumeWeight = (productsParent) => {
  let result = 0;

  productsParent?.forEach(({ product, quantity }) => {
    result +=
      ((product?.length || 0) *
        (product?.width || 0) *
        (product?.height || 0) *
        quantity) /
      4000;
  });

  if (result === 0) {
    return 0;
  }
  return result.toFixed(2);
};
