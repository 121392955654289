import { MaterialIcons } from '@expo/vector-icons';
import { Box, Center, Flex, Icon } from 'native-base';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { Portal } from 'react-native-paper';

import { RequestHandler, Typography } from '../../../components';
import { useCurrencyContext } from '../../../context';
import { useGetCurrenciesQuery } from '../../../hooks';
import HandlerClickOutside from '../../HandlerClickOutside/HandlerClickOutside';
import { SourceCurrencyIcon } from '../../Icons';
import { styles } from './styles';

export const CurrencySelect = () => {
  const { currentCurrency, changeCurrency } = useCurrencyContext();
  const { data: currenciesData, ...queryProps } = useGetCurrenciesQuery();

  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleChangeCurrency = (currency) => {
    changeCurrency(currency);
    setIsOpen(false);
  };

  return (
    <RequestHandler {...queryProps}>
      <Box position='relative'>
        <TouchableOpacity onPress={handleButtonClick} activeOpacity={1}>
          <Flex>
            {currentCurrency?.symbol ? (
              <CurrencyIcon color='white' textColor='#CE5DAE'>
                {currentCurrency?.symbol}
              </CurrencyIcon>
            ) : (
              <SourceCurrencyIcon color='white' />
            )}
            <Icon as={<MaterialIcons name='arrow-drop-down' />} size='sm' color='white' />
          </Flex>
        </TouchableOpacity>
        {isOpen ? (
          <Portal>
            <HandlerClickOutside onClickOutside={() => setIsOpen(false)}>
              <Box style={styles.inner}>
                <Typography intlId='app.currencyToDisplay' color='dark' fontSize='lg' />
                {currenciesData?.currencies?.map((item) => (
                  <CurrencyItem
                    isActive={currentCurrency?.id === item.id}
                    key={item.id}
                    item={item}
                    onPress={() => handleChangeCurrency(item)}
                  />
                ))}
                <CurrencyItem
                  isActive={!currentCurrency?.id}
                  isDefault
                  onPress={() => handleChangeCurrency('default')}
                />
              </Box>
            </HandlerClickOutside>
          </Portal>
        ) : null}
      </Box>
    </RequestHandler>
  );
};

const CurrencyIcon = ({ color = '#CE5DAE', textColor = 'white', children }) => {
  return (
    <Center width='20px' height='20px' backgroundColor={color} borderRadius='20px'>
      <Typography color={textColor} fontWeight='700' fontSize='12px'>
        {children}
      </Typography>
    </Center>
  );
};

CurrencyIcon.propTypes = {
  color: PropTypes.string,
  textColor: PropTypes.string,
  children: PropTypes.string,
};

const CurrencyItem = ({ item, onPress, isDefault = false, isActive = false }) => {
  return (
    <Box shadow={isActive ? 2 : false} borderRadius={5} p='10px' mt='10px'>
      <TouchableOpacity onPress={onPress}>
        <Flex>
          {isDefault ? (
            <SourceCurrencyIcon />
          ) : (
            <CurrencyIcon>{item.symbol}</CurrencyIcon>
          )}
          {isDefault ? (
            <Typography intlId='app.sourceCurrency' ml='10px' color='gray.main' />
          ) : (
            <Typography ml='10px' color='gray.main'>
              {item.name}
            </Typography>
          )}
          {!isDefault ? (
            <Typography fontWeight='700' color='gray.main' ml='auto'>
              {item?.code}
            </Typography>
          ) : null}
        </Flex>
      </TouchableOpacity>
    </Box>
  );
};

CurrencyItem.propTypes = {
  item: PropTypes.object,
  onPress: PropTypes.func,
  isDefault: PropTypes.bool,
  isActive: PropTypes.bool,
};
