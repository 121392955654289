import React from 'react';
import { TextInput, View, Text } from 'react-native';
import { DatePicker } from '../../../../components/UI';
import globalStyles from '../../../../globalStyles';
import { PhoneField, EmailField } from '../filterFields/';
import styles from '../Style';
import { isWeb } from '../../../../constants';
import { useIntl } from '../../../../hooks';

const FilterFieldsDesktop = ({ data, setFieldValue, values, handleSubmit }) => {
  const intl = useIntl();
  return (
    <View style={globalStyles.filterWeb}>
      <View>
        <Text>{intl.formatMessage({ id: 'app.id' })}</Text>
      </View>
      <View>
        <Text style={{ color: '#4F4F4F' }}>
          {intl.formatMessage({ id: 'customer.name' })}
        </Text>
        <TextInput
          style={[styles.inputName, isWeb && { outline: 'none' }]}
          value={values.name}
          onChangeText={(text) => {
            setFieldValue('name', text);
            if (values.name.length > 2) handleSubmit();
          }}
        />
      </View>
      <View>
        <EmailField
          name={'email'}
          label={intl.formatMessage({ id: 'app.email' })}
          handleChange={setFieldValue}
          value={values.email}
          emails={data.emails}
        />
      </View>
      <View>
        <PhoneField
          name={'phone'}
          label={intl.formatMessage({ id: 'app.phone' })}
          handleChange={setFieldValue}
          value={values.phone}
          phones={data.phones}
        />
      </View>
      <View style={{ paddingTop: 5 }}>
        <DatePicker
          name='dateFrom'
          value={values.dateFrom || new Date()}
          label={intl.formatMessage({ id: 'date.from' })}
          setFieldValue={setFieldValue}
        />
      </View>
      <View style={{ paddingTop: 5 }}>
        <DatePicker
          name='dateTo'
          value={values.dateTo || new Date()}
          label={intl.formatMessage({ id: 'date.to' })}
          setFieldValue={setFieldValue}
        />
      </View>
    </View>
  );
};

export default FilterFieldsDesktop;
