import { StyleSheet } from 'react-native';

import { colors } from '../../../../theme';
import { isWeb } from '../../../../constants';

export const styles = StyleSheet.create({
  download: {
    backgroundColor: colors.gray.background,
    borderColor: colors.gray.border,
    borderRadius: 3,
    borderStyle: 'solid',
    borderWidth: 1,
    flexDirection: 'column',
    height: 400,
    justifyContent: 'center',
    marginBottom: 24,
    width: 400,
  },
  text: {
    color: colors.gray.main,
    fontSize: 14,
    marginTop: 16,
  },
  textInfoUploadFile: {
    color: colors.gray.main,
    fontSize: 10,
    lineHeight: 12,
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.4)', 
    borderRadius:'5px'
  },
  isNotLastItem: {
    marginRight: 24
  },
  imagesWrapper: {
    gap:'24px'
  },
  photo: {
    cursor:'pointer'
  },
  modalWrapper: {
    ...(isWeb ? { boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)' } : null),
  }
});
