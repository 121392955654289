import { MaterialIcons } from '@expo/vector-icons';
import { Box, Icon } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import { IS_MOBILE_SCREEN } from '../../constants';
import { Modal } from '../Modal/Modal';
import { Typography } from '../Typography/Typography';
import { styles } from './styles';

export const Filters = ({ onClose, children }) => {
  if (IS_MOBILE_SCREEN) {
    return (
      <Modal isOpen onClose={onClose}>
        <Box p='md' bg='white' rounded='lg'>
          <Typography intlId='app.filters' fontSize='xl' mb='sm' textAlign='center' />
          {children}
        </Box>
      </Modal>
    );
  }

  return (
    <Box position='absolute' right='15px' top='125px' width='304px' zIndex={10}>
      <TouchableOpacity onPress={onClose} style={styles.close}>
        <Icon name='close' as={MaterialIcons} style={styles.closeIcon} />
      </TouchableOpacity>
      <ScrollView nestedScrollEnabled>
        <Box px='md' py='lg' bg='white' rounded='lg'>
          <Typography intlId='app.filters' fontSize='xl' mb='sm' />
          {children}
        </Box>
      </ScrollView>
    </Box>
  );
};

Filters.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node,
};
