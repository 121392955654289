import { StyleSheet } from 'react-native';

import { colors, rounded, space } from '../../theme';

export const styles = StyleSheet.create({
  close: {
    position: 'absolute',
    right: 15,
    top: 15,
    zIndex: 10,
  },
  inner: {
    backgroundColor: `rgba(0,0,0, 0.5)`,
    flex: 1,
    padding: space.md,
  },
  view: {
    backgroundColor: colors.white,
    borderRadius: rounded.lg,
    height: 'auto',
    marginBottom: 'auto',
    marginTop: 'auto',
    overflow: 'hidden',
    padding: space.md,
    position: 'relative',
    width: '100%',
  },
});
