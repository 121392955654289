/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Image, Text, TouchableOpacity, View } from 'react-native';

import { Button } from '../../components/UI';
import { styles } from './styles';

const Slider = ({ data, heading }) => {
  const intl = useIntl();
  const [currntSlid, setCurrntSlid] = useState(0);
  const item = data[currntSlid];
  return (
    <View style={styles.wrapper}>
      {
        <View style={styles.wrapperSlider}>
          <Text style={styles.heading}>{heading}</Text>
          <View style={styles.wrapperStep}>
            <Text style={styles.step}>{currntSlid + 1}</Text>
          </View>
          <Text style={styles.title}>{item.title}</Text>
          <Image></Image>
          <Text style={styles.description}>{item.description}</Text>
        </View>
      }
      <View style={styles.wrapperSteps}>
        {data.map((item, index) => (
          <TouchableOpacity
            onPress={() => setCurrntSlid(index)}
            //eslint-disable-next-line
            style={{
              width: 12,
              height: 12,
              backgroundColor: index === currntSlid ? '#965A85' : '#DCDCDC',
              borderRadius: 6,
            }}
            key={index}
          ></TouchableOpacity>
        ))}
      </View>
      <Button style={styles.button} onPress={() => setCurrntSlid(currntSlid + 1)}>
        {intl.formatMessage({ id: 'app.ready' })}
      </Button>
    </View>
  );
};

export default Slider;
