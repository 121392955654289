import { Input } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { FieldWrapper } from '../../components';
import { FLOAT_NUMBER_REGEX, IS_MOBILE_SCREEN } from '../../constants';
import { getValueFromPath } from '../../utils';

export const TextFieldHookFrom = (props) => {
  const { watch, formState, setValue } = useFormContext();
  const field = watch(props.name);

  const errorMessageText = getValueFromPath(formState.errors, props.name)?.message;

  const hasError = Boolean(errorMessageText);

  return (
    <TextFieldHookFormNested
      {...props}
      field={field}
      hasError={hasError}
      setValue={setValue}
      errorMessageText={errorMessageText}
    />
  );
};

const TextFieldHookFormNested = memo(
  ({
    hasError,
    width,
    label,
    name,
    errorMessageText,
    placeholderIntlId = 'app.enterValue',
    isReadOnly,
    value,
    setValue,
    numberFixedCount,
    maxValue,
    type = 'text',
    inputType = 'text',
    keyboardType = 'default',
    field,
  }) => {
    const intl = useIntl();

    const getNumberTypeValue = (value, numberFixedCount) => {
      const valueArray = value.replace(FLOAT_NUMBER_REGEX, '').split('.');
      if (valueArray.length > 1) {
        if (valueArray[1].length >= numberFixedCount) {
          setValue(
            name,
            Number(value.replace(FLOAT_NUMBER_REGEX, '')).toFixed(numberFixedCount),
          );
        } else {
          setValue(name, value.replace(FLOAT_NUMBER_REGEX, ''));
        }
      } else {
        setValue(name, value.replace(FLOAT_NUMBER_REGEX, ''));
      }
    };

    const handleInputChange = (currentValue) => {
      if (type === 'number') {
        if (numberFixedCount === 0 || numberFixedCount) {
          getNumberTypeValue(currentValue, numberFixedCount);
        } else {
          setValue(name, Number(currentValue.replace(FLOAT_NUMBER_REGEX, '')));
        }
        if (
          (maxValue || maxValue === 0) &&
          (currentValue > maxValue || currentValue === '0')
        ) {
          setValue(name, maxValue || '');
        }
      } else {
        setValue(name, currentValue);
      }
    };

    return (
      <FieldWrapper
        isInvalid={hasError}
        label={label}
        errorMessageText={errorMessageText}
        width={width || (IS_MOBILE_SCREEN ? '100%' : '50%')}
      >
        <Input
          isReadOnly={isReadOnly}
          value={value || field}
          onChangeText={handleInputChange}
          placeholder={
            intl.messages[placeholderIntlId]
              ? intl.formatMessage({ id: placeholderIntlId })
              : placeholderIntlId
          }
          keyboardType={type === 'number' ? 'numeric' : keyboardType}
          type={inputType}
          height={'33px'}
        />
      </FieldWrapper>
    );
  },
);

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholderIntlId: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isReadOnly: PropTypes.bool,
  value: PropTypes.string,
};

TextFieldHookFormNested.displayName = 'TextFieldHookFormNested';
TextFieldHookFrom.propTypes = propTypes;

TextFieldHookFormNested.propTypes = {
  ...propTypes,
  hasError: PropTypes.bool,
  control: PropTypes.object,
  errorMessageText: PropTypes.string,
};
