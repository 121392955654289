/* eslint-disable react-hooks/rules-of-hooks */
import { useLocation as useLocationWeb } from 'react-router-dom';
import { useLocation as useLocationNative } from 'react-router-native';

import { IS_APP, ORDERS_STATUSES_NAMES } from '../constants';

const useRoute = () => {
  const location = IS_APP ? useLocationNative() : useLocationWeb();

  const isOrderStatusesPage = ORDERS_STATUSES_NAMES.includes(
    location.pathname.split('/')[3],
  );

  return {
    ...location,
    root: location.pathname.split('/')[1]?.toLowerCase(),
    path: !isOrderStatusesPage
      ? location.pathname.split('/')[2]?.toLowerCase()
      : location.pathname.split('/')[3]?.toLowerCase(),
  };
};

export default useRoute;
