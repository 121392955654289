import { useFormikContext } from 'formik';
import { Center } from 'native-base';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import { FlatList, Platform } from 'react-native';

import { isWeb, LIMIT_USERS } from '../../constants';
import { useSearchContext } from '../../context';
import { useGetUsersQuery } from '../../hooks';
import { getNameInitials } from '../../utils';
import {
  AddModal,
  DesktopTable,
  ModalButton,
  RequestHandler,
  Typography,
  UserCard,
} from '../index';

export const AddUserModal = ({ isOpen, onClose }) => {
  const { searchValue, changeSearchValue } = useSearchContext();
  const { values, setFieldValue } = useFormikContext();

  const { data, fetchMore, ...queryProps } = useGetUsersQuery();

  const isLoadMoreEnabled = useRef(true);

  const handleSet = useCallback(
    (item) => {
      setFieldValue('user', item);
      onClose();
    },
    [onClose, setFieldValue],
  );

  const fetchMoreData = useCallback(
    async (resolve, reject) => {
      if (isLoadMoreEnabled.current) {
        await fetchMore({
          variables: {
            search: searchValue,
            offset: data?.filteredUsers.length,
            limit: LIMIT_USERS,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              reject?.();

              return prev;
            }

            resolve?.();

            if (!fetchMoreResult?.filteredUsers?.length) {
              isLoadMoreEnabled.current = false;
            }

            return {
              filteredUsers: [...prev?.filteredUsers, ...fetchMoreResult?.filteredUsers],
            };
          },
        });
      }
    },
    [searchValue, data, fetchMore],
  );

  const onEndReached = () =>
    new Promise((resolve, reject) => {
      fetchMoreData(resolve, reject);
    });

  const renderItem = useCallback(
    ({ item }) => {
      return <UserCard item={item} handleSet={handleSet} />;
    },
    [handleSet],
  );

  return (
    <AddModal
      isOpen={isOpen}
      onClose={onClose}
      searchValue={searchValue}
      onChangeSearchValue={changeSearchValue}
      queryProps={queryProps}
      withScan={false}
      title='app.user'
    >
      <RequestHandler {...queryProps}>
        {isWeb ? (
          <>
            <DesktopTable inModal fetchMoreData={fetchMoreData}>
              <DesktopTable.Body>
                {data?.filteredUsers?.map((item) => (
                  <DesktopTable.Row key={item.id}>
                    <DesktopTable.Cell maxWidth={45} width={45}>
                      <Center
                        width='40px'
                        height='40px'
                        borderRadius='20px'
                        bgColor='success'
                        mr='md'
                      >
                        <Typography color='gray.extraLight'>
                          {getNameInitials(`${item.lastName} ${item.firstName}`)}
                        </Typography>
                      </Center>
                    </DesktopTable.Cell>
                    <DesktopTable.Cell>
                      <Typography intlId={`${item.lastName} ${item.firstName}`} />
                    </DesktopTable.Cell>
                    <ModalButton
                      item={item}
                      addedItemsIds={values?.user?.id}
                      handleSet={handleSet}
                    />
                  </DesktopTable.Row>
                ))}
              </DesktopTable.Body>
            </DesktopTable>
          </>
        ) : (
          <FlatList
            data={data?.filteredUsers}
            renderItem={renderItem}
            scrollEventThrottle={1}
            onEndReachedThreshold={0.1}
            onEndReached={onEndReached}
          />
        )}
      </RequestHandler>
    </AddModal>
  );
};

AddUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
