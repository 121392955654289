import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';

export const LeftArrowIcon = ({ size = 28, style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 28 28' style={{ ...style }}>
      <Rect width='28' height='28' rx='5' fill='white' fillOpacity='0.1' />
      <Path
        d='M11.2185 13.3334H19.3332V14.6667H11.2185L14.7945 18.2427L13.8518 19.1854L8.6665 14L13.8518 8.8147L14.7945 9.75736L11.2185 13.3334Z'
        fill='white'
      />
    </Svg>
  );
};

LeftArrowIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
