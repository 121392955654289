export const getRouteName = (key, intl) => {
  switch (key.toLowerCase()) {
    case '/sales/dashboard':
      return intl.formatMessage({
        id: 'app.dashboard',
      });
    case '/sales/orders':
      return intl.formatMessage({
        id: 'app.orders',
      });
    case '/sales/order':
      return intl.formatMessage({
        id: 'app.order',
      });
    case '/sales/customers':
      return intl.formatMessage({
        id: 'app.customers',
      });
    case '/sales/customer':
      return intl.formatMessage({
        id: 'app.customer',
      });
    case '/purchase/purchases':
      return intl.formatMessage({
        id: 'app.purchases',
      });
    case '/purchase/purchase':
      return intl.formatMessage({
        id: 'app.purchase',
      });
    case '/purchase/parcels':
      return intl.formatMessage({
        id: 'app.deliverytoukraine',
      });
    case '/purchase/parcel':
      return intl.formatMessage({
        id: 'app.deliverytoukraine',
      });
    case '/purchase/forwardings':
      return intl.formatMessage({
        id: 'app.usshipping',
      });
    case '/purchase/forwarding':
      return intl.formatMessage({
        id: 'app.usshipping',
      });
    case '/warehouse/home':
      return intl.formatMessage({
        id: 'app.home',
      });
    case '/warehouse/packaging':
      return intl.formatMessage({
        id: 'app.packaging',
      });
    case '/warehouse/products':
      return intl.formatMessage({
        id: 'app.products',
      });
    case '/warehouse/product':
      return intl.formatMessage({
        id: 'app.product',
      });
    case '/warehouse/boxes':
      return intl.formatMessage({
        id: 'app.boxes',
      });
    case '/warehouse/box':
      return intl.formatMessage({
        id: 'app.box',
      });
    case '/login':
      return intl.formatMessage({
        id: 'app.login',
      });
    case 'sales':
      return intl.formatMessage({
        id: 'app.sales',
      });
    case 'bookkeeping':
      return intl.formatMessage({
        id: 'app.bookkeeping',
      });
    case 'warehouse':
      return intl.formatMessage({
        id: 'app.warehouse',
      });
    case 'statistic':
      return intl.formatMessage({
        id: 'app.statistic',
      });
    case 'purchase':
      return intl.formatMessage({
        id: 'app.purchase',
      });
    case 'documentation':
      return intl.formatMessage({
        id: 'app.documentation',
      });
    case 'newsletters':
      return intl.formatMessage({
        id: 'app.newsletters',
      });
    case 'settings':
      return intl.formatMessage({
        id: 'app.settings',
      });
    default:
      return null;
  }
};
