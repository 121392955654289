/* eslint-disable react-native/no-inline-styles */
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Text, View } from 'react-native';

import { Layout } from '../../../components';
import { Button } from '../../../components/UI';

const Home = () => {
  const intl = useIntl();
  const [processe, setProcesse] = useState(false);
  return (
    <Layout>
      <View
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <View style={{ height: 17 }}>
          {processe && <Text>{intl.formatMessage({ id: 'app.ordersProcessed' })}</Text>}
        </View>
        <Button color={'#fff'} onPress={() => setProcesse(!processe)}>
          {processe
            ? intl.formatMessage({ id: 'app.refresh' })
            : intl.formatMessage({ id: 'app.startProcessingOrders' })}
        </Button>
      </View>
    </Layout>
  );
};

export default Home;
