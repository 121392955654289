import { Flex } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { TouchableOpacity } from 'react-native';

import { BoxWrapper, Typography } from '../../../../../components';

const RozetkaorderStatusMobileItem = ({ id, description, status, onItemPress }) => {
  return (
    <BoxWrapper>
      <TouchableOpacity onPress={onItemPress}>
        <Flex mb='md' justify='space-between'>
          <Typography intlId='flexibleshop.id' color='gray.main' />
          <Typography>{id}</Typography>
        </Flex>

        <Flex mb='md' justify='space-between'>
          <Typography intlId='orderStatuses.description' color='gray.main' />
          <Typography>{description}</Typography>
        </Flex>
        <Flex mb='md' justify='space-between'>
          <Typography intlId='app.status' color='gray.main' />
          <Typography>{status}</Typography>
        </Flex>
      </TouchableOpacity>
    </BoxWrapper>
  );
};

export default memo(RozetkaorderStatusMobileItem);

RozetkaorderStatusMobileItem.propTypes = {
  description: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  onItemPress: PropTypes.func,
};
