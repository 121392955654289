import { StyleSheet } from 'react-native';

import { IS_DESKTOP_SCREEN, STATUS_BAR_HEIGHT, theme } from '../../constants';

export const styles = StyleSheet.create({
  close: {
    alignItems: 'flex-end',
    display: 'flex',
    marginRight: 15,
    position: 'relative',
    top: -57,
  },
  footer: IS_DESKTOP_SCREEN && {
    bottom: 16,
    position: 'fixed',
  },
  header: {
    borderBottomColor: theme.colors.white,
    borderBottomWidth: 1,
    marginBottom: 22,
    marginTop: IS_DESKTOP_SCREEN ? 41 : 40.8,
  },
  icon: {
    alignItems: 'center',
    height: 40,
    justifyContent: 'center',
    marginRight: 20,
    marginTop: 0,
    width: 40,
  },
  link: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    paddingHorizontal: theme.spaces.large,
    paddingVertical: 2,
  },
  linkWrapper: {
    marginBottom: 10,
  },
  path: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    height: 63,
    justifyContent: 'flex-start',
    marginBottom: 20,
    paddingHorizontal: theme.spaces.large,
    paddingVertical: 2,
  },
  title: {
    marginTop: 10,
    minWidth: 130,
  },
  wrapper: {
    backgroundColor: theme.colors.blueDark,
    elevation: 12,
    height: '100%',
    marginTop: STATUS_BAR_HEIGHT,
    overflow: 'hidden',
    position: 'relative',
  },
  wrapperNavigationDrawer: {
    backgroundColor: theme.colors.blueDark,
    elevation: 12,
    flex: 1,
    height: '100%',
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    width: 250,
    zIndex: 10,
  },
});
