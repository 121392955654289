import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const RightLowerCloud = (props) => {
  return (
    <Svg
      width='296'
      height='207'
      viewBox='0 0 296 207'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <Path
        d='M296 206.7C296 206.7 292.497 90.9971 240.5 40.2002C175.5 -23.2998 74 -10.2998 74 70.2002C74 130.2 0 206.7 0 206.7L296 206.7Z'
        fill='#47C8FF'
        fillOpacity='0.1'
      />
    </Svg>
  );
};
