import { StyleSheet, Dimensions } from 'react-native';

const width = Dimensions.get('window').width;
export default StyleSheet.create({
  field: {
    width: '100%',
    marginBottom: 5,
    position: 'relative',
    zIndex: 15,
  },
  select: {
    backgroundColor: 'transparent',
    paddingBottom: 0,
    paddingRight: 5,
    paddingLeft: 5,
    borderBottomWidth: 1,
    borderBottomColor: '#333333',
    color: '#4F4F4F',
  },
  hasError: {
    borderColor: 'tomato',
  },
});
