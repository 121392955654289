import { useField, useFormikContext } from 'formik';
import { Flex } from 'native-base';
import React, { useEffect, useState } from 'react';

import {
  AddUserModal,
  BoxWrapper,
  Button,
  TransactionsAdditionalData,
  Typography,
} from '../../../components';

export const TransactionUser = () => {
  const { values, setFieldValue } = useFormikContext();
  const [{ value }, meta] = useField('user');

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    return () => setFieldValue('user', '');
  }, [setFieldValue]);

  return (
    <>
      <BoxWrapper mb='120px'>
        <Flex justify='space-between'>
          <Typography intlId='app.user' variant='heading' mb={0} />
          <Button
            intlId={value?.id ? 'app.change' : 'app.select'}
            onPress={() => setIsModalOpen(true)}
          />
        </Flex>
        {value ? <TransactionsAdditionalData item={values} /> : null}
        {meta.error && meta.touched && (
          <Typography intlId={meta.error} fontSize='xs' color='error' />
        )}
      </BoxWrapper>
      {isModalOpen ? (
        <AddUserModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      ) : null}
    </>
  );
};
