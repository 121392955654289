/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */

// import './style.css';

import PropTypes from 'prop-types';
import React from 'react';

import { colors } from '../../theme';

export const DatePickerWebInput = ({
  value = '',
  onChange,
  placeholder,
  hasError,
  styles,
}) => {
  return (
    <input
      type='date'
      value={value}
      onChange={(event) => onChange(event.target.value)}
      placeholder={placeholder}
      style={{
        borderBottomColor: hasError ? colors.error : colors.dark,
        lineHeight: '24px',
        paddingRight: '10px',
        paddingLeft: '10px',
        fontSize: '14px',
        height: '32px',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        outline: 'none',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        fontFamily: 'roboto-regular',
        ...styles,
      }}
    />
  );
};

DatePickerWebInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  isOutlined: PropTypes.bool,
  hasError: PropTypes.bool,
  placeholder: PropTypes.string,
  styles: PropTypes.instanceOf(Object),
};
