import React from 'react';

import {
  DesktopTable,
  Layout,
  MobileTable,
  RequestHandler,
  TextLink,
} from '../../../components';
import { IS_DESKTOP_SCREEN } from '../../../constants';
import { useGetSourcesQuery } from '../../../hooks';
import OrdersStatusMobileItem from './OrdersStatusMobileItem';

const COLUMNS = [{ intlId: 'app.name' }];

const OrdersStatuses = () => {
  const { data, ...queryProps } = useGetSourcesQuery();

  const handleGetViewStatusBySourceLink = (key) => {
    return `/settings/ordersstatuses/${key}statuses`;
  };

  if (IS_DESKTOP_SCREEN) {
    return (
      <Layout isScrollable={false}>
        <RequestHandler {...queryProps}>
          <DesktopTable>
            <DesktopTable.Head>
              {COLUMNS?.map(({ intlId }) => (
                <DesktopTable.Title key={intlId} intlId={intlId} />
              ))}
            </DesktopTable.Head>
            <DesktopTable.Body>
              {data?.sources?.map((item) => (
                <DesktopTable.Row key={item?.id}>
                  <DesktopTable.Cell>
                    <TextLink
                      link={handleGetViewStatusBySourceLink(item?.key)}
                      intlId={item?.name}
                    />
                  </DesktopTable.Cell>
                </DesktopTable.Row>
              ))}
            </DesktopTable.Body>
          </DesktopTable>
        </RequestHandler>
      </Layout>
    );
  }
  return (
    <Layout isScrollable={false}>
      <RequestHandler {...queryProps}>
        <MobileTable
          data={data?.sources}
          renderItem={(item) => (
            <OrdersStatusMobileItem
              link={handleGetViewStatusBySourceLink(item?.key)}
              name={item.name}
            />
          )}
          fetchMoreData={(resolve) => resolve()}
        />
      </RequestHandler>
    </Layout>
  );
};

export default OrdersStatuses;
