import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: 'black',
    height: 49,
    alignItems: 'center',
    borderColor: '#d6d6d6',
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
});
