import { Text as NBText } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';

export const Typography = memo(({ intlId, children, ...props }) => {
  const intl = useIntl();

  const renderText = () => {
    if (intl.messages[intlId] && children)
      return `${intl.formatMessage({ id: intlId })} ${children}`;
    if (intl.messages[intlId]) return intl.formatMessage({ id: intlId });
    if (children !== undefined) return children;
    return intlId;
  };

  return <NBText {...props}>{renderText()}</NBText>;
});

Typography.displayName = 'Typography';

Typography.propTypes = {
  intlId: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
};
