import { StyleSheet, Platform } from 'react-native';
import { getStatusBarHeight } from 'react-native-status-bar-height';

export default StyleSheet.create({
  wrapped: {
    position: 'absolute',
    top: 80 + (Platform.OS !== 'web' ? getStatusBarHeight() : 0),
    height: 1000,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    alignItems: 'center',
  },
  modal: {
    position: 'relative',
    marginTop: 56,
    width: '95%',
    backgroundColor: 'white',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 5,
  },
  head: {
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    fontSize: 18,
    color: '#4F4F4F',
  },
  content: {
    width: '95%',
    minHeight: 80,
  },
});
