import { FormControl } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { Typography } from '../Typography/Typography';

export const FormControlLabel = ({ label, isLight }) => {
  return (
    <FormControl.Label mb='3px'>
      <Typography intlId={label} fontSize='sm' color={isLight ? 'white' : 'gray.main'} />
    </FormControl.Label>
  );
};

FormControlLabel.propTypes = {
  label: PropTypes.string,
  isLight: PropTypes.bool,
};
