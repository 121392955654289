import { AddIcon, Flex, Spinner } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { BoxWrapper, Button, Typography } from '../../../../components';
import { theme } from '../../../../constants';
import stylesGlobal from '../../../../globalStyles';
import { styles } from './styles';
import { UploadImageModal } from './UploadImageModal';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableItem from './DragNDrop/DraggableItem';
import DropZone from './DragNDrop/DropZone';
import {
  useParams,
  usePhotoMutation,
  useUpdateAlloProductPictures,
  useUpdateRozetkaProductPictures,
} from '../../../../hooks';
import { useNotificationsContext } from '../../../../context';

const UploadImage = ({ photos, loading, productId }) => {
  const { showNotification } = useNotificationsContext();
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [photoId, setPhotoId] = useState('');
  const params = useParams();

  const [photoMutation, { loading: photoMutationLoading }] = usePhotoMutation({
    productId,
    isDrag: true,
  });

  const [updateRozetkaProductPictures] = useUpdateRozetkaProductPictures();
  const [updateAlloProductPictures] = useUpdateAlloProductPictures();

  const handleOpenModal = (photoId) => {
    if (photoId) {
      setPhotoId(photoId);
    }
    setIsUploadOpen(true);
  };

  const handleMoveField = (dragIndex, hoverIndex, draggedPhotoId, hoverPhotoId) => {
    Promise.all([
      photoMutation({
        variables: {
          input: { id: draggedPhotoId, order: hoverIndex, productId: Number(productId) },
        },
      }),
      photoMutation({
        variables: {
          input: { id: hoverPhotoId, order: dragIndex, productId: Number(productId) },
        },
      }),
    ])
      .then(() => {
        const updatedPhotos = photos.map((photo) => {
          if (photo.id === hoverPhotoId) return { ...photo, order: dragIndex };
          if (photo.id === draggedPhotoId) return { ...photo, order: hoverIndex };

          return { ...photo };
        });
        updateRozetkaProductPictures({
          variables: {
            internalId: Number(params.id),
            pictures: updatedPhotos?.map((photo) => ({
              order: photo.order,
              url: photo.path,
            })),
          },
        });
        updateAlloProductPictures({
          variables: {
            internalId: Number(params.id),
            pictures: updatedPhotos?.map((photo) => ({
              order: photo.order,
              url: photo.path,
            })),
          },
        });

        showNotification({ message: 'photo.moved' });
      })
      .catch((error) => {
        showNotification({
          message: error.message,
          type: 'error',
          title: 'snackbar.error',
        });
      });
  };

  useEffect(() => {
    if (!photos) return;
  }, [photos]);

  const getPhotoToEdit = () => {
    return photos?.find((photo) => photo.id === photoId);
  };

  return (
    <BoxWrapper style={stylesGlobal.form}>
      {loading || photoMutationLoading ? (
        <Flex paddingY='lg'>
          <Spinner size='lg' />
        </Flex>
      ) : (
        <>
          <Typography mb={'8px'} intlId='app.productPhoto' />
          <Typography
            mb={6}
            style={styles.textInfoUploadFile}
            intlId='app.infoUploadFile'
          />
          <Flex
            direction='row'
            flexWrap='wrap'
            justify='flex-start'
            style={styles.imagesWrapper}
          >
            <Button
              onPress={handleOpenModal}
              bgColor={theme.colors.bgLightGrayOpacity}
              borderWidth={1}
              borderColor={theme.colors.bgLightGray}
              borderStyle='solid'
              borderRadius='3px'
              h={82}
              w={82}
              _hover={{ bgColor: theme.colors.bgLightGray }}
              isDisabled={!productId}
            >
              <AddIcon size={'20px'} color={theme.colors.primary} />
            </Button>
            {!productId ? (
              <Typography intlId={'app.addProductWarning'} color={theme.colors.danger} />
            ) : null}
            <DndProvider backend={HTML5Backend}>
              {photos?.map((photo, index) => {
                return (
                  <DropZone
                    key={photo.id}
                    onDrop={(item) => handleDrop(item, index)}
                    index={index}
                  >
                    <DraggableItem
                      photo={photo}
                      index={index}
                      moveForm={(dragIndex, hoverIndex, draggedPhotoId, hoverPhotoId) =>
                        handleMoveField(
                          dragIndex,
                          hoverIndex,
                          draggedPhotoId,
                          hoverPhotoId,
                        )
                      }
                      onHandleOpen={handleOpenModal}
                    />
                  </DropZone>
                );
              })}
            </DndProvider>
          </Flex>
          <UploadImageModal
            isOpen={isUploadOpen}
            onClose={() => setIsUploadOpen(false)}
            photoToEdit={getPhotoToEdit()}
            productId={productId}
            order={photos?.length}
            photos={photos}
          />
        </>
      )}
    </BoxWrapper>
  );
};

export default UploadImage;

UploadImage.propTypes = {
  photos: PropTypes.array,
  loading: PropTypes.bool,
  productId: PropTypes.string,
};
