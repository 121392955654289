import gql from 'graphql-tag';

export default gql`
  query findCustomers($search: String) {
    findCustomers(search: $search) {
      id
      firstName
      lastName
      middleName
      createdAt
      emails {
        id
        email
      }
      phones {
        id
        phone
      }
    }
  }
`;
