import { Box, Flex } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { BoxWrapper } from '../BoxWrapper/BoxWrapper';
import { Typography } from '../Typography/Typography';

export const MobileTableItem = ({ item, columns, wrapInBox = true }) => {
  const list = columns?.map((column, index) => (
    <Flex
      key={column.id}
      align='flex-start'
      justify='space-between'
      wrap='wrap'
      mb={columns.length === index + 1 ? 0 : 'md'}
    >
      <Typography intlId={column.name} color='gray.main' />
      <Typography>{item[column.id]}</Typography>
    </Flex>
  ));

  if (wrapInBox) return <BoxWrapper>{list}</BoxWrapper>;

  return (
    <Box paddingY='md' borderBottomWidth='1px' borderColor='gray.light'>
      {list}
    </Box>
  );
};

MobileTableItem.propTypes = {
  item: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  wrapInBox: PropTypes.bool,
};
