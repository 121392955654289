import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { View } from 'react-native';

import { IS_DESKTOP_SCREEN, IS_MOBILE_SCREEN, ROUTES } from '../../constants';
import { useRoute } from '../../hooks';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';
import { HeaderContent } from './HeaderContent/HeaderContent';
import { styles } from './styles';

export const Header = () => {
  const { path } = useRoute();

  const currentRoute = ROUTES.getCurrentRouteName(path);
  const isSingleScreen = IS_MOBILE_SCREEN && ROUTES.isSingleScreen(currentRoute?.name);

  return (
    <View style={styles.wrapper}>
      <LinearGradient
        style={styles.inner}
        colors={['#965A85', '#5A6896']}
        start={{ x: 1, y: 0 }}
        end={{ x: 0, y: 1 }}
      >
        <HeaderContent isSingleScreen={isSingleScreen} />
      </LinearGradient>
      {!IS_DESKTOP_SCREEN && !isSingleScreen ? <Breadcrumbs /> : null}
    </View>
  );
};
