import {
  BookkeepingIcon,
  DocumentationIcon,
  NewslettersIcon,
  PurchaseIcon,
  SalesIcon,
  SettingsIcon,
  StatisticIcon,
  WarehouseIcon,
} from '../../components/Icons';

export default {
  sales: { name: 'Sales', icon: SalesIcon },
  bookkeeping: { name: 'Bookkeeping', icon: BookkeepingIcon },
  documentation: { name: 'Documentation', icon: DocumentationIcon },
  newsletters: { name: 'Newsletters', icon: NewslettersIcon },
  purchase: { name: 'Purchase', icon: PurchaseIcon },
  settings: { name: 'Settings', icon: SettingsIcon },
  statistic: { name: 'Statistic', icon: StatisticIcon },
  warehouse: { name: 'Warehouse', icon: WarehouseIcon },
};
