import { Flex } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { Pressable } from 'react-native';

import { BoxWrapper, Typography } from '../../../../components';

const PackagingTemplatesMobile = ({ data, rows, handleEditButtonClick }) => {
  return (
    <Pressable onPress={handleEditButtonClick}>
      <BoxWrapper>
        <Typography variant='heading' color='dark' m={0}>
          {data.name}
        </Typography>
        {rows.slice(1).map((row, index, rowAr) => (
          <Flex
            justify='space-between'
            key={row.intlId}
            py={'16px'}
            borderBottomWidth={rowAr.length - 1 !== index ? 1 : 0}
            borderColor='gray.main'
          >
            <Typography color='gray.main' intlId={row.intlId} />

            <Typography color='text.default' fontWeight={400}>
              {data[row.sortId]}
            </Typography>
          </Flex>
        ))}
      </BoxWrapper>
    </Pressable>
  );
};

PackagingTemplatesMobile.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
  }),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      intlId: PropTypes.string,
      sortId: PropTypes.string,
    }),
  ),
  handleEditButtonClick: PropTypes.func,
};

export default PackagingTemplatesMobile;
