import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const ArrowAgainstMobileIcon = () => {
  return (
    <Svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <Path
        d='M8.75033 6.99992L11.667 4.66659L8.75033 2.33325V4.08267H1.16699V5.24934H8.75033V6.99992ZM12.8337 8.74992H5.25033V6.99992L2.33366 9.33325L5.25033 11.6666V9.91659H12.8337V8.74992Z'
        fill='#C4C4C4'
      />
    </Svg>
  );
};
