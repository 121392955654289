import { FieldArray, Formik } from 'formik';
import { Box } from 'native-base';
import React from 'react';

import {
  BoxWrapper,
  DatePicker,
  FieldRow,
  FormRow,
  FormWrapper,
  Layout,
  QuerySelectWithSearch,
  RequestHandler,
  Select,
  TextField,
  Typography,
} from '../../../components';
import { FIND_PURCHASE_PARSELS_QUERY } from '../../../graphql';
import {
  useGetPurchaseForwardingQuery,
  useGetPurchaseForwardingStatusesQuery,
  useParams,
  useSaveForwarding,
} from '../../../hooks';
import { addForwardingSchema } from '../../../services/validation';
import { transformSelectOptions } from '../../../utils';
import ForwardingStructure from './Structure/ForwardingStructure';

const Forwarding = () => {
  const { id } = useParams();

  const [onSaveForwarding, isSaveForwardingLoading] = useSaveForwarding();

  const { data: purchaseForwardingData, loading: purchaseLoading } =
    useGetPurchaseForwardingQuery(id);
  const { data: statuses, loading: statusesLoading } =
    useGetPurchaseForwardingStatusesQuery();

  const loading = [statusesLoading, purchaseLoading].some((load) => load);

  const getStatusOptions = () => {
    return transformSelectOptions({
      data: statuses?.purchaseForwardingStatuses,
    });
  };

  return (
    <RequestHandler loading={loading}>
      <FormWrapper>
        <Formik
          initialValues={{
            id: Number(purchaseForwardingData?.purchaseForwarding?.id) || null,
            status:
              purchaseForwardingData?.purchaseForwarding?.status?.id ||
              statuses?.purchaseForwardingStatuses?.[0]?.id ||
              '',
            deliveryPrice:
              purchaseForwardingData?.purchaseForwarding?.deliveryPrice || '',
            date: purchaseForwardingData?.purchaseForwarding?.date || '',
            trackingCode: purchaseForwardingData?.purchaseForwarding?.trackingCode || '',
            parcels: purchaseForwardingData?.purchaseForwarding?.parcels || [],
            purchases: purchaseForwardingData?.purchaseForwarding?.purchases || [],
            trackingParcelCodeQuantity:
              purchaseForwardingData?.purchaseForwarding?.parcels?.length + 1 || 1,
          }}
          onSubmit={onSaveForwarding}
          validationSchema={addForwardingSchema}
        >
          {({ values, handleSubmit }) => (
            <Layout
              onActionButtonPress={handleSubmit}
              actionButtonType='save'
              actionButtonIsLoading={isSaveForwardingLoading}
            >
              <FormRow>
                <FormRow.Left>
                  <BoxWrapper>
                    <Typography intlId='app.basis' variant='heading' />
                    <FieldRow>
                      <Box>
                        <TextField name={'trackingCode'} label={'app.trackingCode'} />
                        <TextField
                          name='deliveryPrice'
                          label='app.costPriceUSD'
                          type='number'
                          value={values?.deliveryPrice}
                        />
                      </Box>
                      <Box>
                        <Select
                          name='status'
                          label='app.status'
                          options={getStatusOptions()}
                        />
                        <DatePicker name='date' label='app.date' />
                      </Box>
                    </FieldRow>
                  </BoxWrapper>
                </FormRow.Left>
                <FormRow.Right>
                  <BoxWrapper>
                    <Typography intlId='app.parcel' variant='heading' />
                    <FieldArray
                      name={'parcels'}
                      render={(arrayHelpers) => (
                        <FieldRow>
                          {[...values.parcels, {}].map((parcel, index) => {
                            return (
                              <QuerySelectWithSearch
                                alreadyPickedItems={values.parcels}
                                key={index}
                                rowsInResponse
                                label={'app.trackingParcelCode'}
                                fieldName={`parcels.${index}`}
                                graphql={FIND_PURCHASE_PARSELS_QUERY}
                                graphqlName='purchaseParcels'
                                onChange={(option) => {
                                  if (!option) {
                                    arrayHelpers.remove(index);
                                  }
                                }}
                                labelProperty={'trackingCode'}
                                valueProperty={['id', 'trackingCode']}
                              />
                            );
                          })}
                        </FieldRow>
                      )}
                    />
                  </BoxWrapper>
                </FormRow.Right>
              </FormRow>
              <ForwardingStructure />
            </Layout>
          )}
        </Formik>
      </FormWrapper>
    </RequestHandler>
  );
};

export default Forwarding;
