/* eslint-disable react-native/no-inline-styles */
import { MaterialIcons } from '@expo/vector-icons';
import { Flex } from 'native-base';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Text, View } from 'react-native';

import { TextFieldHookFrom, Typography } from '../../../../components';
import styles from '../Style';
import { ItemConditionField, ItemLocation } from '.';

const OrderItemMobile = ({
  onDelete,
  index,
  isStatusReserved,
  orderItem,
  hasItemConditions,
}) => {
  const intl = useIntl();

  const [showLocation, setShowLocation] = useState(false);

  return (
    <Flex style={styles.orderItemMobile}>
      {orderItem?.product?.url ? (
        <Text style={styles.orderItemName}>
          {orderItem?.product?.name || intl.formatMessage({ id: 'app.no' })}
        </Text>
      ) : (
        <Text style={styles.orderItemName}>
          {orderItem?.product?.name || intl.formatMessage({ id: 'app.no' })}
        </Text>
      )}
      <Flex
        style={
          isStatusReserved
            ? styles.orderItemRowMobile
            : styles.orderItemRowMobileOutMargin
        }
      >
        <Text style={styles.field}>{intl.formatMessage({ id: 'app.price' })}</Text>
        <View style={styles.fieldDataMobile}>
          {!isStatusReserved ? (
            <Flex justifyContent='flex-end'>
              <TextFieldHookFrom
                type={'number'}
                width={'60px'}
                mt={5}
                mr={'5px'}
                name={`orderItems.${index}.pricePerPcs`}
              />
              <Typography mb={'10px'} intlId='currency.uah' />
            </Flex>
          ) : (
            <View style={styles.fieldInputWrapper}>
              <Text>
                {orderItem?.pricePerPcs + ' '}
                {intl.formatMessage({ id: 'currency.uah' })}
              </Text>
            </View>
          )}
        </View>
      </Flex>
      <Flex style={styles.orderItemRowMobile}>
        <Text style={styles.field}>{intl.formatMessage({ id: 'app.quantity' })}</Text>
        <View style={styles.fieldInputWrapper}>
          {!isStatusReserved ? (
            <TextFieldHookFrom
              width={'60px'}
              mt={3}
              type={'number'}
              inputProps={{ textAlign: 'center' }}
              name={`orderItems.${index}.quantity`}
            />
          ) : (
            <Text>{orderItem?.quantity}</Text>
          )}
        </View>
      </Flex>
      {hasItemConditions && (
        <Flex
          style={[
            styles.orderItemRowMobile,
            {
              marginBottom: showLocation ? 0 : 15,
            },
          ]}
        >
          <Text style={styles.field}>{intl.formatMessage({ id: 'app.condition' })}</Text>
          <ItemConditionField
            index={index}
            availableConditions={orderItem?.product?.availableConditions}
            condition={orderItem?.availability?.condition}
            readOnly={isStatusReserved}
            setShowLocation={setShowLocation}
            showLocation={showLocation}
            originalOrderItem={orderItem}
          />
        </Flex>
      )}
      {showLocation && orderItem?.place && (
        <ItemLocation
          location={orderItem?.place}
          condition={orderItem?.availability?.condition}
        />
      )}
      <Flex style={styles.orderItemRowMobile}>
        <Text style={styles.field}>{intl.formatMessage({ id: 'app.sum' })}</Text>
        <View style={styles.fieldInputWrapper}>
          <Text style={{ textAlign: 'right' }}>
            {orderItem?.pricePerPcs * orderItem?.quantity}{' '}
            {intl.formatMessage({ id: 'currency.uah' })}
          </Text>
        </View>
      </Flex>
      <View style={styles.wrapButtonRemove}>
        <MaterialIcons onPress={onDelete} name='delete' size={25} color='#BDBDBD' />
      </View>
    </Flex>
  );
};

OrderItemMobile.propTypes = {
  onDelete: PropTypes.func,
  index: PropTypes.number,
  isStatusReserved: PropTypes.string,
  orderItem: PropTypes.object,
  statuses: PropTypes.array,
  hasItemConditions: PropTypes.bool,
  setFieldValue: PropTypes.func,
};

export default OrderItemMobile;
