import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  field: {
    width: '100%',
    paddingBottom: 13,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  datePicker: {
    backgroundColor: 'transparent',
    paddingBottom: 0,
    paddingRight: 5,
    paddingLeft: 5,
    borderBottomWidth: 1,
    borderBottomColor: '#333333',
    color: '#4F4F4F',
  },
  hasError: {
    borderColor: 'tomato',
  },
});
