import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const AppealsIcon = ({ size = 34, style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 20 24' style={{ ...style }}>
      <Path
        d='M20 2H4c-1.103 0-2 .894-2 1.992v12.016C2 17.106 2.897 18 4 18h3v4l6.351-4H20c1.103 0 2-.894 2-1.992V3.992A1.998 1.998 0 0020 2zm-7 13h-2v-2h2v2zm0-4h-2V5h2v6z'
        fill='white'
      />
    </Svg>
  );
};

AppealsIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
