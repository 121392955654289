import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { SelectWithSearchHookForm } from '../../../../../components/SelectWithSearch/SelectWithSearchHookForm';
import { useDebounce, useGetWarehousesUkrposhta } from '../../../../../hooks';
import { transformSelectOptionsWithInfo } from '../../../../../utils';

export const UkrPoshtaWarehouseAutoCompleteFieldHookForm = ({
  name,
  cityId,
  warehouseId,
  warehouseName,
  onChange,
}) => {
  const [options, setOptions] = useState([]);
  const [warehouseSearchValue, setWarehouseSearchValue] = useState('');

  const { debouncedValue: debouncedSearchValue, isDebouncing } =
    useDebounce(warehouseSearchValue);

  const [getWarehouses, { data: warehousesData, loading: warehousesLoading }] =
    useGetWarehousesUkrposhta({ variables: { cityId } });

  useEffect(() => {
    if (warehousesData?.getWarehousesUkrposhta) {
      const filteredWarehouses = warehousesData.getWarehousesUkrposhta.filter((item) =>
        item?.name?.toLowerCase()?.includes(debouncedSearchValue?.toLowerCase()),
      );
      setOptions(
        transformSelectOptionsWithInfo({
          data: filteredWarehouses,
          value: 'id',
          label: 'name',
          info: 'postcode',
        }),
      );
    }
  }, [debouncedSearchValue, warehousesData]);

  const getWarehousesHandler = () => {
    !warehousesData?.getWarehousesUkrposhta && getWarehouses();
  };

  const setSearchHandler = (val) => {
    return val ? setWarehouseSearchValue(val) : setWarehouseSearchValue('');
  };

  const initOption =
    warehouseId && warehouseName ? { value: warehouseId, label: warehouseName } : null;

  return (
    <SelectWithSearchHookForm
      width='100%'
      pl={0}
      pr={0}
      onChange={onChange}
      initialOption={initOption}
      setSearchValue={setSearchHandler}
      isLoading={warehousesLoading || isDebouncing}
      onMenuOpen={getWarehousesHandler}
      options={options}
      name={name}
      label='app.warehouse'
      isUkrposhta
    />
  );
};

UkrPoshtaWarehouseAutoCompleteFieldHookForm.propTypes = {
  name: PropTypes.string.isRequired,
  warehouseName: PropTypes.string.isRequired,
  cityId: PropTypes.number.isRequired,
  warehouseId: PropTypes.number.isRequired,
  onChange: PropTypes.func,
};
