import { Flex } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { TouchableOpacity } from 'react-native';

import { BoxWrapper, Typography } from '../../../../components';

const AccountsMobileItem = ({
  isArchived,
  title,
  onItemPress,
  currency,
  description,
}) => {
  const titleColor = isArchived ? 'gray.main' : 'dark';
  const descriptionColor = isArchived ? 'gray.main' : 'text.default';

  return (
    <BoxWrapper mb={'8px'}>
      <TouchableOpacity onPress={onItemPress}>
        <Flex mb={'16px'} justify={'space-between'}>
          <Typography color={titleColor} fontSize={16}>
            {title}
          </Typography>
          <Flex align={'center'} justify={'center'} borderRadius={'3px'} bg={'#CE5DAE'}>
            <Typography px={'4px'} color={'white'}>
              {currency}
            </Typography>
          </Flex>
        </Flex>
        <Typography color={descriptionColor}>{description}</Typography>
      </TouchableOpacity>
    </BoxWrapper>
  );
};

export default memo(AccountsMobileItem);

AccountsMobileItem.propTypes = {
  title: PropTypes.string.isRequired,
  onItemPress: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  isArchived: PropTypes.bool.isRequired,
};
