import { useField, useFormikContext } from 'formik';
import { CheckIcon, Select as NBSelect } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import { FieldWrapper } from '../FieldWrapper/FieldWrapper';

export const Select = ({ name, value, onChange, ...props }) => {
  if (name) {
    return <FormSelect name={name} onChange={onChange} {...props} />;
  }

  return <SingleSelect value={value} onValueChange={onChange} {...props} />;
};

const SingleSelect = ({ value, onValueChange, ...props }) => {
  return <SelectView value={value} onValueChange={onValueChange} {...props} />;
};

const FormSelect = ({ name, onChange, ...props }) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const hasError = meta.error && meta.touched;

  return (
    <SelectView
      value={field.value}
      onValueChange={(value) =>
        typeof onChange === 'function' ? onChange(value) : setFieldValue(name, value)
      }
      errorMessage={hasError && meta.error}
      {...props}
    />
  );
};

const SelectView = ({
  value,
  label,
  placeholder,
  onValueChange,
  errorMessage,
  options,
  mb,
  style,
  ...props
}) => {
  const intl = useIntl();

  return (
    <FieldWrapper
      isInvalid={!!errorMessage}
      errorMessageText={errorMessage}
      label={label}
      style={style}
      mb={mb}
      {...props}
    >
      <NBSelect
        height={'33px'}
        selectedValue={value}
        accessibilityLabel={placeholder || intl.formatMessage({ id: 'app.choose' })}
        placeholder={placeholder || intl.formatMessage({ id: 'app.choose' })}
        hasError={errorMessage}
        onValueChange={onValueChange}
        _selectedItem={{
          bg: 'cyan.600',
          endIcon: <CheckIcon size={4} />,
        }}
      >
        {options?.map((option, index) => (
          <NBSelect.Item label={option?.label} value={option?.value} key={index} />
        ))}
      </NBSelect>
    </FieldWrapper>
  );
};

SingleSelect.propTypes = {
  value: PropTypes.any,
  onValueChange: PropTypes.func,
};

FormSelect.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

Select.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

SelectView.propTypes = {
  value: PropTypes.any,
  onValueChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
  ).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  errorMessage: PropTypes.any,
};
