import { MaterialIcons } from '@expo/vector-icons';
import { FieldArray, useFormikContext } from 'formik';
import { Flex, Icon, Text } from 'native-base';
import React from 'react';
import { useIntl } from 'react-intl';
import { TouchableOpacity } from 'react-native';

import {
  AddAcceptanceModal,
  BoxWrapper,
  DesktopTable,
  RequestHandler,
  Select,
  SelectWithSearch,
  TextField,
  Typography,
} from '../../../../components';
import { CopyIcon } from '../../../../components/Icons';
import { IS_DESKTOP_SCREEN } from '../../../../constants';
import {
  useAvailabilityQuery,
  useGetConditionsQuery,
  useGetPurchasesInAcceptanceQuery,
} from '../../../../hooks';
import {
  getProductsTotalQuantity,
  getPurchasesOptions,
  transformSelectOptions,
} from '../../../../utils';

export const AcceptanceStructure = () => {
  const intl = useIntl();

  const { data, ...queryProps } = useGetConditionsQuery();
  const { values, setFieldValue } = useFormikContext();

  const { data: purchasesData } = useGetPurchasesInAcceptanceQuery(+values?.forwardingId);

  const products = values?.products;

  const { data: availabilityData } = useAvailabilityQuery(+values?.forwardingId);

  const handleAddProduct = (product, addField) => {
    addField({
      ...product,
      isDuplicate: false,
      purchaseId: '',
      conditionId: '',
      quantity: '',
    });
  };

  const handleDuplicateProduct = (product, addField, index) => {
    addField(index, {
      ...product,
      isDuplicate: true,
      purchaseId: '',
      conditionId: '',
      quantity: '',
    });
  };

  const handleRemoveProduct = (isDuplicate, removeField, index) => {
    !isDuplicate &&
      products[index + 1] &&
      setFieldValue(`products.${index + 1}.isDuplicate`, false);
    removeField(index);
  };

  const productPurchases = (productId) => {
    return purchasesData?.purchases?.filter(
      (purchase) => purchase?.product?.id === productId,
    );
  };

  const availabilityPurchases = (productId) => {
    return availabilityData?.availability?.filter(
      (availability) => availability?.purchase?.product?.id === productId,
    );
  };

  const availabilityQuantity = (productId) => {
    return availabilityPurchases(productId)?.reduce(
      (sum, { quantity }) => sum + quantity,
      0,
    );
  };

  const purchasesQuantity = (productId) => {
    return productPurchases(productId)?.reduce((sum, { quantity }) => sum + quantity, 0);
  };

  const valuesProduct = (productId) => {
    return products?.filter((product) => product?.id === productId);
  };

  const valuesProductQuantity = (productId) => {
    return valuesProduct(productId)?.reduce((sum) => sum + 1, 0);
  };

  const valuesProductAmount = (productId) => {
    const amount = valuesProduct(productId)?.reduce((sum, { quantity, isDuplicate }) => {
      return sum + (!isDuplicate ? 0 : +quantity);
    }, 0);
    return purchasesQuantity(productId) - availabilityQuantity(productId) - amount;
  };

  const getMaxProductQuantityValue = (productId, index) => {
    const amount = valuesProduct(productId)?.reduce((sum, { quantity }) => {
      return sum + +quantity;
    }, 0);
    return (
      purchasesQuantity(productId) -
      availabilityQuantity(productId) -
      amount +
      +products?.[index]?.quantity
    );
  };

  const getMaxProductQuantity = (productId) => {
    return purchasesQuantity(productId) - availabilityQuantity(productId);
  };

  const canDuplicate = (productId, isDuplicate) => {
    const res = getMaxProductQuantity(productId) - valuesProductQuantity(productId);
    return res > 0 && !isDuplicate;
  };

  if (IS_DESKTOP_SCREEN) {
    return (
      <RequestHandler {...queryProps}>
        <BoxWrapper>
          <FieldArray
            name='products'
            render={(arrayHelpers) => {
              return (
                <>
                  <Flex justify='space-between' mb='xs'>
                    <Typography intlId='app.structure' variant='heading' mb={0} />
                    <AddAcceptanceModal
                      getMaxProductQuantity={getMaxProductQuantity}
                      addedProducts={products}
                      onAddProduct={(product) =>
                        handleAddProduct(product, arrayHelpers.push)
                      }
                      forwardingIdViaParcel={+values?.forwardingId}
                    />
                  </Flex>
                  <DesktopTable editable={false}>
                    <DesktopTable.Head>
                      {COLUMNS.map((column, index) => (
                        <DesktopTable.Title
                          key={index}
                          intlId={column.name}
                          width={column?.width || 'auto'}
                        />
                      ))}
                      <DesktopTable.Title />
                    </DesktopTable.Head>
                    <DesktopTable.Body>
                      {products?.map((item, index) => (
                        <DesktopTable.Row key={index}>
                          <DesktopTable.Cell color='primary.default' width={'293px'}>
                            {item?.name}
                          </DesktopTable.Cell>
                          <DesktopTable.Cell width={'160px'}>
                            <SelectWithSearch
                              mb={0}
                              pl={0}
                              pr={50}
                              width={'100%'}
                              name={`products.${index}.purchaseId`}
                              options={getPurchasesOptions(
                                productPurchases(item?.id),
                                intl,
                              )}
                            />
                          </DesktopTable.Cell>
                          <DesktopTable.Cell width={'160px'}>
                            <Select
                              name={`products.${index}.conditionId`}
                              options={transformSelectOptions({
                                data: data?.conditions,
                              })}
                              pr={50}
                              mb={0}
                            />
                          </DesktopTable.Cell>
                          <DesktopTable.Cell width={'70px'} maxWidth={'70px'}>
                            <Flex>
                              <TextField
                                width={'100px'}
                                mb={0}
                                mr={3}
                                name={`products.${index}.quantity`}
                                type={'number'}
                                numberFixedCount={0}
                                maxValue={getMaxProductQuantityValue(item?.id, index)}
                              />
                              {!item?.isDuplicate && (
                                <Text>{`/ ${valuesProductAmount(item?.id)}`}</Text>
                              )}
                            </Flex>
                          </DesktopTable.Cell>
                          <DesktopTable.Cell width={'30px'}>
                            <Flex justifyContent={'space-evenly'}>
                              {canDuplicate(item?.id, item?.isDuplicate) && (
                                <Flex
                                  cursor={'pointer'}
                                  onClick={() =>
                                    handleDuplicateProduct(
                                      item,
                                      arrayHelpers.insert,
                                      index + 1,
                                    )
                                  }
                                >
                                  <CopyIcon />
                                </Flex>
                              )}
                              <Icon
                                as={<MaterialIcons name='delete' />}
                                size='18px'
                                color='gray.dark'
                                onPress={() => {
                                  handleRemoveProduct(
                                    item?.isDuplicate,
                                    arrayHelpers.remove,
                                    index,
                                  );
                                }}
                              />
                            </Flex>
                          </DesktopTable.Cell>
                        </DesktopTable.Row>
                      ))}
                      <DesktopTable.Row>
                        <DesktopTable.Cell>
                          <></>
                        </DesktopTable.Cell>
                        <DesktopTable.Cell>
                          <></>
                        </DesktopTable.Cell>
                        <DesktopTable.Cell>
                          <></>
                        </DesktopTable.Cell>
                        <DesktopTable.Cell>
                          <Flex justify='flex-start' mt='lg'>
                            {products && (
                              <Typography>
                                {`${getProductsTotalQuantity(
                                  products,
                                )} ${intl.formatMessage({ id: 'app.pcs' })}`}
                              </Typography>
                            )}
                          </Flex>
                        </DesktopTable.Cell>
                        <DesktopTable.Cell>
                          <></>
                        </DesktopTable.Cell>
                      </DesktopTable.Row>
                    </DesktopTable.Body>
                  </DesktopTable>
                </>
              );
            }}
          />
        </BoxWrapper>
      </RequestHandler>
    );
  }

  return (
    <RequestHandler {...queryProps}>
      <FieldArray
        name='products'
        render={(arrayHelpers) => {
          return (
            <>
              <Flex justifyContent='space-between' mb='lg'>
                <Typography intlId='app.structure' variant='heading' mb={0} />
                <AddAcceptanceModal
                  getMaxProductQuantity={getMaxProductQuantity}
                  addedProducts={products}
                  onAddProduct={(product) => handleAddProduct(product, arrayHelpers.push)}
                  forwardingIdViaParcel={+values?.forwardingId}
                />
              </Flex>
              {products?.map((item, index) => (
                <BoxWrapper key={index}>
                  <Typography color='primary.default' mb='lg'>
                    {item?.name}
                  </Typography>
                  <SelectWithSearch
                    label='app.purchase'
                    name={`products.${index}.purchaseId`}
                    options={getPurchasesOptions(productPurchases(item?.id), intl)}
                    pl={0}
                    pr={0}
                    mb={0}
                  />
                  <Select
                    label='app.condition'
                    name={`products.${index}.conditionId`}
                    options={transformSelectOptions({
                      data: data?.conditions,
                    })}
                  />
                  <Flex>
                    <TextField
                      flexGrow={2}
                      label='app.quantity'
                      type={'number'}
                      numberFixedCount={0}
                      maxValue={getMaxProductQuantityValue(item?.id, index)}
                      name={`products.${index}.quantity`}
                    />
                    {!item?.isDuplicate && (
                      <Text>{`  / ${valuesProductAmount(item?.id)}`}</Text>
                    )}
                  </Flex>
                  <Flex justifyContent={'space-evenly'}>
                    {canDuplicate(item?.id, item?.isDuplicate) && (
                      <Flex>
                        <TouchableOpacity
                          onPress={() =>
                            handleDuplicateProduct(item, arrayHelpers.insert, index + 1)
                          }
                        >
                          <CopyIcon />
                        </TouchableOpacity>
                      </Flex>
                    )}
                    <TouchableOpacity
                      onPress={() => {
                        handleRemoveProduct(
                          item?.isDuplicate,
                          arrayHelpers.remove,
                          index,
                        );
                      }}
                    >
                      <Icon
                        as={<MaterialIcons name='delete' />}
                        size='18px'
                        color='gray.dark'
                      />
                    </TouchableOpacity>
                  </Flex>
                </BoxWrapper>
              ))}
            </>
          );
        }}
      />
    </RequestHandler>
  );
};

const COLUMNS = [
  { name: 'app.title' },
  { name: 'app.purchase', width: '160px' },
  { name: 'app.condition' },
  { name: 'app.quantity' },
];
