import { Flex } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { TouchableOpacity } from 'react-native';

import {
  BoxWrapper,
  TransactionsAdditionalData,
  Typography,
} from '../../../../components';
import { BookkeepingCalendarIcon } from '../../../../components/Icons';

const TransactionsMobileItem = ({
  item,
  onItemPress,
  date,
  typeName,
  amount,
  comment,
}) => {
  return (
    <TouchableOpacity onPress={() => onItemPress(item?.id)}>
      <BoxWrapper>
        <Flex justify='space-between' paddingY='md'>
          <Flex>
            <BookkeepingCalendarIcon />
            {date}
          </Flex>
          <Flex>{amount}</Flex>
        </Flex>
        <Flex
          borderBottomWidth='1px'
          justify='space-between'
          borderColor='gray.default'
          pb='md'
        >
          <Flex>
            <Typography fontSize='17px' intlId={typeName} />
          </Flex>
          <Flex>
            <TransactionsAdditionalData item={item} vertically />
          </Flex>
        </Flex>
        <Typography pt='md' intlId={comment || 'app.noData'} />
      </BoxWrapper>
    </TouchableOpacity>
  );
};

export default memo(TransactionsMobileItem);

TransactionsMobileItem.propTypes = {
  date: PropTypes.element.isRequired,
  typeName: PropTypes.string.isRequired,
  amount: PropTypes.element.isRequired,
  comment: PropTypes.string.isRequired,
  onItemPress: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};
