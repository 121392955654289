/* eslint-disable react-native/no-inline-styles */
import { MaterialIcons } from '@expo/vector-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, ScrollView, Spinner, useTheme } from 'native-base';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { FAB } from 'react-native-paper';

import { TextFieldHookFrom, Typography } from '../../../../../../components';
import { useCreateTTNMutation, useIntl, useParams } from '../../../../../../hooks';
import { addTTNSchema } from '../../../../../../services/validation';
import { getVolumeWeight } from '../../../../../../utils';

export const TemplateManualForm = ({ onClose }) => {
  const { id: orderId } = useParams();

  const intl = useIntl();
  const theme = useTheme();

  const [createTTN, { loading }] = useCreateTTNMutation();

  const methods = useForm({
    reValidateMode: 'onBlur',
    resolver: yupResolver(addTTNSchema),
    defaultValues: {
      orderId,
      width: '',
      length: '',
      height: '',
      weight: '',
      volumeWeight: '',
    },
  });

  const width = useWatch({
    name: 'width',
    control: methods.control,
  });

  const length = useWatch({
    name: 'length',
    control: methods.control,
  });

  const height = useWatch({
    name: 'height',
    control: methods.control,
  });

  const onSubmit = useCallback(async (values) => {
    const result = await createTTN({
      variables: {
        input: {
          width: Math.round(values.width),
          height: Math.round(values.height),
          length: Math.round(values.length),
          weight: Math.round(values.weight),
          orderId: values.orderId * 1,
        },
      },
    });

    if (result?.data?.createWaybill?.ttn) {
      onClose(result?.data?.createWaybill?.ttn);
    }
  }, []);

  const renderIcon = useCallback(() => {
    if (loading)
      return <Spinner size='sm' mb={-2} style={{ bottom: -2 }} color={'white'} />;
    return <MaterialIcons name={'save'} size={25} color='#fff' />;
  }, [loading]);

  useEffect(() => {
    methods.setValue(
      'volumeWeight',
      `${getVolumeWeight(width, height, length)} ${intl.formatMessage({ id: 'app.kg' })}`,
    );
  }, [width, length, height, methods.setValue, intl]);

  return (
    <ScrollView>
      <Box pt={'25px'} px={'26px'} pb={'30px'} position={'relative'}>
        <FormProvider {...methods}>
          <Typography
            mb={'24px'}
            variant={'heading'}
            intlId={'app.dimensionsOfPackage'}
          />
          <TextFieldHookFrom name={'width'} label={'app.widthCm'} width={'100%'} />
          <TextFieldHookFrom name={'length'} label={'app.lengthCm'} width={'100%'} />
          <TextFieldHookFrom name={'height'} label={'app.heightCm'} width={'100%'} />
          <TextFieldHookFrom name={'weight'} label={'app.weightKg'} width={'100%'} />
          <TextFieldHookFrom
            isReadOnly
            name={'volumeWeight'}
            label={'app.volumeWeight'}
            width={'50%'}
          />

          <FAB
            icon={renderIcon}
            onPress={methods.handleSubmit(onSubmit)}
            style={{
              zIndex: 10,
              backgroundColor: theme.colors.primary.default,
              width: 56,
              height: 56,
              position: 'absolute',
              right: 26,
              bottom: 26,
            }}
            disabled={loading}
          />
        </FormProvider>
      </Box>
    </ScrollView>
  );
};

TemplateManualForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};
