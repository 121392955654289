import { Flex } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import {
  Button,
  DesktopTable,
  MobileTable,
  OrderMobileItem,
  Typography,
} from '../../../components';
import { IS_DESKTOP_SCREEN } from '../../../constants';
import { useHistory, useOrderData } from '../../../hooks';

const COLUMNS = [
  { intlId: 'app.id' },
  { intlId: 'app.dateTime' },
  { intlId: 'app.manager' },
  { intlId: 'app.source' },
  { intlId: 'app.payment' },
  { intlId: 'app.status' },
  { intlId: 'app.sum' },
];

export const CustomerOrders = ({ data }) => {
  const navigation = useHistory();
  const { getOrderData, getOrderStatusColor } = useOrderData();

  const handleAddButtonClick = () => {
    navigation.push('/sales/order/');
  };

  const handleOrderButtonClick = (id) => {
    navigation.push('/sales/order/' + id);
  };

  return (
    <>
      <Flex align={'center'} justify={'space-between'}>
        <Typography intlId='app.orderList' variant='heading' />
        <Button intlId='app.addOrder' onPress={handleAddButtonClick}></Button>
      </Flex>

      {IS_DESKTOP_SCREEN ? (
        <DesktopTable>
          <DesktopTable.Head>
            {COLUMNS?.map(({ intlId, sortId }) => (
              <DesktopTable.Title key={intlId} intlId={intlId} sortId={sortId} />
            ))}
          </DesktopTable.Head>
          <DesktopTable.Body>
            {data?.customer?.orders?.map((order, index) => (
              <DesktopTable.Row
                key={index}
                onEditButtonClick={() => handleOrderButtonClick(order?.id)}
              >
                <DesktopTable.Cell>{getOrderData('id', order)}</DesktopTable.Cell>
                <DesktopTable.Cell>{getOrderData('date', order)}</DesktopTable.Cell>
                <DesktopTable.Cell>{getOrderData('manager', order)}</DesktopTable.Cell>
                <DesktopTable.Cell>{getOrderData('source', order)}</DesktopTable.Cell>
                <DesktopTable.Cell>
                  {getOrderData('paymentStatus', order)}
                </DesktopTable.Cell>
                <DesktopTable.Cell style={getOrderStatusColor(order)}>
                  {getOrderData('status', order)}
                </DesktopTable.Cell>
                <DesktopTable.Cell>{getOrderData('amount', order)}</DesktopTable.Cell>
              </DesktopTable.Row>
            ))}
          </DesktopTable.Body>
        </DesktopTable>
      ) : (
        <MobileTable
          data={data?.customer?.orders}
          autoHeight={true}
          renderItem={(order) => (
            <OrderMobileItem
              id={getOrderData('id', order)}
              date={getOrderData('date', order)}
              managerName={getOrderData('manager', order)}
              customerName={getOrderData('customer', order)}
              sourse={getOrderData('source', order)}
              paymentStatus={getOrderData('paymentStatus', order)}
              status={getOrderData('statusId', order)}
              amount={getOrderData('amount', order)}
            />
          )}
        />
      )}
    </>
  );
};

CustomerOrders.propTypes = {
  data: PropTypes.object,
};
