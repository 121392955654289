import {
  NewslettersMagazineIcon,
  NewslettersMainIcon,
  NewslettersMessagesIcon,
  NewslettersSegmentsIcon,
} from '../../components/Icons';

export default [
  { name: 'Home', icon: NewslettersMainIcon, path: '/home' },
  { name: 'Messages', icon: NewslettersMessagesIcon, path: '/messages' },
  { name: 'Segments', icon: NewslettersSegmentsIcon, path: '/segments' },
  { name: 'Magazine', icon: NewslettersMagazineIcon, path: '/magazine' },
];
