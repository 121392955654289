import { Box, Divider } from 'native-base';
import React from 'react';

import {
  DesktopTable,
  Layout,
  MobileTable,
  OrderMobileItem,
  RequestHandler,
  TextLink,
  Typography,
} from '../../../components';
import { DATA_REQUEST_LIMIT, IS_DESKTOP_SCREEN } from '../../../constants';
import { useSearchContext } from '../../../context';
import {
  useFilter,
  useHistory,
  useIntl,
  useOrderData,
  useOrdersQuery,
  useOrderStatisticQuery,
} from '../../../hooks';
import { OrdersFilters } from './OrdersFilters';

const COLUMNS = [
  { intlId: 'app.id', sortId: 'id' },
  { intlId: 'app.dateTime', sortId: 'datetime' },
  { intlId: 'app.manager', sortId: 'manager' },
  { intlId: 'app.client', sortId: 'customer' },
  { intlId: 'app.source', sortId: 'source' },
  { intlId: 'app.payment' },
  { intlId: 'app.status', sortId: 'status' },
  { intlId: 'app.sum' },
];

const Orders = () => {
  const intl = useIntl();
  const navigation = useHistory();
  const { debouncedSearchValue } = useSearchContext();
  const { data, fetchMore, ...queryProps } = useOrdersQuery();
  const { data: statisticData, ...statisticQueryProps } = useOrderStatisticQuery();
  const { getOrderData, getOrderStatusColor } = useOrderData();
  const { filters } = useFilter();

  let filtersWithValues = {};

  Object.entries(filters).forEach(([key, value]) => {
    if (value) return (filtersWithValues[key] = value);
  });

  const { field, direction, ...filtersWithoutSort } = filtersWithValues;

  const handleAddButtonClick = () => {
    navigation.push('/sales/order/');
  };

  const handleEditButtonClick = (id) => {
    navigation.push('/sales/order/' + id);
  };

  const fetchMoreData = async (resolve, reject) =>
    await fetchMore({
      variables: {
        offset: data?.orders?.length,
        limit: DATA_REQUEST_LIMIT,
        order: direction || 'desc',
        orderBy: field || 'datetime',
        filters: filtersWithoutSort,
        search: debouncedSearchValue,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          reject && reject();
          return prev;
        }

        resolve && resolve();

        return {
          orders: [...prev?.orders, ...fetchMoreResult?.orders],
        };
      },
    });

  const countOrderStatistic = statisticData?.orderStatistic?.count;
  const totalOrderStatistic = statisticData?.orderStatistic?.total;

  if (IS_DESKTOP_SCREEN) {
    return (
      <Layout
        isScrollable={false}
        onActionButtonPress={handleAddButtonClick}
        filters={<OrdersFilters />}
      >
        <RequestHandler {...(queryProps || statisticQueryProps)}>
          {countOrderStatistic && totalOrderStatistic ? (
            <Box display={'flex'} flexDirection={'row'} mb={2}>
              <Box display={'flex'} flexDirection={'row'} mb={0}>
                <Typography intlId='app.ordersCount' color='gray' mr={2} />
                <Typography color='black' fontWeight='bold'>
                  {countOrderStatistic}
                </Typography>
              </Box>
              <Divider orientation='vertical' mx={2} bg='gray.300' />
              <Box display={'flex'} flexDirection={'row'}>
                <Typography intlId='app.totalSum' color='gray' mr={2} />
                <Typography color='black' fontWeight='bold'>
                  {`${totalOrderStatistic
                    ?.toFixed(2)
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ${intl.formatMessage({
                    id: 'currency.uah',
                  })}`}
                </Typography>
              </Box>
            </Box>
          ) : null}
          <DesktopTable fetchMoreData={fetchMoreData}>
            <DesktopTable.Head>
              {COLUMNS?.map(({ intlId, sortId }) => (
                <DesktopTable.Title key={intlId} intlId={intlId} sortId={sortId} />
              ))}
            </DesktopTable.Head>
            <DesktopTable.Body>
              {data?.orders?.map((order) => (
                <DesktopTable.Row
                  key={order?.id}
                  onEditButtonClick={() => handleEditButtonClick(order?.id)}
                >
                  <DesktopTable.Cell>
                    <TextLink link={`/sales/order/${order?.id}`} intlId={order?.id} />
                  </DesktopTable.Cell>
                  <DesktopTable.Cell>{getOrderData('date', order)}</DesktopTable.Cell>
                  <DesktopTable.Cell>{getOrderData('manager', order)}</DesktopTable.Cell>
                  <DesktopTable.Cell>{getOrderData('customer', order)}</DesktopTable.Cell>
                  <DesktopTable.Cell>{getOrderData('source', order)}</DesktopTable.Cell>
                  <DesktopTable.Cell>
                    {getOrderData('paymentStatus', order)}
                  </DesktopTable.Cell>
                  <DesktopTable.Cell style={getOrderStatusColor(order)}>
                    {getOrderData('status', order)}
                  </DesktopTable.Cell>
                  <DesktopTable.Cell>{getOrderData('amount', order)}</DesktopTable.Cell>
                </DesktopTable.Row>
              ))}
            </DesktopTable.Body>
          </DesktopTable>
        </RequestHandler>
      </Layout>
    );
  }

  return (
    <Layout
      isScrollable={false}
      onActionButtonPress={handleAddButtonClick}
      filters={<OrdersFilters />}
    >
      <RequestHandler {...queryProps}>
        <MobileTable
          data={data?.orders}
          sortableColumns={COLUMNS}
          handleEndReached={fetchMoreData}
          renderItem={(order) => (
            <OrderMobileItem
              onItemPress={handleEditButtonClick}
              id={getOrderData('id', order)}
              date={getOrderData('date', order)}
              managerName={getOrderData('manager', order)}
              customerName={getOrderData('customer', order)}
              sourse={getOrderData('source', order)}
              paymentStatus={getOrderData('paymentStatus', order)}
              status={getOrderData('statusId', order)}
              amount={getOrderData('amount', order)}
            />
          )}
        />
      </RequestHandler>
    </Layout>
  );
};

export default Orders;
