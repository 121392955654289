import { MaterialIcons } from '@expo/vector-icons';
import { Icon } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal as RNModal, ScrollView, TouchableOpacity, View } from 'react-native';

import { DEVICE_HEIGHT } from '../../constants';
import { styles } from './styles';

export const Modal = ({ isOpen, onClose, isScrollable = true, children }) => {
  const content = isScrollable ? (
    <ScrollView
      style={{
        maxHeight: DEVICE_HEIGHT - 80,
      }}
    >
      {children}
    </ScrollView>
  ) : (
    children
  );

  return (
    <RNModal visible={isOpen} transparent={true}>
      <View style={styles.inner}>
        <View style={styles.view}>
          <TouchableOpacity onPress={onClose} style={styles.close}>
            <Icon name='close' as={MaterialIcons} />
          </TouchableOpacity>
          {content}
        </View>
      </View>
    </RNModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isScrollable: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
