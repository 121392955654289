import { gql } from '@apollo/client';

import { FRAGMENT_PRODUCT, FRAGMENT_STOCK_PLACE } from './fragments';

export const ORDER_MUTATION = gql`
  mutation order($input: OrderInput!) {
    order(input: $input) {
      id
      createdAt
      user {
        id
        firstName
        lastName
      }
      customer {
        id
        firstName
        lastName
        middleName
        emails {
          id
          email
        }
        phones {
          id
          phone
        }
      }
      source {
        id
        name
      }
      status {
        id
        name
      }
      orderItems {
        id
        pricePerPcs
        quantity
        product {
          id
          name
          url
        }
      }
      discount
      deliveryInformation {
        id
        address
        comment
        ttn
        city {
          id
          name
          region {
            id
            name
            country {
              id
              name
            }
          }
        }
      }
      shippingType {
        id
        name
      }
      paymentType {
        id
        name
      }
      paymentStatus {
        id
        name
      }
      callbackOff
      customerComment
      managerComment
    }
  }
`;

export const CUSTOMER_MUTATION = gql`
  mutation customer($input: CustomerInput!) {
    customer(input: $input) {
      id
      firstName
      middleName
      lastName
      middleName
      createdAt
      birthday
      emails {
        id
        email
      }
      phones {
        id
        phone
      }
      children {
        id
        name
        birthday
        sex
        relationType {
          id
          name
        }
      }
      orders {
        id
        createdAt
        source {
          id
          name
        }
        status {
          id
          name
        }
        user {
          id
          firstName
          lastName
        }
        paymentStatus {
          id
          name
        }
        orderItems {
          id
          pricePerPcs
          quantity
        }
      }
    }
  }
`;

export const PRODUCT_MUTATION = gql`
  ${FRAGMENT_PRODUCT}
  mutation ($input: ProductInput!, $hasAccess: Boolean = true) {
    product(input: $input) {
      ...FragmentProduct
    }
  }
`;

export const STOCK_PLACE_MUTATION = gql`
  ${FRAGMENT_STOCK_PLACE}
  mutation ($input: StockPlaceInput!) {
    stockPlace(input: $input) {
      ...FragmentStockPlace
    }
  }
`;

export const USER_MUTATION = gql`
  mutation user($input: UserInput!) {
    user(input: $input) {
      id
      firstName
      middleName
      lastName
      middleName
      createdAt
      birthday
      sex
      status
      email
      phone
      skype
      roles {
        id
        name
      }
    }
  }
`;

export const ADD_PURCHASE_MUTATION = gql`
  mutation purchase($input: PurchaseInput!) {
    purchase(input: $input) {
      id
      product {
        id
        name
        price
        weight
        width
        height
        length
      }
      parcel {
        id
        trackingCode
        status {
          id
          name
        }
      }
      forwarding {
        id
        trackingCode
        deliveryPrice
        status {
          id
          name
        }
      }
      price
      deliveryPrice
      purchaseTime
      quantity
    }
  }
`;

export const AVAILABILITY_MOVE_MUTATION = gql`
  mutation availability(
    $fromPlaceId: Int!
    $purchaseId: Int!
    $fromConditionId: Int!
    $toPlaceId: Int!
    $toConditionId: Int!
    $value: Int!
  ) {
    availability {
      move(
        fromPlaceId: $fromPlaceId
        purchaseId: $purchaseId
        fromConditionId: $fromConditionId
        toPlaceId: $toPlaceId
        toConditionId: $toConditionId
        value: $value
      ) {
        old {
          id
        }
        new {
          id
        }
      }
    }
  }
`;

export const AVAILABILITY_REMOVE_MUTATION = gql`
  mutation availability(
    $placeId: Int!
    $purchaseId: Int!
    $conditionId: Int!
    $value: Int!
  ) {
    availability {
      remove(
        placeId: $placeId
        purchaseId: $purchaseId
        conditionId: $conditionId
        value: $value
      ) {
        id
      }
    }
  }
`;

export const AVAILABILITY_ADD_MUTATION = gql`
  mutation availability(
    $placeId: Int!
    $purchaseId: Int!
    $conditionId: Int!
    $value: Int!
  ) {
    availability {
      add(
        placeId: $placeId
        purchaseId: $purchaseId
        conditionId: $conditionId
        value: $value
      ) {
        id
      }
    }
  }
`;

export const ADD_PURCHASE_PARCEL_MUTATION = gql`
  mutation ($input: PurchaseParcelInput!) {
    purchaseParcel(input: $input) {
      id
      trackingCode
      date
      forwardings {
        id
        trackingCode
      }
      status {
        id
        name
      }
      source {
        id
        name
      }
      purchases {
        id
        quantity
        product {
          id
          length
          width
          height
          weight
        }
      }
    }
  }
`;

export const ADD_PURCHASE_FORWARDING_MUTATION = gql`
  mutation ($input: PurchaseForwardingInput!) {
    purchaseForwarding(input: $input) {
      id
      date
      trackingCode
      deliveryPrice
      parcels {
        id
        trackingCode
      }
      status {
        id
        name
      }
      purchases {
        id
        quantity
        product {
          id
          length
          width
          height
          weight
        }
      }
    }
  }
`;

export const ADD_CURRENCY_MUTATION = gql`
  mutation ($input: CurrencyInput!) {
    currency(input: $input) {
      id
      name
      code
      symbol
      currencyRates {
        id
        ratio
        date
        dateTo
        currencyId
      }
      lastRate {
        id
        ratio
        date
        dateTo
        currencyId
      }
    }
  }
`;

export const ADD_TRANSACTION_MUTATION = gql`
  mutation ($input: TransactionInput!) {
    transaction(input: $input) {
      id
      date
      amount
      comment
      orderId
      userId
      purchaseId
      forwardingId
      type {
        id
        name
        type
      }
      bankAccount {
        id
        title
        description
        currencyId
        isArchived
        currency {
          symbol
          currencyRates {
            ratio
            date
            dateTo
            currencyId
          }
          lastRate {
            ratio
            date
            dateTo
            currencyId
          }
        }
      }
      user {
        id
        firstName
        lastName
      }
      purchase {
        id
        purchaseTime
      }
      order {
        id
        createdAt
      }
      forwarding {
        id
        trackingCode
      }
    }
  }
`;

export const DELETE_TRANSACTION_MUTATION = gql`
  mutation ($id: Int!) {
    deleteTransaction(id: $id)
  }
`;

export const ADD_EXCHANGE_MUTATION = gql`
  mutation ($input: ExchangeInput!) {
    exchange(input: $input) {
      id
      date
      fromAmount
      fromBankAccountId
      toAmount
      toBankAccountId
      comment
      fromBankAccount {
        id
        title
        description
        currencyId
        currency {
          id
          name
          code
          symbol
          lastRate {
            id
            ratio
            date
            dateTo
            currencyId
          }
        }
        isArchived
      }
      toBankAccount {
        id
        title
        description
        currencyId
        currency {
          id
          name
          code
          symbol
          lastRate {
            id
            ratio
            date
            dateTo
            currencyId
          }
        }
        isArchived
      }
    }
  }
`;

export const UPDATE_PURCHASES_MUTATION = gql`
  mutation ($input: PurchasesInput!) {
    purchases(input: $input) {
      id
      price
      quantity
      deliveryPrice
      parcel {
        id
        trackingCode
      }
      forwarding {
        id
      }
      product {
        id
        length
        width
        height
        weight
        name
      }
    }
  }
`;
export const ADD_PACKAGING_TEMPLATE = gql`
  mutation ($input: PackagingTemplateInput!) {
    packagingTemplate(input: $input) {
      id
      name
      width
      length
      height
      weight
    }
  }
`;

export const DELETE_PACKAGING_TEMPLATE = gql`
  mutation ($id: Int!) {
    deletePackagingTemplate(id: $id)
  }
`;

export const DELETE_PURCHASE = gql`
  mutation ($id: Int!) {
    deletePurchase(id: $id)
  }
`;

export const ADD_FORMATTED_PHOTO = gql`
  mutation ($input: FormattedPhotoInput) {
    formattedPhoto(input: $input) {
      photoId
      format
      pathWithTime
      width
      height
    }
  }
`;

export const CREATE_BANK_ACCOUNT = gql`
  mutation bankAccount($input: BankAccountInput!) {
    bankAccount(input: $input) {
      id
      title
      description
      currencyId
      currency {
        id
        name
        code
        symbol
        lastRate {
          id
          ratio
          date
          dateTo
          currencyId
        }
      }
      isArchived
    }
  }
`;

export const DELETE_BANK_ACCOUNT = gql`
  mutation deleteBankAccount($id: ID!) {
    deleteBankAccount(id: $id)
  }
`;

export const CREATE_TTN = gql`
  mutation CreateWaybill($input: WaybillInput!) {
    createWaybill(input: $input) {
      ttn
    }
  }
`;

export const CREATE_PURCHASE_SOURCE = gql`
  mutation PurchaseSource($input: PurchaseSourceInput!) {
    purchaseSource(input: $input) {
      id
      isInternational
      link
      name
    }
  }
`;

export const CREATE_SHIPPING_TYPE = gql`
  mutation ShippingType($input: ShippingTypeInput!) {
    shippingType(input: $input) {
      id
      name
    }
  }
`;

export const DELETE_SHIPPING_TYPE = gql`
  mutation DeleteShippingType($id: ID!) {
    deleteShippingType(id: $id)
  }
`;

export const CREATE_PAYMENT_TYPE = gql`
  mutation PaymentType($input: PaymentTypeInput!) {
    paymentType(input: $input) {
      id
      name
      type
    }
  }
`;

export const DELETE_PAYMENT_TYPE = gql`
  mutation DeletePaymentType($id: ID!) {
    deletePaymentType(id: $id)
  }
`;

export const ADD_STOCK_LOCATION = gql`
  mutation StockLocation($input: StockLocationInput!) {
    stockLocation(input: $input) {
      id
      name
      parentId
      typeId
    }
  }
`;

export const CREATE_PROM_ORDER_STATUS_MAP = gql`
  mutation CreatePromOrderStatusMap($input: PromOrderStatusMapInput!) {
    createPromOrderStatusMap(input: $input) {
      isPromStatusDefault
      isStatusDefault
      promStatusDesc
      promStatusName
      statusId
    }
  }
`;

export const UPDATE_PROM_ORDER_STATUS_MAP = gql`
  mutation UpdatePromOrderStatusMap($input: PromOrderStatusMapInput!) {
    updatePromOrderStatusMap(input: $input) {
      isPromStatusDefault
      isStatusDefault
      promStatusDesc
      promStatusName
      statusId
    }
  }
`;

export const DELETE_PROM_ORDER_STATUS_MAP = gql`
  mutation DeletePromOrderStatusMap($params: PromOrderStatusMapArgs!) {
    deletePromOrderStatusMap(params: $params) {
      isPromStatusDefault
      isStatusDefault
      promStatusDesc
      promStatusName
      statusId
    }
  }
`;

export const CREATE_FLEXIBLESHOP_ORDER_STATUS_MAP = gql`
  mutation CreateFlexibleshopOrderStatusMap($input: FlexibleshopOrderStatusMapInput!) {
    createFlexibleshopOrderStatusMap(input: $input) {
      flexibleshopStatusDesc
      flexibleshopStatusId
      isFlexibleshopStatusDefault
      isStatusDefault
      statusId
    }
  }
`;

export const UPDATE_FLEXIBLESHOP_ORDER_STATUS_MAP = gql`
  mutation UpdateFlexibleshopOrderStatusMap($input: FlexibleshopOrderStatusMapInput!) {
    updateFlexibleshopOrderStatusMap(input: $input) {
      flexibleshopStatusDesc
      flexibleshopStatusId
      isFlexibleshopStatusDefault
      isStatusDefault
      statusId
    }
  }
`;

export const DELETE_FLEXIBLESHOP_ORDER_STATUS_MAP = gql`
  mutation DeleteFlexibleshopOrderStatusMap($params: FlexibleshopOrderStatusMapArgs!) {
    deleteFlexibleshopOrderStatusMap(params: $params) {
      flexibleshopStatusDesc
      flexibleshopStatusId
      isFlexibleshopStatusDefault
      isStatusDefault
      statusId
    }
  }
`;

export const CREATE_ROZETKA_ORDER_STATUS_MAP = gql`
  mutation CreateRozetkaOrderStatusMap($input: RozetkaOrderStatusMapInput!) {
    createRozetkaOrderStatusMap(input: $input) {
      isRozetkaStatusDefault
      isStatusDefault
      rozetkaStatusDesc
      rozetkaStatusId
      statusId
    }
  }
`;

export const UPDATE_ROZETKA_ORDER_STATUS_MAP = gql`
  mutation UpdateRozetkaOrderStatusMap($input: RozetkaOrderStatusMapInput!) {
    updateRozetkaOrderStatusMap(input: $input) {
      isRozetkaStatusDefault
      isStatusDefault
      rozetkaStatusDesc
      rozetkaStatusId
      statusId
    }
  }
`;

export const DELETE_ROZETKA_ORDER_STATUS_MAP = gql`
  mutation DeleteRozetkaOrderStatusMap($params: RozetkaOrderStatusMapArgs!) {
    deleteRozetkaOrderStatusMap(params: $params) {
      isRozetkaStatusDefault
      isStatusDefault
      rozetkaStatusDesc
      rozetkaStatusId
      statusId
    }
  }
`;

export const CREATE_STRAPI_ORDER_STATUS_MAP = gql`
  mutation CreateStrapiOrderStatusMap($input: StrapiOrderStatusMapInput!) {
    createStrapiOrderStatusMap(input: $input) {
      isStatusDefault
      isStrapiStatusDefault
      statusId
      strapiStatusDesc
      strapiStatusName
    }
  }
`;

export const UPDATE_STRAPI_ORDER_STATUS_MAP = gql`
  mutation UpdateStrapiOrderStatusMap($input: StrapiOrderStatusMapInput!) {
    updateStrapiOrderStatusMap(input: $input) {
      isStatusDefault
      isStrapiStatusDefault
      statusId
      strapiStatusDesc
      strapiStatusName
    }
  }
`;

export const DELETE_STRAPI_ORDER_STATUS_MAP = gql`
  mutation DeleteStrapiOrderStatusMap($params: StrapiOrderStatusMapArgs!) {
    deleteStrapiOrderStatusMap(params: $params) {
      isStatusDefault
      isStrapiStatusDefault
      statusId
      strapiStatusDesc
      strapiStatusName
    }
  }
`;

export const CREATE_ALLO_ORDER_STATUS_MAP = gql`
  mutation CreateAlloOrderStatusMap($input: AlloOrderStatusMapInput!) {
    createAlloOrderStatusMap(input: $input) {
      alloStatusId
      statusId
      isAlloStatusDefault
      isStatusDefault
      alloStatusName
    }
  }
`;

export const UPDATE_ALLO_ORDER_STATUS_MAP = gql`
  mutation UpdateAlloOrderStatusMap($input: AlloOrderStatusMapInput!) {
    updateAlloOrderStatusMap(input: $input) {
      alloStatusId
      statusId
      isAlloStatusDefault
      isStatusDefault
      alloStatusName
    }
  }
`;

export const DELETE_ALLO_ORDER_STATUS_MAP = gql`
  mutation DeleteAlloOrderStatusMap($params: AlloOrderStatusMapArgs!) {
    deleteAlloOrderStatusMap(params: $params) {
      alloStatusId
      statusId
      isAlloStatusDefault
      isStatusDefault
      alloStatusName
    }
  }
`;

export const PHOTO_MUTATION = gql`
  mutation Photo($input: PhotoInput) {
    photo(input: $input) {
      alt
      id
      order
      path
    }
  }
`;

export const UPDATE_ROZETKA_PRODUCT_PICTURES_MUTATION = gql`
  mutation UpdateRozetkaProductPictures(
    $internalId: Int!
    $pictures: [RozetkaProductPictureInput!]!
  ) {
    updateRozetkaProductPictures(internalId: $internalId, pictures: $pictures) {
      url
      order
    }
  }
`;

export const UPDATE_ROZETKA_PRODUCT_MUTATION = gql`
  mutation UpdateRozetkaProduct($input: RozetkaProductUpdateInput!) {
    updateRozetkaProduct(input: $input) {
      id
      internalId
      offerId
      name
      nameUa
      description
      descriptionUa
      url
      price
      oldPrice
      availability
      categoryId
      categoryName
      sku
      vendor
    }
  }
`;

export const CUSTOM_PARAM_MUTATION = gql`
  mutation CustomParam($input: CustomParamInput) {
    customParam(input: $input) {
      id
      name
      type
      customParamOptions {
        customParamId
        id
        name
      }
    }
  }
`;

export const DELETE_CUSTOM_PARAM_MUTATION = gql`
  mutation DeleteCustomParam($deleteCustomParamId: ID!) {
    deleteCustomParam(id: $deleteCustomParamId)
  }
`;

export const CUSTOM_PARAM_OPTION_MUTATION = gql`
  mutation CustomParamOption($input: CustomParamOptionInput) {
    customParamOption(input: $input) {
      id
      name
      customParamId
    }
  }
`;

export const DELETE_CUSTOM_PARAM_OPTION_MUTATION = gql`
  mutation DeleteCustomParamOption($deleteCustomParamOptionId: ID!) {
    deleteCustomParamOption(id: $deleteCustomParamOptionId)
  }
`;

export const PRODUCT_CUSTOM_PARAM_MUTATION = gql`
  mutation ProductCustomParam($input: ProductCustomParamInput) {
    productCustomParam(input: $input) {
      id
      productId
      customParamId
      customParamOptionId
      textValue
      numberValue
      booleanValue
      rangeFromValue
      rangeToValue
    }
  }
`;

export const DELETE_PRODUCT_CUSTOM_PARAM_MUTATION = gql`
  mutation DeleteProductCustomParam($deleteProductCustomParamId: ID!) {
    deleteProductCustomParam(id: $deleteProductCustomParamId)
  }
`;

export const UPDATE_ALLO_PRODUCT_PICTURES_MUTATION = gql`
  mutation UpdateAlloProductPictures(
    $internalId: Int!
    $pictures: [AlloProductPictureInput!]!
  ) {
    updateAlloProductPictures(internalId: $internalId, pictures: $pictures) {
      url
      order
    }
  }
`;

export const UPDATE_ALLO_PRODUCT_MUTATION = gql`
  mutation UpdateAlloProduct($input: AlloProductUpdateInput!) {
    updateAlloProduct(input: $input) {
      categoryId
      categoryName
      vendor
    }
  }
`;

export const UPDATE_ALL_PRODUCTS_TO_MARKETPLACES_MUTATION = gql`
  mutation Mutation {
    updateAllProductsToMarketplaces
  }
`;
