import RNDateTimePicker from '@react-native-community/datetimepicker';
import { format } from 'date-fns';
import { useField, useFormikContext } from 'formik';
import { Input } from 'native-base';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { IS_IOS, IS_WEB } from '../../constants';
import { FieldWrapper } from '../FieldWrapper/FieldWrapper';
import { DatePickerWebInput } from './DatePickerWebInput';

const getFormattedDate = (date) => {
  if (!date) return '';
  return format(new Date(date), 'yyyy-MM-dd');
};

export const DatePicker = ({
  name,
  label,
  onChange,
  placeholder = 'Выберите дату',
  style,
  inputStyles,
  mb,
  ...props
}) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const [isShowPicker, setIsShowPicker] = useState(false);

  const handleChangeInput = (value, selectedDate) => {
    setIsShowPicker(IS_IOS);
    if (selectedDate) {
      if (onChange) {
        onChange(selectedDate);
      } else {
        setFieldValue(name, selectedDate);
      }
    } else {
      if (onChange) {
        onChange(getFormattedDate(value));
      } else {
        setFieldValue(name, getFormattedDate(value));
      }
    }
  };

  const hasError = Boolean(meta.error && meta.touched);
  const formattedDate = getFormattedDate(field?.value || null);

  return (
    <FieldWrapper
      isInvalid={hasError}
      label={label}
      errorMessageText={meta.error}
      mb={mb}
      style={style}
      {...props}
    >
      {IS_WEB ? (
        <DatePickerWebInput
          placeholder={placeholder}
          value={formattedDate}
          hasError={hasError}
          onChange={handleChangeInput}
          styles={inputStyles}
        />
      ) : (
        <>
          <TouchableOpacity onPress={() => setIsShowPicker(true)}>
            <Input value={formattedDate} isReadOnly placeholder={placeholder} />
          </TouchableOpacity>
          {isShowPicker ? (
            <RNDateTimePicker
              value={field?.value ? new Date(field?.value) : new Date()}
              onChange={handleChangeInput}
              defaultValue={null}
              mode='date'
              display='default'
              locale='ru-RU'
              onTouchCancel={() => setIsShowPicker(false)}
            />
          ) : null}
        </>
      )}
    </FieldWrapper>
  );
};

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  inputStyles: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isOutlined: PropTypes.bool,
};
