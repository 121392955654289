import { Box, FormControl } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { Typography } from '../Typography/Typography';

export const FormControlError = ({ errorMessageText, height }) => {
  return (
    <Box height={height}>
      <FormControl.ErrorMessage
        mt={'3px'}
        marginLeft='auto'
        marginRight='auto'
        textAlign='center'
      >
        <Typography intlId={errorMessageText} fontSize='xs' color='error' />
      </FormControl.ErrorMessage>
    </Box>
  );
};

FormControlError.propTypes = {
  errorMessageText: PropTypes.string,
  height: PropTypes.string,
};
