import { MaterialIcons } from '@expo/vector-icons';
import { Box, Flex, Icon } from 'native-base';
import React from 'react';
import { TouchableOpacity } from 'react-native';

import { ROUTES } from '../../constants';
import { useHistory, useRoute } from '../../hooks';
import { Typography } from '../Typography/Typography';
import { BreadcrumbsItem } from './BreadcrumbsItem';
import { styles } from './styles';

export const Breadcrumbs = () => {
  const { path } = useRoute();
  const navigation = useHistory();

  const currentRoute = ROUTES.getCurrentRouteName(path);

  const prevRoutes = formattedPrevRoutes(currentRoute, true)?.reverse();

  return (
    <Flex
      position='relative'
      bg={{ base: 'secondary', lg: 'transparent' }}
      pl={{ base: 'md', lg: '0px' }}
      pr='md'
      paddingY='14px'
    >
      {prevRoutes.map((route) => {
        if (!route?.path || !route?.intlId) return null;
        return (
          <React.Fragment key={route.path}>
            <TouchableOpacity onPress={() => navigation.push(route.path)}>
              <Typography
                intlId={route.intlId}
                fontSize={{ base: '20px', lg: '2xl' }}
                color='gray.extraLight'
                style={styles.rootScreen}
                fontWeight={300}
              />
            </TouchableOpacity>
            <Box w={{ base: '20px', lg: '36px' }}>
              <Icon
                name='chevron-right'
                as={MaterialIcons}
                color='#58456B'
                size={{ base: '20px', lg: '36px' }}
                pt={{ base: '0px', lg: '5px' }}
              />
            </Box>
          </React.Fragment>
        );
      })}
      <BreadcrumbsItem />
    </Flex>
  );
};

function formattedPrevRoutes(route, isCurrentRoute) {
  let result = isCurrentRoute ? [] : [{ path: route?.path, intlId: route?.intlId }];

  if (route?.parent) {
    result = [...result, ...formattedPrevRoutes(route?.parent)];
  }

  return result;
}
