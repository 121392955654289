import { Box } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { TouchableOpacity } from 'react-native';

import { BoxWrapper, TextLink } from '../../../components';

const OrdersStatusMobileItem = ({ name, link }) => {
  return (
    <BoxWrapper>
      <TouchableOpacity>
        <Box mr='md'>
          <TextLink link={link} intlId={name} />
        </Box>
      </TouchableOpacity>
    </BoxWrapper>
  );
};

export default memo(OrdersStatusMobileItem);

OrdersStatusMobileItem.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};
