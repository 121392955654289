import { Flex, View } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { IS_APP } from '../../constants';
import { LeftLowerCloud } from './LeftLowerCloud';
import { LeftUpperCloud } from './LeftUpperCloud';
import { RightLowerCloud } from './RightLowerCloud';
import { RightUpperCloud } from './RightUpperCloud';

export const ErrorPageWrapper = ({ children }) => {
  return (
    <View>
      <LeftUpperCloud />
      <View position={IS_APP ? 'absolute' : 'fixed'} bottom={0}>
        <LeftLowerCloud />
      </View>
      <View position={IS_APP ? 'absolute' : 'fixed'} right={0}>
        <RightUpperCloud />
      </View>
      <View position={IS_APP ? 'absolute' : 'fixed'} bottom={0} right={0}>
        <RightLowerCloud />
      </View>
      <Flex
        position={'absolute'}
        flexDirection={'column'}
        height={IS_APP ? '100%' : '100vh'}
        width={IS_APP ? '100%' : '100vw'}
        justifyContent={'center'}
        top={IS_APP ? '320' : '-30'}
      >
        {children}
      </Flex>
    </View>
  );
};

ErrorPageWrapper.propTypes = {
  children: PropTypes.array.isRequired,
};
