import { MaterialIcons } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { Box, Flex, Icon } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { IS_DESKTOP_SCREEN, STATUS_BAR_HEIGHT } from '../../constants';
import { Typography } from '../Typography/Typography';

export const AddModalHeader = ({ title, onClose }) => {
  if (IS_DESKTOP_SCREEN) {
    return (
      <Box padding='lg'>
        <Typography intlId={title} fontSize='lg' />
      </Box>
    );
  }

  return (
    <Box mt={STATUS_BAR_HEIGHT}>
      <LinearGradient
        colors={['#965A85', '#5A6896']}
        start={{ x: 1, y: 0 }}
        end={{ x: 0, y: 1 }}
      >
        <Flex paddingY='md' paddingX='md'>
          <Icon
            as={<MaterialIcons name='arrow-back' />}
            size={25}
            color='#fff'
            onPress={onClose}
            mr='md'
          />
          <Typography intlId={title} fontSize='lg' color='white' />
        </Flex>
      </LinearGradient>
    </Box>
  );
};

AddModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
