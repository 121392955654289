import { Box, Flex, Radio, Text, View } from 'native-base';
import React, { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { FormRow, TextFieldHookFrom } from '../../../../components';
import { SelectHookForm } from '../../../../components/Select/SelectHookForm';
import { useGetPayersTypesNP, useParams } from '../../../../hooks';
import { transformSelectOptions } from '../../../../utils';
import { CityAutoCompleteFieldHookForm } from './CityAutoCompleteFieldHookForm';
import { CreateTTNButton } from './CreateTTNButton';
import { StreetAutoCompleteFieldHookForm } from './StreetAutoCompleteFieldHookForm';
import styles from './styles';
import { WarehouseAutoCompleteFieldHookForm } from './WarehouseAutoCompleteFieldHookForm';

export const NovaPoshtaFieldsHookForm = () => {
  const { id } = useParams();

  const { data: payersTypes } = useGetPayersTypesNP();

  const intl = useIntl();

  const { setValue } = useFormContext();

  const novaPoshtaShipping = useWatch({ name: 'novaPoshtaShipping' });

  const handleCityChanged = useCallback(() => {
    setValue('novaPoshtaShipping.addressRef', null);
  }, []);

  const handleStreetChanged = useCallback((val) => {
    setValue('novaPoshtaShipping.street', val?.label || '');
  }, []);

  return (
    <>
      <FormRow>
        <FormRow.Left>
          <CityAutoCompleteFieldHookForm
            onChange={handleCityChanged}
            name='novaPoshtaShipping.cityRef'
            cityRef={novaPoshtaShipping?.cityRef}
          />
        </FormRow.Left>
        <FormRow.Right>
          <Flex alignItems={'center'}>
            <Box flexGrow={1} mr={'14px'}>
              <TextFieldHookFrom
                width={'100%'}
                name='deliveryInformation.ttn'
                label='app.ttn'
                keyboardType='numeric'
              />
            </Box>
            {id && <CreateTTNButton />}
          </Flex>
        </FormRow.Right>
      </FormRow>
      <FormRow>
        <FormRow.Left>
          <Radio.Group
            defaultValue={novaPoshtaShipping?.serviceType}
            name='novaPoshtaShipping.serviceType'
            label={intl.formatMessage({ id: 'app.serviceType' })}
            onChange={(value) => {
              setValue('novaPoshtaShipping.serviceType', value);
            }}
          >
            <View style={styles.serviceType}>
              <Radio value='WarehouseWarehouse'>
                <Text style={styles.radioLabel}>
                  {intl.formatMessage({ id: 'app.warehouse' })}
                </Text>
              </Radio>
              <Radio value='WarehouseDoors'>
                <Text style={styles.radioLabel}>
                  {intl.formatMessage({ id: 'app.courier' })}
                </Text>
              </Radio>
              <Radio value='WarehousePostomat'>
                <Text style={styles.radioLabel}>
                  {intl.formatMessage({ id: 'app.postomat' })}
                </Text>
              </Radio>
            </View>
          </Radio.Group>
          <SelectHookForm
            name='novaPoshtaShipping.payerType'
            label='app.payerType'
            options={transformSelectOptions({
              data: payersTypes?.getTypesOfPayersNovaPoshta,
              label: 'description',
              value: 'ref',
            })}
          />
        </FormRow.Left>
        <FormRow.Right>
          {(novaPoshtaShipping?.serviceType === 'WarehouseWarehouse' ||
            novaPoshtaShipping?.serviceType === 'WarehousePostomat') &&
            novaPoshtaShipping?.cityRef && (
              <WarehouseAutoCompleteFieldHookForm
                cityRef={novaPoshtaShipping?.cityRef}
                warehouseRef={novaPoshtaShipping?.addressRef}
                name='novaPoshtaShipping.addressRef'
              />
            )}
        </FormRow.Right>
      </FormRow>
      {novaPoshtaShipping?.serviceType === 'WarehouseDoors' &&
        novaPoshtaShipping?.cityRef && (
          <FormRow>
            <FormRow.Left>
              <StreetAutoCompleteFieldHookForm
                cityRef={novaPoshtaShipping?.cityRef}
                streetRef={novaPoshtaShipping?.streetRef}
                street={novaPoshtaShipping?.street}
                onChange={handleStreetChanged}
                name='novaPoshtaShipping.streetRef'
              />
            </FormRow.Left>
            <FormRow.Right>
              <Flex justifyContent='space-between' alignItems='flex-start'>
                <View width='40%'>
                  <TextFieldHookFrom
                    width={'100%'}
                    name='novaPoshtaShipping.buildingNumber'
                    label='app.building'
                  />
                </View>
                <View width='50%'>
                  <TextFieldHookFrom
                    width={'100%'}
                    name='novaPoshtaShipping.flatNumber'
                    label='app.flat'
                  />
                </View>
              </Flex>
            </FormRow.Right>
          </FormRow>
        )}
    </>
  );
};
