import { getFormattedDate } from './getFormattedDate';

export const getPurchasesOptions = (purchasesArray, intl) => {
  return purchasesArray?.map((item) => {
    if (item?.id) {
      return {
        value: item?.id,
        label: item?.purchaseTime
          ? `№ ${item.id} ${intl.formatMessage({ id: 'app.from' })} ${getFormattedDate(
              item?.purchaseTime,
            )}`
          : `№ ${item.id}`,
      };
    }
  });
};
