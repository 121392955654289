import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const ShipIcon = ({ size = '15', fill = 'white', style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 14 14' style={{ ...style }}>
      <Path d='M6.20312 6.03247H4.89111V7.34448H6.20312V6.03247Z' fill={fill} />
      <Path d='M8.38427 6.03247H7.07227V7.34448H8.38427V6.03247Z' fill={fill} />
      <Path d='M6.20312 3.85132H4.89111V5.16333H6.20312V3.85132Z' fill={fill} />
      <Path d='M8.38427 3.85132H7.07227V5.16333H8.38427V3.85132Z' fill={fill} />
      <Path d='M10.5652 6.03247H9.25317V6.68785H10.5652V6.03247Z' fill={fill} />
      <Path
        d='M2.60071 4.67767V4.16183H3.26136V3.29276H2.60071V2.63208H1.73164V3.29276H1.07096V4.16183H1.73164V4.67767H0.367188V7.34442H1.23625H3.09609H3.96516V4.67767H2.60071Z'
        fill={fill}
      />
      <Path
        d='M13.0882 7.55688H9.52465H9.23494L9.02642 8.21352H8.38411H7.07211H6.20304H4.02197H3.96512H0.911832H0.367172H0L1.00168 11.3679H12.7898L14 7.55688H13.0882ZM11.1013 9.23068H10.2033V8.36161H11.1013V9.23068ZM12.5132 9.23068H11.6151V8.36161H12.5132V9.23068Z'
        fill={fill}
      />
    </Svg>
  );
};

ShipIcon.propTypes = {
  size: PropTypes.string,
  style: PropTypes.object,
  fill: PropTypes.string,
};
