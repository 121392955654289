import * as Linking from 'expo-linking';
import { Flex } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { TouchableOpacity } from 'react-native';

import { BoxWrapper, TextLink, Typography } from '../../../components';

const SourcesOrderMobileItem = ({ onItemPress, id, name, link, internationalTitle }) => {
  const handleLinkPress = () => {
    if (link) {
      Linking.openURL(link);
    }
  };

  return (
    <BoxWrapper>
      <TouchableOpacity onPress={() => onItemPress(id)}>
        <Flex mb='md'>
          <Typography>{name}</Typography>
        </Flex>

        <Flex mb='md' justify='space-between'>
          <Typography intlId='app.link' color='gray.main' />
          <TouchableOpacity onPress={handleLinkPress}>
            <TextLink intlId={link} link={'#'} />
          </TouchableOpacity>
        </Flex>
        <Flex mb='md' justify='space-between'>
          <Typography intlId='app.international' color='gray.main' />
          <Typography>{internationalTitle}</Typography>
        </Flex>
      </TouchableOpacity>
    </BoxWrapper>
  );
};

export default memo(SourcesOrderMobileItem);

SourcesOrderMobileItem.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  internationalTitle: PropTypes.string.isRequired,
  onItemPress: PropTypes.func,
};
