import { getLabel } from './getLabel';
import { getValue } from './getValue';

export const transformSelectOption = ({ item, value = 'id', label = 'name' }) => {
  if (typeof item === 'object') {
    return {
      value: getValue(item, value),
      label: getLabel(item, label),
    };
  }

  return {};
};
