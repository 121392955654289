import { useQuery } from '@apollo/client';

import { GET_STOCK_LOCATIONS } from '../graphql';
import { transformSelectOptions } from '../utils';

export const useStockLocationsOptions = () => {
  const { data: stockLocationsData, ...stockLocationsQueryProps } = useQuery(
    GET_STOCK_LOCATIONS,
    {
      variables: {
        offset: 0,
        limit: 10000000,
      },
    },
  );

  const getRoomsOptions = () => {
    return transformSelectOptions({
      data: stockLocationsData?.stockLocations?.filter(
        (location) => location.typeId === 2,
      ),
    });
  };

  const getShelvingOptions = (selectedRoom) => {
    return transformSelectOptions({
      data: stockLocationsData?.stockLocations?.filter(
        (location) => location.typeId === 1 && location.parentId === +selectedRoom,
      ),
    });
  };

  return {
    getRoomsOptions,
    getShelvingOptions,
    stockLocationsData,
    stockLocationsQueryProps,
  };
};
