import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const LeftUpperCloud = (props) => {
  return (
    <Svg
      width='298'
      height='167'
      viewBox='0 0 298 167'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <Path
        d='M0.546661 3.53531e-06C0.546661 3.53531e-06 -9.45312 172 56.0467 166.5C146.597 158.896 145.196 114.205 222.547 136.5C307.547 161 296.547 5.54553e-09 296.547 5.54553e-09L0.546661 3.53531e-06Z'
        fill='#47C8FF'
        fillOpacity='0.1'
      />
    </Svg>
  );
};
