import { StyleSheet } from 'react-native';

import { IS_DESKTOP_SCREEN } from '../../constants';
import { colors } from '../../theme';

const overflowX = IS_DESKTOP_SCREEN ? { overflowX: 'hidden' } : {};

export default StyleSheet.create({
  iconBack: {
    position: 'absolute',
    right: 8,
    top: 4,
  },
  item: {
    padding: 5,
  },
  listItemDesktop: {
    backgroundColor: colors.white,
    display: 'flex',
    maxHeight: IS_DESKTOP_SCREEN ? 100 : 200,
    paddingHorizontal: 10,
    width: '100%',
    ...overflowX,
    borderColor: colors.black,
    borderRadius: 5,
    borderWidth: 1,
    marginBottom: 5,
    overflow: IS_DESKTOP_SCREEN ? 'scroll' : 'hidden',
    position: IS_DESKTOP_SCREEN ? 'absolute' : 'relative',
  },
  wrapper: { flexGrow: 1, minWidth: 202.87 },
});
