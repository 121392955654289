import PropTypes from 'prop-types';
import React from 'react';

import { Typography } from '../Typography/Typography';

const currency = 'грн';

export const Price = ({ value, ...props }) => {
  if (!value) return null;
  return <Typography {...props}>{`${value} ${currency}`}</Typography>;
};

Price.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
