import PropTypes from 'prop-types';
import React from 'react';

import { Typography } from '../Typography/Typography';

export const AccountWithAmountHeader = ({ accountTitle }) => {
  return (
    <>
      <Typography intlId={accountTitle} />
      <Typography>{',\n'}</Typography>
      <Typography intlId={'app.lowerCaseSum'} />
    </>
  );
};

AccountWithAmountHeader.propTypes = {
  accountTitle: PropTypes.string,
};
