import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Animated, View } from 'react-native';

import { AccessHandler, Header } from '../components';
import NavigationDrawer from '../components/NavigationDrawer/NavigationDrawer';
import { DEVICE_WIDTH, ROUTES } from '../constants';
import {
  BreadcrumbsProvider,
  CurrencyProvider,
  DrawerMenuProvider,
  FiltersProvider,
  SearchProvider,
  useAccessContext,
  useAuthContext,
} from '../context';
import { useLocalStorage, useMediaQuery } from '../hooks';
import { AccessClosed, ScreenNotFound } from '../screens/errors';
import { LocalStorage } from '../services';
import { Redirect, Route, Router, Switch } from './Router';
import { routes } from './routes';
import { styles } from './styles';

export const Navigation = () => {
  const { isAuth } = useAuthContext();
  const isDesktop = useMediaQuery('isDesktop');
  const { isAccessAllowed } = useAccessContext();
  // const { mainPagePath } = useMainPagePath();
  const { isLoading, value, setValue, setValueHandler } = useLocalStorage(
    'NavigationDrawerIsOpen',
    DEVICE_WIDTH >= 1440,
  );

  useEffect(() => {
    (async () => {
      const item = await LocalStorage.getValue('NavigationDrawerIsOpen');
      if (!item || item === 'false') {
        setValue(false);
      } else {
        setValue(true);
      }
    })();
  }, []);

  const movingAnimationValue = useRef(new Animated.Value(value ? 0 : 1)).current;
  const inputRange = [0, 1];

  const animationStyles = {
    wrapperWidth: movingAnimationValue.interpolate({
      inputRange: inputRange,
      outputRange: [48, 230],
    }),

    footerRight: movingAnimationValue.interpolate({
      inputRange: inputRange,
      outputRange: [10, 16],
    }),

    footerLeft: movingAnimationValue.interpolate({
      inputRange: inputRange,
      outputRange: [10, 186],
    }),

    wrapperLeft: movingAnimationValue.interpolate({
      inputRange: inputRange,
      outputRange: [-20, -8],
    }),

    openButtonOpacity: movingAnimationValue.interpolate({
      inputRange: [0, 0.5, 1],
      outputRange: [0, 0, 1],
    }),

    closeButtonOpacity: movingAnimationValue.interpolate({
      inputRange: [0, 0.5, 1],
      outputRange: [1, 0, 0],
    }),

    buttonOpacity: movingAnimationValue.interpolate({
      inputRange: [0, 0.5, 1],
      outputRange: [1, 0, 1],
    }),
  };

  useEffect(() => {
    if (isDesktop) {
      Animated.timing(movingAnimationValue, {
        toValue: value,
        duration: 200,
        useNativeDriver: false,
      }).start();
    }
  }, [value, movingAnimationValue, isDesktop]);

  const renderRoute = ({
    path,
    exact = true,
    isPrivate = true,
    component: Component,
    scopes,
  }) => {
    return (
      <Route
        key={path}
        path={path}
        exact={exact}
        render={(props) => {
          if (path === ROUTES.login.path) {
            if (isAuth) return <Redirect to={ROUTES.sales.path} />;
            return <Component {...props} />;
          }

          if (isPrivate && !isAuth) return <Redirect to={ROUTES.login.path} />;
          return (
            <RouteLayout
              animationStyles={animationStyles}
              isOpen={value}
              setValueIsOpen={setValueHandler}
              isLoading={isLoading}
            >
              <AccessHandler scopesArray={scopes}>
                <Component {...props} />
              </AccessHandler>
            </RouteLayout>
          );
        }}
      />
    );
  };

  return (
    <Router>
      <Switch>
        {!isAccessAllowed && <AccessClosed />}
        {routes.map(renderRoute)}
        <ScreenNotFound />
      </Switch>
    </Router>
  );
};

const RouteLayout = ({
  children,
  animationStyles,
  isLoading,
  isOpen,
  setValueIsOpen,
}) => {
  const width = useMediaQuery('isDesktop');

  return (
    <SearchProvider>
      <FiltersProvider>
        <DrawerMenuProvider>
          <CurrencyProvider>
            <BreadcrumbsProvider>
              <View style={styles.wrapper}>
                {width && !isLoading ? (
                  <NavigationDrawer
                    isOpen={isOpen}
                    setIsOpen={setValueIsOpen}
                    animationStyles={animationStyles}
                  />
                ) : null}
                <View style={styles.inner}>
                  <Header />
                  {React.Children.map(children, (child) =>
                    React.cloneElement(child, { setTitle: () => {} }),
                  )}
                </View>
              </View>
            </BreadcrumbsProvider>
          </CurrencyProvider>
        </DrawerMenuProvider>
      </FiltersProvider>
    </SearchProvider>
  );
};

RouteLayout.propTypes = {
  children: PropTypes.node.isRequired,
  animationStyles: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setValueIsOpen: PropTypes.func.isRequired,
};
