export const button = {
  Button: {
    baseStyle: {
      rounded: 'md',
      _text: {
        fontWeight: 'normal',
        textAlign: 'center',
      },
    },
    variants: {
      solid: () => ({
        bgColor: 'primary.default',
        _text: {
          color: 'white',
        },
        _hover: {
          bgColor: 'primary.600',
        },
        _pressed: {
          bgColor: 'primary.700',
        },
      }),
      secondary: () => ({
        bg: 'gray.dark',
        _text: {
          color: 'white',
        },
      }),
      link: () => ({
        justifyContent: 'center',
        _pressed: {
          bg: 'transparent',
        },
        _focus: {
          bg: 'transparent',
        },
        _text: {
          color: 'primary.default',
        },
      }),
      remove: () => ({
        bg: 'danger',
        _text: {
          color: 'white',
        },
        _focus: {
          bg: 'danger',
        },
        width: '150px',
        height: '32px',
      }),
    },
  },
};
