import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const SalesIcon = ({
  size = 24,
  style = {},
  color1 = '#01A3A4',
  color2 = '#48DBFB',
}) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 40 40' style={{ ...style }}>
      <Path
        d='M8.33333 36.6665H31.6667C32.5507 36.6665 33.3986 36.3154 34.0237 35.6902C34.6488 35.0651 35 34.2173 35 33.3332V14.9999C35 14.5579 34.8244 14.1339 34.5118 13.8214C34.1993 13.5088 33.7754 13.3332 33.3333 13.3332H28.3333V12.0382C28.3333 7.68988 25.1617 3.79655 20.8333 3.37488C19.6748 3.25974 18.505 3.38838 17.3991 3.75255C16.2933 4.11672 15.276 4.70833 14.4125 5.48934C13.5491 6.27035 12.8587 7.22345 12.3858 8.28733C11.9129 9.35121 11.6679 10.5023 11.6667 11.6665V13.3332H6.66667C6.22464 13.3332 5.80072 13.5088 5.48816 13.8214C5.17559 14.1339 5 14.5579 5 14.9999V33.3332C5 34.2173 5.35119 35.0651 5.97631 35.6902C6.60143 36.3154 7.44928 36.6665 8.33333 36.6665ZM28.3333 16.6665V19.9999H25V16.6665H28.3333ZM15 11.6665C15 8.90988 17.2433 6.66655 20 6.66655C22.7567 6.66655 25 8.90988 25 11.6665V13.3332H15V11.6665ZM11.6667 16.6665H15V19.9999H11.6667V16.6665Z'
        fill={color1}
      />
      <Path
        d='M28.3334 13.3332V12.0382C28.3334 7.68988 25.1617 3.79655 20.8334 3.37488C19.6749 3.25974 18.5051 3.38838 17.3992 3.75255C16.2934 4.11672 15.276 4.70833 14.4126 5.48934C13.5492 6.27035 12.8588 7.22345 12.3859 8.28733C11.913 9.35121 11.668 10.5023 11.6667 11.6665V13.3332V16.6665H15.0001V13.3332V11.6665C15.0001 8.90988 17.2434 6.66655 20.0001 6.66655C22.7567 6.66655 25.0001 8.90988 25.0001 11.6665V13.3332V16.6665H28.3334V13.3332Z'
        fill={color2}
      />
    </Svg>
  );
};

SalesIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
  color1: PropTypes.string,
  color2: PropTypes.string,
};
