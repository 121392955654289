import React from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';

import Slider from '../../../components/Slider';
import { useSlides } from '../../../hooks/useSlides';

const Packaging = () => {
  const intl = useIntl();
  return (
    <View>
      <Slider
        heading={intl.formatMessage({ id: 'app.packProducts' })}
        data={useSlides()}
      />
    </View>
  );
};

export default Packaging;
