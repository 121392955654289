import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';
import { Title } from 'react-native-paper';

import { TextLink } from '../../../../components';
import { theme } from '../../../../constants';
import stylesGlobal from '../../../../globalStyles';
import styles from '../Style';
import ReceiptModal from '../../../../components/ReceiptModal/ReceiptModal';
import { Flex } from 'native-base';

const OrderReceipts = memo(({ receipts = [] }) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState('');

  const onClose = () => {
    setIsOpen(false);
    setUrl('');
  };

  const onOpenModal = (url) => () => {
    setIsOpen(true);
    setUrl(url);
  };

  return (
    <View>
      <View style={styles.blockHeader}>
        <View style={[styles.orderItemHeader, stylesGlobal.title]}>
          <Title>
            {intl.formatMessage({
              id: 'order.receipts',
            })}
          </Title>
        </View>
      </View>
      <Flex direction='column' justify='start' align='start' gap={2}>
        {receipts.map((receipt) => (
          <TextLink
            key={receipt.uuid}
            link={`#`}
            onClick={onOpenModal(receipt.receiptUrl)}
            style={{
              color: receipt.isReturn ? theme.colors.danger : theme.colors.primary,
            }}
          >
            {receipt.isReturn
              ? `${receipt.uuid} (${intl.formatMessage({
                  id: 'order.receipt',
                })} ${receipt.relatedReceiptId})`
              : receipt.uuid}
          </TextLink>
        ))}
      </Flex>
      <ReceiptModal isOpen={isOpen} onClose={onClose} url={url} />
    </View>
  );
});

OrderReceipts.displayName = 'OrderReceipts';

OrderReceipts.propTypes = {
  receipts: PropTypes.array,
};

export default OrderReceipts;
