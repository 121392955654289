import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import create from 'zustand';

import { API_HOST } from '../constants';
import { LocalStorage } from '../services';
import { getCurrentLocale } from '../utils/getCurrentLocale';

export const useErrorsStore = create(() => ({
  hasError: false,
  error: null,
  date: null,
}));

const httpLink = createUploadLink({
  uri: `${API_HOST}/graphql`,
  headers: { 'Apollo-Require-Preflight': true },
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
  console.log('graphQLErrors', graphQLErrors);

  if (networkError || graphQLErrors[0]) {
    useErrorsStore.setState({
      hasError: true,
      error: graphQLErrors[0] || networkError,
      date: Date.now(),
    });
  }
  if (networkError && networkError.statusCode === 401) {
    LocalStorage.deleteValue('token');
  }
});

const authLink = setContext(async (_, { headers }) => {
  const token = await LocalStorage.getValue('token');

  const languageLocale = getCurrentLocale();

  return {
    headers: {
      ...headers,
      locale: languageLocale,
      authorization: token ? `Bearer ${token}` : '',
      'Apollo-Require-Preflight': true,
    },
  };
});

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    purchases: {
      Query: {
        fields: {
          purchases: { merge: false },
        },
      },
    },
  }),
});
