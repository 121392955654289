import React, { useEffect, useState } from 'react';

import { DesktopTable, Layout, RequestHandler } from '../../../../../components';
import {
  useGetStatusesQuery,
  useGetAlloOrderStatusMapsQuery,
  useHistory,
} from '../../../../../hooks';
import { getOrderStatusName } from '../../../../../utils/getOrderStatusName';

const COLUMNS = [{ intlId: 'app.name' }, { intlId: 'app.status' }];

const AlloOrderStatuses = () => {
  const navigation = useHistory();
  const [statuses, setStatuses] = useState([]);
  const { data: statusesData } = useGetStatusesQuery();

  const { data: alloOrderStatusMapsData, ...alloOrderStatusMapsQueryProps } =
    useGetAlloOrderStatusMapsQuery();

  useEffect(() => {
    if (!statusesData) return;
    setStatuses(statusesData?.statuses);
  }, [statusesData]);

  const handleEditButtonClick = (alloStatusId, statusId) => {
    navigation.push('/settings/ordersstatuses/allo/' + alloStatusId + '/' + statusId);
  };

  const handleClickAddButton = () => {
    navigation.push('/settings/ordersstatuses/allo/add');
  };

  return (
    <Layout isScrollable={false} onActionButtonPress={handleClickAddButton}>
      <RequestHandler {...alloOrderStatusMapsQueryProps}>
        <DesktopTable>
          <DesktopTable.Head>
            {COLUMNS?.map(({ intlId, sortId }) => (
              <DesktopTable.Title key={intlId} intlId={intlId} sortId={sortId} />
            ))}
          </DesktopTable.Head>
          <DesktopTable.Body>
            {alloOrderStatusMapsData?.alloOrderStatusMaps?.map((item) => (
              <DesktopTable.Row
                key={item?.id}
                onEditButtonClick={() =>
                  handleEditButtonClick(item.alloStatusId, item.statusId)
                }
              >
                <DesktopTable.Cell>{item.alloStatusName}</DesktopTable.Cell>
                <DesktopTable.Cell>
                  {getOrderStatusName(statuses, item.statusId)}
                </DesktopTable.Cell>
              </DesktopTable.Row>
            ))}
          </DesktopTable.Body>
        </DesktopTable>
      </RequestHandler>
    </Layout>
  );
};

export default AlloOrderStatuses;
