import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const WarehouseIcon = ({
  size = 24,
  style = {},
  color1 = '#EE5253',
  color2 = '#FF9F43',
}) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 40 40' style={{ ...style }}>
      <Path
        d='M9.99992 31.6667H13.3333V35H9.99992V31.6667ZM19.9999 5L3.33325 13.3333V35H6.66659V21.6667H33.3333V35H36.6666V13.3333L19.9999 5ZM13.3333 18.3333H6.66659V15H13.3333V18.3333ZM23.3333 18.3333H16.6666V15H23.3333V18.3333ZM33.3333 18.3333H26.6666V15H33.3333V18.3333ZM9.99992 25H13.3333V28.3333H9.99992V25ZM16.6666 25H19.9999V28.3333H16.6666V25ZM16.6666 31.6667H19.9999V35H16.6666V31.6667ZM23.3333 31.6667H26.6666V35H23.3333V31.6667Z'
        fill={color1}
      />
      <Path
        d='M10 31.6667H13.3333V35H10V31.6667ZM10 25H13.3333V28.3333H10V25ZM16.6667 25H20V28.3333H16.6667V25ZM16.6667 31.6667H20V35H16.6667V31.6667ZM23.3333 31.6667H26.6667V35H23.3333V31.6667Z'
        fill={color2}
      />
    </Svg>
  );
};

WarehouseIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
  color1: PropTypes.string,
  color2: PropTypes.string,
};
