import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const PurchaseIcon = ({
  size = 24,
  style = {},
  color1 = '#2E86DE',
  color2 = '#0ABDE3',
}) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 40 40' style={{ ...style }}>
      <Path
        d='M11.6667 29.9999C9.83341 29.9999 8.35008 31.4999 8.35008 33.3333C8.35008 35.1666 9.83341 36.6666 11.6667 36.6666C13.5001 36.6666 15.0001 35.1666 15.0001 33.3333C15.0001 31.4999 13.5001 29.9999 11.6667 29.9999ZM1.66675 4.99992C1.66675 5.91659 2.41675 6.66659 3.33341 6.66659H5.00008L11.0001 19.3166L8.75008 23.3833C7.53341 25.6166 9.13341 28.3333 11.6667 28.3333H30.0001C30.9167 28.3333 31.6667 27.5833 31.6667 26.6666C31.6667 25.7499 30.9167 24.9999 30.0001 24.9999H11.6667L13.5001 21.6666H25.9167C27.1667 21.6666 28.2667 20.9833 28.8334 19.9499L34.8001 9.13325C34.9405 8.88063 35.0126 8.59571 35.0091 8.30668C35.0057 8.01765 34.9268 7.73454 34.7803 7.48536C34.6338 7.23617 34.4248 7.02956 34.174 6.88595C33.9231 6.74235 33.6391 6.66673 33.3501 6.66659H8.68341L7.56675 4.28325C7.4328 3.99876 7.22058 3.75832 6.95493 3.59008C6.68928 3.42183 6.38119 3.33275 6.06675 3.33325H3.33341C2.41675 3.33325 1.66675 4.08325 1.66675 4.99992ZM28.3334 29.9999C26.5001 29.9999 25.0167 31.4999 25.0167 33.3333C25.0167 35.1666 26.5001 36.6666 28.3334 36.6666C30.1667 36.6666 31.6667 35.1666 31.6667 33.3333C31.6667 31.4999 30.1667 29.9999 28.3334 29.9999Z'
        fill={color1}
      />
      <Path
        d='M11.6668 30C9.83343 30 8.3501 31.5 8.3501 33.3333C8.3501 35.1667 9.83343 36.6667 11.6668 36.6667C13.5001 36.6667 15.0001 35.1667 15.0001 33.3333C15.0001 31.5 13.5001 30 11.6668 30ZM28.3334 30C26.5001 30 25.0168 31.5 25.0168 33.3333C25.0168 35.1667 26.5001 36.6667 28.3334 36.6667C30.1668 36.6667 31.6668 35.1667 31.6668 33.3333C31.6668 31.5 30.1668 30 28.3334 30Z'
        fill={color2}
      />
    </Svg>
  );
};

PurchaseIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
  color1: PropTypes.string,
  color2: PropTypes.string,
};
