import React from 'react';

import {
  DesktopTable,
  FormattedDate,
  Layout,
  MobileTable,
  RequestHandler,
} from '../../../components';
import { IS_DESKTOP_SCREEN } from '../../../constants';
import { useGetCurrenciesQuery, useHistory } from '../../../hooks';
import { getCurrencyRatio } from '../../../utils';
import CurrenciesMobileItem from './CurrenciesMobileItem/CurrenciesMobileItem';

const Currencies = () => {
  const navigation = useHistory();
  const { data, ...queryProps } = useGetCurrenciesQuery();

  const handleClickAddButton = () => {
    navigation.push('/bookkeeping/currency/');
  };

  const handleEditButtonClick = (item) => {
    navigation.push(`/bookkeeping/currency/${item?.id}`);
  };

  return (
    <Layout isScrollable={false} onActionButtonPress={handleClickAddButton}>
      <RequestHandler {...queryProps}>
        {IS_DESKTOP_SCREEN ? (
          <DesktopTable>
            <DesktopTable.Head>
              <DesktopTable.Title intlId='app.title' />
              <DesktopTable.Title intlId='app.code' />
              <DesktopTable.Title intlId='app.symbol' />
              <DesktopTable.Title intlId='app.ratio&rate' />
              <DesktopTable.Title intlId='app.validWith' />
            </DesktopTable.Head>
            <DesktopTable.Body>
              {data?.currencies?.map((item, index) => (
                <DesktopTable.Row
                  key={index}
                  onEditButtonClick={() => handleEditButtonClick(item)}
                >
                  <DesktopTable.Cell>{item.name}</DesktopTable.Cell>
                  <DesktopTable.Cell>{item.code}</DesktopTable.Cell>
                  <DesktopTable.Cell>{item.symbol}</DesktopTable.Cell>
                  <DesktopTable.Cell>
                    {getCurrencyRatio(item?.lastRate?.ratio)}
                  </DesktopTable.Cell>
                  <DesktopTable.Cell>
                    {item?.lastRate?.date ? (
                      <FormattedDate date={new Date(item.lastRate.date)} />
                    ) : null}
                  </DesktopTable.Cell>
                </DesktopTable.Row>
              ))}
            </DesktopTable.Body>
          </DesktopTable>
        ) : (
          <MobileTable
            data={data?.currencies}
            renderItem={(item) => (
              <CurrenciesMobileItem
                onItemPress={() => handleEditButtonClick(item)}
                name={item.name}
                code={item.code}
                symbol={item.symbol}
                ratio={getCurrencyRatio(item?.lastRate?.ratio)}
                date={
                  item?.lastRate?.date ? (
                    <FormattedDate date={new Date(item.lastRate.date)} />
                  ) : null
                }
              />
            )}
          />
        )}
      </RequestHandler>
    </Layout>
  );
};

export default Currencies;
