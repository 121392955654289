import { useFormikContext } from 'formik';

function getOptionByValue(options, value) {
  return options?.find((el) => Number(el?.value) === Number(value));
}

export const useMultiSelect = (name, options) => {
  const { values, setFieldValue } = useFormikContext();

  const handleChange = (value) => {
    const valueAlreadyAdded = getOptionByValue(values[name], value);
    if (!valueAlreadyAdded && value) {
      setFieldValue(name, [...values[name], getOptionByValue(options, value)]);
    } else if (value) {
      setFieldValue(name, [...values[name]]);
    }
  };

  const handleRemove = (value) => {
    setFieldValue(name, [
      ...values[name]?.filter((el) => Number(el?.value) !== Number(value)),
    ]);
  };

  return {
    onChange: handleChange,
    onRemove: handleRemove,
  };
};
