import React from 'react';

import {
  DesktopTable,
  Layout,
  MobileTable,
  RequestHandler,
} from '../../../../../components';
import { IS_DESKTOP_SCREEN } from '../../../../../constants';
import { useGetStatusesQuery } from '../../../../../hooks';
import CrmOrderStatusMobileItem from './CrmOrderStatusMobileItem';

const COLUMNS = [
  { intlId: 'crm.id' },
  { intlId: 'app.name' },
  { intlId: 'crm.reservationType' },
];

const CrmOrderStatuses = () => {
  const { data, ...queryProps } = useGetStatusesQuery();

  if (IS_DESKTOP_SCREEN) {
    return (
      <Layout isScrollable={false}>
        <RequestHandler {...queryProps}>
          <DesktopTable editable={false}>
            <DesktopTable.Head>
              {COLUMNS?.map(({ intlId, sortId }) => (
                <DesktopTable.Title key={intlId} intlId={intlId} sortId={sortId} />
              ))}
            </DesktopTable.Head>
            <DesktopTable.Body>
              {data?.statuses?.map((item) => (
                <DesktopTable.Row key={item?.id}>
                  <DesktopTable.Cell>{item.id}</DesktopTable.Cell>
                  <DesktopTable.Cell>{item.name}</DesktopTable.Cell>
                  <DesktopTable.Cell>{item.reservationType}</DesktopTable.Cell>
                </DesktopTable.Row>
              ))}
            </DesktopTable.Body>
          </DesktopTable>
        </RequestHandler>
      </Layout>
    );
  }
  return (
    <Layout isScrollable={false}>
      <RequestHandler {...queryProps}>
        <MobileTable
          data={data?.statuses}
          renderItem={(item) => (
            <CrmOrderStatusMobileItem
              id={item.id}
              name={item.name}
              reservationType={item.reservationType}
            />
          )}
        />
      </RequestHandler>
    </Layout>
  );
};

export default CrmOrderStatuses;
