import { Checkbox as NBCheckbox, Flex } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { Typography } from '../Typography/Typography';

export const Checkbox = ({
  checked,
  label,
  onChange,
  justify = 'flex-start',
  ...props
}) => {
  return (
    <Flex justify={justify}>
      <NBCheckbox
        isChecked={checked}
        colorScheme='info'
        onChange={onChange}
        size='sm'
        {...props}
      >
        {label ? <Typography intlId={label} ml='xs' /> : null}
      </NBCheckbox>
    </Flex>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  justify: PropTypes.string,
  style: PropTypes.object,
};
