import { StyleSheet } from 'react-native';

import { theme } from '../../theme';

export const styles = StyleSheet.create({
  close: {
    position: 'absolute',
    right: 15,
    top: 15,
    zIndex: 10,
  },
  closeIcon: {
    color: theme.colors.text.default,
    fontSize: 18,
  },
});
