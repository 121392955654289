import { Flex, Modal, View } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, Typography } from '../../../components';
import { IS_MOBILE_SCREEN } from '../../../constants';
import styles from './styles';

const RemovePackagModal = ({ isOpen, handleClose, handleSubmit }) => {
  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <View style={styles.modalWrapper}>
        <Typography style={styles.modalTitle} intlId='app.isDeleteRackage' />
        <Typography style={styles.modalDescription} intlId='app.attention' />
        <Flex
          justifyContent={'flex-end'}
          flexDirection={IS_MOBILE_SCREEN ? 'column' : 'row'}
        >
          <Button
            variant='remove'
            style={styles.buttonCancel}
            onPress={handleClose}
            intlId={'app.cancel'}
          />

          <Button variant='remove' onPress={handleSubmit} intlId={'app.remove'} />
        </Flex>
      </View>
    </Modal>
  );
};

RemovePackagModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default RemovePackagModal;
