import { useField, useFormikContext } from 'formik';
import { Flex } from 'native-base';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import {
  AddPurchaseModal,
  BoxWrapper,
  PurchaseDescription,
  TransactionsAdditionalData,
  Typography,
} from '../../../components';
import { IS_DESKTOP_SCREEN } from '../../../constants';
import { getVolumeWeightProduct, getWeightProduct } from '../../../utils';

export const TransactionPurchase = () => {
  const intl = useIntl();
  const { values, setFieldValue, errors } = useFormikContext();
  const [{ value }, meta] = useField('purchase');

  useEffect(() => {
    return () => setFieldValue('purchase', '');
  }, [setFieldValue]);

  return (
    <>
      <BoxWrapper mb='120px'>
        <Flex justify='space-between'>
          <Typography intlId='app.purchase' variant='heading' mb={0} />
          <AddPurchaseModal
            onAddPurchase={(purchase) => setFieldValue('purchase', purchase)}
            buttonText={value?.id ? 'app.change' : 'app.select'}
            addedPurchases={[values?.purchase]}
          />
        </Flex>
        {value ? (
          <Flex align='flex-start' direction='column'>
            <TransactionsAdditionalData item={values} />
            <Flex width={IS_DESKTOP_SCREEN ? '80%' : '100%'}>
              <Flex direction={IS_DESKTOP_SCREEN ? 'row' : 'column'}>
                <PurchaseDescription
                  title={'app.cost'}
                  data={value?.price ? `${value?.price} $` : 'app.noData'}
                />
                <PurchaseDescription
                  title={'app.deliveryCost'}
                  data={value?.deliveryPrice ? `${value?.deliveryPrice} $` : 'app.noData'}
                />
              </Flex>
              <Flex direction={IS_DESKTOP_SCREEN ? 'row' : 'column'}>
                <PurchaseDescription
                  title={'app.volumeWeight'}
                  data={getVolumeWeightProduct(value, intl) || 'app.noData'}
                />
                <PurchaseDescription
                  title={'app.weight'}
                  data={getWeightProduct(value) || 'app.noData'}
                />
              </Flex>
            </Flex>
          </Flex>
        ) : null}
        {meta.error && meta.touched && (
          <Typography intlId={errors.purchase} fontSize='xs' color='error' />
        )}
      </BoxWrapper>
    </>
  );
};
