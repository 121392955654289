import { useField, useFormikContext } from 'formik';
import { Flex } from 'native-base';
import React, { useEffect, useState } from 'react';

import {
  AddOrderModal,
  BoxWrapper,
  Button,
  OrderDescription,
  TransactionsAdditionalData,
  Typography,
} from '../../../components';
import {
  OrderCustomerIcon,
  OrderPaymentIcon,
  OrderSourceIcon,
  OrderUserIcon,
} from '../../../components/Icons';
import { IS_DESKTOP_SCREEN } from '../../../constants';

export const TransactionOrder = () => {
  const { values, setFieldValue, errors } = useFormikContext();
  const [{ value }, meta] = useField('order');

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    return () => setFieldValue('order', '');
  }, [setFieldValue]);

  return (
    <>
      <BoxWrapper mb='120px'>
        <Flex justify='space-between'>
          <Typography intlId='app.order' variant='heading' mb={0} />
          <Button
            intlId={value?.id ? 'app.change' : 'app.select'}
            onPress={() => setIsModalOpen(true)}
          />
        </Flex>
        {value ? (
          <Flex align='flex-start' direction='column'>
            <Flex paddingY='8px'>
              <TransactionsAdditionalData item={values} />
            </Flex>
            <Flex
              align={'flex-start'}
              justify={'space-between'}
              direction={IS_DESKTOP_SCREEN ? 'row' : 'column'}
              width={IS_DESKTOP_SCREEN ? '80%' : '100%'}
            >
              <Flex direction={'column'} align={'flex-start'}>
                <OrderDescription
                  mb={'12px'}
                  icon={<OrderCustomerIcon />}
                  intlId={`${value?.customer?.firstName} ${value?.customer?.lastName}`}
                />
                <OrderDescription
                  mb={'12px'}
                  icon={<OrderUserIcon />}
                  intlId={`${value?.user?.firstName} ${value?.user?.lastName}`}
                />
              </Flex>
              <Flex direction={'column'} align={'flex-start'}>
                <OrderDescription
                  mb={'12px'}
                  icon={<OrderSourceIcon />}
                  intlId={value?.source?.name}
                />
                <OrderDescription
                  mb={'12px'}
                  icon={<OrderPaymentIcon />}
                  intlId={value?.paymentType?.name}
                />
              </Flex>
            </Flex>
          </Flex>
        ) : null}
        {meta.error && meta.touched && (
          <Typography intlId={errors?.order} fontSize='xs' color='error' />
        )}
      </BoxWrapper>
      {isModalOpen ? (
        <AddOrderModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      ) : null}
    </>
  );
};
