import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { View } from 'react-native';

import { ROUTES } from '../../../constants';
import { useSearchContext } from '../../../context';
import { useRoute } from '../../../hooks';
import { SearchField } from '../../SearchField/SearchField';
import { HeaderLeft } from '../HeaderLeft/HeaderLeft';
import { HeaderRight } from '../HeaderRight/HeaderRight';
import { styles } from './styles';

export const HeaderContent = ({ isSingleScreen }) => {
  const { path } = useRoute();
  const { searchValue, changeSearchValue } = useSearchContext();

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  return (
    <View style={styles.wrapper}>
      {!isSearchOpen ? <HeaderLeft /> : null}

      <View style={styles.actions}>
        {isSingleScreen || !ROUTES.isSearchScreen(path) ? null : (
          <SearchField
            value={searchValue}
            onChange={changeSearchValue}
            isOpen={isSearchOpen}
            onOpen={() => setIsSearchOpen(true)}
            onClose={() => setIsSearchOpen(false)}
          />
        )}

        {!isSearchOpen ? <HeaderRight isSingleScreen={isSingleScreen} /> : null}
      </View>
    </View>
  );
};

HeaderContent.propTypes = {
  isSingleScreen: PropTypes.bool,
};
