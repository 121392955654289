import { MaterialIcons } from '@expo/vector-icons';
import { Flex, Icon } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '../Button/Button';
import { DesktopTable } from '../DesktopTable/DesktopTable';
import { Typography } from '../Typography/Typography';

export const ModalButton = ({
  handleSet,
  addedItemsIds,
  item,
  withQuantity = false,
  isAvailable = true,
  disableText = 'app.notInStock',
}) => {
  return (
    <DesktopTable.Cell width={80} textAlign={'center'}>
      {addedItemsIds?.includes(item?.id) ? (
        <Flex justify={'center'} marginX={8}>
          <Icon as={<MaterialIcons name='check-circle' />} size='sm' color='success' />
        </Flex>
      ) : withQuantity && !isAvailable ? (
        <Typography intlId={disableText} fontSize='11px' color='gray.main' />
      ) : (
        <Button intlId='app.add' size='sm' onPress={() => handleSet(item)} />
      )}
    </DesktopTable.Cell>
  );
};

ModalButton.propTypes = {
  addedItemsIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  item: PropTypes.object.isRequired,
  handleSet: PropTypes.func.isRequired,
  withQuantity: PropTypes.bool,
  disableText: PropTypes.string,
  isAvailable: PropTypes.bool,
};
