import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const BoxesArrangementIcon = ({ size = 24, style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' style={{ ...style }}>
      <Path
        d='M23.3333 13H20V17L18.6667 16.1125L17.3333 17V13H14C13.6333 13 13.3333 13.3 13.3333 13.6667V21.6667C13.3333 22.0333 13.6333 22.3333 14 22.3333H23.3333C23.7 22.3333 24 22.0333 24 21.6667V13.6667C24 13.3 23.7 13 23.3333 13ZM7.33333 10.3333H16.6667C17.0333 10.3333 17.3333 10.0333 17.3333 9.66667V1.66667C17.3333 1.3 17.0333 1 16.6667 1H13.3333V5L12 4.1125L10.6667 5V1H7.33333C6.96667 1 6.66667 1.3 6.66667 1.66667V9.66667C6.66667 10.0333 6.96667 10.3333 7.33333 10.3333ZM10 13H6.66667V17L5.33333 16.1125L4 17V13H0.666667C0.3 13 0 13.3 0 13.6667V21.6667C0 22.0333 0.3 22.3333 0.666667 22.3333H10C10.3667 22.3333 10.6667 22.0333 10.6667 21.6667V13.6667C10.6667 13.3 10.3667 13 10 13Z'
        fill='white'
      />
    </Svg>
  );
};

BoxesArrangementIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
