import { MaterialIcons } from '@expo/vector-icons';
import { Box, Flex, Icon } from 'native-base';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  BarCodeScanner,
  Button,
  TextFieldHookFrom,
  Typography,
} from '../../../../components';
import BarCodeReader from '../../../../components/BarCodeReader/BarCodeReader';
import { IS_DESKTOP_SCREEN } from '../../../../constants';
import { useNotificationsContext } from '../../../../context';

const Barcodes = () => {
  // const { values, setValue } = useFormikContext();
  const { watch, setValue } = useFormContext();
  const values = watch();
  const [isScanOpen, setIsScanOpen] = useState(false);
  const { showNotification } = useNotificationsContext();

  const handleAddButtonClick = () => {
    if (values.barcodes.includes(values.addedBarcode)) {
      showNotification({ message: 'errors.barcodeAlreadyAdded', type: 'error' });
      setValue('addedBarcode', '');
      return;
    }
    if (values.addedBarcode.length < 5 || values.addedBarcode.length > 14) {
      showNotification({
        message: 'validation.barcodeCharacters',
        type: 'error',
      });
      return;
    }

    if (values.addedBarcode) {
      setValue('barcodes', [...values.barcodes, values.addedBarcode]);
      setValue('addedBarcode', '');
    }
  };

  const handleDeleteButtonClick = (barcode) => {
    setValue(
      'barcodes',
      values.barcodes.filter((currentBarcode) => currentBarcode !== barcode),
    );
  };

  const handleChangeBarcode = (code) => {
    if (code && !values.barcodes.includes(code)) {
      setValue('barcodes', [...values.barcodes, code]);
      setValue('addedBarcode', '');
    }
  };

  return (
    <>
      {!IS_DESKTOP_SCREEN ? (
        <BarCodeScanner
          isOpen={isScanOpen}
          onClose={() => setIsScanOpen(false)}
          onChangeCode={handleChangeBarcode}
        />
      ) : null}
      <Flex justify='space-between' alignItems='baseline'>
        <Typography intlId='app.barcodes' variant='heading' />
        {!IS_DESKTOP_SCREEN ? (
          <Button intlId='app.scan' onPress={() => setIsScanOpen(true)} />
        ) : null}
      </Flex>
      <Flex
        alignItems='flex-start'
        direction={{ base: 'column', sm: 'row' }}
        justify='space-between'
      >
        <Box
          width={{ base: '100%', sm: '50%' }}
          mb={{ base: '20px', sm: 0 }}
          pd={{ base: 0, sm: '70px' }}
        >
          {values?.barcodes?.map((barcode, index) => (
            <Box key={index}>
              <BarCodeReader value={barcode} />
              <Flex
                justify={{ base: 'center', sm: 'flex-start' }}
                align='center'
                marginY='xs'
                pl='8px'
              >
                <Typography mr={'90px'} noOfLines={2}>
                  {barcode}
                </Typography>
                <Icon
                  alignSelf={'flex-end'}
                  name='delete'
                  as={MaterialIcons}
                  color='gray.dark'
                  size='18'
                  onPress={() => handleDeleteButtonClick(barcode)}
                />
              </Flex>
            </Box>
          ))}
        </Box>
        <Box
          flexDirection={{ base: 'row', sm: 'column' }}
          width={{ base: '100%', sm: '50%' }}
          justifyContent={{ base: 'center' }}
        >
          <TextFieldHookFrom
            placeholderIntlId='app.addBarcodes'
            name='addedBarcode'
            type='number'
            width={!IS_DESKTOP_SCREEN ? '70%' : '100%'}
            mb={10}
          />
          <Button variant='link' intlId='app.add' onPress={handleAddButtonClick} />
        </Box>
      </Flex>
    </>
  );
};

export default Barcodes;
