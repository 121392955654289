import { Formik } from 'formik';
import { Box } from 'native-base';
import React, { useState } from 'react';

import {
  BoxWrapper,
  FieldRow,
  FormRow,
  FormWrapper,
  Layout,
  QuerySelectWithSearch,
  RequestHandler,
  SelectWithSearch,
  TabNavigation,
  TextField,
  Typography,
} from '../../../components';
import { useNotificationsContext } from '../../../context';
import { FIND_PURCHASE_FORWARDINGS_QUERY, GET_STOCK_PLACES } from '../../../graphql';
import {
  useAcceptanceMutation,
  usePurchaseMutation,
  useStockLocationsOptions,
  useStockPlaceInAcceptanceMutation,
} from '../../../hooks';
import { acceptanceSchema } from '../../../services/validation';
import { AcceptanceStructure } from './AcceptanceStructure/AcceptanceStructure';

const Acceptance = () => {
  const { getRoomsOptions, getShelvingOptions, stockLocationsQueryProps } =
    useStockLocationsOptions();

  const [isNewPlace, setIsNewPlace] = useState(true);

  const [acceptanceMutation, { loading: acceptanceLoading }] = useAcceptanceMutation();

  const [addPlace, { loading: addPlaceLoading }] = useStockPlaceInAcceptanceMutation();

  const { showNotification } = useNotificationsContext();

  const handleFormSubmit = async (values, { resetForm }) => {
    if (values.products.length === 0) {
      showNotification({
        type: 'error',
        title: 'errors.default',
        message: 'products.required',
      });
      return;
    }

    const variables = [];

    const handlerAcceptanceMutation = async (placeId) => {
      values.products.forEach((product) => {
        variables.push({
          placeId: parseInt(placeId),
          purchaseId: parseInt(product.purchaseId),
          conditionId: parseInt(product.conditionId),
          value: parseInt(product.quantity),
        });
      });

      for (const item of variables) {
        await acceptanceMutation({ variables: item });
      }
      resetForm();
    };

    if (isNewPlace) {
      const newPlace = await addPlace({
        variables: {
          input: {
            name: values.name,
            locationId: +values.shelving,
            archival: false,
          },
        },
      });
      await handlerAcceptanceMutation(newPlace?.data?.stockPlace?.id);
    }

    if (!isNewPlace) {
      await handlerAcceptanceMutation(values.place.id);
    }
  };

  return (
    <FormWrapper>
      <Formik
        initialValues={{
          name: '',
          room: '',
          shelving: '',
          place: null,
          forwardingId: '',
          products: [],
        }}
        onSubmit={handleFormSubmit}
        validationSchema={acceptanceSchema(isNewPlace)}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Layout
            onActionButtonPress={handleSubmit}
            actionButtonType='save'
            actionButtonIsLoading={acceptanceLoading || addPlaceLoading}
            isScrollable={false}
          >
            <FormRow>
              <FormRow.Left>
                <BoxWrapper p={0}>
                  <TabNavigation
                    onChange={(index) => setIsNewPlace(index === 0)}
                    items={['app.newFeminine', 'app.select']}
                  >
                    <TabNavigation.Item>
                      <Box p={{ base: 'md', sm: 'lg' }}>
                        <FieldRow>
                          <TextField name='name' label='app.title' isFullWidth />
                        </FieldRow>

                        <Typography intlId='app.location' variant='heading' mt={'lg'} />

                        <RequestHandler {...stockLocationsQueryProps}>
                          <FieldRow>
                            <SelectWithSearch
                              name='room'
                              label='app.room'
                              options={getRoomsOptions()}
                              onChange={() => {
                                setFieldValue('shelving', '');
                              }}
                            />
                            <SelectWithSearch
                              name='shelving'
                              label='app.shelving'
                              options={getShelvingOptions(values.room)}
                            />
                          </FieldRow>
                        </RequestHandler>
                      </Box>
                    </TabNavigation.Item>

                    <TabNavigation.Item>
                      <Box p={{ base: 'md', sm: 'lg' }}>
                        <FieldRow>
                          <QuerySelectWithSearch
                            width={'100%'}
                            graphql={GET_STOCK_PLACES}
                            graphqlName='stockPlaces'
                            fieldName='place'
                            label='app.box'
                            valueProperty={['id', 'name']}
                            labelProperty={'name'}
                          />
                        </FieldRow>
                      </Box>
                    </TabNavigation.Item>
                  </TabNavigation>
                </BoxWrapper>
              </FormRow.Left>
              <FormRow.Right>
                <BoxWrapper>
                  <Typography intlId='app.trackingCode' variant='heading' />
                  <FieldRow>
                    <QuerySelectWithSearch
                      width={'100%'}
                      label={'app.trackingCode'}
                      fieldName={'forwardingId'}
                      rowsInResponse
                      graphql={FIND_PURCHASE_FORWARDINGS_QUERY}
                      graphqlName='purchaseForwardings'
                      labelProperty={'trackingCode'}
                      onChange={() => setFieldValue('products', [])}
                    />
                  </FieldRow>
                </BoxWrapper>
              </FormRow.Right>
            </FormRow>
            <AcceptanceStructure />
          </Layout>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default Acceptance;
