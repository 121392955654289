import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const DisplacementsIcon = ({ size = 24, style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' style={{ ...style }}>
      <Path
        d='M19.9239 10.383C19.9996 10.2002 20.0194 9.99907 19.9807 9.80505C19.9421 9.61102 19.8468 9.43281 19.7069 9.29297L14.7069 4.29297L13.2929 5.70697L16.5859 8.99997H3.99987V11H18.9999C19.1976 11 19.391 10.9414 19.5554 10.8316C19.7199 10.7218 19.8481 10.5657 19.9239 10.383ZM4.07587 13.617C4.00017 13.7997 3.98038 14.0009 4.019 14.1949C4.05763 14.3889 4.15294 14.5671 4.29287 14.707L9.29287 19.707L10.7069 18.293L7.41387 15H19.9999V13H4.99987C4.80208 12.9998 4.60868 13.0583 4.44418 13.1682C4.27969 13.278 4.15151 13.4342 4.07587 13.617Z'
        fill='white'
      />
    </Svg>
  );
};

DisplacementsIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
