import { Text, View } from 'native-base';
import React from 'react';
import { useIntl } from 'react-intl';

import { Layout } from '../../../components';

const SettingsMain = () => {
  const intl = useIntl();
  return (
    <Layout>
      <View>
        <Text>{intl.formatMessage({ id: 'page.settingsMainCoomingSoon' })}</Text>
      </View>
    </Layout>
  );
};

export default SettingsMain;
