import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const BackArrowIcon = (props) => {
  return (
    <Svg width={14} height={15} viewBox='0 0 14 6' fill='currentColor' {...props}>
      <Path
        d='M1.914 3.49984H14V4.49984H1.914L4.596 7.18184L3.889 7.88884L0 3.99984L3.889 0.11084L4.596 0.81784L1.914 3.49984Z'
        fill='white'
      />
    </Svg>
  );
};
