import { useHistory as useHistoryWeb } from 'react-router-dom';
import { useHistory as useHistoryNative } from 'react-router-native';

import { isWeb } from '../constants';

const useHistory = () => {
  if (isWeb) {
    return useHistoryWeb();
  } else {
    return useHistoryNative();
  }
};

export default useHistory;
