import { StyleSheet } from 'react-native';

import { isWeb, theme } from '../../../constants';

export default StyleSheet.create({
  accordionItem: { overflow: 'hidden' },
  addLocationButton: {
    fontSize: 14,
    lineHeight: 16.41,
    marginLeft: 8,
    paddingBottom: 19,
    paddingTop: 19,
  },
  addSubLocationButton: {
    backgroundColor: theme.colors.white,
    paddingBottom: 0,
    paddingLeft: 8,
    paddingTop: 0,
  },
  addSubLocationLinkWrapper: { paddingBottom: 19.5, paddingTop: 19.5 },
  addTextFieldWrapper: {},
  childrenContainer: {},
  container: {
    backgroundColor: theme.colors.white,
    borderRadius: 5,
    ...(isWeb && { minWidth: 570 }),
    overflow: 'hidden',
    paddingBottom: 8,
    paddingTop: 8,
  },
  content: {
    fontSize: 16,
  },
  disabledCursor: { ...(isWeb && { cursor: 'pointer' }) },
  editContainer: {
    backgroundColor: theme.colors.white,
    borderRadius: 5,
    ...(isWeb && { minWidth: 570 }),
    overflow: 'hidden',
    paddingBottom: 8,
    paddingTop: 8,
  },
  horizontalLine: {
    borderBottomColor: theme.colors.black,
    borderBottomWidth: 1,
    marginBottom: 8,
    marginTop: 8,
  },
  icon: { ...(isWeb && { cursor: 'pointer' }), marginLeft: 10 },
  title: {
    color: theme.colors.dark,
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 18.75,
  },
});
