import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const BookkeepingCurrenciesIcon = ({ size = 24, style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' style={{ ...style }}>
      <Path
        d='M20.25 5.125H2.375C2.375 4.76033 2.51987 4.41059 2.77773 4.15273C3.03559 3.89487 3.38533 3.75 3.75 3.75H20.25C20.6147 3.75 20.9644 3.89487 21.2223 4.15273C21.4801 4.41059 21.625 4.76033 21.625 5.125H20.25Z'
        fill='white'
      />
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.625 7.875H2.375V18.875H21.625V7.875ZM2.375 6.5C2.01033 6.5 1.66059 6.64487 1.40273 6.90273C1.14487 7.16059 1 7.51033 1 7.875L1 18.875C1 19.2397 1.14487 19.5894 1.40273 19.8473C1.66059 20.1051 2.01033 20.25 2.375 20.25H21.625C21.9897 20.25 22.3394 20.1051 22.5973 19.8473C22.8551 19.5894 23 19.2397 23 18.875V7.875C23 7.51033 22.8551 7.16059 22.5973 6.90273C22.3394 6.64487 21.9897 6.5 21.625 6.5H2.375Z'
        fill='white'
      />
      <Path
        d='M18.875 7.875C18.875 8.60435 19.1647 9.30382 19.6805 9.81954C20.1962 10.3353 20.8957 10.625 21.625 10.625V7.875H18.875ZM5.125 7.875C5.125 8.60435 4.83527 9.30382 4.31954 9.81954C3.80382 10.3353 3.10435 10.625 2.375 10.625V7.875H5.125ZM18.875 18.875C18.875 18.1457 19.1647 17.4462 19.6805 16.9305C20.1962 16.4147 20.8957 16.125 21.625 16.125V18.875H18.875ZM5.125 18.875C5.125 18.1457 4.83527 17.4462 4.31954 16.9305C3.80382 16.4147 3.10435 16.125 2.375 16.125V18.875H5.125ZM14.75 13.375C14.75 14.1043 14.4603 14.8038 13.9445 15.3195C13.4288 15.8353 12.7293 16.125 12 16.125C11.2707 16.125 10.5712 15.8353 10.0555 15.3195C9.53973 14.8038 9.25 14.1043 9.25 13.375C9.25 12.6457 9.53973 11.9462 10.0555 11.4305C10.5712 10.9147 11.2707 10.625 12 10.625C12.7293 10.625 13.4288 10.9147 13.9445 11.4305C14.4603 11.9462 14.75 12.6457 14.75 13.375V13.375Z'
        fill='white'
      />
    </Svg>
  );
};

BookkeepingCurrenciesIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
