import RNDateTimePicker from '@react-native-community/datetimepicker';
import { format } from 'date-fns';
import { Input } from 'native-base';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TouchableOpacity } from 'react-native';

import { IS_IOS, IS_WEB } from '../../constants';
import { FieldWrapper } from '../FieldWrapper/FieldWrapper';
import { DatePickerWebInput } from './DatePickerWebInput';

const getFormattedDate = (date) => {
  if (!date) return '';
  return format(new Date(date), 'yyyy-MM-dd');
};

export const DatePickerHookForm = ({
  name,
  label,
  placeholder = 'Выберите дату',
  style,
  inputStyles,
  mb,
  ...props
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [isShowPicker, setIsShowPicker] = useState(false);

  const hasError = Boolean(errors[name]);

  return (
    <FieldWrapper
      isInvalid={hasError}
      label={label}
      errorMessageText={errors[name]?.message}
      mb={mb}
      style={style}
      {...props}
    >
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) =>
          IS_WEB ? (
            <DatePickerWebInput
              placeholder={placeholder}
              value={getFormattedDate(value)}
              hasError={hasError}
              onChange={(value) => onChange(getFormattedDate(value))}
              styles={inputStyles}
            />
          ) : (
            <>
              <TouchableOpacity onPress={() => setIsShowPicker(true)}>
                <Input
                  value={getFormattedDate(value)}
                  isReadOnly
                  placeholder={placeholder}
                />
              </TouchableOpacity>
              {isShowPicker && (
                <RNDateTimePicker
                  value={value ? new Date(value) : new Date()}
                  onChange={(event, selectedDate) => {
                    setIsShowPicker(IS_IOS);
                    if (selectedDate) {
                      onChange(selectedDate);
                    }
                  }}
                  mode='date'
                  display='default'
                  locale='ru-RU'
                  onTouchCancel={() => setIsShowPicker(false)}
                />
              )}
            </>
          )
        }
      />
    </FieldWrapper>
  );
};

DatePickerHookForm.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  inputStyles: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isOutlined: PropTypes.bool,
};
