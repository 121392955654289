import PropTypes from 'prop-types';
import React from 'react';
import Barcode from 'react-barcode';
import { ScrollView } from 'react-native';

const BarCodeReader = ({ value }) => {
  return (
    <ScrollView horizontal={true}>
      <Barcode value={value} height={50} format='CODE128' displayValue={false} />
    </ScrollView>
  );
};

BarCodeReader.propTypes = {
  value: PropTypes.string.isRequired,
};

export default BarCodeReader;
