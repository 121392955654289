import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const PrintIcon = ({ size = 24, style = {} }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...style}
    >
      <Path
        d='M23 12H22V7H10V12H9C7.34 12 6 13.34 6 15V21H10V25H22V21H26V15C26 13.34 24.66 12 23 12ZM12 9H20V12H12V9ZM20 21V23H12V19H20V21ZM22 19V17H10V19H8V15C8 14.45 8.45 14 9 14H23C23.55 14 24 14.45 24 15V19H22Z'
        fill='#47C8FF'
      />
      <Path
        d='M22 16.5C22.5523 16.5 23 16.0523 23 15.5C23 14.9477 22.5523 14.5 22 14.5C21.4477 14.5 21 14.9477 21 15.5C21 16.0523 21.4477 16.5 22 16.5Z'
        fill='#47C8FF'
      />
    </Svg>
  );
};

PrintIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
