import { StyleSheet } from 'react-native';

import { theme } from '../../../constants';

export default StyleSheet.create({
  container: {
    position: 'relative',
    zIndex: 10,
  },
  loader: {
    backgroundColor: theme.colors.lightOverlay,
    borderRadius: 4,
    bottom: 0,
    height: '100%',
    left: 0,
    paddingTop: 100,
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 10,
  },
});
