import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { SelectWithSearch } from '../../../../components';
import { useDebounce, useGetWarehouseNP, useGetWarehousesNP } from '../../../../hooks';
import { transformSelectOption, transformSelectOptions } from '../../../../utils';

export const WarehouseAutoCompleteField = ({ name, cityRef, warehouseRef }) => {
  const [warehouseSearchValue, setWarehouseSearchValue] = useState('');

  const { debouncedValue: debouncedSearchValue, isDebouncing } =
    useDebounce(warehouseSearchValue);

  const [getWarehouses, { data: warehousesData, loading }] = useGetWarehousesNP({
    variables: {
      cityRef: cityRef,
    },
  });

  const [getInitWarehouse, { data: warehouseData, loading: initWarehouseLoading }] =
    useGetWarehouseNP();

  useEffect(() => {
    cityRef &&
      warehouseRef &&
      getInitWarehouse({
        variables: {
          warehouseRef: warehouseRef,
        },
      });
  }, []);

  const getWarehousesHandler = () => {
    !warehousesData?.getWarehousesNovaPoshta && getWarehouses();
  };

  const setSearchHandler = (val) => {
    return val ? setWarehouseSearchValue(val) : setWarehouseSearchValue('');
  };

  return (
    <SelectWithSearch
      width='100%'
      pl={0}
      pr={0}
      searchValue={debouncedSearchValue}
      setSearchValue={setSearchHandler}
      isLoading={loading || isDebouncing || initWarehouseLoading}
      onMenuOpen={getWarehousesHandler}
      initialOption={
        warehouseRef &&
        transformSelectOption({
          item: warehouseData?.getWarehousesNovaPoshta?.[0],
          value: 'ref',
          label: 'description',
        })
      }
      options={transformSelectOptions({
        data: warehousesData?.getWarehousesNovaPoshta.slice(0, 50),
        value: 'ref',
        label: 'description',
      })}
      name={name}
      label='app.warehouse'
    />
  );
};

WarehouseAutoCompleteField.propTypes = {
  name: PropTypes.string.isRequired,
  cityRef: PropTypes.string.isRequired,
  warehouseRef: PropTypes.string.isRequired,
};
