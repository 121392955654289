import { Formik } from 'formik';
import React from 'react';

import {
  BoxWrapper,
  FieldRow,
  FormRow,
  FormWrapper,
  Layout,
  QuerySelectWithSearch,
  Typography,
} from '../../../components';
import { useNotificationsContext } from '../../../context';
import { GET_STOCK_PLACES } from '../../../graphql';
import { useAnnulMutation } from '../../../hooks';
import { annulSchema } from '../../../services/validation';
import { AnnulStructure } from './AnnulStructure/AnnulStructure';

const Annul = () => {
  const { showNotification } = useNotificationsContext();
  const [annulMutation, { loading }] = useAnnulMutation();

  const handleFormSubmit = async (values, { resetForm }) => {
    const variables = [];

    values.availabilities.forEach((availability) => {
      variables.push({
        placeId: parseInt(values.fromPlace?.id),
        purchaseId: parseInt(availability.purchaseId),
        conditionId: parseInt(availability.conditionId),
        value: parseInt(availability.quantity),
      });
    });

    for (const item of variables) {
      await annulMutation({ variables: item });
    }

    if (!variables.length) {
      showNotification({
        title: 'errors.networkError',
        message: 'product.needAdd',
        type: 'error',
      });
    } else {
      resetForm();
    }
  };

  const initialValues = {
    fromPlace: '',
    availabilities: [],
  };

  return (
    <FormWrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={annulSchema}
      >
        {({ handleSubmit, setFieldValue }) => {
          return (
            <Layout
              onActionButtonPress={handleSubmit}
              actionButtonType='save'
              actionButtonIsLoading={loading}
            >
              <FormRow>
                <FormRow.Left>
                  <BoxWrapper>
                    <Typography intlId='app.annul' variant='heading' />
                    <FieldRow>
                      <QuerySelectWithSearch
                        width={'100%'}
                        graphql={GET_STOCK_PLACES}
                        graphqlName='stockPlaces'
                        fieldName='fromPlace'
                        label='app.fromTheBox'
                        labelProperty={'name'}
                        valueProperty={['id', 'name', 'availabilities']}
                        onChange={() => setFieldValue('availabilities', [])}
                      />
                    </FieldRow>
                  </BoxWrapper>
                </FormRow.Left>
                <FormRow.Right />
              </FormRow>
              <AnnulStructure />
            </Layout>
          );
        }}
      </Formik>
    </FormWrapper>
  );
};

export default Annul;
