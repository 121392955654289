export const convertToInt = (data) => {
  const result = {};

  Object.entries(data).map(([key, value]) => {
    if (Array.isArray(value))
      return (result[key] = value.map((elem) => (+elem ? +elem : elem)));

    result[key] = +value ? +value : value;
  });

  return result;
};
