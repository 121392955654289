import { Formik } from 'formik';
import { Flex } from 'native-base';
import React from 'react';

import {
  Button,
  Checkbox,
  FieldWrapper,
  RequestHandler,
  SelectWithSearch,
} from '../../../components';
import { IS_MOBILE_SCREEN } from '../../../constants';
import { useFiltersContext } from '../../../context';
import { useFilter, useStockLocationsOptions } from '../../../hooks';

export const BoxesFilters = () => {
  const { filters, setFilters, clearFilters } = useFilter();

  const { toggleFilter } = useFiltersContext();

  const { getRoomsOptions, getShelvingOptions, stockLocationsQueryProps } =
    useStockLocationsOptions();

  const onSubmit = (values) => {
    setFilters({
      roomId: values.room,
      shelvingId: values.shelving,
      archival: values.archival ? true : '',
    });

    if (IS_MOBILE_SCREEN) {
      toggleFilter();
    }
  };

  const handleResetForm = (setFieldValue) => {
    clearFilters();
    setFieldValue('room', '');
    setFieldValue('shelving', '');
    setFieldValue('archival', false);
  };

  const initialValues = {
    room: filters?.roomId?.toString() || '',
    shelving: filters?.shelvingId?.toString() || '',
    archival: filters?.archival === 'false' ? false : filters?.archival === 'true',
  };

  return (
    <RequestHandler {...stockLocationsQueryProps}>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, handleSubmit, setFieldValue }) => (
          <>
            <SelectWithSearch
              width={'100%'}
              pr={0}
              pl={0}
              name='room'
              label='app.room'
              options={getRoomsOptions()}
              onChange={() => {
                setFieldValue('shelving', null);
              }}
            />
            <SelectWithSearch
              pr={0}
              pl={0}
              width={'100%'}
              name='shelving'
              label='app.shelving'
              options={getShelvingOptions(values.room)}
            />
            <FieldWrapper mb={'100px'} pl={'10px'}>
              <Checkbox
                checked={values.archival}
                onChange={() => setFieldValue('archival', !values.archival)}
                label='app.archival'
              />
            </FieldWrapper>
            <Flex justify='space-between'>
              <Button
                onPress={() => {
                  handleResetForm(setFieldValue);
                }}
                variant='secondary'
                intlId='app.clear'
              />
              <Button onPress={handleSubmit} intlId='app.apply' />
            </Flex>
          </>
        )}
      </Formik>
    </RequestHandler>
  );
};
