import { Flex } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { Typography } from '../Typography/Typography';

export const OrderDescription = ({ icon, intlId, ...props }) => {
  return (
    <Flex {...props}>
      {icon}
      <Typography ml={'6px'} intlId={intlId || 'app.noData'} />
    </Flex>
  );
};

OrderDescription.propTypes = {
  icon: PropTypes.element.isRequired,
  intlId: PropTypes.string.isRequired,
};
