import 'intl';
import 'intl/locale-data/jsonp/ru';
import 'intl/locale-data/jsonp/en';

import PropTypes from 'prop-types';
import React from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import messages from '../language';
import { getCurrentLocale } from '../utils/getCurrentLocale';

export function IntlProvider({ children }) {
  return (
    <ReactIntlProvider
      messages={messages[getCurrentLocale()]}
      locale={getCurrentLocale()}
    >
      {children}
    </ReactIntlProvider>
  );
}

IntlProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
