import { MaterialIcons } from '@expo/vector-icons';
import { Box, Flex, Text } from 'native-base';
import React, { useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Button, Typography } from '../../../../components';
import { SelectWithSearchHookForm } from '../../../../components/SelectWithSearch/SelectWithSearchHookForm';
import { CustomParamTypesEnum, DATA_REQUEST_LIMIT, theme } from '../../../../constants';
import { useDebounce, useGetCustomParamsLazy, useParams } from '../../../../hooks';
import { transformSelectOptionsWithCustomInfo } from '../../../../utils';
import Param from './Param';
import { styles } from './styles';

const CustomParams = () => {
  const params = useParams();
  const { watch, setValue, control } = useFormContext();
  const values = watch();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'productCustomParams',
  });

  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const { debouncedValue: debouncedSearchValue, isDebouncing } = useDebounce(searchValue);

  const [lazyQueryRequest, { loading, fetchMore }] = useGetCustomParamsLazy({
    fetchPolicy: 'cache-first',
  });

  const fetchMoreData = async () => {
    const { data } = await fetchMore({
      variables: {
        params: {
          search: debouncedSearchValue,
          offset: 0,
          limit: DATA_REQUEST_LIMIT,
        },
      },
    });
    const result = data.customParams;
    const selectedValues = values.productCustomParams.map((param) => param.id);

    const filterdResult = result.filter((res) => !selectedValues.includes(res.id));

    setOptions([...filterdResult]);
  };

  const fetchQueryResponse = async () => {
    await lazyQueryRequest({
      variables: {
        params: {
          search: debouncedSearchValue,
          offset: 0,
          limit: DATA_REQUEST_LIMIT,
        },
      },
      onCompleted: (response) => {
        const result = response?.customParams;
        const selectedValues = values.productCustomParams.map((param) => param.id);

        const filterdResult = result.filter((res) => !selectedValues.includes(res.id));

        setOptions([...filterdResult]);
      },
    });
  };

  const handleChangeParam = (option) => {
    setValue('customParam', option);
  };

  const handleAddParam = () => {
    if (values.customParam) {
      const { value, label, info, customInfo } = values.customParam;
      setOptions((prev) => prev.filter((param) => param.id !== value));
      setValue('customParam.value', '');
      const initParam = {
        customParamId: Number(value),
        name: label,
        productId: Number(params?.id),
        type: info,
        ...(customInfo.length && { customParamOptions: customInfo }),
      };
      if (info === CustomParamTypesEnum.Boolean) {
        append({
          ...initParam,
          booleanValue: false,
        });
      } else if (info === CustomParamTypesEnum.Number) {
        append({
          ...initParam,
          numberValue: null,
        });
      } else if (info === CustomParamTypesEnum.Range) {
        append({
          ...initParam,
          rangeFromValue: null,
          rangeToValue: null,
        });
      } else {
        append({
          ...initParam,
        });
      }
    }
  };

  const labelProperty = useMemo(() => {
    return ['name'];
  }, []);

  const handleRemoveParam = (index) => () => {
    remove(index);
    const removedParam = values.productCustomParams[index];
    setOptions((prev) => [...prev, removedParam]);
  };

  return (
    <>
      <Flex justify='space-between' alignItems='baseline'>
        <Typography intlId='app.customparams' variant='heading' />
      </Flex>
      <Flex justify='space-between' alignItems='center'>
        <SelectWithSearchHookForm
          width={'80%'}
          label='app.customparam'
          onMenuOpen={fetchQueryResponse}
          onMenuScrollToBottom={fetchMoreData}
          onChange={handleChangeParam}
          placeholderIntlId='app.enterValue'
          setSearchValue={setSearchValue}
          options={transformSelectOptionsWithCustomInfo({
            data: options,
            value: 'id',
            label: labelProperty,
            info: 'type',
            customInfo: 'customParamOptions',
          })}
          isLoading={loading || isDebouncing}
          name={'customParam.value'}
          mb={0}
          pr={0}
          pl={0}
          isValChange
        />
        <Button
          intlId='app.add'
          onPress={handleAddParam}
          variant='ghost'
          isDisabled={!values?.customParam?.value}
        />
      </Flex>
      {!!fields.length && <Box style={styles.divider}></Box>}
      <Box style={styles.wrapper}>
        {fields.map((field, index) => (
          <Box key={field.id}>
            <Flex justify='space-between' alignItems='center' mb={4}>
              <Text style={styles.text}>{field.name}</Text>
              <MaterialIcons
                onPress={handleRemoveParam(index)}
                name='delete'
                size={25}
                color={theme.colors.primary}
              />
            </Flex>
            <Param type={field.type} index={index} options={field.customParamOptions} />
          </Box>
        ))}
      </Box>
    </>
  );
};

export default CustomParams;
