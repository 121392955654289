import { Pressable } from 'native-base';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Modal } from 'react-native';
import { useWindowDimensions } from 'react-native-web';

import { TabNavigation } from '../../../../../components';
import { TemplateForm } from './components/TemplateForm';
import { TemplateManualForm } from './components/TemplateManualForm';

export const AddTTNModal = ({ isOpen, onClose }) => {
  const { height } = useWindowDimensions();

  const handleStopClose = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      propagateSwipe={true}
      animationType='none'
      visible={true}
      transparent={true}
      onRequestClose={handleClose}
    >
      <Pressable
        onPress={handleClose}
        flex={1}
        alignItems='center'
        justifyContent='center'
        bgColor={'rgba(0,0,0, 0.4)'}
      >
        <Pressable
          onPress={handleStopClose}
          maxHeight={height - 32}
          bgColor={'white'}
          elevation={5}
          width='100%'
          maxWidth={'320px'}
          borderRadius={{ base: 0, lg: '5px' }}
        >
          <TabNavigation items={['app.ttnTemplate', 'app.ttnManual']}>
            <TabNavigation.Item>
              <TemplateForm onClose={onClose} />
            </TabNavigation.Item>

            <TabNavigation.Item>
              <TemplateManualForm onClose={onClose} />
            </TabNavigation.Item>
          </TabNavigation>
        </Pressable>
      </Pressable>
    </Modal>
  );
};

AddTTNModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
