import { StyleSheet } from 'react-native';

import { IS_APP } from '../../constants';
import { colors } from '../../theme/colors';

export const styles = StyleSheet.create({
  item: {
    padding: 10,
  },
  list: {
    maxHeight: 180,
  },
  wrapper: {
    backgroundColor: colors.white,
    borderColor: colors.gray.dark,
    borderRadius: 4,
    borderWidth: 1,
    left: 0,
    position: IS_APP ? 'relative' : 'absolute',
    right: 0,
    top: IS_APP ? 0 : 62,
  },
});
