import { useParams as useParamsWeb } from 'react-router-dom';
import { useParams as useParamsNative } from 'react-router-native';

import { IS_APP } from '../constants';

const useParams = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return IS_APP ? useParamsWeb() : useParamsNative();
};

export default useParams;
