import gql from 'graphql-tag';

export default gql`
  query findPhones($search: String) {
    findPhones(search: $search) {
      id
      phone
    }
  }
`;
