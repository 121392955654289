import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const OrderSourceIcon = ({ size = 16, fill = 'gray', style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 16 16' style={{ ...style }}>
      <Path
        d='M13.7813 4.25156V2.25C13.7813 1.97344 13.5578 1.75 13.2813 1.75H2.71876C2.4422 1.75 2.21876 1.97344 2.21876 2.25V4.25156C1.95782 4.26719 1.75001 4.48438 1.75001 4.75V6.80781C1.74901 7.18601 1.82559 7.56039 1.97501 7.90781C2.0422 8.06719 2.12501 8.21719 2.21876 8.35938V13.75C2.21876 14.025 2.4422 14.25 2.71876 14.25H7.00001V11.5H9.00001V14.25H13.2813C13.5578 14.25 13.7813 14.0266 13.7813 13.75V8.35938C13.8765 8.21683 13.9581 8.06564 14.025 7.90781C14.1734 7.55938 14.25 7.18906 14.25 6.80781V4.75C14.25 4.48438 14.0422 4.26719 13.7813 4.25156ZM12.6563 13.1266H10V11C10 10.7234 9.77657 10.5 9.50001 10.5H6.50001C6.22345 10.5 6.00001 10.7234 6.00001 11V13.1266H3.34376V9.34219C3.38907 9.36406 3.43595 9.38594 3.48438 9.40469C3.83282 9.55156 4.20313 9.625 4.58438 9.625C4.96563 9.625 5.33438 9.55156 5.68438 9.40469C5.90001 9.31406 6.10313 9.19844 6.28907 9.05937C6.2922 9.05781 6.29532 9.05781 6.29845 9.05937C6.48496 9.19903 6.68807 9.31502 6.90313 9.40469C7.25157 9.55156 7.62188 9.625 8.00313 9.625C8.38438 9.625 8.75313 9.55156 9.10313 9.40469C9.31876 9.31406 9.52188 9.19844 9.70782 9.05937C9.71095 9.05781 9.71407 9.05781 9.7172 9.05937C9.90371 9.19903 10.1068 9.31502 10.3219 9.40469C10.6703 9.55156 11.0406 9.625 11.4219 9.625C11.8031 9.625 12.1719 9.55156 12.5219 9.40469C12.5688 9.38438 12.6156 9.36406 12.6625 9.34219V13.1266H12.6563ZM12.6563 4.25H3.34376V2.875H12.6563V4.25Z'
        fill={fill}
      />
    </Svg>
  );
};

OrderSourceIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
  fill: PropTypes.string,
};
