import React from 'react';
import { View, Text } from 'react-native';
import globalStyles from '../../../../globalStyles';
import { Button, DatePicker, MultiSelect, Modal } from '../../../../components/UI';
import WebSorting from '../../../../components/WebSorting/WebSorting';
import columnData from '../columnData';
import CustomerField from '../filterFields/CustomerField';
import { useIntl } from '../../../../hooks';

const FilterFieldsMobile = ({ data, setFieldValue, values, handleSubmit, onClose }) => {
  const intl = useIntl();
  return (
    <Modal
      title={intl.formatMessage({
        id: 'app.filters',
      })}
    >
      <MultiSelect
        intl={intl}
        name={'manager'}
        placeholder={intl.formatMessage({
          id: 'app.manager',
        })}
        handleChange={setFieldValue}
        value={values.manager}
      >
        {data.users.map((item) => (
          <Text key={item.id}>{`${item.firstName} ${item.lastName}`}</Text>
        ))}
      </MultiSelect>
      <View>
        <CustomerField
          label={intl.formatMessage({ id: 'app.customer' })}
          customers={data.customers}
          name={'customer'}
          handleChange={setFieldValue}
          value={values.customer}
        />
      </View>
      <MultiSelect
        intl={intl}
        list={data.sources.map((item) => ({ name: item.name, id: item.id }))}
        name={'source'}
        placeholder={intl.formatMessage({
          id: 'app.source',
        })}
        handleChange={setFieldValue}
        value={values.source}
      >
        {data.sources.map((item) => (
          <Text key={item.id}>{item.name}</Text>
        ))}
      </MultiSelect>
      <MultiSelect
        intl={intl}
        list={data.paymentStatuses.map((item) => ({ name: item.name, id: item.id }))}
        name={'payment'}
        placeholder={intl.formatMessage({
          id: 'app.payment',
        })}
        handleChange={setFieldValue}
        value={values.payment}
      >
        {data.paymentStatuses.map((item) => (
          <Text key={item.id}>{item.name}</Text>
        ))}
      </MultiSelect>
      <MultiSelect
        intl={intl}
        name={'status'}
        placeholder={intl.formatMessage({
          id: 'app.status',
        })}
        handleChange={setFieldValue}
        value={values.status}
      >
        {data.statuses.map((item) => (
          <Text key={item.id}>{item.name}</Text>
        ))}
      </MultiSelect>
      <View
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}
      >
        <DatePicker
          name='dateFrom'
          appWidth={'47%'}
          value={values.dateFrom || new Date()}
          label={intl.formatMessage({ id: 'date.from' })}
          setFieldValue={setFieldValue}
        />
        <DatePicker
          name='dateTo'
          appWidth={'47%'}
          value={values.dateTo || new Date()}
          label={intl.formatMessage({ id: 'date.to' })}
          setFieldValue={setFieldValue}
        />
      </View>
      <WebSorting columnData={columnData} sorting={values} setSorting={setFieldValue} />
      <View style={globalStyles.listButton}>
        <Button
          mode='thin'
          onPress={() => {
            setFieldValue('manager', '');
            setFieldValue('customers', '');
            setFieldValue('source', '');
            setFieldValue('payment', '');
            setFieldValue('dateFrom', '');
            setFieldValue('dateTo', '');
          }}
        >
          {intl.formatMessage({
            id: 'app.clear',
          })}
        </Button>
        <Button mode='thin' onPress={onClose}>
          {intl.formatMessage({
            id: 'app.cancel',
          })}
        </Button>
        <Button
          color='#47C8FF'
          mode='thin'
          onPress={() => {
            handleSubmit();
            onClose();
          }}
        >
          OK
        </Button>
      </View>
    </Modal>
  );
};

export default FilterFieldsMobile;
