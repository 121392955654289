import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const CopyIcon = ({ size = 18, style = {} }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ ...style }}
    >
      <Path
        d='M7.5 6H15C15.8273 6 16.5 6.67275 16.5 7.5V15C16.5 15.8273 15.8273 16.5 15 16.5H7.5C6.67275 16.5 6 15.8273 6 15V7.5C6 6.67275 6.67275 6 7.5 6Z'
        fill='#BDBDBD'
      />
      <Path
        d='M3 1.5H10.5C10.8978 1.5 11.2794 1.65804 11.5607 1.93934C11.842 2.22064 12 2.60218 12 3V4.5H6C5.60218 4.5 5.22064 4.65804 4.93934 4.93934C4.65804 5.22064 4.5 5.60218 4.5 6V12H3C2.60218 12 2.22064 11.842 1.93934 11.5607C1.65804 11.2794 1.5 10.8978 1.5 10.5V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5Z'
        fill='#BDBDBD'
      />
    </Svg>
  );
};

CopyIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
