import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';

import styles from './styles';

export const Preloader = () => {
  return <ActivityIndicator animating={true} color='#47C8FF' />;
};

export const PreloaderContainer = ({ isLoading, children }) => {
  return (
    <View style={styles.container}>
      {isLoading ? (
        <View style={styles.loader}>
          <Preloader />
        </View>
      ) : null}
      {children}
    </View>
  );
};

PreloaderContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
