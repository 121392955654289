import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const OrderPaymentIcon = ({ size = 16, fill = 'gray', style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 16 16' style={{ ...style }}>
      <Path
        d='M13.9999 2.66663H1.99992C1.82311 2.66663 1.65354 2.73686 1.52851 2.86189C1.40349 2.98691 1.33325 3.15648 1.33325 3.33329V12.6666C1.33325 12.8434 1.40349 13.013 1.52851 13.138C1.65354 13.2631 1.82311 13.3333 1.99992 13.3333H13.9999C14.1767 13.3333 14.3463 13.2631 14.4713 13.138C14.5963 13.013 14.6666 12.8434 14.6666 12.6666V3.33329C14.6666 3.15648 14.5963 2.98691 14.4713 2.86189C14.3463 2.73686 14.1767 2.66663 13.9999 2.66663ZM13.3333 9.99996C12.8028 9.99996 12.2941 10.2107 11.919 10.5857C11.544 10.9608 11.3333 11.4695 11.3333 12H4.66659C4.66659 11.4695 4.45587 10.9608 4.0808 10.5857C3.70573 10.2107 3.19702 9.99996 2.66659 9.99996V5.99996C3.19702 5.99996 3.70573 5.78925 4.0808 5.41417C4.45587 5.0391 4.66659 4.53039 4.66659 3.99996H11.3333C11.3333 4.53039 11.544 5.0391 11.919 5.41417C12.2941 5.78925 12.8028 5.99996 13.3333 5.99996V9.99996Z'
        fill={fill}
      />
      <Path
        d='M7.99992 5.33337C6.52925 5.33337 5.33325 6.52937 5.33325 8.00004C5.33325 9.47071 6.52925 10.6667 7.99992 10.6667C9.47059 10.6667 10.6666 9.47071 10.6666 8.00004C10.6666 6.52937 9.47059 5.33337 7.99992 5.33337ZM7.99992 9.33337C7.26459 9.33337 6.66659 8.73537 6.66659 8.00004C6.66659 7.26471 7.26459 6.66671 7.99992 6.66671C8.73525 6.66671 9.33325 7.26471 9.33325 8.00004C9.33325 8.73537 8.73525 9.33337 7.99992 9.33337Z'
        fill={fill}
      />
    </Svg>
  );
};

OrderPaymentIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
  fill: PropTypes.string,
};
