import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  idColumn: {
    width: '10%',
  },
  datetimeColumn: {
    width: '15%',
  },
  managerColumn: {
    width: '15%',
  },
  customerColumn: {
    width: '15%',
  },
  sourceColumn: {
    width: '10%',
  },
  paymentColumn: {
    width: '10%',
  },
  statusColumn: {
    width: '15%',
  },
  amountColumn: {
    width: '9%',
  },
  headerText: {
    color: '#333333',
  },
  wrapper: {
    height: '100%',
  },
  card: {
    marginBottom: 25,
    marginHorizontal: 10,
  },
  role: {
    fontSize: 11,
    color: '#828282',
  },
  contact: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
    marginTop: 5,
  },
  contactText: {
    fontSize: 14,
    marginLeft: 15,
  },
  top: {
    flexDirection: 'row',
    marginBottom: 25,
  },
  name: {
    fontSize: 16,
    color: '#4F4F4F',
    marginBottom: 8,
  },
  date: {
    fontSize: 13,
    color: '#828282',
  },
  left: {
    marginRight: 25,
  },
  center: {
    marginRight: 25,
  },
  right: {
    marginLeft: 'auto',
    alignSelf: 'flex-end',
  },
  id: {
    fontSize: 13,
    color: '#828282',
  },
  icon: {
    width: 40,
    height: 40,
    borderRadius: 40 / 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  info: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  statusReceived: {
    backgroundColor: '#38761d',
  },
  statusDelivered: {
    backgroundColor: '#fbbc04',
  },
  statusCanceled: {
    backgroundColor: '#E0E0E0',
  },
  statusSent: {
    backgroundColor: '#4a86e8',
  },
  statusDraft: {
    backgroundColor: '#E0E0E0',
  },
  statusPending: {
    backgroundColor: '#666',
  },
  statusPaid: {
    backgroundColor: '#666',
  },
  statusStore: {
    backgroundColor: '#b45f06',
  },
  statusWaiting: {
    backgroundColor: '#cc0000',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 10,
  },
  buttonText: {
    fontSize: 16,
  },
  submit: {
    color: '#47C8FF',
  },
});
