import { MaterialIcons } from '@expo/vector-icons';
import PropTypes from 'prop-types';
import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import { useIntl } from '../../hooks';
import { getFormattedDate, getName } from '../../utils';
import { BoxWrapper, Typography } from '../index';
import styles from './Style';

export const OrderCard = ({ item, handleSet }) => {
  const intl = useIntl();

  const getIcon = () => {
    switch (parseInt(item?.status ? item?.status.id : 0)) {
      case 1:
        return (
          <View style={[styles.icon, styles.statusReceived]}>
            <MaterialIcons name='shopping-cart' size={25} color='#fff' />
          </View>
        );
      case 2:
        return (
          <View style={[styles.icon, styles.statusDelivered]}>
            <MaterialIcons name='check' size={25} color='#fff' />
          </View>
        );
      case 3:
        return (
          <View style={[styles.icon, styles.statusCanceled]}>
            <MaterialIcons name='clear' size={25} color='#fff' />
          </View>
        );
      case 4:
        return (
          <View style={[styles.icon, styles.statusSent]}>
            <MaterialIcons name='local-shipping' size={25} color='#fff' />
          </View>
        );
      case 5:
        return (
          <View style={[styles.icon, styles.statusDraft]}>
            <MaterialIcons name='remove-shopping-cart' size={25} color='#fff' />
          </View>
        );
      case 6:
        return (
          <View style={[styles.icon, styles.statusPending]}>
            <MaterialIcons name='person-add' size={25} color='#fff' />
          </View>
        );
      case 7:
        return (
          <View style={[styles.icon, styles.statusPaid]}>
            <MaterialIcons name='payment' size={25} color='#fff' />
          </View>
        );
      case 8:
        return (
          <View style={[styles.icon, styles.statusStore]}>
            <MaterialIcons name='local-shipping' size={25} color='#fff' />
          </View>
        );
      case 9:
        return (
          <View style={[styles.icon, styles.statusWaiting]}>
            <MaterialIcons name='payment' size={25} color='#fff' />
          </View>
        );
      default:
        return <Text>0</Text>;
    }
  };

  const amount =
    item?.orderItems.reduce((sum, value) => sum + value.pricePerPcs * value.quantity, 0) -
    item?.discount;

  return (
    <TouchableOpacity onPress={() => handleSet(item)}>
      <BoxWrapper mb={'8px'} p={'30px'}>
        <View style={styles.top}>
          <View style={styles.left}>{getIcon()}</View>
          <View style={styles.center}>
            <Typography
              intlId={getName(item?.customer?.firstName, item?.customer?.lastName)}
              style={styles.name}
            />
            <Text style={styles.date}>
              {getFormattedDate(item?.createdAt, 'd.MM.y HH:mm')}
            </Text>
          </View>
          <View style={styles.right}>
            <Text style={styles.id}>{`№ ${item?.id}`}</Text>
          </View>
        </View>
        <View style={styles.bottom}>
          <View style={styles.info}>
            <Typography intlId={getName(item?.user?.firstName, item?.user?.lastName)} />
            <Text>{item?.source.name}</Text>
          </View>
          <View style={styles.info}>
            <Typography intlId={item?.paymentStatus?.name} />
            <Text>
              {amount.toFixed(2)} {intl.formatMessage({ id: 'currency.uah' })}
            </Text>
          </View>
        </View>
      </BoxWrapper>
    </TouchableOpacity>
  );
};

OrderCard.propTypes = {
  item: PropTypes.object.isRequired,
  handleSet: PropTypes.func.isRequired,
};
