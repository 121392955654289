import { theme } from '../../constants';

export const input = {
  Input: {
    defaultProps: {
      variant: 'unstyled',
      borderBottomColor: 'dark',
      fontSize: 'md',
      borderBottomWidth: '1px',
      borderRadius: 0,
      bgColor: 'white',
      height: 'auto',
      color: 'text.default',
      paddingLeft: '10px',
      paddingRight: '10px',
      fontWeight: '400',
      _light: {
        bg: 'white',
        borderBottomColor: theme.colors.dark,
        _text: { color: 'text.default' },
        _focus: {
          _stack: {
            style: {
              outlineWidth: 0,
              outlineColor: 'none',
              outlineStyle: 'none',
            },
          },
        },
      },
      _dark: {
        bg: 'white',
        borderBottomColor: theme.colors.dark,
        _text: { color: 'text.default' },
        _focus: {
          _stack: {
            style: {
              outlineWidth: 0,
              outlineColor: 'none',
              outlineStyle: 'none',
            },
          },
        },
      },
      _invalid: {
        borderBottomColor: 'error',
      },
      _focus: {
        borderBottomColor: 'primary.default',
      },
      _disabled: {
        bgColor: 'white',
        color: 'text.default',
        opacity: 1,
      },
      _hover: {
        borderBottomColor: 'primary.default',
      },
    },
  },
};
