export const getValueFromPath = (obj, path) => {
  const pathParts = path.split('.');
  let current = obj;

  for (let i = 0; i < pathParts.length; i++) {
    if (current[pathParts[i]]) {
      current = current[pathParts[i]];
    } else {
      return undefined;
    }
  }

  return current;
};
