import gql from 'graphql-tag';

export default gql`
  query emails($ids: [ID]) {
    emails(ids: $ids) {
      id
      email
    }
  }
`;
