import { CheckIcon, Select as NBSelect } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { getValueFromPath } from '../../utils';
import { FieldWrapper } from '../FieldWrapper/FieldWrapper';

export const SelectHookForm = ({ name, defaultValue, rules, ...props }) => {
  const { control, formState } = useFormContext();

  const errorMessageText = getValueFromPath(formState.errors, name)?.message;

  const hasError = Boolean(errorMessageText);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field }) => (
        <SelectHookFormView
          errorMessage={errorMessageText}
          hasError={hasError}
          {...field}
          {...props}
        />
      )}
    />
  );
};

const SelectHookFormView = ({
  value,
  label,
  placeholder,
  onChange,
  errorMessage,
  options,
  mb,
  style,
  hasError,
  ...props
}) => {
  const intl = useIntl();

  return (
    <FieldWrapper
      isInvalid={hasError}
      errorMessageText={errorMessage}
      label={label}
      style={style}
      mb={mb}
      {...props}
    >
      <NBSelect
        height={'33px'}
        selectedValue={value}
        accessibilityLabel={placeholder || intl.formatMessage({ id: 'app.choose' })}
        placeholder={placeholder || intl.formatMessage({ id: 'app.choose' })}
        hasError={errorMessage}
        onValueChange={onChange}
        _selectedItem={{
          bg: 'cyan.600',
          endIcon: <CheckIcon size={4} />,
        }}
      >
        {options?.map((option, index) => (
          <NBSelect.Item label={option?.label} value={option?.value} key={index} />
        ))}
      </NBSelect>
    </FieldWrapper>
  );
};

SelectHookForm.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  rules: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
  ).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  errorMessage: PropTypes.any,
};

SelectHookFormView.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
  ).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  errorMessage: PropTypes.any,
  hasError: PropTypes.bool,
};
