/* eslint-disable react-native/no-raw-text */
/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/prop-types */
import { Picker } from '@react-native-picker/picker';
import { useField } from 'formik';
import React, { Children } from 'react';
import { Text, View } from 'react-native';
import { HelperText } from 'react-native-paper';

import { isWeb } from '../../../constants';
import stylesGlobal from '../../../globalStyles';
import { useMediaQuery } from '../../../hooks';
import styles from './styles';

const Select = ({ name, label, children, appWidth, desktopWidth = '100%', mt = 0 }) => {
  const isDesktop = useMediaQuery('isDesktop');

  const [field, meta, helpers] = useField(name);

  const { value } = field;
  const { setValue: onChange } = helpers;
  const { error, touched } = meta;

  const renderItems = () => {
    return Children.map(children, (child) => {
      return (
        <Picker.Item value={child.props?.id} label={child.props?.children?.toString()} />
      );
    });
  };
  return (
    <View
      style={[styles.field, isDesktop ? { width: appWidth } : { width: desktopWidth }]}
    >
      {label ? <Text style={stylesGlobal.label}>{label}</Text> : null}
      {isWeb ? (
        <select
          style={{
            backgroundColor: 'transparent',
            paddingBottom: 8,
            paddingRight: 5,
            paddingLeft: 5,
            borderTopWidth: 0,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderBottomWidth: 1,
            borderBottomColor: '#333333',
            color: '#4F4F4F',
            marginTop: mt,
          }}
          value={value}
          onChange={(event) => onChange(event.target.value)}
        >
          <option value='' hidden={true}>
            {label || 'select'}*
          </option>
          {children}
        </select>
      ) : (
        <View style={[styles.select, error && styles.hasError]}>
          <Picker
            selectedValue={value}
            onValueChange={(selectValue) => onChange(selectValue)}
          >
            {renderItems()}
          </Picker>
        </View>
      )}
      {error && touched ? (
        <HelperText type='error' visible={error} style={styles.error}>
          {error}
        </HelperText>
      ) : null}
    </View>
  );
};

Select.Item = ({ children, id }) => {
  if (isWeb) {
    return (
      <option key={id} value={id}>
        {children}
      </option>
    );
  }
  return children;
};

export default Select;
