import { Flex } from 'native-base';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { IS_DESKTOP_SCREEN } from '../../constants';
import { useSearchContext } from '../../context';
import { useGetPurchasesQuery } from '../../hooks';
import { getVolumeWeightProduct, getWeightProduct } from '../../utils';
import {
  AddModal,
  Button,
  DesktopTable,
  LinkDescription,
  ModalButton,
  PurchaseCard,
  RequestHandler,
  Typography,
} from '../index';

const COLUMNS = [
  { intlId: 'app.cost' },
  { intlId: 'app.delivery' },
  { intlId: 'app.volumeWeight' },
  { intlId: 'app.weight' },
];

export const AddPurchaseModal = ({
  onAddPurchase,
  addedPurchases = [],
  isMultiSelect = false,
  buttonText = 'app.select',
}) => {
  const intl = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { searchValue, changeSearchValue } = useSearchContext();

  const { data, ...queryProps } = useGetPurchasesQuery({
    searchValue,
    skip: !searchValue,
  });

  const addedPurchasesIds = addedPurchases?.map((el) => el?.id);

  const handleSet = (item) => {
    onAddPurchase(item);
    !isMultiSelect && setIsModalOpen(false);
  };

  return (
    <>
      <Button
        intlId={buttonText}
        onPress={() => setIsModalOpen(true)}
        width={'auto'}
        size='md'
      />
      <AddModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        searchValue={searchValue}
        onChangeSearchValue={changeSearchValue}
        queryProps={queryProps}
        withScan={false}
        title='app.addPurchase'
      >
        <RequestHandler {...queryProps}>
          {IS_DESKTOP_SCREEN ? (
            <>
              <DesktopTable inModal editable={!isMultiSelect}>
                <DesktopTable.Head>
                  <DesktopTable.Title />
                  {COLUMNS.map((item, index) => (
                    <DesktopTable.Title
                      color='dark'
                      textAlign={'center'}
                      key={index}
                      {...item}
                    />
                  ))}
                  <DesktopTable.Title width={80} />
                </DesktopTable.Head>
                <DesktopTable.Body>
                  {data?.purchases?.map((item) => (
                    <DesktopTable.Row key={item.id}>
                      <DesktopTable.Cell height={37.4}>
                        <Flex width={'200px'}>
                          <LinkDescription
                            date={item?.purchaseTime}
                            dateFormat={'d.MM.y'}
                            intlId={'№ ' + item?.id}
                            link={`/purchase/purchase/${item?.id}`}
                            vertically
                          />
                        </Flex>
                      </DesktopTable.Cell>
                      <DesktopTable.Cell textAlign='center'>
                        <Typography
                          color='text.default'
                          intlId={item?.price ? `${item?.price} $` : 'app.noData'}
                        />
                      </DesktopTable.Cell>
                      <DesktopTable.Cell textAlign='center'>
                        <Typography
                          color='text.default'
                          intlId={
                            item?.deliveryPrice
                              ? `${item?.deliveryPrice} $`
                              : 'app.noData'
                          }
                        />
                      </DesktopTable.Cell>
                      <DesktopTable.Cell textAlign='center'>
                        <Typography
                          color='gray.main'
                          fontWeight='400'
                          intlId={getVolumeWeightProduct(item, intl) || 'app.noData'}
                        />
                      </DesktopTable.Cell>
                      <DesktopTable.Cell textAlign='center'>
                        <Typography
                          color='gray.main'
                          fontWeight='400'
                          intlId={getWeightProduct(item) || 'app.noData'}
                        />
                      </DesktopTable.Cell>
                      <ModalButton
                        item={item}
                        addedItemsIds={addedPurchasesIds}
                        handleSet={handleSet}
                      />
                    </DesktopTable.Row>
                  ))}
                </DesktopTable.Body>
              </DesktopTable>
            </>
          ) : (
            <>
              {data?.purchases?.map((item, index) => (
                <PurchaseCard key={index} item={item} handleSet={handleSet} />
              ))}
            </>
          )}
        </RequestHandler>
      </AddModal>
    </>
  );
};

AddPurchaseModal.propTypes = {
  onAddPurchase: PropTypes.func.isRequired,
  addedPurchases: PropTypes.array.isRequired,
  isMultiSelect: PropTypes.bool,
  buttonText: PropTypes.string,
};
