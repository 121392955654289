import { AntDesign } from '@expo/vector-icons';
import { Box, Flex, Icon, Text, View } from 'native-base';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Button, MobileTable, RequestHandler, TextField } from '../../../../components';
import { ArrowDown, ArrowUp, LineIcon } from '../../../../components/Icons';
import { theme } from '../../../../theme';
import styles from '../styles';

const AccordionMobileItem = ({
  location,
  level = 0,
  isLast = false,
  isEdit = false,
  handleSubmit = () => {},
  values,
  setFieldValue = () => {},
  loading = false,
  isAddLocation = false,
  setIsAddLocation = () => {},
  textFieldId = '',
  setTextFieldId = () => {},
}) => {
  const [expanded, setExpanded] = useState(false);

  const paddingRight = level > 0 ? 0 : 16;
  const paddingLeft = level > 0 ? 8 : 16;
  const paddingBottom = level > 0 ? 8 : 4;
  const paddingTop = level > 0 ? 8 : 4;

  const marginLeft = level === 1 ? 24 : 0;

  const marginLeftLinkWrapper = level <= 1 ? 24 : 0;

  useEffect(() => {
    if (!isEdit) return;
    if (location?.children?.length) setExpanded(!expanded);
  }, [isEdit, location.name]);

  useEffect(() => {
    if (!textFieldId) return;
    setFieldValue('parentId', Number(textFieldId));
    setFieldValue('typeId', 2);
  }, [location?.name, textFieldId]);

  useEffect(() => {
    if (!isLast && !isEdit) return;
    setFieldValue('parentId', null);
    setFieldValue('typeId', 1);
  }, [isEdit]);

  const isDisabled = !location?.children?.length || isEdit;

  const toggleAccordion = useCallback(() => {
    if (!isDisabled) setExpanded((prev) => !prev);
  }, [isDisabled]);

  const toggleInput = (id) => {
    setTextFieldId(id);
    setIsAddLocation(false);
  };

  const addLocationHandler = () => {
    handleSubmit();
    setTextFieldId('');
    setIsAddLocation(false);
  };

  return (
    <RequestHandler loading={loading}>
      <View style={[styles.accordionItem, { paddingRight, marginLeft }]}>
        <TouchableOpacity
          onPress={toggleAccordion}
          activeOpacity={isDisabled ? 1 : 0.2}
          style={isDisabled && styles.disabledCursor}
        >
          <Flex justifyContent={'space-between'} alignItems={'center'}>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              {level > 0
                ? Array.from({ length: level }, (_, index) => <LineIcon key={index} />)
                : null}
              <Text
                style={[
                  styles.title,
                  { paddingRight, paddingLeft, paddingBottom, paddingTop },
                ]}
              >
                {location.name}
              </Text>
            </Box>

            {!isDisabled ? expanded ? <ArrowUp /> : <ArrowDown /> : null}
          </Flex>
        </TouchableOpacity>
        {expanded && location.children ? (
          <View style={styles.childrenContainer}>
            <MobileTable
              data={location.children}
              renderItem={(child) => (
                <AccordionMobileItem
                  key={child.id}
                  location={child}
                  level={level + 1}
                  isEdit={isEdit}
                  handleSubmit={handleSubmit}
                  values={values}
                  toggleInput={toggleInput}
                  setFieldValue={setFieldValue}
                  textFieldId={textFieldId}
                  setTextFieldId={setTextFieldId}
                />
              )}
              fetchMoreData={(resolve) => resolve()}
            />
          </View>
        ) : null}
      </View>
      {isEdit ? (
        textFieldId === location.id ? (
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            style={[
              styles.addTextFieldWrapper,
              {
                marginLeft: marginLeftLinkWrapper,
              },
            ]}
            width={{ base: '100%' }}
          >
            <TextField name='name' width={260} mb={0} height='16px' />
            <Flex>
              <Icon
                as={<AntDesign name='pluscircle' />}
                size={6}
                color={theme.colors.primary.default}
                onPress={addLocationHandler}
                // eslint-disable-next-line react-native/no-inline-styles
                style={{
                  marginLeft: 10,
                }}
              />

              <Icon
                as={<AntDesign name='close' />}
                size={6}
                color={theme.colors.primary.black}
                onPress={() => {
                  toggleInput('');
                }}
                // eslint-disable-next-line react-native/no-inline-styles
                style={{
                  marginLeft: 10,
                }}
              />
            </Flex>
          </Box>
        ) : (
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            style={[
              styles.addSubLocationLinkWrapper,
              {
                marginLeft: marginLeftLinkWrapper,
              },
            ]}
          >
            {Array.from({ length: level + 1 }, (_, index) => (
              <LineIcon key={index} />
            ))}
            <Button
              onPress={() => {
                toggleInput(location.id);
              }}
              _text={{
                fontSize: 14,
                lineHeight: 17,
                color: theme.colors.primary.default,
              }}
              intlId='app.addSubLocation'
              style={styles.addSubLocationButton}
            />
          </Box>
        )
      ) : null}
      {!isLast && level === 0 && !expanded ? (
        <View style={styles.horizontalLine} />
      ) : null}
      {isLast && isEdit ? (
        isAddLocation ? (
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            style={[
              styles.addTextFieldWrapper,
              {
                marginLeft: marginLeftLinkWrapper,
              },
            ]}
          >
            <TextField name='name' width={160} mb={0} height='16px' />
            <Flex>
              <Icon
                as={<AntDesign name='pluscircle' />}
                size={6}
                color={theme.colors.primary.default}
                onPress={addLocationHandler}
                // eslint-disable-next-line react-native/no-inline-styles
                style={{
                  marginLeft: 10,
                }}
              />

              <Icon
                as={<AntDesign name='close' />}
                size={6}
                color={theme.colors.primary.black}
                onPress={() => {
                  toggleInput('');
                }}
                // eslint-disable-next-line react-native/no-inline-styles
                style={{
                  marginLeft: 10,
                }}
              />
            </Flex>
          </Box>
        ) : (
          <Box pr={4} pl={4} pb={2} pt={2}>
            <Button
              type='button'
              intlId='app.addLocation'
              size='sm'
              style={styles.addLocationButton}
              onPress={() => {
                toggleInput();
                setIsAddLocation(true);
              }}
            />
          </Box>
        )
      ) : null}
    </RequestHandler>
  );
};

export default React.memo(AccordionMobileItem);

AccordionMobileItem.propTypes = {
  location: PropTypes.object,
  level: PropTypes.number,
  isLast: PropTypes.bool,
  isEdit: PropTypes.bool,
  handleSubmit: PropTypes.func,
  toggleInput: PropTypes.func,
  isTextField: PropTypes.object,
  loading: PropTypes.bool,
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  isAddLocation: PropTypes.bool,
  setIsAddLocation: PropTypes.func,
  textFieldId: PropTypes.string,
  setTextFieldId: PropTypes.func,
};
