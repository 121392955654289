import React from 'react';

import {
  BoxWrapper,
  DesktopTable,
  Layout,
  MobileTable,
  RequestHandler,
  Typography,
} from '../../../components';
import { DATA_REQUEST_LIMIT, IS_DESKTOP_SCREEN } from '../../../constants';
import { useGetPurchaseParcelsQuery, useHistory } from '../../../hooks';
import {
  formatDateTime,
  getProductsTotalQuantity,
  getProductsVolumeWeight,
  getProductsWeight,
} from '../../../utils';
import ParcelMobileItem from './ParcelMobileItem/ParcelMobileItem';

const Parcels = () => {
  const navigation = useHistory();
  const { data, fetchMore, ...queryProps } = useGetPurchaseParcelsQuery();

  const fetchMoreData = async (resolve, reject) => {
    await fetchMore({
      variables: {
        offset: data.purchaseParcels?.rows?.length,
        limit: DATA_REQUEST_LIMIT,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          reject && reject();
          return prev;
        }

        resolve && resolve();

        return {
          purchaseParcels: {
            ...fetchMoreResult?.purchaseParcels,
            rows: [
              ...prev?.purchaseParcels?.rows,
              ...fetchMoreResult?.purchaseParcels?.rows,
            ],
          },
        };
      },
    });
  };

  const handleEditButtonClick = (id) => {
    navigation.push(`/purchase/parcel/${id}`);
  };

  const handleClickAddButton = () => {
    navigation.push('/purchase/parcel/');
  };

  if (IS_DESKTOP_SCREEN) {
    return (
      <Layout isScrollable={false} onActionButtonPress={handleClickAddButton}>
        <RequestHandler {...queryProps}>
          <BoxWrapper mb={0}>
            <Typography intlId='app.listParcel' variant='heading' mb='xs' />
            <DesktopTable fetchMoreData={fetchMoreData}>
              <DesktopTable.Head>
                <DesktopTable.Title intlId='app.date' />
                <DesktopTable.Title intlId='app.code' />
                <DesktopTable.Title intlId='app.source' />
                <DesktopTable.Title intlId='app.quantity' />
                <DesktopTable.Title intlId='app.fixWeight' />
                <DesktopTable.Title intlId='app.weightVolume' />
                <DesktopTable.Title intlId='app.status' />
              </DesktopTable.Head>
              <DesktopTable.Body>
                {data?.purchaseParcels?.rows?.map((item, index) => {
                  return (
                    <DesktopTable.Row
                      key={index}
                      onEditButtonClick={() => handleEditButtonClick(item?.id)}
                    >
                      <DesktopTable.Cell>
                        {formatDateTime(item?.date, 'dd.MM.yyyy')}
                      </DesktopTable.Cell>
                      <DesktopTable.Cell>{item?.trackingCode}</DesktopTable.Cell>
                      <DesktopTable.Cell>{item?.source?.name}</DesktopTable.Cell>
                      <DesktopTable.Cell>
                        {getProductsTotalQuantity(item?.purchases)}
                      </DesktopTable.Cell>
                      <DesktopTable.Cell>
                        {getProductsWeight(item?.purchases)}
                      </DesktopTable.Cell>
                      <DesktopTable.Cell>
                        {getProductsVolumeWeight(item?.purchases)}
                      </DesktopTable.Cell>
                      <DesktopTable.Cell>{item?.status?.name}</DesktopTable.Cell>
                    </DesktopTable.Row>
                  );
                })}
              </DesktopTable.Body>
            </DesktopTable>
          </BoxWrapper>
        </RequestHandler>
      </Layout>
    );
  }

  return (
    <Layout isScrollable={false} onActionButtonPress={handleClickAddButton}>
      <RequestHandler {...queryProps}>
        <Typography
          ml={'md'}
          mb='lg'
          color='dark'
          fontSize='lg'
          intlId='app.listParcel'
        />
        <MobileTable
          data={data?.purchaseParcels?.rows}
          fetchMoreData={fetchMoreData}
          renderItem={(item) => (
            <ParcelMobileItem
              onItemPress={handleEditButtonClick}
              statusId={item?.status?.id}
              quantity={getProductsTotalQuantity(item?.purchases)}
              trackingCode={item?.trackingCode}
              productWeight={getProductsWeight(item?.purchases)}
              parcelTime={item?.date}
              sourceName={item?.source?.name}
              id={item?.id}
            />
          )}
        />
      </RequestHandler>
    </Layout>
  );
};

export default Parcels;
