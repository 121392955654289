import { MaterialIcons } from '@expo/vector-icons';
import { Box, Flex, Icon } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { TouchableOpacity } from 'react-native';

import { BoxWrapper, Typography } from '../../../components';

const UserMobileItem = ({ id, name, avatar, roles, email, phone, onItemPress }) => {
  return (
    <BoxWrapper>
      <TouchableOpacity onPress={() => onItemPress(id)}>
        <Flex justify='space-between' mb='md'>
          <Box mr='md'>
            <Typography>{name}</Typography>
            <Typography mt='xs' color='gray.main' fontSize='sm' width='200px'>
              {roles}
            </Typography>
          </Box>
          {avatar}
        </Flex>
        <Flex>
          <Icon
            as={<MaterialIcons name='email' />}
            size='18px'
            mr='md'
            color='text.default'
          />
          <Typography intlId={email || 'app.no'} />
        </Flex>
        <Flex mt='sm'>
          <Icon
            as={<MaterialIcons name='phone' />}
            size='18px'
            mr='md'
            color='text.default'
          />
          <Typography intlId={phone || 'app.no'} />
        </Flex>
        <Flex>
          <Typography>{phone}</Typography>
        </Flex>
      </TouchableOpacity>
    </BoxWrapper>
  );
};

export default memo(UserMobileItem);

UserMobileItem.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  avatar: PropTypes.node.isRequired,
  roles: PropTypes.string.isRequired,
  email: PropTypes.string,
  phone: PropTypes.string,
  onItemPress: PropTypes.func,
};
