export const getProductsWeight = (productsParent) => {
  let result = productsParent?.reduce((sum, { product, quantity }) => {
    return sum + (product?.weight * quantity || 0);
  }, 0);

  if (result === 0) {
    return 0;
  }
  return result.toFixed(2);
};
