import { ApolloProvider } from '@apollo/client';
import { registerRootComponent } from 'expo';
import { NativeBaseProvider } from 'native-base';
import React from 'react';
import { Provider as PaperProvider } from 'react-native-paper';

import { client } from './client/client';
import {
  AccessProvider,
  AuthProvider,
  IntlProvider,
  NotificationsProvider,
} from './context';
import { Navigation } from './navigation/Navigation';
import { theme } from './theme';

const App = () => {
  return (
    <IntlProvider>
      <ApolloProvider client={client}>
        <NativeBaseProvider theme={theme}>
          <PaperProvider>
            <NotificationsProvider>
              <AuthProvider>
                <AccessProvider>
                  <Navigation />
                </AccessProvider>
              </AuthProvider>
            </NotificationsProvider>
          </PaperProvider>
        </NativeBaseProvider>
      </ApolloProvider>
    </IntlProvider>
  );
};

export default registerRootComponent(App);
