import React from 'react';

import {
  DesktopTable,
  Layout,
  MobileTable,
  RequestHandler,
  Typography,
} from '../../../components';
import { CheckIcon } from '../../../components/Icons';
import { DATA_REQUEST_LIMIT, IS_DESKTOP_SCREEN } from '../../../constants';
import { useSearchContext } from '../../../context';
import {
  useFindStockPlacesQuery,
  useHistory,
  useStockLocationsOptions,
} from '../../../hooks';
import useFilter from '../../../hooks/useFilter';
import { BoxesFilters } from './BoxesFilters';
import BoxMobileItem from './BoxMobileItem/BoxMobileItem';

const Boxes = () => {
  const navigation = useHistory();

  const { filters } = useFilter();

  const { debouncedSearchValue } = useSearchContext();

  const { getShelvingOptions } = useStockLocationsOptions();

  const {
    data: findStockPlacesData,
    fetchMore: boxesFetchMore,
    ...boxesQueryProps
  } = useFindStockPlacesQuery();

  const boxes = filters?.archival
    ? findStockPlacesData?.stockPlaces
    : findStockPlacesData?.stockPlaces?.filter((box) => !box?.archival);

  const fetchMoreData = async (resolve, reject) => {
    await boxesFetchMore({
      variables: {
        offset: findStockPlacesData?.stockPlaces?.length,
        limit: DATA_REQUEST_LIMIT,
        search: debouncedSearchValue,
        locationIds: filters?.roomId
          ? filters?.shelvingId
            ? [+filters?.shelvingId]
            : getShelvingOptions(+filters?.roomId).map((shelving) => +shelving?.value)
          : undefined,
        sort: {
          type: filters?.direction || 'desc',
          field: filters?.field || 'id',
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          reject && reject();
          return prev;
        }

        resolve && resolve();

        return {
          stockPlaces: [...prev?.stockPlaces, ...fetchMoreResult?.stockPlaces],
        };
      },
    });
  };

  const handleClickAddButton = () => {
    navigation.push('/warehouse/box/');
  };

  const handleEditButtonClick = (item) => {
    navigation.push(`/warehouse/box/${item?.id}`);
  };

  return (
    <Layout
      isScrollable={false}
      onActionButtonPress={handleClickAddButton}
      filters={<BoxesFilters />}
    >
      <RequestHandler {...boxesQueryProps}>
        <Typography intlId='app.listBox' variant='heading' mb='xs' />
        {IS_DESKTOP_SCREEN ? (
          <DesktopTable fetchMoreData={fetchMoreData}>
            <DesktopTable.Head>
              <DesktopTable.Title intlId='app.title' />
              <DesktopTable.Title intlId='app.room' />
              <DesktopTable.Title intlId='app.shelving' />
              {filters?.archival ? (
                <DesktopTable.Title
                  textAlign={'center'}
                  intlId='app.archival'
                  sortId={'archival'}
                />
              ) : null}
            </DesktopTable.Head>
            <DesktopTable.Body>
              {boxes?.map((item, index) => {
                const style = item?.archival && { opacity: 0.6 };

                return (
                  <DesktopTable.Row
                    key={index}
                    onEditButtonClick={() => handleEditButtonClick(item)}
                  >
                    <DesktopTable.Cell style={style}>{item.name}</DesktopTable.Cell>
                    <DesktopTable.Cell style={style}>
                      {item.location[1]?.name}
                    </DesktopTable.Cell>
                    <DesktopTable.Cell style={style}>
                      {item.location[0]?.name}
                    </DesktopTable.Cell>
                    {filters?.archival ? (
                      <DesktopTable.Cell textAlign={'center'}>
                        {item?.archival ? <CheckIcon /> : ' '}
                      </DesktopTable.Cell>
                    ) : null}
                  </DesktopTable.Row>
                );
              })}
            </DesktopTable.Body>
          </DesktopTable>
        ) : (
          <MobileTable
            data={boxes}
            fetchMoreData={fetchMoreData}
            renderItem={(item) => (
              <BoxMobileItem
                onItemPress={() => handleEditButtonClick(item)}
                name={item.name}
                archival={item?.archival ? <CheckIcon /> : ' '}
                room={item.location[1]?.name}
                shelving={item.location[0]?.name}
              />
            )}
          />
        )}
      </RequestHandler>
    </Layout>
  );
};

export default Boxes;
