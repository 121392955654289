import { Center, Flex } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { IS_APP, IS_DESKTOP_SCREEN } from '../../constants';
import { LocalStorage } from '../../services';
import { Button, Checkbox } from '../index';

export const AddModalFooter = ({ searchValue, onClose, withScan = true }) => {
  if (IS_DESKTOP_SCREEN) {
    return (
      <Flex justify='flex-end' padding='md'>
        <Button intlId='app.close' onPress={onClose} variant='ghost' />
      </Flex>
    );
  }

  if (IS_APP && withScan && !searchValue) {
    return <AddModalAppFooter />;
  }

  return null;
};

const AddModalAppFooter = () => {
  const [value, setValue] = React.useState();

  React.useEffect(() => {
    (async function () {
      const currentValue = await LocalStorage.getValue('start-auto-scan');
      setValue(currentValue === 'true');
    })();
  }, []);

  const handleChange = () => {
    LocalStorage.setValue('start-auto-scan', !value);
    setValue(!value);
  };

  return (
    <Center pb='lg'>
      <Button intlId='app.scan' width='176px' mb='lg' />
      <Checkbox checked={value} onChange={handleChange} label='Запускать автоматически' />
    </Center>
  );
};

AddModalFooter.propTypes = {
  searchValue: PropTypes.string,
  onClose: PropTypes.func,
  withScan: PropTypes.bool,
};
