import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const PaymentMethodsIcon = ({ size = 24, style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' style={{ ...style }}>
      <Path
        d='M20 4H4C2.897 4 2 4.897 2 6V8H22V6C22 4.897 21.103 4 20 4ZM2 18C2 19.103 2.897 20 4 20H20C21.103 20 22 19.103 22 18V12H2V18ZM5 15H11V17H5V15Z'
        fill='white'
      />
    </Svg>
  );
};

PaymentMethodsIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
