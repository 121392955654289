import { Box } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Link as WebLink } from 'react-router-dom';

import { isWeb } from '../../constants';
import { useHistory } from '../../hooks';
import { Typography } from '../Typography/Typography';

export const TextLink = ({ children, intlId, link, underline = false, ...props }) => {
  const navigation = useHistory();

  const handleLinkClick = () => {
    navigation.push(link);
  };

  const linkStyles = {
    textDecoration: 'none',
    width: '100%',
    height: '100%',
  };

  const BlueColoredText = ({ underline, intlId, children, ...props }) => {
    return (
      <Typography
        underline={underline}
        _hover={{
          color: 'secondary',
        }}
        color='primary.default'
        intlId={intlId}
        {...props}
      >
        {children}
      </Typography>
    );
  };

  if (isWeb) {
    return (
      <Box display={'inline'}>
        <WebLink style={linkStyles} to={link}>
          <BlueColoredText underline={underline} intlId={intlId} {...props}>
            {children}
          </BlueColoredText>
        </WebLink>
      </Box>
    );
  } else {
    return (
      <TouchableOpacity onPress={handleLinkClick}>
        <BlueColoredText underline={underline} intlId={intlId} {...props}>
          {children}
        </BlueColoredText>
      </TouchableOpacity>
    );
  }
};

TextLink.propTypes = {
  intlId: PropTypes.string,
  link: PropTypes.string.isRequired,
  underline: PropTypes.bool,
  children: PropTypes.any,
};
