import { Flex } from 'native-base';
import React from 'react';
import { StyleSheet } from 'react-native';

import {
  DesktopTable,
  Layout,
  MobileTable,
  RequestHandler,
  Typography,
} from '../../../components';
import { IS_DESKTOP_SCREEN } from '../../../constants';
import { useGetBankAccounts, useHistory } from '../../../hooks';
import { AccountsFilter } from './AccountsFilter';
import AccountsMobileItem from './AccountsMobileItem/AccountsMobileItem';

const COLUMNS = [
  { intlId: 'app.title', sortId: 'title' },
  { intlId: 'app.currency', sortId: 'currencyId' },
  { intlId: 'app.specification' },
];

const Accounts = () => {
  const navigation = useHistory();

  const { data: bankAccounts, ...bankAccountsQueryProps } = useGetBankAccounts();

  const handleClickAddButton = () => {
    navigation.push('/bookkeeping/account/');
  };

  const handleEditButtonClick = (item) => {
    navigation.push(`/bookkeeping/account/${item?.id}`);
  };

  return (
    <Layout
      filters={<AccountsFilter />}
      isScrollable={false}
      onActionButtonPress={handleClickAddButton}
    >
      <RequestHandler {...bankAccountsQueryProps}>
        {IS_DESKTOP_SCREEN ? (
          <DesktopTable>
            <DesktopTable.Head>
              {COLUMNS.map((item, index) => (
                <DesktopTable.Title key={index} {...item} />
              ))}
            </DesktopTable.Head>
            <DesktopTable.Body>
              {bankAccounts?.bankAccounts?.map((item, index) => {
                const style = item?.isArchived ? styles.cellDisabled : {};

                return (
                  <DesktopTable.Row
                    key={index}
                    onEditButtonClick={() => handleEditButtonClick(item)}
                  >
                    <DesktopTable.Cell style={style}>{item?.title}</DesktopTable.Cell>
                    <DesktopTable.Cell style={style}>
                      {item?.currency?.code}
                    </DesktopTable.Cell>
                    <DesktopTable.Cell maxWidth='900px' width={'900px'} style={style}>
                      <Flex>
                        <Typography
                          intlId={item.description ? undefined : 'app.noData'}
                          isTruncated
                        >
                          {item?.description}
                        </Typography>
                      </Flex>
                    </DesktopTable.Cell>
                  </DesktopTable.Row>
                );
              })}
            </DesktopTable.Body>
          </DesktopTable>
        ) : (
          <MobileTable
            data={bankAccounts?.bankAccounts}
            renderItem={(item) => (
              <AccountsMobileItem
                isArchived={item?.isArchived}
                currency={item?.currency?.code}
                title={item?.title}
                description={item?.description}
                onItemPress={() => handleEditButtonClick(item)}
              />
            )}
          />
        )}
      </RequestHandler>
    </Layout>
  );
};

const styles = StyleSheet.create({
  cellDisabled: {
    opacity: 0.6,
  },
});

export default Accounts;
