import { StyleSheet } from 'react-native';

import {
  IS_DESKTOP_SCREEN,
  IS_LAPTOP_SCREEN,
  IS_WEB,
  STATUS_BAR_HEIGHT,
  theme,
} from '../../../constants';

export const styles = StyleSheet.create({
  iconWrapper: {
    alignItems: 'center',
    height: 40,
    justifyContent: 'center',
    marginBottom: 12.5,
    width: 40,
  },
  inner: {
    borderRadius: 5,
    maxWidth: IS_LAPTOP_SCREEN || IS_DESKTOP_SCREEN ? 440 : 304,
    ...(IS_WEB ? { boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)' } : null),
    backgroundColor: theme.colors.white,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingBottom: 16.5,
    paddingHorizontal: IS_LAPTOP_SCREEN || IS_DESKTOP_SCREEN ? 8 : 5,
    paddingTop: 24,
    position: 'absolute',
    right: IS_LAPTOP_SCREEN || IS_DESKTOP_SCREEN ? 82 : 10,
    top: IS_WEB ? 93 : STATUS_BAR_HEIGHT + 30,
    zIndex: 10,
  },
  section: {
    alignItems: 'center',
    borderColor: theme.colors.white,
    borderRadius: 6,
    borderWidth: 1,
    display: 'flex',
    height: 90,
    justifyContent: 'center',
  },
  // eslint-disable-next-line react-native/no-color-literals
  sectionActive: {
    borderColor: 'rgba(0, 0, 0, 0.05)',
  },
  sectionWrapper: {
    marginBottom: 8.5,
    marginHorizontal: IS_LAPTOP_SCREEN || IS_DESKTOP_SCREEN ? 8 : 4,
    width: 90,
  },
  wrapper: {
    position: 'relative',
  },
});
