import gql from 'graphql-tag';

export default gql`
  query findEmails($search: String) {
    findEmails(search: $search) {
      id
      email
    }
  }
`;
