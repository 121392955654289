import { Box } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { View } from 'react-native';
import { Title } from 'react-native-paper';

import {
  Checkbox,
  FieldRow,
  FormRow,
  RequestHandler,
  TextFieldHookFrom,
  TextLink,
  Typography,
} from '../../../../components';
import { QuerySelectWithSearchHookForm } from '../../../../components/QuerySelectWithSearch/QuerySelectWithSearchHookForm';
import { IS_MOBILE_SCREEN } from '../../../../constants';
import stylesGlobal from '../../../../globalStyles';
import { GET_CUSTOMERS } from '../../../../graphql';
import { useGetCustomerLazyQuery } from '../../../../hooks';
import { getContacts } from '../../../../utils';
import { CustomerContactHookForm } from '../../Customer/CustomerContactHookForm';
import stylesLocal from './styles';

const OrderCustomerContainer = () => {
  const { control, reset, getValues } = useFormContext();

  return <OrderCustomer control={control} reset={reset} getValues={getValues} />;
};

const OrderCustomer = memo(({ control, reset, getValues }) => {
  const { formatMessage } = useIntl();

  const { fields, append, remove, update } = useFieldArray({
    name: 'customer.contacts',
  });

  const customer = useWatch({
    name: 'customer',
    control,
  });

  const isCallbackOff = useWatch({
    name: 'isCallbackOff',
    control,
  });

  const customerComment = useWatch({
    name: 'customerComment',
    control,
  });

  const [loading, setLoading] = useState(false);

  const [getCustomerLazyQuery] = useGetCustomerLazyQuery();

  const onCustomersSelectChange = useCallback(
    async (option) => {
      if (option) {
        setLoading(true);

        const customerResponse = await getCustomerLazyQuery({
          variables: { id: +option },
        });

        const { id, phones, emails, ...otherProps } = customerResponse?.data?.customer;

        const contacts = getContacts(phones, emails).map((value) => ({ value }));

        reset(
          {
            ...getValues(),
            customer: { contacts: [...contacts, { value: '' }], id: +id, ...otherProps },
          },
          { keepDirty: true },
        );

        setLoading(false);
      } else {
        reset({
          ...getValues(),
          customer: {
            contacts: [{ value: '' }],
            id: null,
            firstName: '',
            lastName: '',
            middleName: '',
          },
        });
      }
    },
    [getCustomerLazyQuery, fields, append, update, getValues],
  );

  const initialCustomerOption = useMemo(() => {
    return customer?.id
      ? {
          value: customer?.id,
          label: `${customer?.firstName || ''} ${customer?.lastName || ''} ${
            customer?.contacts[0].value || ''
          }`,
        }
      : null;
  }, [customer]);

  const handleFieldChange = (value, fieldIndex) => {
    if (fieldIndex === fields.length - 1 && value) {
      append({ value: '' });
    }

    if (fieldIndex !== fields.length - 1 && !value) {
      remove(fieldIndex);
    }
  };

  const infoFields = useMemo(() => {
    return fields.map((field, index) => {
      return (
        <CustomerContactHookForm
          control={control}
          onFieldChanged={handleFieldChange}
          width='100%'
          key={field.id}
          index={index}
          name={`customer.contacts.${index}.value`}
        />
      );
    });
  }, [fields]);

  const labelProperty = useMemo(() => {
    return ['firstName', 'lastName', 'phones.[0].phone'];
  }, []);

  return (
    <RequestHandler backgroundLoading={loading}>
      <View style={stylesLocal.header}>
        <Title style={stylesGlobal.title}>{formatMessage({ id: 'app.customer' })}</Title>
        <QuerySelectWithSearchHookForm
          width={'100%'}
          initialOption={initialCustomerOption}
          placeholderIntlId='new.customer'
          fieldName='customer.id'
          labelProperty={labelProperty}
          pl={0}
          pr={0}
          onChange={onCustomersSelectChange}
          graphql={GET_CUSTOMERS}
          graphqlName='customers'
        />
      </View>
      <FormRow style={stylesLocal.fields}>
        <FormRow.Left>
          <FieldRow>{infoFields}</FieldRow>
        </FormRow.Left>
        <FormRow.Right>
          <TextFieldHookFrom
            width={'100%'}
            name='customer.lastName'
            label='customer.lastName'
          />
          <TextFieldHookFrom
            width={'100%'}
            name='customer.firstName'
            label='customer.name'
          />
          <TextFieldHookFrom
            width={'100%'}
            name='customer.middleName'
            label='customer.middleName'
          />
        </FormRow.Right>
      </FormRow>
      {customer?.id && (
        <Box ml={2} pt={customer?.contacts?.[2] ? 6 : 0} pb={3}>
          <TextLink
            link={'/sales/customer/' + customer?.id}
            underline
            intlId='customer.profile'
          />
        </Box>
      )}
      {customerComment ? (
        <Box ml={2} pb={3}>
          <Typography intlId={'customer.comment'} color='black' mb={1} />
          <Typography>{customerComment}</Typography>
        </Box>
      ) : null}
      {isCallbackOff ? (
        <Box ml={2} pb={3}>
          <Checkbox
            mt={!IS_MOBILE_SCREEN ? '16px' : 0}
            justify={!IS_MOBILE_SCREEN ? 'flex-start' : 'center'}
            label={'order.callbackOff'}
            onChange={() => {}}
            disabled
            checked={isCallbackOff}
          />
        </Box>
      ) : null}
    </RequestHandler>
  );
});

OrderCustomer.displayName = 'OrderCustomer';

OrderCustomer.propTypes = {
  control: PropTypes.object,
  reset: PropTypes.func,
  getValues: PropTypes.func,
};

export default OrderCustomerContainer;
