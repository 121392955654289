import { MaterialIcons } from '@expo/vector-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, ScrollView, Spinner, useTheme } from 'native-base';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { FAB } from 'react-native-paper';

import { TextFieldHookFrom, Typography } from '../../../../../../components';
import { SelectHookForm } from '../../../../../../components/Select/SelectHookForm';
import {
  useCreateTTNMutation,
  useGetPackagingTemplates,
  useIntl,
  useParams,
} from '../../../../../../hooks';
import { addTTNSchema } from '../../../../../../services/validation';
import { getVolumeWeight, transformSelectOptions } from '../../../../../../utils';

export const TemplateForm = ({ onClose }) => {
  const { id: orderId } = useParams();

  const intl = useIntl();
  const theme = useTheme();

  const { data } = useGetPackagingTemplates();

  const [createTTN, { loading }] = useCreateTTNMutation();

  const methods = useForm({
    reValidateMode: 'onBlur',
    resolver: yupResolver(addTTNSchema),
    defaultValues: {
      orderId,
      status: '',
      width: '',
      length: '',
      height: '',
      weight: '',
      volumeWeight: '',
    },
  });

  const status = useWatch({
    name: 'status',
    control: methods.control,
  });

  const onSubmit = useCallback(async (values) => {
    const result = await createTTN({
      variables: {
        input: {
          width: Math.round(values.width),
          height: Math.round(values.height),
          length: Math.round(values.length),
          weight: Math.round(values.weight),
          orderId: values.orderId * 1,
        },
      },
    });

    if (result?.data?.createWaybill?.ttn) {
      onClose(result?.data?.createWaybill?.ttn);
    }
  }, []);

  const renderIcon = useCallback(() => {
    if (loading)
      // eslint-disable-next-line react-native/no-inline-styles
      return <Spinner size='sm' mb={-2} style={{ bottom: -2 }} color={'white'} />;
    return <MaterialIcons name={'save'} size={25} color='#fff' />;
  }, [loading]);

  useEffect(() => {
    const packagingTemplates = data?.packagingTemplates?.find(
      (val) => val.id.toString() === status.toString(),
    );

    if (packagingTemplates) {
      const { width = 0, height = 0, length = 0, weight = 0 } = packagingTemplates;

      methods.setValue('width', width);
      methods.setValue('height', height);
      methods.setValue('length', length);
      methods.setValue('weight', weight);

      methods.setValue(
        'volumeWeight',
        `${getVolumeWeight(width, height, length)} ${intl.formatMessage({
          id: 'app.kg',
        })}`,
      );
    }
  }, [methods.setValue, intl, status, data]);

  return (
    <ScrollView>
      <Box pt={'25px'} px={'26px'} pb={'30px'} position={'relative'}>
        <FormProvider {...methods}>
          <Typography
            mb={'24px'}
            variant={'heading'}
            intlId={'app.dimensionsOfPackage'}
          />
          <SelectHookForm
            name='status'
            label='app.boxLayout'
            options={transformSelectOptions({
              data: data?.packagingTemplates?.map((val) => ({
                ...val,
                id: val.id.toString(),
              })),
              label: 'name',
            })}
            control={methods.control}
          />
          <TextFieldHookFrom
            isReadOnly
            name={'width'}
            label={'app.widthCm'}
            width={'100%'}
          />
          <TextFieldHookFrom
            isReadOnly
            name={'length'}
            label={'app.lengthCm'}
            width={'100%'}
          />
          <TextFieldHookFrom
            isReadOnly
            name={'height'}
            label={'app.heightCm'}
            width={'100%'}
          />
          <TextFieldHookFrom
            isReadOnly
            name={'weight'}
            label={'app.weightKg'}
            width={'100%'}
          />
          <TextFieldHookFrom
            isReadOnly
            name={'volumeWeight'}
            label={'app.volumeWeight'}
            width={'50%'}
          />

          <FAB
            icon={renderIcon}
            onPress={methods.handleSubmit(onSubmit)}
            // eslint-disable-next-line react-native/no-inline-styles
            style={{
              zIndex: 10,
              backgroundColor: theme.colors.primary.default,
              width: 56,
              height: 56,
              position: 'absolute',
              right: 26,
              bottom: 26,
            }}
            disabled={loading}
          />
        </FormProvider>
      </Box>
    </ScrollView>
  );
};

TemplateForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};
