import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

const CurrencyContext = createContext();

const useCurrencyContext = () => {
  const context = useContext(CurrencyContext);
  if (!context) {
    throw new Error('useCurrencyContext must be used within a CurrencyProvider');
  }
  return context;
};

const CurrencyProvider = ({ children }) => {
  const [currentCurrency, setCurrentCurrency] = useState('default');

  const handleChangeCurrency = (currency) => {
    if (currency === 'default') {
      setCurrentCurrency('default');
    } else {
      setCurrentCurrency(currency);
    }
  };

  return (
    <CurrencyContext.Provider
      value={{ currentCurrency, changeCurrency: handleChangeCurrency }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

CurrencyProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { CurrencyProvider, useCurrencyContext };
