import { useLazyQuery } from '@apollo/client';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { DATA_REQUEST_LIMIT } from '../../constants';
import { useDebounce } from '../../hooks';
import { transformSelectOption, transformSelectOptions } from '../../utils';
import { SelectWithSearch } from '../SelectWithSearch/SelectWithSearch';

export const QuerySelectWithSearch = ({
  label,
  labelProperty,
  valueProperty = 'id',
  placeholderIntlId = 'app.enterValue',
  fieldName,
  initialOption,
  graphqlName,
  graphql,
  width,
  mb,
  onChange,
  rowsInResponse = false,
  pr,
  alreadyPickedItems,
  pl,
}) => {
  const [field] = useField(fieldName);
  const [options, setOptions] = useState([]);

  const [searchValue, setSearchValue] = useState('');

  const { debouncedValue: debouncedSearchValue, isDebouncing } = useDebounce(searchValue);

  const [lazyQueryRequest, { loading, fetchMore }] = useLazyQuery(graphql, {
    variables: {
      search: debouncedSearchValue,
      offset: 0,
      limit: DATA_REQUEST_LIMIT,
    },
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      const result = rowsInResponse
        ? response?.[graphqlName]?.['rows']
        : response?.[graphqlName];
      setOptions(
        transformSelectOptions({
          data: result?.filter?.((item) =>
            item?.[labelProperty] && alreadyPickedItems
              ? !alreadyPickedItems.some((pickedItem) => pickedItem.id === item.id)
              : true,
          ),
          value: valueProperty,
          label: labelProperty,
        }),
      );
    },
  });

  const fetchMoreData = async () => {
    const { data } = await fetchMore({
      variables: {
        offset: options?.length,
        limit: DATA_REQUEST_LIMIT,
      },
    });
    const result = rowsInResponse ? data?.[graphqlName]?.['rows'] : data?.[graphqlName];

    setOptions((prevState) => [
      ...prevState,
      ...transformSelectOptions({
        data: result?.filter?.((item) =>
          item?.[labelProperty] && alreadyPickedItems
            ? !alreadyPickedItems.some((pickedItem) => pickedItem.id === item.id)
            : true,
        ),
        value: valueProperty,
        label: labelProperty,
      }),
    ]);
  };

  const fetchQueryResponse = () => {
    lazyQueryRequest();
  };

  const initialOptionOrFind =
    initialOption !== undefined
      ? initialOption
      : field?.value
      ? transformSelectOption({
          item: field.value,
          value: valueProperty,
          label: labelProperty,
        })
      : null;

  return (
    <SelectWithSearch
      onMenuOpen={fetchQueryResponse}
      onMenuScrollToBottom={fetchMoreData}
      onChange={onChange}
      placeholderIntlId={placeholderIntlId}
      setSearchValue={setSearchValue}
      options={options}
      isLoading={loading || isDebouncing}
      label={label}
      name={fieldName}
      initialOption={initialOptionOrFind}
      pr={pr}
      pl={pl}
      mb={mb}
      width={width}
    />
  );
};

QuerySelectWithSearch.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  fieldName: PropTypes.string.isRequired,
  graphqlName: PropTypes.string.isRequired,
  placeholderIntlId: PropTypes.string,
  graphql: PropTypes.object.isRequired,
  initialOption: PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  }),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  labelProperty: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  valueProperty: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
  ]),
  pr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rowsInResponse: PropTypes.bool,
  alreadyPickedItems: PropTypes.array,
};
