import React, { useEffect } from 'react';
import { FlatList, TouchableOpacity } from 'react-native';
import _ from 'lodash';
import PropTypes from 'prop-types';
import columnData from './columnData';
import { FlyinIconRow } from '../../../components/UI';
import WebSorting from '../../../components/WebSorting/WebSorting';
import CustomersRowDesktop from './desktop/CustomersRowDesktop';
import CustomersRowMobile from './mobile/CustomersRowMobile';
import { useMediaQuery, useHistory, useFilter } from '../../../hooks';
import { isWeb, isApp } from '../../../constants';

const CustomersRows = ({ customers, loading, fetchMore }) => {
  const navigation = useHistory();
  const { filters, setFilters } = useFilter();
  const isMobile = useMediaQuery('isMobile');

  if (isWeb)
    useEffect(() => {
      const fetchMoreOnce = _.once(fetchMore);
      const handleScroll = _.throttle(() => {
        if (
          window.innerHeight + window.scrollY > document.body.clientHeight - 100 &&
          !loading
        ) {
          fetchMoreOnce();
        }
      }, 200);
      window.addEventListener('scroll', handleScroll);
      const unsubscribeBlur = () => {
        window.removeEventListener('scroll', handleScroll);
      };
      return () => unsubscribeBlur();
    }, [fetchMore, loading]);

  const onEditButtonClick = (item) => {
    navigation.push('/sales/customer/' + item.id);
  };

  const renderItemMobile = ({ item }) => (
    <TouchableOpacity activeOpacity={0.9} onPress={() => onEditButtonClick(item)}>
      <CustomersRowMobile
        id={item.id}
        firstName={item.firstName}
        lastName={item.lastName}
        createdAt={item.createdAt}
        phones={item.phones}
        emails={item.emails}
      />
    </TouchableOpacity>
  );
  const renderItemWeb = ({ item }) => (
    <FlyinIconRow handleRowClick={() => onEditButtonClick(item)}>
      <CustomersRowDesktop
        id={item.id}
        firstName={item.firstName}
        lastName={item.lastName}
        createdAt={item.createdAt}
        phones={item.phones}
        emails={item.emails}
      />
    </FlyinIconRow>
  );

  return (
    <>
      {!isMobile && (
        <WebSorting
          name={'Customers'}
          styleWrapper={!isMobile && { marginLeft: 85, marginRight: 138 }}
          columnData={columnData}
          sorting={filters}
          setSorting={setFilters}
        />
      )}
      <FlatList
        data={customers}
        onEndReached={isApp ? () => fetchMore() : null}
        style={{ minHeight: '100%' }}
        renderItem={isMobile ? renderItemMobile : renderItemWeb}
        keyExtractor={(item) => item.id.toString()}
      />
    </>
  );
};

CustomersRows.propTypes = {
  loading: PropTypes.bool.isRequired,
  customers: PropTypes.array,
  fetchMore: PropTypes.func.isRequired,
};

export default CustomersRows;
