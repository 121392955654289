import { Center, Flex } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { TouchableOpacity } from 'react-native';

import { getNameInitials } from '../../utils';
import { BoxWrapper, Typography } from '../index';

export const UserCard = ({ item, handleSet }) => {
  return (
    <TouchableOpacity onPress={() => handleSet(item)}>
      <BoxWrapper mb={'8px'}>
        <Flex marginY='sm' bgColor='white' justify='space-between'>
          <Flex>
            <Center
              width='40px'
              height='40px'
              borderRadius='20px'
              bgColor='success'
              mr='md'
            >
              <Typography color='gray.extraLight'>
                {getNameInitials(`${item.lastName} ${item.firstName}`)}
              </Typography>
            </Center>
            <Typography intlId={`${item.lastName} ${item.firstName}`} />
          </Flex>
        </Flex>
      </BoxWrapper>
    </TouchableOpacity>
  );
};

UserCard.propTypes = {
  item: PropTypes.object.isRequired,
  handleSet: PropTypes.func.isRequired,
};
