import React from 'react';
import { View } from 'react-native';
import styles from '../Style';
import { format } from 'date-fns';
import { TextCopy } from '../../../../components/UI';
import { useIntl } from '../../../../hooks';

const OrdersRowDesktop = ({
  orderItems,
  discount,
  id,
  createdAt,
  user,
  customer,
  source,
  paymentStatus,
  status,
}) => {
  const intl = useIntl();
  const userName = customer
    ? `${customer.firstName ? customer.firstName : ''} ${
        customer.lastName ? customer.lastName : ''
      }`
    : null;
  const parseDate = new Date(parseInt(createdAt));
  const amount =
    orderItems.reduce((sum, value) => sum + value.pricePerPcs * value.quantity, 0) -
    discount;
  const getColore = () => {
    switch (parseInt(status ? status.id : 0)) {
      case 1:
        return { color: '#38761d', fontWeight: 'bold' };
      case 2:
        return { color: '#fbbc04', fontWeight: 'bold' };
      case 3:
        return { color: '#666666', fontWeight: 'bold' };
      case 4:
        return { color: '#4a86e8', fontWeight: 'bold' };
      case 5:
        return { color: 'rgba(0, 0, 0, 0.87)', fontWeight: 'bold' };
      case 6:
        return { color: '#666', fontWeight: 'bold' };
      case 7:
        return { color: '#666', fontWeight: 'bold' };
      case 8:
        return { color: '#b45f06', fontWeight: 'bold' };
      case 9:
        return { color: '#cc0000', fontWeight: 'bold' };
      default:
        return {};
    }
  };
  return (
    <>
      <View style={styles.idColumn}>
        <TextCopy title={id} />
      </View>
      <View style={styles.datetimeColumn}>
        <TextCopy title={format(parseDate, 'dd.MM.yyyy HH:mm')} />
      </View>
      <View style={styles.customerColumn}>
        <TextCopy
          title={
            user
              ? `${user.lastName} ${user.firstName}`
              : intl.formatMessage({ id: 'app.no' })
          }
        />
      </View>
      <View style={styles.managerColumn}>
        <TextCopy title={userName ? userName : intl.formatMessage({ id: 'app.no' })} />
      </View>
      <View style={styles.sourceColumn}>
        <TextCopy title={source.name} />
      </View>
      <View style={styles.paymentColumn}>
        <TextCopy
          title={
            paymentStatus ? paymentStatus.name : intl.formatMessage({ id: 'app.no' })
          }
        />
      </View>
      <View style={styles.statusColumn}>
        <TextCopy
          styleText={getColore()}
          title={status ? status.name : intl.formatMessage({ id: 'app.no' })}
        />
      </View>
      <View style={styles.amountColumn}>
        <TextCopy
          title={`${amount.toFixed(2)} ${intl.formatMessage({
            id: 'currency.uah',
          })}`}
        />
      </View>
    </>
  );
};

export default OrdersRowDesktop;
