import PropTypes from 'prop-types';
import React from 'react';

import {
  OrdersIcon,
  SettingsUsersIcon,
  ShipIcon,
  TransactionForwardingIcon,
} from '../Icons';
import { LinkDescription, Typography } from '../index';

export const TransactionsAdditionalData = ({ item, vertically, ...props }) => {
  if ([7].includes(+item?.typeId)) {
    return (
      <LinkDescription
        link={`/purchase/forwarding/${item?.forwarding?.id}`}
        intlId={item?.forwarding?.trackingCode}
        icon={item?.type?.id && <TransactionForwardingIcon />}
        {...props}
      />
    );
  }

  if ([6].includes(item?.purchase?.id && +item?.typeId)) {
    return (
      <LinkDescription
        link={`/purchase/purchase/${item?.purchase?.id}`}
        date={item?.purchase?.purchaseTime}
        dateFormat={'d.MM.y'}
        intlId={`№ ${item?.purchase?.id}`}
        icon={item?.type?.id && <ShipIcon />}
        {...(item?.type?.id && { dateWithFrom: true })}
        {...props}
      />
    );
  }
  if ([4, 5].includes(item?.user?.firstName && item?.user?.id && +item?.typeId)) {
    return (
      <>
        <LinkDescription
          link={`/settings/user/${item?.user?.id}`}
          intlId={`${item?.user?.lastName || ''} ${item?.user?.firstName}`}
          icon={item?.type?.id && <SettingsUsersIcon />}
          {...props}
        />
      </>
    );
  }
  if ([1, 3, 8].includes(item?.order?.id && +item?.typeId)) {
    return (
      <>
        <LinkDescription
          link={`/sales/order/${item?.order?.id}`}
          intlId={`№ ${item?.order?.id}`}
          icon={item?.type?.id && <OrdersIcon />}
          date={item?.order?.createdAt}
          {...(item?.type?.id && { dateWithFrom: true })}
          vertically={vertically}
          {...props}
        />
      </>
    );
  }
  return <Typography intlId='app.noData' />;
};

TransactionsAdditionalData.propTypes = {
  item: PropTypes.object.isRequired,
  vertically: PropTypes.bool,
};
