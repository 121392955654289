import _ from 'lodash';

import { CustomParamTypesEnum } from '../constants';

export const compareArraysById = (originalArray, initParamOptions, newArray) => {
  let idsToDelete = [];
  let changes = [];
  let newElements = [];
  const transformedParams = [];
  newArray.forEach((param) => {
    if (param.customParamOptionIds) {
      param.customParamOptionIds.forEach((customParamOptionId) => {
        const transormedcustomParamOption = {
          customParamId: param.id ? Number(param.id) : param.customParamId,
          customParamOptionId: Number(customParamOptionId.value),
          name: param.name,
          productId: param.productId,
          ...(customParamOptionId.id && {
            productParamId: Number(customParamOptionId.id),
          }),
        };
        transformedParams.push(transormedcustomParamOption);
      });
      return;
    }

    return transformedParams.push({ ...param });
  });

  const isEqual = _.isEqual(initParamOptions, transformedParams);
  if (isEqual) {
    return { newElements: [], idsToDelete: [], changes: [] };
  }
  newElements = transformedParams.filter((el) => !el.productParamId);
  const uniqueIds = Array.from(new Set(originalArray.map((item) => item.id)));
  const newIds = transformedParams.map(
    (el) => el.productParamId && Number(el.productParamId),
  );

  if (uniqueIds.length) {
    uniqueIds.forEach((id) => {
      if (!newIds.includes(Number(id)) && id) {
        idsToDelete.push(id);
      }
    });
  } else {
    idsToDelete = newIds;
  }

  transformedParams.forEach((newParam) => {
    const oldParam = initParamOptions.find(
      (param) => param.productParamId === newParam.productParamId,
    );
    if (oldParam) {
      // Проверяем только для булевых значений
      if (
        oldParam.type === CustomParamTypesEnum.Boolean &&
        oldParam.booleanValue !== newParam.booleanValue
      ) {
        changes.push({
          ...newParam,
          id: newParam.productParamId,
          customParamId: Number(newParam.id),
        });
      }
      if (
        oldParam.type === CustomParamTypesEnum.Number &&
        oldParam.numberValue !== newParam.numberValue
      ) {
        changes.push({
          ...newParam,
          id: newParam.productParamId,
          customParamId: Number(newParam.id),
        });
      }
      if (
        oldParam.type === CustomParamTypesEnum.Text &&
        oldParam.textValue !== newParam.textValue
      ) {
        changes.push({
          ...newParam,
          id: newParam.productParamId,
          customParamId: Number(newParam.id),
        });
      }
      if (
        oldParam.type === CustomParamTypesEnum.Range &&
        (oldParam.rangeFromValue !== newParam.rangeFromValue ||
          oldParam.rangeToValue !== newParam.rangeToValue)
      ) {
        changes.push({
          ...newParam,
          id: newParam.productParamId,
          customParamId: Number(newParam.id),
        });
      }
    }
  });

  return { newElements, idsToDelete, changes };
};
