import { Center, Flex } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { TouchableOpacity } from 'react-native';

import { ShipIcon } from '../Icons';
import { BoxWrapper, LinkDescription } from '../index';

export const ForwardingCard = ({ item, handleSet }) => {
  return (
    <TouchableOpacity onPress={() => handleSet(item)}>
      <BoxWrapper mb={'8px'}>
        <Flex marginY='sm' bgColor='white' justify='space-between'>
          <Flex>
            <Center
              width='40px'
              height='40px'
              borderRadius='20px'
              bgColor='primary.default'
              mr='md'
            >
              <ShipIcon />
            </Center>
            <Flex>
              <LinkDescription
                intlId={item.trackingCode}
                date={item?.date}
                withClockIcon
                item={item}
                link={`/purchase/forwarding/${item?.forwarding?.id}`}
                vertically
              />
            </Flex>
          </Flex>
        </Flex>
      </BoxWrapper>
    </TouchableOpacity>
  );
};

ForwardingCard.propTypes = {
  item: PropTypes.object.isRequired,
  handleSet: PropTypes.func.isRequired,
};
