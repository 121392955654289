import { Button as NBButton } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

export const Button = ({ intlId, children, ...props }) => {
  const intl = useIntl();

  const renderText = () => {
    if (intl.messages[intlId]) return intl.formatMessage({ id: intlId });
    if (children) return children;
    return intlId;
  };

  return <NBButton {...props}>{renderText()}</NBButton>;
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  intlId: PropTypes.string,
};
