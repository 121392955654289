import { useFormikContext } from 'formik';
import { Flex } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { IS_DESKTOP_SCREEN } from '../../constants';
import { useSearchContext } from '../../context';
import { useOrdersQuery } from '../../hooks';
import {
  OrderCustomerIcon,
  OrderPaymentIcon,
  OrderSourceIcon,
  OrderUserIcon,
} from '../Icons';
import {
  AddModal,
  DesktopTable,
  LinkDescription,
  ModalButton,
  OrderCard,
  OrderDescription,
  RequestHandler,
} from '../index';

export const AddOrderModal = ({ isOpen, onClose }) => {
  const { searchValue, changeSearchValue } = useSearchContext();
  const { values, setFieldValue } = useFormikContext();

  const { data, ...queryProps } = useOrdersQuery({
    searchValue,
    skip: !searchValue,
  });

  const handleSet = (item) => {
    setFieldValue('order', item);
    onClose();
  };

  return (
    <AddModal
      isOpen={isOpen}
      onClose={onClose}
      searchValue={searchValue}
      onChangeSearchValue={changeSearchValue}
      queryProps={queryProps}
      title='app.addOrder'
      withScan={false}
    >
      <RequestHandler {...queryProps}>
        {IS_DESKTOP_SCREEN ? (
          <>
            <DesktopTable inModal>
              <DesktopTable.Title />
              <DesktopTable.Title />
              <DesktopTable.Title />
              <DesktopTable.Title width={80} />
              <DesktopTable.Body>
                {data?.orders?.map((item) => (
                  <DesktopTable.Row key={item.id}>
                    <DesktopTable.Cell>
                      <LinkDescription
                        date={item?.createdAt}
                        link={`/sales/order/${item?.id}`}
                        intlId={'№ ' + item?.id}
                        vertically
                      />
                    </DesktopTable.Cell>
                    <DesktopTable.Cell>
                      <Flex direction={'column'} align={'flex-start'}>
                        <OrderDescription
                          icon={<OrderCustomerIcon />}
                          intlId={`${item?.customer?.firstName} ${item?.customer?.lastName}`}
                        />
                        <OrderDescription
                          icon={<OrderUserIcon />}
                          intlId={`${item?.user?.firstName} ${item?.user?.lastName}`}
                        />
                      </Flex>
                    </DesktopTable.Cell>
                    <DesktopTable.Cell>
                      <Flex direction={'column'} align={'flex-start'}>
                        <OrderDescription
                          icon={<OrderSourceIcon />}
                          intlId={item?.source?.name}
                        />
                        <OrderDescription
                          icon={<OrderPaymentIcon />}
                          intlId={item?.paymentType?.name}
                        />
                      </Flex>
                    </DesktopTable.Cell>
                    <ModalButton
                      item={item}
                      addedItemsIds={values?.order?.id}
                      handleSet={handleSet}
                    />
                  </DesktopTable.Row>
                ))}
              </DesktopTable.Body>
            </DesktopTable>
          </>
        ) : (
          data?.orders?.map((item, index) => (
            <OrderCard key={index} item={item} handleSet={handleSet} />
          ))
        )}
      </RequestHandler>
    </AddModal>
  );
};

AddOrderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
