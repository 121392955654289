import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const PackagingTemplatesIcon = ({ size = 24, style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' style={{ ...style }}>
      <Path
        d='M21.079 0.399903H0.920195C0.521795 0.399903 0.200195 0.720303 0.200195 1.1199V3.9999H21.8002V1.1199C21.8002 1.02525 21.7815 0.931526 21.7453 0.844094C21.709 0.756661 21.6559 0.677235 21.5889 0.610362C21.5219 0.543488 21.4424 0.490478 21.3549 0.454365C21.2674 0.418252 21.1736 0.399746 21.079 0.399903ZM1.4002 17.9199C1.4002 18.8439 2.155 19.5999 3.07899 19.5999H18.919C19.843 19.5999 20.599 18.8427 20.599 17.9199V5.1999H1.4002V17.9199ZM7.4002 7.5999H14.6002V9.9999H7.4002V7.5999Z'
        fill='white'
      />
    </Svg>
  );
};

PackagingTemplatesIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
