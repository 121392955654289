import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
    borderRadius: 3,
    height: 32,
    paddingHorizontal: 16,
    width: '100%',
  },
  input: {
    borderRadius: 3,
    width: '85%',
  },
});
