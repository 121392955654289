import React, { Component } from 'react';
import { View, Text } from 'react-native';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { format } from 'date-fns';

import { MaterialIcons } from '@expo/vector-icons';

import styles from '../Style';
import stylesGlobal from '../../../../globalStyles';

class CustomersRowMobile extends Component {
  render() {
    const { id, firstName, createdAt, lastName, phones, emails, intl } = this.props;

    const parseDate = new Date(parseInt(createdAt));
    const formattedDate = format(parseDate, 'dd.MM.yyyy');

    return (
      <View style={[styles.item, stylesGlobal.wrapper]}>
        <View style={styles.top}>
          <View style={styles.topLeft}>
            {firstName || lastName ? (
              <Text style={styles.name}>{`${firstName} ${lastName}`}</Text>
            ) : null}
            {createdAt ? (
              <Text style={styles.role}>{`Регистрация - ${formattedDate}`}</Text>
            ) : null}
          </View>
        </View>
        <View style={styles.bottom}>
          {this.showEmails(emails, intl)}
          {this.showPhones(phones, intl)}
        </View>
      </View>
    );
  }

  showEmails = (emails, intl) => {
    if (!emails.length) {
      return <Text>{intl.formatMessage({ id: 'app.no' })}</Text>;
    }

    let result = `${emails[0].email}`;

    if (emails.length > 1) {
      return (
        <View style={styles.contact}>
          <MaterialIcons name='email' size={16} color='#4F4F4F' />
          <Text style={styles.contactText}>{`${result} ${emails.length - 1}`}</Text>
        </View>
      );
    }

    return (
      <View style={styles.contact}>
        <MaterialIcons name='email' size={16} color='#4F4F4F' />
        <Text style={styles.contactText}>{result}</Text>
      </View>
    );
  };

  showPhones = (phones, intl) => {
    if (!phones.length) {
      return <Text>{intl.formatMessage({ id: 'app.no' })}</Text>;
    }

    let result = `${phones[0].phone}`;

    if (phones.length > 1) {
      return (
        <View style={styles.contact}>
          <MaterialIcons name='call' size={16} color='#4F4F4F' />
          <Text style={styles.contactText}>{`${result} ${phones.length - 1}`}</Text>
        </View>
      );
    }

    return (
      <View style={styles.contact}>
        <MaterialIcons name='call' size={16} color='#4F4F4F' />
        <Text style={styles.contactText}>{result}</Text>
      </View>
    );
  };
}

CustomersRowMobile.propTypes = {
  id: PropTypes.string.isRequired,
  lastName: PropTypes.string,
  firstName: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  phones: PropTypes.array,
  emails: PropTypes.array,
};

export default injectIntl(CustomersRowMobile);
