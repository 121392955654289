/* eslint-disable react-native/no-raw-text */
/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/prop-types */
import { Picker } from '@react-native-picker/picker';
import PropTypes from 'prop-types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { View } from 'react-native';

import { isWeb, theme } from '../../constants';
import { FieldWrapper } from '../FieldWrapper/FieldWrapper';
import styles from './style';

const CurrencyIdSelect = ({ name, label, currenciesData, style, isLight, ...props }) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const field = Number(watch(name));
  const hasError = errors[name];
  const intl = useIntl();

  return (
    <FieldWrapper
      width={{ base: 'auto', lg: '30%' }}
      mb={{ base: 'md', sm: 'lg' }}
      style={style}
      isLight={isLight}
      isInvalid={hasError}
      label={label}
      errorMessageText={errors[name]?.message}
      {...props}
    >
      {isWeb ? (
        <select
          name={name}
          label={label}
          style={{
            fontSize: 14,
            backgroundColor: 'transparent',
            paddingTop: 8,
            paddingBottom: 6,
            paddingRight: 2,
            paddingLeft: 5,
            borderTopWidth: 0,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderBottomWidth: 1,
            borderBottomColor: '#333333',
            color: '#4F4F4F',
          }}
          value={field}
          onChange={(event) => setValue(name, event.target.value)}
        >
          <option style={{ display: 'none', color: theme.colors.gray }} value={''}>
            {intl.formatMessage({ id: 'app.currency' })}
          </option>
          {currenciesData.currencies.map(({ id, code }) => {
            return (
              <option id={id} key={id} value={id}>
                {code}
              </option>
            );
          })}
        </select>
      ) : (
        <View style={styles.view}>
          <Picker
            style={styles.picker}
            mode='dropdown'
            selectedValue={field}
            onValueChange={(selectValue) => setValue(name, selectValue)}
          >
            {currenciesData.currencies.map(({ id, code }) => {
              return (
                //eslint-disable-next-line
                <Picker.Item value={id} label={code.toString()} />
              );
            })}
          </Picker>
        </View>
      )}
    </FieldWrapper>
  );
};

CurrencyIdSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  currenciesData: PropTypes.arrayOf(PropTypes.array).isRequired,
};

export default CurrencyIdSelect;
