import { Box, FormControl } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { FormControlError } from '../FormControlError/FormControlError';
import { FormControlLabel } from '../FormControlLabel/FormControlLabel';

export const FieldWrapper = memo(
  ({
    label,
    isLight,
    errorMessageText,
    isInvalid,
    children,
    style,
    width,
    mb,
    pl,
    pr,
    height,
    ...props
  }) => {
    return (
      <Box
        pl={pl}
        pr={pr}
        style={style}
        width={{ base: width, md: width }}
        maxWidth={width}
        mb={{ base: 'md', sm: mb ?? 'md' }}
        {...props}
      >
        <FormControl isInvalid={isInvalid}>
          <FormControlLabel label={label} isLight={isLight} />
          {children}
          {errorMessageText && (
            <FormControlError height={height} errorMessageText={errorMessageText} />
          )}
        </FormControl>
      </Box>
    );
  },
);

FieldWrapper.displayName = 'FieldWrapper';
FieldWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isInvalid: PropTypes.bool,
  label: PropTypes.string,
  height: PropTypes.string,
  isLight: PropTypes.bool,
  errorMessageText: PropTypes.string,
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  pl: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  pr: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
