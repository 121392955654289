import React from 'react';
import { View } from 'react-native';
import { format } from 'date-fns';
import { TextCopy } from '../../../../components/UI';
import { useIntl } from '../../../../hooks';

const CustomersRowDesktop = ({ id, firstName, lastName, createdAt, phones, emails }) => {
  const intl = useIntl();
  const userName =
    firstName && lastName
      ? `${firstName ? firstName : ''} ${lastName ? lastName : ''}`
      : null;
  const parseDate = new Date(parseInt(createdAt));
  return (
    <>
      <View style={{ width: '10%' }}>
        <TextCopy title={id} />
      </View>
      <View style={{ width: '25%' }}>
        <TextCopy title={userName || intl.formatMessage({ id: 'app.no' })} />
      </View>
      <View style={{ width: '20%' }}>
        <TextCopy title={format(parseDate, 'dd.MM.yyyy HH:mm')} />
      </View>
      <View style={{ width: '20%' }}>
        <TextCopy
          title={
            phones.length
              ? `${phones[0].phone} ${phones.length > 1 ? `+${phones.length - 1}` : ''}`
              : intl.formatMessage({ id: 'app.no' })
          }
        />
      </View>
      <View style={{ width: '25%' }}>
        <TextCopy
          title={
            emails.length
              ? `${emails[0].email} ${emails.length > 1 ? `+${emails.length - 1}` : ''}`
              : intl.formatMessage({ id: 'app.no' })
          }
        />
      </View>
    </>
  );
};

export default CustomersRowDesktop;
