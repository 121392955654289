import { Flex } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { TouchableOpacity } from 'react-native';

import { BoxWrapper, Typography } from '../../../../components';

const ProductMobileItem = ({
  id,
  articul,
  name,
  weight,
  costPrice,
  MSRprice,
  price,
  quantity,
  hasScope,
  onItemPress,
}) => {
  return (
    <TouchableOpacity onPress={() => onItemPress(id)}>
      <BoxWrapper>
        <Typography mb='md' color='primary.default'>
          {name}
        </Typography>
        <Flex mb='md' justify='space-between'>
          <Typography intlId='app.sku' color='gray.main' />
          <Typography>{articul}</Typography>
        </Flex>
        <Flex mb='md' justify='space-between'>
          <Typography intlId='app.weightKg' color='gray.main' />
          <Typography>{weight}</Typography>
        </Flex>
        {hasScope && (
          <Flex mb='md' justify='space-between'>
            <Typography intlId='app.costPrice' color='gray.main' />
            <Typography>{costPrice}</Typography>
          </Flex>
        )}
        <Flex mb='md' justify='space-between'>
          <Typography intlId='app.MSRprice' color='gray.main' />
          <Typography>{MSRprice}</Typography>
        </Flex>
        <Flex mb='md' justify='space-between'>
          <Typography intlId='app.price' color='gray.main' />
          <Typography>{price}</Typography>
        </Flex>
        <Flex justify='space-between'>
          <Typography intlId='app.availabilityCount' color='gray.main' />
          <Typography>{quantity}</Typography>
        </Flex>
      </BoxWrapper>
    </TouchableOpacity>
  );
};

export default memo(ProductMobileItem);

ProductMobileItem.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  articul: PropTypes.string.isRequired,
  weight: PropTypes.number,
  quantity: PropTypes.number,
  costPrice: PropTypes.number,
  MSRprice: PropTypes.number,
  price: PropTypes.number,
  onItemPress: PropTypes.func,
  hasScope: PropTypes.bool,
};
