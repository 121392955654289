import React from 'react';
import { Text } from 'react-native';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { DesktopTable, Layout, RequestHandler } from '../../../components';
import { DATA_REQUEST_LIMIT } from '../../../constants';
import { useSearchContext } from '../../../context';
import { useGetCustomParams, useIntl } from '../../../hooks';

const COLUMNS = [{ intlId: 'app.name' }, { intlId: 'app.type' }];

const CustomProductParams = () => {
  const history = useHistory();
  const intl = useIntl();

  const { debouncedSearchValue } = useSearchContext();

  const { data, fetchMore, ...queryProps } = useGetCustomParams();

  const fetchMoreData = async (resolve, reject) => {
    await fetchMore({
      variables: {
        search: debouncedSearchValue,
        offset: data.customParams?.length,
        limit: DATA_REQUEST_LIMIT,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          reject && reject();
          return prev;
        }

        resolve && resolve();

        return {
          customParams: [...prev?.customParams, ...fetchMoreResult?.customParams],
        };
      },
    });
  };
  const handleEditButtonClick = (id) => {
    history.push(`/settings/customparam/${id}`);
  };

  const handleClickAddButton = () => {
    history.push('/settings/customparam/');
  };
  return (
    <Layout onActionButtonPress={handleClickAddButton}>
      <RequestHandler {...queryProps}>
        <DesktopTable fetchMoreData={fetchMoreData}>
          <DesktopTable.Head>
            {COLUMNS?.map(({ intlId }) => (
              <DesktopTable.Title key={intlId} intlId={intlId} />
            ))}
          </DesktopTable.Head>
          <DesktopTable.Body>
            {data?.customParams?.map((item) => (
              <DesktopTable.Row
                key={item?.id}
                onEditButtonClick={() => handleEditButtonClick(item.id)}
              >
                <DesktopTable.Cell>{item.name}</DesktopTable.Cell>
                <DesktopTable.Cell>
                  <Text>{`${intl.formatMessage({
                    id: `customparam.${item.type}`,
                  })}`}</Text>
                </DesktopTable.Cell>
              </DesktopTable.Row>
            ))}
          </DesktopTable.Body>
        </DesktopTable>
      </RequestHandler>
    </Layout>
  );
};

export default CustomProductParams;
