import { Box } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { TouchableOpacity } from 'react-native';

import { BoxWrapper, Typography } from '../../../components';

const DeliveryMethodMobileItem = ({ id, name, onItemPress }) => {
  return (
    <BoxWrapper>
      <TouchableOpacity onPress={() => onItemPress(id)}>
        <Box mr='md'>
          <Typography>{name}</Typography>
        </Box>
      </TouchableOpacity>
    </BoxWrapper>
  );
};

export default memo(DeliveryMethodMobileItem);

DeliveryMethodMobileItem.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onItemPress: PropTypes.func,
};
