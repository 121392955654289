import PropTypes from 'prop-types';
import React from 'react';

import {
  BoxWrapper,
  DesktopTable,
  MobileTable,
  TextLink,
  Typography,
} from '../../../../components';
import { IS_DESKTOP_SCREEN } from '../../../../constants';
import stylesGlobal from '../../../../globalStyles';
import { formatDate } from '../../../../utils';
import AvailabilityMobileItem from './AvailabilityMobileItem/AvailabilityMobileItem';

const COLUMNS_HAS_SCOPE = [
  { name: 'app.box', id: 'id' },
  { name: 'app.shelving', id: 'st' },
  { name: 'app.room', id: 'room' },
  { name: 'app.purchase', id: 'buy' },
  { name: 'app.condition', id: 'status' },
  { name: 'app.deliveryPrice', id: 'delivery' },
  { name: 'app.costPrice', id: 'price' },
  { name: 'app.quantity', id: 'quantity' },
];

const COLUMNS = [
  { name: 'app.box', id: 'id' },
  { name: 'app.shelving', id: 'st' },
  { name: 'app.room', id: 'room' },
  { name: 'app.purchase', id: 'buy' },
  { name: 'app.condition', id: 'status' },
  { name: 'app.quantity', id: 'quantity' },
];

export const Availability = ({ availabilities, hasScope }) => {
  const getCostPrice = (deliveryPrice, price) => {
    return `${deliveryPrice + price} $`;
  };

  const getDateWithNumber = (date, number) => {
    return `№${number} от ${formatDate(date)}`;
  };

  return (
    <>
      {IS_DESKTOP_SCREEN ? (
        <BoxWrapper style={stylesGlobal.form}>
          <Typography intlId='app.availability' variant='heading' mb='xs' />
          <DesktopTable editable={false}>
            <DesktopTable.Head>
              {(hasScope ? COLUMNS_HAS_SCOPE : COLUMNS).map((column) => (
                <DesktopTable.Title key={column.id} intlId={column.name} />
              ))}
            </DesktopTable.Head>
            <DesktopTable.Body>
              {availabilities?.map((item, index) => (
                <DesktopTable.Row key={index}>
                  <DesktopTable.Cell>
                    <TextLink
                      link={`/warehouse/box/${item?.place?.id}`}
                      intlId={item?.place?.name}
                    />
                  </DesktopTable.Cell>
                  <DesktopTable.Cell>{item?.place?.location[0]?.name}</DesktopTable.Cell>
                  <DesktopTable.Cell>{item?.place?.location[1]?.name}</DesktopTable.Cell>
                  <DesktopTable.Cell>
                    <TextLink
                      link={`/purchase/purchase/${item?.purchase?.id}`}
                      intlId={getDateWithNumber(
                        +item?.purchase?.purchaseTime,
                        item?.purchase?.id,
                      )}
                    />
                  </DesktopTable.Cell>
                  <DesktopTable.Cell>{item?.condition?.name}</DesktopTable.Cell>
                  {hasScope && (
                    <>
                      <DesktopTable.Cell>
                        {item?.purchase?.deliveryPrice + ' $'}
                      </DesktopTable.Cell>
                      <DesktopTable.Cell>
                        {getCostPrice(
                          +item?.purchase?.deliveryPrice,
                          +item?.purchase?.price,
                        )}
                      </DesktopTable.Cell>
                    </>
                  )}
                  <DesktopTable.Cell>{item?.quantity}</DesktopTable.Cell>
                </DesktopTable.Row>
              ))}
            </DesktopTable.Body>
          </DesktopTable>
        </BoxWrapper>
      ) : (
        <BoxWrapper>
          <Typography intlId='app.availability' variant='heading' />
          <MobileTable
            autoHeight
            data={availabilities}
            renderItem={(item) => (
              <AvailabilityMobileItem
                boxId={item?.place?.id}
                box={item?.place?.name}
                shelving={item?.place?.location[0]?.name}
                room={item?.place?.location[1]?.name}
                purchaseId={item?.purchase?.id}
                purchase={getDateWithNumber(
                  +item?.purchase?.purchaseTime,
                  item?.purchase?.id,
                )}
                condition={item?.condition?.name}
                deliveryPrice={item?.purchase?.deliveryPrice + ' $'}
                costPrice={getCostPrice(
                  +item?.purchase?.deliveryPrice,
                  +item?.purchase?.price,
                )}
                quantity={item?.quantity}
                hasScope={hasScope}
              />
            )}
          />
        </BoxWrapper>
      )}
    </>
  );
};
Availability.propTypes = {
  availabilities: PropTypes.arrayOf(
    PropTypes.shape({
      condition: PropTypes.object,
      id: PropTypes.number,
      place: PropTypes.object,
      purchase: PropTypes.object,
      quantity: PropTypes.number,
    }),
  ),
  hasScope: PropTypes.bool,
};
