/* eslint-disable react-native/no-raw-text */
import { Avatar as NBAvatar } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

export const Avatar = ({ uri, firstName, lastName, bgColor }) => {
  if (uri) return <NBAvatar source={{ uri }} bgColor={bgColor} />;

  return (
    <NBAvatar bgColor={bgColor}>{`${firstName ? firstName[0] : ''}${
      lastName ? lastName[0] : ''
    }`}</NBAvatar>
  );
};

Avatar.propTypes = {
  uri: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  bgColor: PropTypes.string,
};
