/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { Text, TouchableHighlight } from 'react-native';
import { Button as PaperButton } from 'react-native-paper';

// eslint-disable-next-line react/prop-types
const Button = ({ onPress, mode = 'contained', color, children, ...otherProps }) => {
  return mode === 'thin' ? (
    <TouchableHighlight onPress={onPress} {...otherProps}>
      <Text style={[{ fontSize: 16 }, color && { color: color }]}>{children}</Text>
    </TouchableHighlight>
  ) : (
    <PaperButton mode={mode} onPress={onPress} {...otherProps}>
      <Text style={color && { color: color }}>{children}</Text>
    </PaperButton>
  );
};

export default Button;
