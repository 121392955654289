import React from 'react';
import { useFormContext } from 'react-hook-form';

import { QuerySelectWithSearchHookForm } from '../../../../components/QuerySelectWithSearch/QuerySelectWithSearchHookForm';
import { GET_PRICE_TAGS } from '../../../../graphql';
import { transformSelectOptions } from '../../../../utils';

const PriceTags = () => {
  const { watch, setValue } = useFormContext();
  const productPriceTags = watch('productPriceTags');

  const initialOption = transformSelectOptions({
    data: productPriceTags?.map(({ id, name }) => ({
      id,
      name,
    })),
    value: 'id',
    label: 'name',
  });

  const handleCreateOption = (createdOption) => {
    setValue('productPriceTags', [...productPriceTags, { name: createdOption }]);
  };

  const onChangeTagSelecte = (option) => {
    setValue(
      'productPriceTags',
      option?.map(({ value, label }) => ({ id: value, name: label })),
    );
  };

  return (
    <QuerySelectWithSearchHookForm
      isMulti
      width={'100%'}
      label='app.priceTags'
      valueProperty={'id'}
      labelProperty={'name'}
      placeholderIntlId='app.enterValue'
      fieldName='productPriceTags'
      initialOption={initialOption}
      graphqlName={'priceTags'}
      graphql={GET_PRICE_TAGS}
      onChange={onChangeTagSelecte}
      isCreateble
      handleCreateOption={handleCreateOption}
      mb={0}
      pr={0}
      pl={0}
      isValChange
    />
  );
};

export default PriceTags;
