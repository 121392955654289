import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const SourcesOrdersIcon = ({ size = 24, style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' style={{ ...style }}>
      <Path
        d='M20.6719 6.37734V3.375C20.6719 2.96016 20.3367 2.625 19.9219 2.625H4.07814C3.6633 2.625 3.32814 2.96016 3.32814 3.375V6.37734C2.93673 6.40078 2.62501 6.72656 2.62501 7.125V10.2117C2.62351 10.779 2.73838 11.3406 2.96251 11.8617C3.0633 12.1008 3.18751 12.3258 3.32814 12.5391V20.625C3.32814 21.0375 3.6633 21.375 4.07814 21.375H10.5V17.25H13.5V21.375H19.9219C20.3367 21.375 20.6719 21.0398 20.6719 20.625V12.5391C20.8147 12.3252 20.9371 12.0985 21.0375 11.8617C21.2602 11.3391 21.375 10.7836 21.375 10.2117V7.125C21.375 6.72656 21.0633 6.40078 20.6719 6.37734ZM18.9844 19.6898H15V16.5C15 16.0852 14.6649 15.75 14.25 15.75H9.75001C9.33517 15.75 9.00001 16.0852 9.00001 16.5V19.6898H5.01564V14.0133C5.08361 14.0461 5.15392 14.0789 5.22658 14.107C5.74923 14.3273 6.3047 14.4375 6.87658 14.4375C7.44845 14.4375 8.00158 14.3273 8.52658 14.107C8.85001 13.9711 9.1547 13.7977 9.43361 13.5891C9.4383 13.5867 9.44298 13.5867 9.44767 13.5891C9.72744 13.7985 10.0321 13.9725 10.3547 14.107C10.8774 14.3273 11.4328 14.4375 12.0047 14.4375C12.5766 14.4375 13.1297 14.3273 13.6547 14.107C13.9781 13.9711 14.2828 13.7977 14.5617 13.5891C14.5664 13.5867 14.5711 13.5867 14.5758 13.5891C14.8556 13.7985 15.1602 13.9725 15.4828 14.107C16.0055 14.3273 16.561 14.4375 17.1328 14.4375C17.7047 14.4375 18.2578 14.3273 18.7828 14.107C18.8531 14.0766 18.9235 14.0461 18.9938 14.0133V19.6898H18.9844ZM18.9844 6.375H5.01564V4.3125H18.9844V6.375Z'
        fill='white'
      />
    </Svg>
  );
};

SourcesOrdersIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
