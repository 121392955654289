import { MaterialIcons } from '@expo/vector-icons';
import { Box, Center, Flex, Icon } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { ConditionNumber } from '../../screens/sales/Order/Properties';
import { getDateWithNumber } from '../../utils/getDateWithNumber';
import { ImageView, Price, TextLink, Typography } from '../index';

export const ProductCard = ({
  product,
  isProductAdded,
  isNotAvailable,
  onPress,
  inOrder = false,
  isAnnul = false,
  hasScopes = false,
}) => {
  const intl = useIntl();
  const getColor = () => {
    if (isProductAdded) return 'success';
    if (isNotAvailable) return '#E0E0E0';
    return 'primary.default';
  };

  const getIcon = () => {
    if (isProductAdded) return 'check-circle';
    return 'add-circle';
  };

  return (
    <Flex
      mb='sm'
      bgColor='white'
      borderRadius='lg'
      borderColor='gray.main'
      borderWidth={1}
      justify='space-between'
      overflow='hidden'
    >
      <Box p='md' flex={1}>
        <Flex mb={inOrder ? 'md' : 'auto'}>
          <ImageView uri={product?.photo?.path} />
          <Box ml='md'>
            <Typography color='primary.default' fontSize='13px'>
              {product?.name || product?.purchase?.product?.name}
            </Typography>
            <Typography fontSize='12px'>
              {product?.sku || product?.purchase?.product?.sku}
            </Typography>
            {isAnnul && (
              <>
                <Typography fontSize='12px'>
                  <Flex>
                    {hasScopes ? (
                      <TextLink
                        fontSize='xs'
                        link={`/purchase/purchase/${product?.purchase?.id}`}
                      >
                        {getDateWithNumber(product?.purchase, intl)}
                      </TextLink>
                    ) : (
                      <Typography fontSize='xs'>
                        {getDateWithNumber(product?.purchase, intl)}
                      </Typography>
                    )}
                  </Flex>
                </Typography>
                <Typography fontSize='12px'>
                  <ConditionNumber condition={product?.condition} />
                </Typography>
              </>
            )}
          </Box>
        </Flex>
        {inOrder && (
          <Flex>
            <Typography
              intlId='app.available'
              fontSize='13px'
              color='gray.main'
              mr='md'
            />
            <Typography fontSize='13px' mr='md'>
              {`${product?.quantity} шт.`}
            </Typography>
            <Typography intlId='app.by' fontSize='13px' color='gray.main' mr='md'>
              {product?.quantity}
            </Typography>
            <Price value={product?.price} />
          </Flex>
        )}
      </Box>
      <TouchableOpacity
        disabled={isProductAdded || isNotAvailable}
        onPress={() => onPress(product)}
        style={styles.button}
      >
        <Center width='58px' flex={1} bgColor={getColor()}>
          <Icon as={<MaterialIcons name={getIcon()} />} size='sm' color='white' />
        </Center>
      </TouchableOpacity>
    </Flex>
  );
};

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  isProductAdded: PropTypes.bool.isRequired,
  isNotAvailable: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
  inOrder: PropTypes.bool,
  isAnnul: PropTypes.bool,
  hasScopes: PropTypes.boolean,
};

const styles = StyleSheet.create({
  button: {
    alignSelf: 'stretch',
    minWidth: 58,
  },
});
