import { extendTheme } from 'native-base';

import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { button } from './components/button';
import { flex } from './components/flex';
import { input } from './components/input';
import { link } from './components/link';
import { modalContent } from './components/modal';
import { select } from './components/select';
import { spinner } from './components/spinner';
import { text } from './components/text';
import { fonts } from './fonts';
import { fontSizes } from './fontSizes';
import { rounded } from './rounded';
import { space } from './space';

export const theme = extendTheme({
  colors,
  fonts,
  fontSizes,
  space,
  breakpoints,
  radii: rounded,
  components: {
    ...text,
    ...button,
    ...input,
    ...select,
    ...modalContent,
    ...flex,
    ...spinner,
    ...link,
  },
});
