import { StyleSheet } from 'react-native';

import { theme } from '../../constants';

export const styles = StyleSheet.create({
  button: { color: theme.colors.white, width: 175 },
  description: { color: theme.colors.dark, fontSize: 14 },
  heading: { fontSize: 16, textAlign: 'center' },
  slide: {
    backgroundColor: theme.colors.blueDark,
    borderRadius: 6,
    height: 12,
    width: 12,
  },
  step: { color: theme.colors.white, fontSize: 24 },
  title: { color: theme.colors.dark, fontSize: 18, textAlign: 'center' },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    paddingBottom: 24,
    paddingHorizontal: 16,
  },
  wrapperSlider: { paddingHorizontal: 16 },
  wrapperStep: {
    alignItems: 'center',
    backgroundColor: theme.colors.blueDark,
    borderRadius: 20,
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 40,
  },
  wrapperSteps: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 156,
  },
});
