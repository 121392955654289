import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const CommentsIcon = ({ size = 24, style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' style={{ ...style }}>
      <Path
        d='M20 2H4C2.897 2 2 2.894 2 3.992V16.008C2 17.106 2.897 18 4 18H7V22L13.351 18H20C21.103 18 22 17.106 22 16.008V3.992C21.9984 3.46279 21.7869 2.95583 21.412 2.58237C21.037 2.20891 20.5292 1.99947 20 2ZM14 13H7V11H14V13ZM17 9H7V7H17V9Z'
        fill='white'
      />
    </Svg>
  );
};

CommentsIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
