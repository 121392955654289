/* eslint-disable react-native/no-color-literals */
// import { RelativeTimeFormat } from 'intl';
import { StyleSheet } from 'react-native';

import { IS_APP, theme } from '../../constants';

export default StyleSheet.create({
  dropdown: {
    backgroundColor: theme.colors.white,
    borderRadius: 5,
    elevation: 3,
    left: 0,
    marginTop: 6,
    maxHeight: 250,
    overflow: IS_APP ? 'hidden' : 'auto',
    padding: 10,
    position: 'relative',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    top: 0,
  },
  dropdownWrapper: {
    display: IS_APP ? 'flex' : 'block',
  },
  dropdownWrapperHide: {
    display: 'none',
  },

  overlay: {
    height: '100%',
    width: '100%',
  },
});
