/* eslint-disable */
import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { Text, TextInput, View } from 'react-native';
import { HelperText } from 'react-native-paper';

import { isWeb } from '../../../constants';
import stylesGlobal from '../../../globalStyles';
import { useMediaQuery } from '../../../hooks';
import styles from './styles';

const TextareaAutosize = isWeb ? require('react-textarea-autosize').default : null;

const Input = ({
  name = '',
  label,
  hidden,
  isDisabled,
  isLogin,
  appWidth = '100%',
  desktopWidth = '100%',
  value,
  inputStyle = {},
  autoSize = false,
  ...otherProps
}) => {
  const isDesktop = useMediaQuery('isDesktop');
  const [field, meta, helpers] = useField(name);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const length = field.value?.toString().length;
    if (length) setWidth(length);
  }, [field.value]);

  const { multiline, placeholder, onBlur } = field;
  const { error, touched } = meta;
  const { setValue } = helpers;

  return (
    <View
      style={[
        styles.field,
        hidden && styles.hidden,
        isDesktop ? { width: appWidth } : { width: desktopWidth },
        autoSize && { width: width * 15 },
        { minWidth: 50 },
      ]}
    >
      {label ? (
        <Text style={[stylesGlobal.label, isLogin && { color: 'white' }]}>{label}</Text>
      ) : null}
      {multiline && isWeb ? (
        <TextareaAutosize
          style={{
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            isDesktop: '100%',
            paddingTop: 2,
            paddingBottom: 8,
            paddingRight: 5,
            paddingLeft: 5,
            outline: 'none',
            resize: 'none',
            ...inputStyle,
          }}
          rows='6'
          name={name}
          value={value || field.value}
          onBlur={onBlur}
          placeholder={placeholder}
          {...otherProps}
          wrap='hard'
          onChange={setValue}
        />
      ) : (
        <TextInput
          name={name}
          onBlur={onBlur}
          placeholder={placeholder}
          style={[
            styles.input,
            isDisabled && styles.disabled,
            error && touched && styles.hasError,
            isWeb && { outline: 'none' },
            isLogin && styles.inputLogin,
            { ...inputStyle },
          ]}
          onChangeText={setValue}
          value={value || field.value}
          // onContentSizeChange={(e) => console.log(e.nativeEvent.contentSize.width)}
          {...otherProps}
        />
      )}
      {error && touched && (
        <HelperText type='error' visible={error} style={styles.error}>
          {error}
        </HelperText>
      )}
    </View>
  );
};

export default Input;
