import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const ExchangeIcon = ({ size = 19, style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 18 19' style={{ ...style }}>
      <Path
        d='M9 17C4.85775 17 1.5 13.6423 1.5 9.5C1.5 5.35775 4.85775 2 9 2C13.1423 2 16.5 5.35775 16.5 9.5C16.5 13.6423 13.1423 17 9 17ZM9 7.25H6V8.75H12.75L9 5V7.25ZM5.25 10.25L9 14V11.75H12V10.25H5.25Z'
        fill='#47C8FF'
      />
    </Svg>
  );
};

ExchangeIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
