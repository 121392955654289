import { Box, Flex } from 'native-base';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { IS_DESKTOP_SCREEN } from '../../constants';
import { useNotificationsContext, useSearchContext } from '../../context';
import { useGetPurchasesInAcceptanceQuery } from '../../hooks';
import {
  AddModal,
  Button,
  DesktopTable,
  ImageView,
  ModalButton,
  ProductCard,
  RequestHandler,
  Typography,
} from '../index';

export const AddAcceptanceModal = ({
  onAddProduct,
  addedProducts = [],
  forwardingIdViaParcel,
  getMaxProductQuantity,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { searchValue, changeSearchValue } = useSearchContext();

  const { showNotification } = useNotificationsContext();

  const { data, ...queryProps } = useGetPurchasesInAcceptanceQuery(forwardingIdViaParcel);

  const addedProductsIds = addedProducts?.map((el) => el?.id);

  const isProductAdded = (id) => addedProductsIds?.includes(id);

  const uniqueProducts = [...new Set(data?.purchases?.map((el) => el?.product))];

  const onAddProductHandler = (product) => {
    onAddProduct(product);
  };

  const isAvailable = (productId) => {
    return getMaxProductQuantity(productId) > 0;
  };

  const handlerButtonPress = () => {
    if (!forwardingIdViaParcel) {
      showNotification({
        message: 'app.chooseTrackingCode',
        type: 'error',
        title: 'snackbar.error',
      });
    } else {
      setIsModalOpen(true);
    }
  };

  const handleModalClose = () => {
    changeSearchValue('');
    setIsModalOpen(false);
  };

  return (
    <Box>
      <Flex justifyContent={'flex-end'}>
        <Button
          intlId={'app.addProduct'}
          onPress={handlerButtonPress}
          minWidth={'120px'}
          size='sm'
        />
      </Flex>
      <AddModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        searchValue={searchValue}
        onChangeSearchValue={changeSearchValue}
        queryProps={queryProps}
        title='app.addProduct'
      >
        <RequestHandler {...queryProps}>
          {IS_DESKTOP_SCREEN ? (
            <DesktopTable editable={false} inModal>
              <DesktopTable.Head>
                <DesktopTable.Title width={50} />
                <DesktopTable.Title intlId={'app.title'} textAlign={'left'} />
                <DesktopTable.Title width={10} />
                <DesktopTable.Title width={80} />
              </DesktopTable.Head>
              <DesktopTable.Body>
                {uniqueProducts.map((product, index) => (
                  <DesktopTable.Row key={index}>
                    <DesktopTable.Cell width={50}>
                      <ImageView uri={product?.photo?.path} />
                    </DesktopTable.Cell>
                    <DesktopTable.Cell textAlign={'left'}>
                      <Box>
                        <Typography color='primary.default'>{product?.name}</Typography>
                        <Typography fontSize='xs'>{product?.sku}</Typography>
                      </Box>
                    </DesktopTable.Cell>
                    <DesktopTable.Cell>
                      <Typography />
                    </DesktopTable.Cell>
                    <ModalButton
                      withQuantity
                      isAvailable={isAvailable(product.id)}
                      disableText={'app.alreadyInStock'}
                      addedItemsIds={addedProductsIds}
                      handleSet={onAddProductHandler}
                      item={product}
                    />
                  </DesktopTable.Row>
                ))}
              </DesktopTable.Body>
            </DesktopTable>
          ) : (
            <>
              {uniqueProducts.map((product, index) => (
                <ProductCard
                  key={index}
                  product={product}
                  isProductAdded={isProductAdded(product?.id)}
                  onPress={onAddProductHandler}
                />
              ))}
            </>
          )}
        </RequestHandler>
      </AddModal>
    </Box>
  );
};

AddAcceptanceModal.propTypes = {
  onAddProduct: PropTypes.func.isRequired,
  addedProducts: PropTypes.array.isRequired,
  forwardingIdViaParcel: PropTypes.number,
  getMaxProductQuantity: PropTypes.func,
};
