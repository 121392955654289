import React, { Component, createRef } from 'react';
import {
  View,
  Text,
  SafeAreaView,
  TextInput,
  FlatList,
  TouchableHighlight,
  Dimensions,
} from 'react-native';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { MaterialIcons } from '@expo/vector-icons';
import { displayWidth } from '../../../../constants/index';
import client from '../../../../client';
import findPhonesQuery from '../../../../containers/Customers/graphql/queries/findPhones';
import phonesQuery from '../../../../containers/Customers/graphql/queries/phones';
import styles from '../Style';
import { isWeb } from '../../../../constants';

class PhoneField extends Component {
  UNSAFE_componentWillMount() {
    isWeb && document.addEventListener('click', this.handleClickOutside, false);

    const { phones, value } = this.props;
    if (value.split(',').length) {
      client
        .query({
          query: phonesQuery,
          variables: { ids: value.split(',') },
        })
        .then((result) => {
          this.setState({
            phones: _.uniqBy([...phones, ...result.data.phones], 'id'),
          });
        });
    } else {
      this.setState({ phones });
    }
  }
  componentWillUnmount() {
    isWeb && document.removeEventListener('click', this.handleClickOutside, false);
  }
  state = {
    search: this.props.phone ? this.props.phone : '',
    phones: [],
    isOpen: false,
  };
  myRef = createRef();

  handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this.myRef.current);
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  };

  render() {
    const { value, label } = this.props;
    const { search, isOpen, phones } = this.state;

    const options = phones
      .map((i) => ({
        value: i.id,
        label: i.phone,
      }))
      .filter((item) => item.label.includes(search));
    const width = Dimensions.get('window').width >= displayWidth;
    return (
      <>
        <View ref={this.myRef} style={styles.input}>
          <View style={styles.items}>
            <Text style={{ color: '#4F4F4F' }}>{label}</Text>
            {options.map((item) =>
              value.split(',').includes(item.value) ? (
                <View style={styles.user} key={item.value}>
                  <Text>{item.label}</Text>
                  <MaterialIcons
                    onPress={() => this.selecting(item.value, false)}
                    name='delete-forever'
                    color='#333333'
                    size={18}
                  />
                </View>
              ) : null,
            )}
          </View>
          <TextInput
            style={[{ marginTop: 3, marginBottom: 3 }, isWeb && { outline: 'none' }]}
            onChangeText={(text) => this.handleInputChange(text)}
            value={search}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                this.selecting(
                  options.filter((item) => !value.includes(item.value))[0].value,
                  true,
                );
              }
            }}
            onFocus={() => this.setState({ isOpen: true })}
          />
          <MaterialIcons
            name={`arrow-drop-${isOpen ? 'up' : 'down'}`}
            size={25}
            color='black'
            onPress={() => this.setState({ isOpen: !isOpen })}
            style={styles.iconOpen}
          />
        </View>
        {isOpen && (
          <SafeAreaView
            style={{ flex: 1 }}
            horizontal
            showsHorizontalScrollIndicator={false}
            style={[styles.listCity, width && { position: 'absolute', bottom: -205 }]}
          >
            <FlatList
              data={options.filter((item) => !value.includes(item.value))}
              renderItem={({ item }) => (
                <TouchableHighlight
                  style={styles.city}
                  onPress={() => this.selecting(item.value, true)}
                >
                  <Text>{item.label}</Text>
                </TouchableHighlight>
              )}
              keyExtractor={(item) => item.value}
            />
          </SafeAreaView>
        )}
      </>
    );
  }

  selecting = (id, type) => {
    const { value, name, handleChange } = this.props;
    const filter = !!value ? value.split(',') : [];
    if (type) {
      filter.push(id);
      handleChange(name, filter.join(','));
      this.setState({ search: '' });
      this.setState({ isOpen: false });
    } else {
      filter.splice(filter.indexOf(id), 1);
      handleChange(name, filter.join(','));
    }
    this.setState({ isOpen: false });
  };

  handleInputChange = async (value) => {
    this.setState({ search: value });

    const phones = await client
      .query({
        query: findPhonesQuery,
        variables: { search: value },
      })
      .then((result) => {
        return result.data.findPhones;
      });
    this.setState({
      phones: _.uniqBy([...phones, ...this.state.phones], 'id'),
    });
  };
}

// function NoOptionsMessage(props) {
//     return <Text {...props.innerProps}>{props.children}</Text>;
// }

// function inputComponent({ inputRef, ...props }) {
//     return <View ref={inputRef} {...props} />;
// }

// function Control(props) {
//     return (
//         <TextField
//             fullWidth
//             InputProps={{
//                 inputComponent,
//                 inputProps: {
//                     className: props.selectProps.classes.input,
//                     inputRef: props.innerRef,
//                     children: props.children,
//                     ...props.innerProps,
//                 },
//             }}
//             {...props.selectProps.textFieldProps}
//         />
//     );
// }

// function Option(props) {
//     return (
//         <MenuItem
//             buttonRef={props.innerRef}
//             selected={props.isFocused}
//             component='div'
//             style={{
//                 fontWeight: props.isSelected ? 500 : 400,
//             }}
//             {...props.innerProps}
//         >
//             {props.children}
//         </MenuItem>
//     );
// }

// function Placeholder(props) {
//     return (
//         <Typography color='textSecondary' className={props.selectProps.classes.placeholder} {...props.innerProps}>
//             {props.children}
//         </Typography>
//     );
// }

// function SingleValue(props) {
//     return (
//         <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
//             {props.children}
//         </Typography>
//     );
// }

// function ValueContainer(props) {
//     return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
// }

// function MultiValue(props) {
//     return (
//         <Chip
//             tabIndex={-1}
//             label={props.children}
//             className={classNames(props.selectProps.classes.chip, {
//                 [props.selectProps.classes.chipFocused]: props.isFocused,
//             })}
//             onDelete={props.removeProps.onClick}
//             deleteIcon={<CancelIcon {...props.removeProps} />}
//         />
//     );
// }

// function Menu(props) {
//     return (
//         <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
//             {props.children}
//         </Paper>
//     );
// }

PhoneField.propTypes = {
  // classes: PropTypes.object.isRequired,
  // phones: PropTypes.array.isRequired,
  // value: PropTypes.string.isRequired,
  // placeholder: PropTypes.string.isRequired,
  // property: PropTypes.string.isRequired,
  // handleChange: PropTypes.func.isRequired,
  // handleSubmit: PropTypes.func.isRequired,
};

export default PhoneField;
