/* eslint-disable react-native/no-inline-styles */
import { MaterialIcons } from '@expo/vector-icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Text, TextInput, View } from 'react-native';

import { Dropdown } from '../../../../components';
import stylesInput from '../../../../components/UI/Input/styles';
import { isWeb } from '../../../../constants';
import stylesGlobal from '../../../../globalStyles';
import styles from '../Style';

export const AutoCompleteField = ({ data, label, setValue, search = '', setSearch }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (search && !isOpen) {
      setIsOpen(true);
    }
  }, [search]);

  return (
    <>
      {label && <Text style={stylesGlobal.label}>{label}</Text>}

      <Dropdown
        data={data}
        setValue={setValue}
        changeCondition={setIsOpen}
        isOpen={isOpen}
        renderItem={(item) => (
          <View style={styles.city}>
            <Text>{item.name}</Text>
          </View>
        )}
        renderInput={() => (
          <View style={[stylesInput.input, { marginBottom: 5 }]}>
            <TextInput
              style={[styles.inputCity, isWeb && { outline: 'none' }]}
              onChangeText={setSearch}
              value={search}
              autoCompleteType='off'
            />
            <MaterialIcons
              name={isOpen ? 'keyboard-arrow-up' : 'keyboard-arrow-down'}
              size={17}
              color='black'
              style={styles.iconBack}
            />
          </View>
        )}
      />
    </>
  );
};

AutoCompleteField.propTypes = {
  data: PropTypes.array,
  label: PropTypes.string,
  setValue: PropTypes.func,
  search: PropTypes.string,
  setSearch: PropTypes.func,
};
