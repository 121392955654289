import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import { DatePicker, MultiSelect } from '../../../../components/UI';
import globalStyles from '../../../../globalStyles';
import { useIntl } from '../../../../hooks';
import CustomerField from '../filterFields/CustomerField';

const FilterFieldsDesktop = ({ data, onChange, values }) => {
  const intl = useIntl();
  return (
    <View style={globalStyles.filterWeb}>
      <View>
        <Text>{intl.formatMessage({ id: 'app.id' })}</Text>
      </View>
      <View>
        <MultiSelect
          intl={intl}
          name={'manager'}
          placeholder={intl.formatMessage({
            id: 'app.manager',
          })}
          handleChange={onChange}
          value={values.manager}
        >
          {data.users.map((item) => (
            <Text key={item.id}>{`${item.firstName} ${item.lastName}`}</Text>
          ))}
        </MultiSelect>
      </View>
      <View>
        <CustomerField
          label={intl.formatMessage({ id: 'app.customer' })}
          customers={data.customers}
          name={'customer'}
          handleChange={onChange}
          value={values.customer}
        />
      </View>
      <View>
        <MultiSelect
          intl={intl}
          list={data.sources.map((item) => ({ name: item.name, id: item.id }))}
          name={'source'}
          placeholder={intl.formatMessage({
            id: 'app.source',
          })}
          handleChange={onChange}
          value={values.source}
        >
          {data.sources.map((item) => (
            <Text key={item.id}>{item.name}</Text>
          ))}
        </MultiSelect>
      </View>
      <View>
        <MultiSelect
          intl={intl}
          list={data.paymentStatuses.map((item) => ({ name: item.name, id: item.id }))}
          name={'payment'}
          placeholder={intl.formatMessage({
            id: 'app.payment',
          })}
          handleChange={onChange}
          value={values.payment}
        >
          {data.paymentStatuses.map((item) => (
            <Text key={item.id}>{item.name}</Text>
          ))}
        </MultiSelect>
      </View>
      <View>
        <MultiSelect
          intl={intl}
          name={'status'}
          placeholder={intl.formatMessage({
            id: 'app.status',
          })}
          handleChange={onChange}
          value={values.status}
        >
          {data.statuses.map((item) => (
            <Text key={item.id}>{item.name}</Text>
          ))}
        </MultiSelect>
      </View>
      <View style={{ paddingTop: 5 }}>
        <DatePicker
          name='dateFrom'
          value={values.dateFrom || new Date()}
          label={intl.formatMessage({ id: 'date.from' })}
          onChange={onChange}
        />
      </View>
      <View style={{ paddingTop: 5 }}>
        <DatePicker
          name='dateTo'
          value={values.dateTo || new Date()}
          label={intl.formatMessage({ id: 'date.to' })}
          onChange={onChange}
        />
      </View>
      <View>
        <Text>{intl.formatMessage({ id: 'app.sum' })}</Text>
      </View>
    </View>
  );
};

export default FilterFieldsDesktop;
