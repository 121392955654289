import React, { useEffect, useState } from 'react';

import {
  DesktopTable,
  Layout,
  MobileTable,
  RequestHandler,
} from '../../../../../components';
import { IS_DESKTOP_SCREEN } from '../../../../../constants';
import {
  useGetPromOrderStatusMapsQuery,
  useGetStatusesQuery,
  useHistory,
} from '../../../../../hooks';
import { getOrderStatusName } from '../../../../../utils/getOrderStatusName';
import PromOrderStatusMobileItem from './PromOrderStatusMobileItem';

const COLUMNS = [
  { intlId: 'app.name' },
  { intlId: 'orderStatuses.description' },
  { intlId: 'app.status' },
];

const PromOrderStatuses = () => {
  const navigation = useHistory();
  const [statuses, setStatuses] = useState([]);

  const { data: statusesData } = useGetStatusesQuery();

  const { data: promOrderStatusMapsData, ...promOrderStatusMapsQueryProps } =
    useGetPromOrderStatusMapsQuery();

  useEffect(() => {
    if (!statusesData) return;
    setStatuses(statusesData?.statuses);
  }, [statusesData]);

  const handleEditButtonClick = (promStatusName, statusId) => {
    navigation.push('/settings/ordersstatuses/prom/' + promStatusName + '/' + statusId);
  };

  const handleClickAddButton = () => {
    navigation.push('/settings/ordersstatuses/prom/add');
  };

  if (IS_DESKTOP_SCREEN) {
    return (
      <Layout isScrollable={false} onActionButtonPress={handleClickAddButton}>
        <RequestHandler {...promOrderStatusMapsQueryProps}>
          <DesktopTable>
            <DesktopTable.Head>
              {COLUMNS?.map(({ intlId, sortId }) => (
                <DesktopTable.Title key={intlId} intlId={intlId} sortId={sortId} />
              ))}
            </DesktopTable.Head>
            <DesktopTable.Body>
              {promOrderStatusMapsData?.promOrderStatusMaps?.map((item) => (
                <DesktopTable.Row
                  key={item?.id}
                  onEditButtonClick={() =>
                    handleEditButtonClick(item.promStatusName, item.statusId)
                  }
                >
                  <DesktopTable.Cell>{item.promStatusName}</DesktopTable.Cell>
                  <DesktopTable.Cell>{item.promStatusDesc}</DesktopTable.Cell>
                  <DesktopTable.Cell>
                    {getOrderStatusName(statuses, item.statusId)}
                  </DesktopTable.Cell>
                </DesktopTable.Row>
              ))}
            </DesktopTable.Body>
          </DesktopTable>
        </RequestHandler>
      </Layout>
    );
  }
  return (
    <Layout isScrollable={false} onActionButtonPress={handleClickAddButton}>
      <RequestHandler {...promOrderStatusMapsQueryProps}>
        <MobileTable
          data={promOrderStatusMapsData?.promOrderStatusMaps}
          renderItem={(item) => (
            <PromOrderStatusMobileItem
              onItemPress={() =>
                handleEditButtonClick(item.promStatusName, item.statusId)
              }
              name={item.promStatusName}
              description={item.promStatusDesc}
              status={getOrderStatusName(statuses, item.statusId)}
            />
          )}
        />
      </RequestHandler>
    </Layout>
  );
};

export default PromOrderStatuses;
