import {
  BankIcon,
  BookkeepingCurrenciesIcon,
  BookkeepingTransactionsIcon,
} from '../../components/Icons';
import { BookkeepingExchangesIcon } from '../../components/Icons/BookkeepingExchangesIcon';

export default [
  {
    name: 'Accounts',
    icon: BankIcon,
    path: '/accounts',
    scopes: ['Report:Read', 'Currency:Read'],
  },

  {
    name: 'Currencies',
    icon: BookkeepingCurrenciesIcon,
    path: '/currencies',
    scopes: ['Currency:Read'],
  },
  {
    name: 'Transactions',
    icon: BookkeepingTransactionsIcon,
    path: '/transactions',
    scopes: ['Transaction:Read', 'Currency:Read'],
  },
  {
    name: 'Exchanges',
    icon: BookkeepingExchangesIcon,
    path: '/exchanges',
    scopes: ['Exchange:Read'],
  },
];
