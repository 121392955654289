import React from 'react';

import { BackToMainPageButton, Typography } from '../../components';
import { ErrorPageWrapper, ScreenNotFoundCenter } from '../../components/ErrorPages';

const ScreenNotFound = () => {
  return (
    <ErrorPageWrapper>
      <ScreenNotFoundCenter />
      <Typography
        mt={10}
        intlId='errors.screenNotFound'
        fontFamily='light'
        textAlign={'center'}
        fontSize='4xl'
      />
      <BackToMainPageButton />
    </ErrorPageWrapper>
  );
};

export default ScreenNotFound;
