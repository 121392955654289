import { StyleSheet } from 'react-native';

import { colors } from '../../../theme';

export const styles = StyleSheet.create({
  text: {
    color: colors.gray.main,
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: 1.17,
    marginRight: '8px',
  },
});
