import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const AddForwardingIcon = ({ size = 24, style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' style={{ ...style }}>
      <Path
        d='M23.1291 14.8937C22.6555 14.4642 21.9171 14.4923 21.4194 14.8937L17.711 17.8595C17.2575 18.2248 16.6916 18.4214 16.1057 18.4214H11.3579C11.0047 18.4214 10.7157 18.1325 10.7157 17.7793C10.7157 17.4261 11.0047 17.1371 11.3579 17.1371H14.5003C15.1385 17.1371 15.7324 16.6997 15.8368 16.0696C15.9692 15.2669 15.3512 14.5686 14.5686 14.5686H8.14715C7.06354 14.5686 6.01605 14.9418 5.17324 15.6241L3.30702 17.1371H1.08361C0.73043 17.1371 0.441467 17.4261 0.441467 17.7793V21.6321C0.441467 21.9853 0.73043 22.2743 1.08361 22.2743H15.4033C15.9853 22.2743 16.5512 22.0776 17.0087 21.7124L23.0769 16.8562C23.687 16.3706 23.7351 15.4395 23.1291 14.8937Z'
        fill='white'
      />
      <Path
        d='M6.27273 3C5.93518 3 5.61146 3.1317 5.37277 3.36612C5.13409 3.60054 5 3.91848 5 4.25V11.125H6.27273C6.27273 11.6223 6.47386 12.0992 6.83189 12.4508C7.18991 12.8025 7.6755 13 8.18182 13C8.68814 13 9.17373 12.8025 9.53175 12.4508C9.88977 12.0992 10.0909 11.6223 10.0909 11.125H13.9091C13.9091 11.6223 14.1102 12.0992 14.4683 12.4508C14.8263 12.8025 15.3119 13 15.8182 13C16.3245 13 16.8101 12.8025 17.1681 12.4508C17.5261 12.0992 17.7273 11.6223 17.7273 11.125H19V8L17.0909 5.5H15.1818V3H6.27273ZM15.1818 6.4375H16.7727L18.0264 8H15.1818V6.4375ZM8.18182 10.1875C8.43498 10.1875 8.67777 10.2863 8.85678 10.4621C9.0358 10.6379 9.13636 10.8764 9.13636 11.125C9.13636 11.3736 9.0358 11.6121 8.85678 11.7879C8.67777 11.9637 8.43498 12.0625 8.18182 12.0625C7.92866 12.0625 7.68586 11.9637 7.50685 11.7879C7.32784 11.6121 7.22727 11.3736 7.22727 11.125C7.22727 10.8764 7.32784 10.6379 7.50685 10.4621C7.68586 10.2863 7.92866 10.1875 8.18182 10.1875ZM15.8182 10.1875C16.0713 10.1875 16.3141 10.2863 16.4931 10.4621C16.6722 10.6379 16.7727 10.8764 16.7727 11.125C16.7727 11.3736 16.6722 11.6121 16.4931 11.7879C16.3141 11.9637 16.0713 12.0625 15.8182 12.0625C15.565 12.0625 15.3222 11.9637 15.1432 11.7879C14.9642 11.6121 14.8636 11.3736 14.8636 11.125C14.8636 10.8764 14.9642 10.6379 15.1432 10.4621C15.3222 10.2863 15.565 10.1875 15.8182 10.1875Z'
        fill='white'
      />
    </Svg>
  );
};

AddForwardingIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
