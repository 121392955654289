import { MaterialIcons } from '@expo/vector-icons';
import PropTypes from 'prop-types';
import React from 'react';
import { Animated, TouchableOpacity, View } from 'react-native';

import { HasScopesHandler, TouchableLink, Typography } from '../../components';
import { IS_DESKTOP_SCREEN, theme } from '../../constants';
import {
  bookkeeping,
  documentation,
  headers,
  newsletters,
  purchase,
  sales,
  settings,
  statistic,
  warehouse,
} from '../../constants/navigationData';
import { useMediaQuery, useRoute } from '../../hooks';
import { LeftArrowIcon, RightArrowIcon } from '../Icons';
import { styles } from './styles';

const nav = {
  sales,
  bookkeeping,
  warehouse,
  statistic,
  purchase,
  documentation,
  newsletters,
  settings,
};

function NavigationDrawer({ isOpen, setIsOpen, animationStyles }) {
  const { root, path } = useRoute();
  const isDesktop = useMediaQuery('isDesktop');
  const header = headers[root];

  const isActive = (itemPath) => `/${path}` === itemPath;

  return (
    <Animated.View
      style={[
        styles.wrapper,
        isDesktop
          ? { width: animationStyles?.wrapperWidth }
          : styles.wrapperNavigationDrawer,
      ]}
    >
      <View style={styles.header}>
        <View style={styles.path}>
          <Animated.View
            style={[styles.icon, { marginLeft: animationStyles?.wrapperLeft }]}
          >
            <header.icon
              size={30}
              color1={theme.colors.white}
              color2={theme.colors.white}
            />
          </Animated.View>
          <Typography
            fontWeight='normal'
            color={theme.colors.white}
            fontSize={18}
            intlId={`app.${header.name.toLowerCase()}`}
            style={[
              styles.title,
              // eslint-disable-next-line react-native/no-inline-styles
              {
                marginBottom: IS_DESKTOP_SCREEN ? 25 : 18,
              },
            ]}
          />
        </View>
        {!isDesktop && (
          <View style={styles.close}>
            <MaterialIcons
              onPress={() => setIsOpen(false)}
              name={'close'}
              size={14}
              color={'#C3CDE0'}
            />
          </View>
        )}
      </View>
      {nav[root]?.map((item) => (
        <HasScopesHandler scopesArray={item?.scopes} key={item.name}>
          <TouchableLink
            to={`/${root}${item?.path}`}
            wrapperStyle={[
              // eslint-disable-next-line react-native/no-inline-styles
              { opacity: isActive(item?.path) ? 1 : 0.5 },
              styles.linkWrapper,
            ]}
            childrenStyle={styles.link}
          >
            <Animated.View
              style={[styles.icon, { marginLeft: animationStyles?.wrapperLeft }]}
            >
              <item.icon size={24} />
            </Animated.View>
            <Typography
              intlId={`app.${item.name.toLowerCase()}`}
              color={theme.colors.white}
              fontFamily={isActive(item?.path) ? 'regular' : 'light'}
              fontSize={15}
              style={styles.title}
            />
          </TouchableLink>
        </HasScopesHandler>
      ))}
      {isDesktop && (
        <Animated.View
          style={[
            styles.footer,
            {
              right: animationStyles?.footerRight,
              opacity: animationStyles?.buttonOpacity,
              left: animationStyles?.footerLeft,
            },
          ]}
        >
          <TouchableOpacity onPress={() => setIsOpen(!isOpen)}>
            <Animated.View
              // eslint-disable-next-line react-native/no-inline-styles
              style={{
                opacity: animationStyles?.openButtonOpacity,
                position: 'absolute',
              }}
            >
              <LeftArrowIcon />
            </Animated.View>
            <Animated.View
              style={{
                opacity: animationStyles?.closeButtonOpacity,
              }}
            >
              <RightArrowIcon />
            </Animated.View>
          </TouchableOpacity>
        </Animated.View>
      )}
    </Animated.View>
  );
}

export default NavigationDrawer;

NavigationDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  animationStyles: PropTypes.object.isRequired,
};
