import { Formik } from 'formik';
import { Box } from 'native-base';
import React, { useMemo } from 'react';

import {
  BoxWrapper,
  Checkbox,
  FieldRow,
  FormRow,
  FormWrapper,
  Layout,
  RequestHandler,
  Select,
  TextField,
  Typography,
} from '../../../../../components';
import { IS_MOBILE_SCREEN } from '../../../../../constants';
import {
  useCreatePromOrderStatusMap,
  useDeletePromOrderStatusMap,
  useGetPromOrderStatusMapQuery,
  useGetStatusesQuery,
  useParams,
  useUpdatePromOrderStatusMap,
} from '../../../../../hooks';
import { createPromOrderStatusSchema } from '../../../../../services/validation';
import { transformSelectOptions } from '../../../../../utils';

const PromOrderStatus = () => {
  const { id: promStatusName, statusId } = useParams();

  const isEdit = promStatusName && statusId;

  const { data, ...queryProps } = useGetPromOrderStatusMapQuery({
    variables: { args: { promStatusName, statusId: Number(statusId) } },
    skip: !isEdit,
    fetchPolicy: 'no-cache',
  });

  const { data: statusesData } = useGetStatusesQuery();

  const [createPromOrderStatusMapMutation, { loading }] = useCreatePromOrderStatusMap();
  const [updatePromOrderStatusMapMutation, { loading: updatePromOrderStatusMapLoading }] =
    useUpdatePromOrderStatusMap();
  const [deletePromOrderStatusMapMutation, { loading: deletePromOrderStatusMapLoading }] =
    useDeletePromOrderStatusMap();

  const initialValues = useMemo(() => {
    return {
      promStatusName: data?.promOrderStatusMap?.promStatusName || '',
      statusId: data?.promOrderStatusMap?.statusId?.toString() || '',
      isPromStatusDefault: data?.promOrderStatusMap?.isPromStatusDefault || false,
      isStatusDefault: data?.promOrderStatusMap?.isStatusDefault || false,
      promStatusDesc: data?.promOrderStatusMap?.promStatusDesc || '',
    };
  }, [data]);

  const handleFormSubmit = (values) => {
    if (isEdit) {
      updatePromOrderStatusMapMutation({
        variables: {
          input: {
            promStatusName,
            statusId: Number(statusId),
            isPromStatusDefault: values.isPromStatusDefault,
            isStatusDefault: values.isStatusDefault,
            promStatusDesc: values.promStatusDesc,
          },
        },
      });
    } else {
      createPromOrderStatusMapMutation({
        variables: {
          input: {
            promStatusName: values.promStatusName,
            statusId: Number(values.statusId),
            isPromStatusDefault: values.isPromStatusDefault,
            isStatusDefault: values.isStatusDefault,
            promStatusDesc: values.promStatusDesc,
          },
        },
      });
    }
  };

  const handleDeletePromOrderStatusMap = () => {
    deletePromOrderStatusMapMutation({
      variables: {
        params: { promStatusName, statusId: Number(statusId) },
      },
    });
  };

  return (
    <RequestHandler {...queryProps}>
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={createPromOrderStatusSchema}
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <Layout
              onActionButtonPress={handleSubmit}
              actionButtonType='save'
              actionButtonIsLoading={
                loading ||
                updatePromOrderStatusMapLoading ||
                deletePromOrderStatusMapLoading
              }
              onRemoveButtonPress={handleDeletePromOrderStatusMap}
            >
              <FormRow>
                <FormRow.Left>
                  <BoxWrapper>
                    <Typography intlId='prom.status' variant='heading' />
                    {!isEdit ? (
                      <FieldRow withNegativeBottomMargin={false}>
                        <TextField name='promStatusName' label='prom.status' />
                      </FieldRow>
                    ) : null}
                    <FieldRow withNegativeBottomMargin={false}>
                      <TextField name='promStatusDesc' label='prom.statusDescription' />
                    </FieldRow>
                    {!isEdit ? (
                      <FieldRow withNegativeBottomMargin={false}>
                        <Select
                          name='statusId'
                          label='orderStatuses.statusCRM'
                          options={transformSelectOptions({
                            data: statusesData?.statuses,
                          })}
                        />
                      </FieldRow>
                    ) : null}
                    <FieldRow withNegativeBottomMargin={false}>
                      <Box ml={2} pb={3}>
                        <Checkbox
                          mt={!IS_MOBILE_SCREEN ? '16px' : 0}
                          justify={!IS_MOBILE_SCREEN ? 'flex-start' : 'center'}
                          label={'orderStatuses.statusDefaultCRM'}
                          onChange={() =>
                            setFieldValue('isStatusDefault', !values.isStatusDefault)
                          }
                          checked={values.isStatusDefault}
                        />
                      </Box>
                    </FieldRow>
                    <FieldRow withNegativeBottomMargin={false}>
                      <Box ml={2} pb={3}>
                        <Checkbox
                          mt={!IS_MOBILE_SCREEN ? '16px' : 0}
                          justify={!IS_MOBILE_SCREEN ? 'flex-start' : 'center'}
                          label={'prom.statusDefault'}
                          onChange={() =>
                            setFieldValue(
                              'isPromStatusDefault',
                              !values.isPromStatusDefault,
                            )
                          }
                          checked={values.isPromStatusDefault}
                        />
                      </Box>
                    </FieldRow>
                  </BoxWrapper>
                </FormRow.Left>
              </FormRow>
            </Layout>
          )}
        </Formik>
      </FormWrapper>
    </RequestHandler>
  );
};

export default PromOrderStatus;
