import { Flex } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { ROUTES } from '../../constants';
import { useHistory } from '../../hooks';
import { Button } from '../Button/Button';
import { BackArrowIcon } from '../Icons';
import { Typography } from '../Typography/Typography';

export const BackToMainPageButton = ({ onPress }) => {
  const navigation = useHistory();

  const onPressHandler = () => {
    navigation.push(ROUTES.sales.path);
    if (onPress) {
      onPress();
    }
  };

  return (
    <Button top={10} onPress={onPressHandler}>
      <Flex>
        <BackArrowIcon />
        <Typography ml={2} color={'white'} intlId={'app.backToMainPage'} />
      </Flex>
    </Button>
  );
};

BackToMainPageButton.propTypes = {
  onPress: PropTypes.func,
};
