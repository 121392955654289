import PropTypes from 'prop-types';
import React from 'react';

import { calculateCostDelivery } from '../../utils';
import { Typography } from '../Typography/Typography';
import styles from './styles';

export const CalculateCostDeliveryButton = ({ values, setFieldValue }) => {
  return (
    <Typography
      onPress={() =>
        calculateCostDelivery(values?.purchases, values?.deliveryPrice, setFieldValue)
      }
      mr={6}
      borderBottomWidth={1}
      borderColor={'#EB5757'}
      style={styles.calculateButton}
      intlId='app.calculateShippingCost'
    />
  );
};

CalculateCostDeliveryButton.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};
