import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const BookkeepingCalendarIcon = ({ size = 12, style = {} }) => {
  return (
    <Svg
      width={size}
      height={size}
      top={1}
      marginRight={6}
      viewBox='0 0 12 12'
      style={{ ...style }}
    >
      <Path
        d='M11.25 10.6667V2.50008C11.25 1.85666 10.7267 1.33341 10.0833 1.33341H8.91667V0.166748H7.75V1.33341H4.25V0.166748H3.08333V1.33341H1.91667C1.27325 1.33341 0.75 1.85666 0.75 2.50008V10.6667C0.75 11.3102 1.27325 11.8334 1.91667 11.8334H10.0833C10.7267 11.8334 11.25 11.3102 11.25 10.6667ZM4.25 9.50008H3.08333V8.33342H4.25V9.50008ZM4.25 7.16675H3.08333V6.00008H4.25V7.16675ZM6.58333 9.50008H5.41667V8.33342H6.58333V9.50008ZM6.58333 7.16675H5.41667V6.00008H6.58333V7.16675ZM8.91667 9.50008H7.75V8.33342H8.91667V9.50008ZM8.91667 7.16675H7.75V6.00008H8.91667V7.16675ZM10.0833 4.25008H1.91667V3.08342H10.0833V4.25008Z'
        fill='#828282'
      />
    </Svg>
  );
};

BookkeepingCalendarIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
