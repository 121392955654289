import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';

import { ErrorIndicator, Modal, Preloader } from '../../../components/UI';
import globalStyles from '../../../globalStyles';
import { useFilter } from '../../../hooks';
import FilterFieldsDesktop from './desktop/FilterFieldsDesktop';
import FilterFieldsMobile from './mobile/FilterFieldsMobile';
import validationSchema from './validationSchema';

function Filter({ error, loading, data, withModal, onClose }) {
  const { filters, setFilters } = useFilter();

  if (withModal) {
    if (loading)
      return (
        <Modal onClose={onClose}>
          <Preloader />
        </Modal>
      );
    if (error)
      return (
        <Modal onClose={onClose}>
          <ErrorIndicator />
        </Modal>
      );
  } else {
    if (loading)
      return (
        <View style={globalStyles.filterWeb}>
          <Preloader />
        </View>
      );
    if (error)
      return (
        <View style={globalStyles.filterWeb}>
          <ErrorIndicator />
        </View>
      );
  }

  return (
    <Formik
      initialValues={{
        status: filters?.status || [],
        source: filters?.source || [],
        payment: filters?.payment || [],
        manager: filters?.manager || [],
        customer: filters?.customer || [],
        dateFrom: filters?.dateFrom || '',
        dateTo: filters?.dateTo || '',
      }}
      onSubmit={(values) => setFilters(values)}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, setFieldValue, values }) => {
        const setValue = (name, value) => {
          setFieldValue(name, value);
          handleSubmit();
        };
        return withModal ? (
          <FilterFieldsMobile
            data={data}
            setFieldValue={setFieldValue}
            values={values}
            handleSubmit={handleSubmit}
            onClose={onClose}
          />
        ) : (
          <FilterFieldsDesktop
            data={data}
            onChange={setValue}
            values={values}
            handleSubmit={handleSubmit}
          />
        );
      }}
    </Formik>
  );
}

Filter.propTypes = {
  onClose: PropTypes.func,
  data: PropTypes.object,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  withModal: PropTypes.bool,
};

export default Filter;
