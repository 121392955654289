import { MaterialIcons } from '@expo/vector-icons';
import { Box, Flex, Icon } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { TouchableOpacity } from 'react-native';

import { BoxWrapper, Typography } from '../../../../components';
import { ExchangeIcon } from '../../../../components/Icons';

const ExchangeMobileItem = ({ date, ratio, fromAmount, toAmount, onItemPress }) => {
  return (
    <BoxWrapper>
      <TouchableOpacity onPress={onItemPress}>
        <Flex
          justify='space-between'
          borderBottomWidth='1px'
          borderColor='gray.default'
          pb='10px'
        >
          <Flex>
            <Icon
              as={<MaterialIcons name='calendar-today' />}
              size='xs'
              color='gray.main'
              mr='5px'
            />
            {date}
          </Flex>
          <Flex>
            <ExchangeIcon />
            <Box ml='5px'>{ratio}</Box>
          </Flex>
        </Flex>
        <Box paddingY='10px'>
          <Flex justify='flex-end' mb='8px'>
            <Typography
              fontSize='18px'
              fontWeight='700'
              color='#EE8585'
            >{`- ${fromAmount}`}</Typography>
          </Flex>
          <Flex justify='flex-end'>
            <Typography
              fontSize='18px'
              fontWeight='700'
              color='#8BC34A'
            >{`+ ${toAmount}`}</Typography>
          </Flex>
        </Box>
        <Box borderTopWidth='1px' borderColor='gray.default' pt='10px'>
          <Typography color='gray.main' fontSize='13px' intlId='app.exchangeMobileDesc' />
        </Box>
      </TouchableOpacity>
    </BoxWrapper>
  );
};

export default memo(ExchangeMobileItem);

ExchangeMobileItem.propTypes = {
  date: PropTypes.node,
  ratio: PropTypes.string,
  fromAmount: PropTypes.string,
  toAmount: PropTypes.string,
  onItemPress: PropTypes.func,
};
