import { StyleSheet, Dimensions, Platform } from 'react-native';
import { displayWidth } from '../../../constants/index';
import { isWeb } from '../../../constants';
const width = Dimensions.get('window').width;

export default StyleSheet.create({
  wrapper: {
    position: 'relative',
    width: '100%',
    minHeight: 41,
    display: 'flex',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: '#000000',
    zIndex: 50,
    marginBottom: width <= displayWidth ? 20 : 0,
  },
  multiSelectHead: {
    width: '95%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  multiSelect: {
    width: '95%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
  },
  selected: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#C4C4C4',
    borderRadius: 10,
    paddingHorizontal: 7,
    marginVertical: 3,
  },
  selecteds: {
    display: 'flex',
    flexDirection: width ? 'column' : 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    width: '100%',
  },
  input: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  fields: {
    backgroundColor: '#f2f2f2',
    padding: 10,
  },
  fieldsWeb: {
    position: 'absolute',
    left: 0,
    width: 204,
    transform: [{ translateY: '100%' }],
    bottom: 0,
    backgroundColor: '#ffffff',
    ...(isWeb ? { boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)' } : null),
  },
  field: {
    paddingVertical: 10,
  },
  fieldWeb: {
    height: 41,
  },
  name: {
    color: '#828282',
  },
});
