import { Box } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { FlatList, TouchableOpacity } from 'react-native';
import { TouchableOpacity as RNGHTouchableOpacity } from 'react-native-gesture-handler';

import { IS_IOS } from '../../constants';
import { Typography } from '../Typography/Typography';
import { styles } from './styles';

export const SearchSelectList = ({ options, searchValue, onPress }) => {
  const list = options?.filter((el) =>
    el?.label?.toLowerCase()?.includes(searchValue?.toLowerCase()),
  );

  if (!list?.length) return null;

  const Touchable = IS_IOS ? TouchableOpacity : RNGHTouchableOpacity;

  return (
    <Box style={styles.wrapper}>
      <FlatList
        data={list}
        renderItem={({ item }) => (
          <Touchable onPress={() => onPress(item?.value)} style={styles.item}>
            <Typography>{item?.label}</Typography>
          </Touchable>
        )}
        keyExtractor={(item, index) => item?.id?.toString() || index}
        nestedScrollEnabled
        style={styles.list}
      />
    </Box>
  );
};

SearchSelectList.propTypes = {
  onPress: PropTypes.func,
  searchValue: PropTypes.string,
  options: PropTypes.array,
};
