/* eslint-disable react-native/no-color-literals */
import { StyleSheet } from 'react-native';

import { IS_DESKTOP_SCREEN, theme } from '../../../constants';

export default StyleSheet.create({
  addProductButton: {
    backgroundColor: theme.colors.primary,
    fontSize: 13,
  },
  boxTotalPrice: {
    alignSelf: 'flex-end',
    paddingBottom: 5,
    paddingTop: 20,
  },
  city: {
    padding: 5,
  },
  field: {
    color: '#828282',
    flex: IS_DESKTOP_SCREEN ? 1 : 2,
  },
  fieldDataMobile: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 5,
  },
  fieldInputWrapper: {
    alignItems: 'flex-end',
    flex: 1,
  },
  iconBack: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  input: {
    backgroundColor: '#fff',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    borderWidth: 1,
    flexDirection: 'row',
    marginBottom: 5,
    paddingBottom: 18,
    paddingLeft: 20,
    paddingRight: 15,
    paddingTop: 18,
  },
  inputCity: {
    width: '97%',
  },
  listCity: {
    display: 'flex',
    flex: 1,
    height: 200,
    marginBottom: 5,
    paddingHorizontal: 10,
  },
  orderItemDeleteButton: { position: 'absolute', right: 0 },
  orderItemHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 25,
  },
  orderItemMobile: {
    alignItems: 'flex-start',
    borderBottomWidth: 0.8,
    borderColor: theme.colors.mediumGray,
    flexDirection: 'column',
    marginBottom: 23,
    paddingBottom: 23,
  },
  orderItemName: {
    color: '#47C8FF',
    marginBottom: 10,
  },
  orderItemPrice: { alignItems: 'flex-end', flex: 2, flexBasis: 45, marginRight: 0 },
  orderItemPriceWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-end',
    width: '100%',
  },
  orderItemProductName: { color: '#47C8FF', flex: 2, marginLeft: 0 },
  orderItemQuantity: {
    flex: 0.5,
    justifyContent: 'center',
    textAlign: 'center',
  },
  orderItemRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginLeft: 32,
  },
  orderItemRowMobile: {
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
    width: '100%',
  },
  orderItemRowMobileOutMargin: {
    alignItems: 'center',
    height: 60,
    justifyContent: 'space-between',
    width: '100%',
  },
  orderItemSum: {
    flex: 1,
    marginLeft: 0,
    marginRight: 32,
  },
  orderItemTitle: {
    flex: 2,
    marginLeft: 0,
    textAlign: 'left',
  },
  reservationCancelButton: {
    backgroundColor: theme.colors.danger,
    fontSize: 13,
    height: 32,
  },
  rowInput: {
    alignItems: 'center',
    borderBottomColor: '#d9d9d9',
    borderBottomWidth: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 10,
    width: '100%',
  },
  text: {
    color: 'white',
  },
  textPrice: {
    borderBottomColor: '#4F4F4F',
    borderBottomWidth: 1,
    paddingBottom: 8,
  },
  titleOrderItem: {
    color: '#828282',
    flex: -1,
    fontSize: 14,
    marginBottom: 5,
    marginLeft: 32,
    textAlign: 'right',
  },
  totalPayable: {
    alignItems: 'center',
    backgroundColor: '#2C3E61',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'row',
    height: 52,
    justifyContent: 'space-between',
    marginBottom: 24,
    marginHorizontal: 21,
    paddingLeft: 16,
    paddingRight: 16,
  },
  totalPayableText: {
    color: '#F2F2F2',
    fontSize: 16,
  },
  wrapButtonRemove: {
    alignSelf: 'flex-end',
  },
});
