import { endOfDay, startOfDay } from 'date-fns';
import { Formik } from 'formik';
import { Flex } from 'native-base';
import React from 'react';

import {
  Button,
  DatePicker,
  RequestHandler,
  SearchSelectInFilter,
} from '../../../components';
import { IS_MOBILE_SCREEN } from '../../../constants';
import { useFiltersContext } from '../../../context';
import { useCustomersFilterQuery, useFilter } from '../../../hooks';
import { getFilterOption } from '../../../utils';

export const CustomersFilters = () => {
  const { filters, setFilters, clearFilters } = useFilter();
  const { data, ...queryProps } = useCustomersFilterQuery();
  const { toggleFilter } = useFiltersContext();

  const onSubmit = (values) => {
    setFilters({
      phoneIds: getFilterOption(values.phoneIds),
      emailIds: getFilterOption(values.emailIds),
      dateFrom: values?.dateFrom ? startOfDay(new Date(values?.dateFrom)) : '',
      dateTo: values?.dateTo ? endOfDay(new Date(values?.dateTo)) : '',
    });

    if (IS_MOBILE_SCREEN) {
      toggleFilter();
    }
  };

  const getSelectOptions = ({ name, label = 'name', isFullName = false }) => {
    if (!data?.[name]?.length) return [];
    return data?.[name]?.map((el) => ({
      value: el.id,
      label: isFullName ? `${el?.firstName} ${el?.lastName}` : el[label],
    }));
  };

  const getInitialValue = ({ value, name, label = 'name', isFullName = false }) => {
    if (!data?.[name]?.length || !value) return [];

    const list = Array(value);

    return data?.[name]
      ?.filter((el) => list?.includes(el.id))
      ?.map((el) => ({
        value: el.id,
        label: isFullName ? `${el?.firstName} ${el?.lastName}` : el[label],
      }));
  };

  const handleResetForm = (setFieldValue) => {
    setFieldValue('emailIds', []);
    setFieldValue('phoneIds', []);
    setFieldValue('dateFrom', '');
    setFieldValue('dateTo', '');
    clearFilters();
  };

  const initialValues = {
    phoneIds: getInitialValue({
      value: filters?.phoneIds,
      name: 'phones',
      label: 'phone',
    }),
    emailIds: getInitialValue({
      value: filters?.emailIds,
      name: 'emails',
      label: 'email',
    }),
    dateFrom: filters?.dateFrom || '',
    dateTo: filters?.dateTo || '',
  };

  return (
    <RequestHandler {...queryProps}>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleSubmit, handleReset, setFieldValue }) => (
          <>
            <SearchSelectInFilter
              name='phoneIds'
              label='app.phone'
              options={getSelectOptions({ name: 'phones', label: 'phone' })}
              mb='md'
              zIndex={2}
            />
            <SearchSelectInFilter
              name='emailIds'
              label='app.email'
              options={getSelectOptions({ name: 'emails', label: 'email' })}
              mb='md'
              zIndex={1}
            />
            <DatePicker name='dateFrom' label='date.from' />
            <DatePicker name='dateTo' label='date.to' />
            <Flex justify='space-between'>
              <Button
                onPress={() => {
                  handleReset();
                  handleResetForm(setFieldValue);
                }}
                variant='secondary'
                intlId='app.clear'
              />
              <Button onPress={handleSubmit} intlId='app.apply' />
            </Flex>
          </>
        )}
      </Formik>
    </RequestHandler>
  );
};
