import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

export const CompletedMobileIcon = () => {
  return (
    <Svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <Circle cx='20' cy='20' r='20' fill='#FDCA7E' />
      <Path
        d='M11.5688 23.7375C11.6406 24.0031 11.9156 24.1625 12.1813 24.0906L18.9438 22.2781C19.2094 22.2063 19.3688 21.9312 19.2969 21.6656L17.7438 15.8688C17.6719 15.6031 17.3969 15.4437 17.1313 15.5156L14.7188 16.1656L15.4938 19.0625L13.5625 19.5781L12.7875 16.6813L10.3719 17.3281C10.1063 17.4 9.94689 17.675 10.0188 17.9406L11.5688 23.7375ZM22 12C21.4469 12 21 12.4469 21 13V23.1125L10.1844 26.0625C10.05 26.1 9.97189 26.2375 10.0094 26.3687L10.4031 27.8156C10.4406 27.95 10.5781 28.0281 10.7094 27.9906L23.0125 24.6344C23.0875 26.5031 24.6125 28 26.5 28C28.4344 28 30 26.4344 30 24.5V12H22ZM26.5 26C25.6719 26 25 25.3281 25 24.5C25 23.6719 25.6719 23 26.5 23C27.3281 23 28 23.6719 28 24.5C28 25.3281 27.3281 26 26.5 26Z'
        fill='white'
      />
    </Svg>
  );
};
