import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { SelectWithSearch } from '../../../../components';
import {
  useDebounce,
  useGetCitiesNovaPoshta,
  useGetCityNovaPoshta,
} from '../../../../hooks';
import { transformSelectOption, transformSelectOptions } from '../../../../utils';

export const CityAutoCompleteField = ({ name, cityRef }) => {
  const [citySearchValue, setCitySearchValue] = useState('');

  const { debouncedValue: debouncedSearchValue, isDebouncing } =
    useDebounce(citySearchValue);

  const [getCities, { data: citiesData, loading }] = useGetCitiesNovaPoshta({
    variables: {
      cityName: debouncedSearchValue,
    },
  });

  const [getInitCity, { data: cityData, loading: initCityLoading }] =
    useGetCityNovaPoshta();

  useEffect(() => {
    cityRef &&
      getInitCity({
        variables: {
          cityRef: cityRef,
        },
      });
  }, []);

  const getCitiesHandler = () => {
    !citiesData?.getCitiesNovaPoshta && getCities();
  };

  const setSearchHandler = (val) => {
    return val ? setCitySearchValue(val) : setCitySearchValue('');
  };

  return (
    <SelectWithSearch
      width='100%'
      pl={0}
      pr={0}
      initialOption={
        cityRef &&
        transformSelectOption({
          item: cityData?.getCitiesNovaPoshta?.[0],
          value: 'ref',
          label: 'description',
        })
      }
      setSearchValue={setSearchHandler}
      isLoading={loading || isDebouncing || initCityLoading}
      onMenuOpen={getCitiesHandler}
      options={transformSelectOptions({
        data: citiesData?.getCitiesNovaPoshta.slice(0, 50),
        value: 'ref',
        label: 'description',
      })}
      name={name}
      label='app.city'
    />
  );
};

CityAutoCompleteField.propTypes = {
  name: PropTypes.string,
  cityRef: PropTypes.string,
};
