import { Box, Flex } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { TextLink, Typography } from '../../../../../components';

const AvailabilityMobileItem = ({
  boxId,
  box,
  shelving,
  room,
  purchaseId,
  purchase,
  condition,
  deliveryPrice,
  costPrice,
  quantity,
  hasScope,
}) => {
  return (
    <Box paddingY='md' borderBottomWidth='1px' borderColor='gray.light'>
      <Flex mb='md' justify='space-between'>
        <Typography intlId='app.box' color='gray.main' />
        <TextLink link={`/warehouse/box/${boxId}`} intlId={box} />
      </Flex>
      <Flex mb='md' justify='space-between'>
        <Typography intlId='app.shelving' color='gray.main' />
        <Typography>{shelving}</Typography>
      </Flex>
      <Flex mb='md' justify='space-between'>
        <Typography intlId='app.room' color='gray.main' />
        <Typography>{room}</Typography>
      </Flex>

      <Flex mb='md' justify='space-between'>
        <Typography intlId='app.purchase' color='gray.main' />
        <TextLink link={`/purchase/purchase/${purchaseId}`} intlId={purchase} />
      </Flex>

      <Flex mb='md' justify='space-between'>
        <Typography intlId='app.condition' color='gray.main' />
        <Typography>{condition}</Typography>
      </Flex>
      {hasScope ? (
        <>
          <Flex mb='md' justify='space-between'>
            <Typography intlId='app.deliveryPrice' color='gray.main' />
            <Typography>{deliveryPrice}</Typography>
          </Flex>
          <Flex justify='space-between'>
            <Typography intlId='app.costPrice' color='gray.main' />
            <Typography>{costPrice}</Typography>
          </Flex>
        </>
      ) : null}

      <Flex justify='space-between'>
        <Typography intlId='app.quantity' color='gray.main' />
        <Typography>{quantity}</Typography>
      </Flex>
    </Box>
  );
};

export default AvailabilityMobileItem;

AvailabilityMobileItem.propTypes = {
  boxId: PropTypes.number,
  box: PropTypes.string,
  shelving: PropTypes.string,
  room: PropTypes.string,
  purchaseId: PropTypes.number,
  purchase: PropTypes.string,
  condition: PropTypes.string,
  deliveryPrice: PropTypes.string,
  costPrice: PropTypes.string,
  quantity: PropTypes.number,
  hasScope: PropTypes.bool,
};
