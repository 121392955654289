import { Platform, StyleSheet } from 'react-native';

import { theme } from '../../../theme';

export default StyleSheet.create({
  removeButton: {
    backgroundColor: theme.colors.danger,
    bottom: 45,
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    right: 80,
    zIndex: 3,
  },
  removeButtonWeb: {
    backgroundColor: theme.colors.danger,
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    right: 148,
    top: 104,
    zIndex: 10,
  },
});
