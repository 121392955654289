import { MaterialIcons } from '@expo/vector-icons';
import { useFormikContext } from 'formik';
import { Flex, Icon } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import {
  BoxWrapper,
  DesktopTable,
  FormattedDate,
  MobileTable,
  Typography,
} from '../../../../components';
import { IS_APP, IS_DESKTOP_SCREEN } from '../../../../constants';
import { getCurrencyRatio } from '../../../../utils';
import CurrencyHistoryMobileItem from './CurrencyHistoryMobileItem';

const COLUMNS = [{ name: 'app.ratio&rate' }, { name: 'date.fromStart' }];

export const CurrencyHistory = ({ data = [] }) => {
  const { setFieldValue, values } = useFormikContext();

  const handleRemoveButtonClick = () => {
    setFieldValue('lastRateRemoved', parseInt(values['lastRateRemoved']) + 1);
  };

  const filteredData = Array.from(data)
    ?.reverse()
    ?.slice(parseInt(values['lastRateRemoved']));

  useEffect(() => {
    if (filteredData.length > 0 && values.lastRateRemoved) {
      const lastCurrency = filteredData[0];

      setFieldValue('ratio', lastCurrency.ratio);
      setFieldValue('date', lastCurrency.date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.lastRateRemoved]);

  if (IS_DESKTOP_SCREEN) {
    return (
      <BoxWrapper>
        <Typography intlId='app.courseHistory' variant='heading' mb='10px' />
        <DesktopTable editable={false}>
          <DesktopTable.Head>
            {COLUMNS.map((column) => (
              <DesktopTable.Title key={column.name} intlId={column.name} />
            ))}
            <DesktopTable.Title />
          </DesktopTable.Head>
          <DesktopTable.Body>
            {filteredData?.map((item, index) => (
              <DesktopTable.Row key={index}>
                <DesktopTable.Cell>{getCurrencyRatio(item?.ratio)}</DesktopTable.Cell>
                <DesktopTable.Cell>
                  <FormattedDate date={new Date(item?.date)} />
                </DesktopTable.Cell>
                <DesktopTable.Cell>
                  <Flex justify='flex-end'>
                    {index === 0 ? (
                      <Icon
                        as={<MaterialIcons name='delete' />}
                        size='sm'
                        color='gray.main'
                        onPress={handleRemoveButtonClick}
                      />
                    ) : null}
                  </Flex>
                </DesktopTable.Cell>
              </DesktopTable.Row>
            ))}
          </DesktopTable.Body>
        </DesktopTable>
      </BoxWrapper>
    );
  }

  return (
    <>
      <Typography intlId='app.courseHistory' variant='heading' mb='10px' />
      <MobileTable
        data={filteredData}
        renderItem={(item, index) => (
          <CurrencyHistoryMobileItem
            ratio={getCurrencyRatio(item?.ratio)}
            date={<FormattedDate date={new Date(item?.date)} />}
            removeButton={
              index === 0 ? (
                <Flex justify='flex-start'>
                  <Icon
                    as={<MaterialIcons name='delete' />}
                    size='sm'
                    color='gray.main'
                    onPress={handleRemoveButtonClick}
                  />
                </Flex>
              ) : null
            }
          />
        )}
        autoHeight={IS_APP ? false : true}
      />
    </>
  );
};

CurrencyHistory.propTypes = {
  data: PropTypes.any,
};
