import { Center, Spinner } from 'native-base';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useReducer } from 'react';

import { useCachedResources, useGetUserQuery } from '../hooks';
import { LocalStorage } from '../services';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const isResourcesLoaded = useCachedResources();
  const { data, loading, refetch, client } = useGetUserQuery();

  const [state, dispatch] = useReducer(authReducer, {
    isAuth: false,
    isInit: false,
  });

  useEffect(() => {
    if (!loading) {
      if (data?.currentUser) {
        dispatch({ type: actionTypes.SUCCESS_AUTH });
      } else {
        dispatch({ type: actionTypes.INIT });
      }
    }
  }, [data, loading]);

  const handleSuccessAuth = () => {
    dispatch({ type: actionTypes.SUCCESS_AUTH });
    refetch();
  };

  const handleLogout = async () => {
    await client.clearStore();
    await LocalStorage.deleteValue('token');
    dispatch({ type: actionTypes.LOGOUT });
  };

  const value = {
    currentUserData: data,
    isAuth: state.isAuth,
    onSuccessAuth: handleSuccessAuth,
    onLogout: handleLogout,
  };

  if (!state.isInit || !isResourcesLoaded) {
    return (
      <Center flex={1}>
        <Spinner />
      </Center>
    );
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return context;
};

const actionTypes = {
  SUCCESS_AUTH: 'SUCCESS_AUTH',
  LOGOUT: 'LOGOUT',
  INIT: 'INIT',
};

function authReducer(state, action) {
  switch (action.type) {
    case actionTypes.INIT: {
      return { ...state, isInit: true };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        isAuth: false,
      };
    }
    case actionTypes.SUCCESS_AUTH: {
      return {
        ...state,
        isAuth: true,
        isInit: true,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export { AuthProvider, useAuthContext };
