import React from 'react';
import { View } from 'react-native';

import { Typography } from '../Typography/Typography';

export const ErrorIndicator = () => {
  return (
    <View>
      <Typography intlId='errors.default' />
    </View>
  );
};
