import { Flex, Text, View } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import { Typography } from '../../../../components';
import { ConditionNumber } from '.';
import styles from './styles';

const Condition = ({ condition }) => (
  <Flex style={styles.locationCondition}>
    <ConditionNumber condition={condition} style={styles.conditionDropdownNumber} />
    <Typography style={styles.conditionDropdownName}>{condition?.name}</Typography>
  </Flex>
);

const Item = ({ intlId, location }) => {
  const intl = useIntl();

  return (
    <View style={styles.locationItem}>
      <Text style={styles.number}>{location}</Text>
      <Text style={styles.unit}>{intl.formatMessage({ id: intlId })}</Text>
    </View>
  );
};

const Location = ({ place }) => {
  const getLocation = (key) => {
    const lib = {
      room: place.location.find(({ typeId }) => typeId === 2),
      rack: place.location.find(({ typeId }) => typeId === 1),
      box: place,
    };

    return lib[key].name;
  };

  return (
    <Flex style={styles.location}>
      <Item intlId='app.room' location={getLocation('room')} />
      <Item intlId='app.rack' location={getLocation('rack')} />
      <Item intlId='app.box' location={getLocation('box')} />
    </Flex>
  );
};

export const ItemLocation = ({ location, condition }) => {
  return (
    <Flex style={styles.itemLocationBar}>
      <Condition condition={condition} />
      <Location place={location} />
    </Flex>
  );
};

Item.propTypes = {
  intlId: PropTypes.string,
  location: PropTypes.string,
};

Location.propTypes = {
  place: PropTypes.shape({
    location: PropTypes.array,
  }),
};

Condition.propTypes = {
  condition: PropTypes.object,
};

ItemLocation.propTypes = {
  location: PropTypes.string,
  condition: PropTypes.object,
};
