import { getInfo } from './getInfo';
import { getLabel } from './getLabel';
import { getValue } from './getValue';

export const transformSelectOptions = ({ data, value = 'id', label = 'name' }) => {
  if (!data || data?.length === 0) return [];
  if (Array.isArray(data)) {
    return data?.map((item) => ({
      value: getValue(item, value),
      label: getLabel(item, label),
    }));
  }
};

export const transformSelectOptionsWithInfo = ({
  data,
  info,
  value = 'id',
  label = 'name',
}) => {
  if (!data || data?.length === 0) return [];
  if (Array.isArray(data)) {
    return data?.map((item) => ({
      value: getValue(item, value),
      label: getLabel(item, label),
      info: getInfo(item, info),
    }));
  }
};

export const transformSelectOptionsWithCustomInfo = ({
  data,
  info,
  customInfo,
  value = 'id',
  label = 'name',
}) => {
  if (!data || data?.length === 0) return [];
  if (Array.isArray(data)) {
    return data?.map((item) => ({
      value: getValue(item, value),
      label: getLabel(item, label),
      info: getInfo(item, info),
      customInfo: getInfo(item, customInfo),
    }));
  }
};
