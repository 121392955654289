import { MaterialIcons } from '@expo/vector-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Flex, Text } from 'native-base';
import React, { useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

import {
  BoxWrapper,
  Button,
  FormRow,
  FormWrapper,
  Layout,
  RequestHandler,
  TextFieldHookFrom,
  Typography,
} from '../../../components';
import { SelectHookForm } from '../../../components/Select/SelectHookForm';
import { CustomParamTypesEnum, theme } from '../../../constants';
import { useNotificationsContext } from '../../../context';
import { GET_CUSTOM_PARAMS } from '../../../graphql';
import {
  useCustomParam,
  useCustomParamOption,
  useDeleteCustomParam,
  useDeleteCustomParamOption,
  useGetCustomParam,
  useHistory,
  useIntl,
  useParams,
} from '../../../hooks';
import { createCustomParamSchema } from '../../../services/validation';
import { transformSelectOptions } from '../../../utils';
import { styles } from './styles';

const CustomProductParam = () => {
  const params = useParams();
  const id = params?.id;
  const intl = useIntl();
  const navigation = useHistory();
  const { showNotification } = useNotificationsContext();
  const { data, ...queryProps } = useGetCustomParam({
    fetchPolicy: 'network-only',
  });

  const [customParamMutation, { loading }] = useCustomParam();
  const [deleteCustomParamMutation, { loading: deleteCustomParamLoading }] =
    useDeleteCustomParam();

  const [customParamOptionMutation, { loading: customParamOprionLoading }] =
    useCustomParamOption();
  const [deleteCustomParamOptionMutation, { loading: deleteCustomParamOptionLoading }] =
    useDeleteCustomParamOption();

  const methods = useForm({
    resolver: yupResolver(createCustomParamSchema),
    reValidateMode: 'onBlur',
    defaultValues: {
      name: data?.customParam?.name || '',
      type: data?.customParam?.type || '',
      customParamOptions: data?.customParam?.customParamOptions || [],
    },
  });

  const values = methods.watch();
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'customParamOptions',
  });

  useEffect(() => {
    methods.reset({
      name: data?.customParam?.name || '',
      type: data?.customParam?.type || '',
      customParamOptions: data?.customParam?.customParamOptions || [],
    });

    setTimeout(() => {
      methods.reset({
        ...methods.getValues(),
      });
    }, 300);
  }, [data, methods.reset, id]);

  const handleFormSubmit = (values) => {
    customParamMutation({
      variables: {
        input: {
          ...(id && { id: Number(id) }),
          name: values.name,
          type: values.type,
        },
      },
      onCompleted: (response) => {
        if (
          response.customParam.id &&
          (response.customParam.type === CustomParamTypesEnum.SingleOption ||
            response.customParam.type === CustomParamTypesEnum.MultiOptions)
        ) {
          values.customParamOptions.forEach((field) => {
            customParamOptionMutation({
              variables: {
                input: {
                  customParamId: Number(response.customParam.id),
                  name: field.name,
                  ...(!!field.id && { id: Number(field.id) }),
                },
              },
            });
          });
        }
        showNotification({ message: 'customparam.paramSaved' });
        navigation.goBack();
      },
      refetchQueries: [{ query: GET_CUSTOM_PARAMS }],
    });
  };

  const handleDeleteCustomParam = () => {
    deleteCustomParamMutation({
      variables: {
        deleteCustomParamId: Number(id),
      },
    });
  };

  const handleAddParam = () => {
    append({
      name: '',
    });
  };

  const handleRemoveParam = (index) => () => {
    const optionForDelete = values.customParamOptions[index];
    if (optionForDelete?.id) {
      deleteCustomParamOptionMutation({
        variables: { deleteCustomParamOptionId: Number(optionForDelete.id) },
      });
    } else {
      remove(index);
    }
  };

  const typeOptions = Object.values(CustomParamTypesEnum).map((key) => ({
    name: `${intl.formatMessage({
      id: `customparam.${key}`,
    })}`,
    value: key,
  }));

  const errors = methods.formState.errors;

  const isLoading =
    loading ||
    deleteCustomParamLoading ||
    customParamOprionLoading ||
    deleteCustomParamOptionLoading;

  return (
    <RequestHandler {...queryProps}>
      <FormWrapper>
        <FormProvider {...methods}>
          <Layout
            onActionButtonPress={methods.handleSubmit(handleFormSubmit)}
            actionButtonType='save'
            actionButtonIsLoading={isLoading}
            onRemoveButtonPress={handleDeleteCustomParam}
          >
            <FormRow>
              <FormRow.Left>
                <BoxWrapper>
                  <Typography intlId='app.customparam' variant='heading' />

                  <TextFieldHookFrom
                    name='name'
                    label='app.name'
                    type='text'
                    width={'100%'}
                  />

                  <SelectHookForm
                    name='type'
                    label={'app.type'}
                    options={transformSelectOptions({
                      data: typeOptions,
                      value: 'value',
                      label: 'name',
                    })}
                  />
                  {values.type === CustomParamTypesEnum.SingleOption ||
                  values.type === CustomParamTypesEnum.MultiOptions ? (
                    <>
                      {fields.map((field, index) => (
                        <Box key={field.id}>
                          <Flex alignItems='center'>
                            <Text style={styles.text}>{`${intl.formatMessage({
                              id: `app.customParamOption`,
                            })} ${index + 1}`}</Text>
                            <MaterialIcons
                              onPress={handleRemoveParam(index)}
                              name='delete'
                              size={25}
                              color={theme.colors.primary}
                            />
                          </Flex>
                          <TextFieldHookFrom
                            type='text'
                            width={'100%'}
                            name={`customParamOptions.${index}.name`}
                          />
                        </Box>
                      ))}
                      <Box>
                        <Button
                          intlId='app.add'
                          onPress={handleAddParam}
                          variant='ghost'
                        />
                        {!fields.length && errors.customParamOptions && (
                          <Typography
                            intlId={intl.formatMessage({
                              id: `customparam.addOption`,
                            })}
                            fontSize='xs'
                            color='error'
                          />
                        )}
                      </Box>
                    </>
                  ) : null}
                </BoxWrapper>
              </FormRow.Left>
              <FormRow.Right></FormRow.Right>
            </FormRow>
          </Layout>
        </FormProvider>
      </FormWrapper>
    </RequestHandler>
  );
};

export default CustomProductParam;
