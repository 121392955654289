export const getValue = (item, value) => {
  let result = {};

  if (Array.isArray(value)) {
    value.forEach((el) => {
      result[el] = item?.[el];
    });
  } else {
    result = item?.[value];
  }

  return result;
};
