import PropTypes from 'prop-types';
import * as React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';

export const CheckIcon = ({ style, size = 14 }) => (
  <Svg
    width={size}
    height={size}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={style}
  >
    <Rect
      x={0.5}
      y={0.5}
      width={13}
      height={13}
      rx={2.5}
      fill='#7C7C7C'
      stroke='#828282'
    />
    <Path stroke='#fff' d='M10.146 4.646 5.904 8.89M6.182 8.475 3.353 5.646' />
  </Svg>
);

CheckIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
