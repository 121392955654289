import '@expo/match-media';

import { useMediaQuery as useMediaQueryResponsive } from 'react-responsive';

const getQuery = (type) => {
  switch (type) {
    case 'isDesktop':
      return '(min-width: 700px)';

    case 'isMobile':
      return '(max-width: 700px)';

    default:
      return '(min-width: 700px)';
  }
};

const useMediaQuery = (type) => {
  return useMediaQueryResponsive({
    query: getQuery(type),
  });
};

export default useMediaQuery;
