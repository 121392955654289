import { Formik } from 'formik';
import { Box } from 'native-base';
import React, { useMemo } from 'react';

import {
  BoxWrapper,
  Checkbox,
  FieldRow,
  FormRow,
  FormWrapper,
  Layout,
  RequestHandler,
  Select,
  TextField,
  Typography,
} from '../../../../../components';
import { IS_MOBILE_SCREEN } from '../../../../../constants';
import {
  useCreateAlloOrderStatusMap,
  useDeleteAlloOrderStatusMap,
  useGetAlloOrderStatusMapQuery,
  useGetStatusesQuery,
  useParams,
  useUpdateAlloOrderStatusMap,
} from '../../../../../hooks';
import { createAlloOrderStatusSchema } from '../../../../../services/validation';
import { transformSelectOptions } from '../../../../../utils';

const AlloOrderStatus = () => {
  const { id: alloStatusId, statusId } = useParams();

  const isEdit = !!alloStatusId && !!statusId;

  const { data, ...queryProps } = useGetAlloOrderStatusMapQuery({
    variables: {
      args: { alloStatusId: Number(alloStatusId), statusId: Number(statusId) },
    },
    skip: !isEdit,
    fetchPolicy: 'no-cache',
  });

  const { data: statusesData } = useGetStatusesQuery();

  const [createAlloOrderStatusMapMutation, { loading }] = useCreateAlloOrderStatusMap();
  const [updateAlloOrderStatusMapMutation, { loading: updateAlloOrderStatusMapLoading }] =
    useUpdateAlloOrderStatusMap();
  const [deleteAlloOrderStatusMapMutation, { loading: deleteAlloOrderStatusMapLoading }] =
    useDeleteAlloOrderStatusMap();

  const initialValues = useMemo(() => {
    return {
      alloStatusName: data?.alloOrderStatusMap?.alloStatusName || '',
      statusId: data?.alloOrderStatusMap?.statusId?.toString() || '',
      alloStatusId: data?.alloOrderStatusMap?.alloStatusId?.toString() || '',
      isAlloStatusDefault: data?.AaloOrderStatusMap?.isAlloStatusDefault || false,
      isStatusDefault: data?.alloOrderStatusMap?.isStatusDefault || false,
    };
  }, [data]);

  const handleFormSubmit = (values) => {
    if (isEdit) {
      updateAlloOrderStatusMapMutation({
        variables: {
          input: {
            alloStatusName: values.alloStatusName,
            statusId: Number(statusId),
            isAlloStatusDefault: values.isAlloStatusDefault,
            isStatusDefault: values.isStatusDefault,
            alloStatusId: Number(values.alloStatusId),
          },
        },
      });
    } else {
      createAlloOrderStatusMapMutation({
        variables: {
          input: {
            alloStatusName: values.alloStatusName,
            statusId: Number(values.statusId),
            isAlloStatusDefault: values.isAlloStatusDefault,
            isStatusDefault: values.isStatusDefault,
            alloStatusId: Number(values.alloStatusId),
          },
        },
      });
    }
  };

  const handleDeleteAlloOrderStatusMap = () => {
    deleteAlloOrderStatusMapMutation({
      variables: {
        params: { alloStatusId: Number(alloStatusId), statusId: Number(statusId) },
      },
    });
  };

  return (
    <RequestHandler {...queryProps}>
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={createAlloOrderStatusSchema}
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <Layout
              onActionButtonPress={handleSubmit}
              actionButtonType='save'
              actionButtonIsLoading={
                loading ||
                updateAlloOrderStatusMapLoading ||
                deleteAlloOrderStatusMapLoading
              }
              onRemoveButtonPress={handleDeleteAlloOrderStatusMap}
            >
              <FormRow>
                <FormRow.Left>
                  <BoxWrapper>
                    <Typography intlId='allo.status' variant='heading' />

                    <FieldRow withNegativeBottomMargin={false}>
                      <TextField name='alloStatusName' label='allo.name' />
                    </FieldRow>

                    <FieldRow withNegativeBottomMargin={false}>
                      <TextField
                        name='alloStatusId'
                        label='allo.status'
                        type='number'
                        inputType='number'
                      />
                    </FieldRow>

                    <FieldRow withNegativeBottomMargin={false}>
                      <Select
                        name='statusId'
                        label='orderStatuses.statusCRM'
                        options={transformSelectOptions({
                          data: statusesData?.statuses,
                        })}
                      />
                    </FieldRow>
                    <FieldRow withNegativeBottomMargin={false}>
                      <Box ml={2} pb={3}>
                        <Checkbox
                          mt={!IS_MOBILE_SCREEN ? '16px' : 0}
                          justify={!IS_MOBILE_SCREEN ? 'flex-start' : 'center'}
                          label={'orderStatuses.statusDefaultCRM'}
                          onChange={() =>
                            setFieldValue('isStatusDefault', !values.isStatusDefault)
                          }
                          checked={values.isStatusDefault}
                        />
                      </Box>
                    </FieldRow>
                    <FieldRow withNegativeBottomMargin={false}>
                      <Box ml={2} pb={3}>
                        <Checkbox
                          mt={!IS_MOBILE_SCREEN ? '16px' : 0}
                          justify={!IS_MOBILE_SCREEN ? 'flex-start' : 'center'}
                          label={'allo.statusDefault'}
                          onChange={() =>
                            setFieldValue(
                              'isAlloStatusDefault',
                              !values.isAlloStatusDefault,
                            )
                          }
                          checked={values.isAlloStatusDefault}
                        />
                      </Box>
                    </FieldRow>
                  </BoxWrapper>
                </FormRow.Left>
              </FormRow>
            </Layout>
          )}
        </Formik>
      </FormWrapper>
    </RequestHandler>
  );
};

export default AlloOrderStatus;
