import { MaterialIcons } from '@expo/vector-icons';
import { BarCodeScanner as ExpoBarCodeScanner } from 'expo-barcode-scanner';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Modal, StyleSheet, TouchableOpacity, View } from 'react-native';

import { theme } from '../../constants';
import BarCodeReader from '../BarCodeReader/BarCodeReader';
import { BoxWrapper } from '../BoxWrapper/BoxWrapper';
import { Button } from '../Button/Button';
import { Typography } from '../Typography/Typography';
import { styles } from './styles';

export const BarCodeScanner = ({
  isOpen,
  onClose,
  onChangeCode,
  closeAfterAdding = true,
  loading = false,
}) => {
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const [code, setCode] = useState(null);

  useEffect(() => {
    (async () => {
      const { status } = await ExpoBarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  const handleBarCodeScanned = ({ data }) => {
    setCode(data);
    setScanned(true);
  };

  const handleRepeatButtonClick = () => {
    setScanned(false);
    setCode(null);
  };

  const handleCloseButtonClick = () => {
    if (!loading) {
      handleRepeatButtonClick();
      onClose();
    }
  };

  const handleAddButtonClick = () => {
    onChangeCode(code);
    if (closeAfterAdding) {
      handleCloseButtonClick();
    }
  };

  return (
    <Modal
      animationType='slide'
      transparent={true}
      visible={isOpen}
      onRequestClose={onClose}
    >
      <View style={styles.inner}>
        <View style={styles.header}>
          <TouchableOpacity onPress={handleCloseButtonClick} style={styles.backIcon}>
            <MaterialIcons name='arrow-back' size={24} color={theme.colors.white} />
          </TouchableOpacity>
          <Typography intlId='app.scanCode' color='white' fontSize={16} />
        </View>
        {hasPermission ? (
          <>
            <ExpoBarCodeScanner
              onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
              style={StyleSheet.absoluteFillObject}
            />
            <BoxWrapper style={styles.footer}>
              {code && (
                <>
                  <BarCodeReader value={code} />
                  <Typography fontSize={20} align='center' marginBottom={16}>
                    {code}
                  </Typography>
                </>
              )}
              <View style={styles.actions}>
                <Button
                  intlId='app.repeat'
                  variant='link'
                  onPress={handleRepeatButtonClick}
                  isLoading={loading}
                />
                <Button
                  intlId='app.add'
                  onPress={handleAddButtonClick}
                  isLoading={loading}
                />
              </View>
            </BoxWrapper>
          </>
        ) : (
          <View style={styles.centerView}>
            <Typography intlId='app.noAccessToCamera' color='white' align='center' />
          </View>
        )}
      </View>
    </Modal>
  );
};

BarCodeScanner.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChangeCode: PropTypes.func.isRequired,
  closeAfterAdding: PropTypes.bool,
  loading: PropTypes.bool,
};
