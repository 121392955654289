import PropTypes from 'prop-types';
import React from 'react';

import { Typography } from '../Typography/Typography';

export const AccountWithAmount = ({ accountTitle, amount }) => {
  return (
    <>
      <Typography color={'#828282'}>{`${accountTitle}\n`}</Typography>
      <Typography>{amount}</Typography>
    </>
  );
};

AccountWithAmount.propTypes = {
  accountTitle: PropTypes.string,
  amount: PropTypes.string,
};
