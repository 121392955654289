import gql from 'graphql-tag';

export default gql`
  query phones($ids: [ID]) {
    phones(ids: $ids) {
      id
      phone
    }
  }
`;
