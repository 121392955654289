export const text = {
  Text: {
    baseStyle: {
      color: 'text.default',
      fontSize: 'md',
      fontWeight: 'normal',
    },
    variants: {
      heading: () => ({
        color: 'dark',
        fontSize: 'lg',
        fontWeight: 'normal',
        marginBottom: 'lg',
      }),
    },
  },
};
