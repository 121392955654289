import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

const AccessContext = createContext(null);

const useAccessContext = () => {
  const context = useContext(AccessContext);
  if (!context) {
    throw new Error('useAccessContext must be used within a AccessProvider');
  }
  return context;
};

const AccessProvider = ({ children }) => {
  const [isAccessAllowed, setIsAccessAllowed] = useState(true);

  return (
    <AccessContext.Provider value={{ isAccessAllowed, setIsAccessAllowed }}>
      {children}
    </AccessContext.Provider>
  );
};

AccessProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AccessProvider, useAccessContext };
