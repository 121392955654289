import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

import NavigationDrawer from '../components/NavigationDrawer/NavigationDrawer';

const DrawerMenuContext = createContext(null);

const DrawerMenuProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DrawerMenuContext.Provider
      value={{ isDrawerMenuOpen: isOpen, setIsDrawerMenuOpen: setIsOpen }}
    >
      {isOpen ? <NavigationDrawer isOpen={isOpen} setIsOpen={setIsOpen} /> : null}
      {children}
    </DrawerMenuContext.Provider>
  );
};

const useDrawerMenuContext = () => {
  return useContext(DrawerMenuContext);
};

DrawerMenuProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { DrawerMenuContext, DrawerMenuProvider, useDrawerMenuContext };
