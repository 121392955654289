import * as Yup from 'yup';

export default Yup.object().shape({
  status: Yup.string(),
  user: Yup.string(),
  source: Yup.string(),
  customer: Yup.string(),
  dateFrom: Yup.string(),
  dateTo: Yup.string(),
  payment: Yup.string(),
});
