import { MaterialIcons } from '@expo/vector-icons';
import { Modal as NBModal } from 'native-base';
import React, { useState } from 'react';
import { Modal, TouchableOpacity, View } from 'react-native';

import { HasScopesHandler, TouchableLink, Typography } from '../../../components';
import { IS_APP } from '../../../constants';
import { root as routes } from '../../../constants/navigationData';
import { useHistory, useIntl, useRoute } from '../../../hooks';
import { getRouteName } from '../../../utils';
import HandlerClickOutside from '../../HandlerClickOutside/HandlerClickOutside';
import { styles } from './styles';

export const NavigationPanel = () => {
  const intl = useIntl();
  const { root } = useRoute();

  const navigation = useHistory();

  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleItemClick = (route) => {
    setIsOpen(false);
    navigation.push(`/${route}`);
  };

  const checkSectionActiveStyle = (routeLabel) => {
    return root === routeLabel.split('/')[0]?.toLowerCase() && styles.sectionActive;
  };

  return (
    <View style={styles.wrapper}>
      <MaterialIcons name='apps' size={24} color='#fff' onPress={handleButtonClick} />
      {IS_APP ? (
        <NBModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <View style={styles.inner}>
            {routes.map((route) => (
              <HasScopesHandler
                name={route.name}
                scopesArray={route?.scopes}
                key={route.name}
              >
                <TouchableOpacity
                  style={[
                    styles.section,
                    styles.sectionWrapper,
                    checkSectionActiveStyle(route.label),
                  ]}
                  onPress={() => handleItemClick(route.label)}
                >
                  <View style={styles.iconWrapper}>
                    <route.icon size={40} />
                  </View>
                  <Typography fontSize={13} numberOfLines={1}>
                    {getRouteName(route.name, intl)}
                  </Typography>
                </TouchableOpacity>
              </HasScopesHandler>
            ))}
          </View>
        </NBModal>
      ) : (
        <Modal transparent={true} visible={isOpen}>
          <HandlerClickOutside onClickOutside={() => setIsOpen(false)}>
            <View style={styles.inner}>
              {routes.map((route) => (
                <HasScopesHandler
                  name={route.name}
                  scopesArray={route?.scopes}
                  key={route.name}
                >
                  <TouchableLink
                    wrapperStyle={styles.sectionWrapper}
                    childrenStyle={[styles.section, checkSectionActiveStyle(route.label)]}
                    to={`/${route?.label}`}
                    onClick={() => setIsOpen(false)}
                  >
                    <View style={styles.iconWrapper}>
                      <route.icon size={40} />
                    </View>
                    <Typography fontSize={13} numberOfLines={1}>
                      {getRouteName(route.name, intl)}
                    </Typography>
                  </TouchableLink>
                </HasScopesHandler>
              ))}
            </View>
          </HandlerClickOutside>
        </Modal>
      )}
    </View>
  );
};
