import React, { useState } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { Snackbar } from 'react-native-paper';
import styles from './styles';

function Alert({ children, type }) {
  const [visible, setVisible] = useState(true);

  if (!visible) return null;

  return (
    <View style={styles.container}>
      <Snackbar
        theme={{ colors: { accent: '#FFFFFF' } }}
        style={styles[type]}
        visible={visible}
        onDismiss={() => setVisible(false)}
        duration={3000}
        action={{
          label: 'Hide',
          onPress: () => {
            setVisible(false);
          },
        }}
      >
        {children}
      </Snackbar>
    </View>
  );
}

Alert.propTypes = {
  children: PropTypes.any,
  type: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
};

export default Alert;
