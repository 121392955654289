import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

import { colors } from '../../theme';

export const DeliveredIcon = ({ size = 40, style = {} }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ ...style }}
    >
      <Circle cx='20' cy='20' r='20' fill={colors.blue.dark} />
      <Path
        d='M14.5007 25.4167H16.334V27.25H14.5007V25.4167ZM20.0007 10.75L10.834 15.3333V27.25H12.6673V19.9167H27.334V27.25H29.1673V15.3333L20.0007 10.75ZM16.334 18.0833H12.6673V16.25H16.334V18.0833ZM21.834 18.0833H18.1673V16.25H21.834V18.0833ZM27.334 18.0833H23.6673V16.25H27.334V18.0833ZM14.5007 21.75H16.334V23.5833H14.5007V21.75ZM18.1673 21.75H20.0007V23.5833H18.1673V21.75ZM18.1673 25.4167H20.0007V27.25H18.1673V25.4167ZM21.834 25.4167H23.6673V27.25H21.834V25.4167Z'
        fill='white'
      />
    </Svg>
  );
};

DeliveredIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
