export const getTransactionAmount = (
  amount,
  transactionType,
  originalSymbol,
  { currencySymbol, originalCurrencyRate = 1, targetCurrencyRate = 1 },
) => {
  if (originalCurrencyRate === targetCurrencyRate) {
    originalCurrencyRate = 1;
    targetCurrencyRate = 1;
  }

  let sign;
  switch (transactionType) {
    case 'income':
      sign = '+';
      break;
    case 'spend':
      sign = '-';
      break;
    default:
      sign = '';
  }

  return `${sign}${((amount / originalCurrencyRate) * targetCurrencyRate).toFixed(
    0,
  )} ${currencySymbol}`;
};
