import _ from 'lodash';

export const getPropFromObj = (obj, key) => {
  let p = 0;

  const path = _.toPath(key);

  while (obj && p < path.length) {
    obj = obj[path[p++]];
  }

  return obj;
};
