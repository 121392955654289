import { Flex } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Checkbox, TextFieldHookFrom } from '../../../../components';
import { MultiSelectHookForm } from '../../../../components/Select/MultiSelectHookForm';
import { SelectHookForm } from '../../../../components/Select/SelectHookForm';
import { CustomParamTypesEnum } from '../../../../constants';
import { transformSelectOptions } from '../../../../utils';

const Param = ({ type, index, options }) => {
  const { watch, setValue } = useFormContext();

  const booleanValue = watch(`productCustomParams.${index}.booleanValue`);

  const handleChange = (value) => () => {
    setValue(`productCustomParams.${index}.booleanValue`, value);
  };

  switch (type) {
    case CustomParamTypesEnum.Text:
      return (
        <TextFieldHookFrom
          width={'100%'}
          name={`productCustomParams.${index}.textValue`}
        />
      );
    case CustomParamTypesEnum.Number:
      return (
        <TextFieldHookFrom
          width={'100%'}
          name={`productCustomParams.${index}.numberValue`}
          type='number'
        />
      );

    case CustomParamTypesEnum.Boolean:
      return (
        <Flex>
          <Checkbox
            checked={booleanValue}
            onChange={handleChange(true)}
            label='app.yes'
            mr={5}
          />
          <Checkbox
            checked={!booleanValue}
            onChange={handleChange(false)}
            label='app.no'
          />
        </Flex>
      );
    case CustomParamTypesEnum.Range:
      return (
        <>
          <TextFieldHookFrom
            width={'100%'}
            name={`productCustomParams.${index}.rangeFromValue`}
            type='number'
          />
          <TextFieldHookFrom
            width={'100%'}
            name={`productCustomParams.${index}.rangeToValue`}
            type='number'
          />
        </>
      );
    case CustomParamTypesEnum.SingleOption:
      return (
        <SelectHookForm
          name={`productCustomParams.${index}.customParamOptionId`}
          options={transformSelectOptions({
            data: options,
          })}
          width={'100%'}
        />
      );
    case CustomParamTypesEnum.MultiOptions:
      return (
        <MultiSelectHookForm
          name={`productCustomParams.${index}.customParamOptionIds`}
          options={transformSelectOptions({
            data: options,
          })}
          width={'100%'}
        />
      );

    default:
      return null;
  }
};

export default Param;

Param.propTypes = {
  type: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  options: PropTypes.array,
};
