import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-native';
import { IS_DESKTOP_SCREEN } from '../../constants';
import { Box, Flex, Image, View } from 'native-base';
import { useIntl } from 'react-intl';
import { Button } from '../Button/Button';
import { styles } from './styles';
import { BoxWrapper } from '../BoxWrapper/BoxWrapper';

const ReceiptModal = ({ isOpen, onClose, url }) => {
  const intl = useIntl();
  return (
    <Modal
      animationType='none'
      visible={isOpen}
      transparent={IS_DESKTOP_SCREEN}
      onRequestClose={onClose}
    >
      <View style={styles.modalOverlay}>
        <BoxWrapper style={styles.modalWrapper} rounded='5px' mb={0} w={448} pb={6}>
          <Flex justify='flex-end' mb={5}>
            <Button intlId='app.close' onPress={onClose} variant='ghost' />
          </Flex>
          <Box>
            <Image
              width={400}
              height={600}
              source={url}
              resizeMode='contain'
              alt={intl.formatMessage({
                id: 'order.receipt',
              })}
            />
          </Box>
        </BoxWrapper>
      </View>
    </Modal>
  );
};

ReceiptModal.propTypes = {
  url: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ReceiptModal;
