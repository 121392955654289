import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const NewslettersSegmentsIcon = ({ size = 24, style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' style={{ ...style }}>
      <Path
        d='M13 2.05103V11H21.949C21.479 6.28302 17.717 2.52103 13 2.05103ZM17.969 20.004C20.158 18.367 21.663 15.864 21.949 13H13.766L17.969 20.004Z'
        fill='white'
      />
      <Path
        d='M11 12V2.05103C5.954 2.55503 2 6.82403 2 12C2 17.514 6.486 22 12 22C13.4723 21.9981 14.9257 21.6688 16.255 21.036C16.255 21.036 11.002 12.121 11.001 12.005C11.0009 12.0033 11.0005 12.0016 11 12Z'
        fill='white'
      />
    </Svg>
  );
};

NewslettersSegmentsIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
