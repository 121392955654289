import { MaterialIcons } from '@expo/vector-icons';
import { Box, Icon } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { Pressable, StyleSheet } from 'react-native';

const OnChangeButton = ({ onClick, iconName, iconColor }) => {
  return (
    <Box position='relative'>
      <Pressable onPress={onClick} style={styles.button}>
        <Icon name={iconName} as={MaterialIcons} color={iconColor} size='26px' />
      </Pressable>
    </Box>
  );
};

OnChangeButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
};

export default OnChangeButton;

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    borderRadius: 40,
    height: 40,
    justifyContent: 'center',
    position: 'absolute',
    right: 5,
    top: -20,
    width: 40,
  },
});
