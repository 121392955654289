import React, { useState, Component } from 'react';
import { Animated, View } from 'react-native';
import { FAB } from 'react-native-paper';
import { MaterialIcons } from '@expo/vector-icons';
import stylesGlobal from '../../../globalStyles';
import styles from './styles';

const FlyinIconRow = ({ handleRowClick, children }) => {
  const [selected, setSelected] = useState(false);
  return (
    <View
      onMouseEnter={() => setSelected(true)}
      onMouseLeave={() => setSelected(false)}
      style={[stylesGlobal.header, styles.row, selected && styles.rowActive]}
    >
      {children}
      <FlyinIcon visible={selected} handleRowClick={handleRowClick} />
    </View>
  );
};

class FlyinIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: props.visible,
    };
  }

  UNSAFE_componentWillMount() {
    this._visibility = new Animated.Value(this.props.visible ? 1 : 0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.visible) {
      this.setState({ visible: true });
    }
    Animated.timing(this._visibility, {
      toValue: nextProps.visible ? 1 : 0,
      duration: nextProps.visible ? 300 : 1000,
    }).start(() => {
      this.setState({ visible: nextProps.visible });
    });
  }

  render() {
    const { visible, handleRowClick, ...rest } = this.props;

    return (
      <Animated.View
        style={{
          right: this._visibility.interpolate({
            inputRange: [0, 1],
            outputRange: [-250, 50],
          }),
        }}
        {...rest}
      >
        <FAB
          icon={() => <MaterialIcons name='edit' size={25} color='#fff' />}
          onPress={handleRowClick}
          style={styles.buttonNav}
        />
      </Animated.View>
    );
  }
}

export default FlyinIconRow;
