import { useFormikContext } from 'formik';
import { Flex, Radio, Text, View } from 'native-base';
import React from 'react';
import { useIntl } from 'react-intl';

import { FormRow, Select, TextField } from '../../../../components';
import { useGetPayersTypesNP } from '../../../../hooks';
import { transformSelectOptions } from '../../../../utils';
import { CityAutoCompleteField } from './CityAutoCompleteField';
import { StreetAutoCompleteField } from './StreetAutoCompleteField';
import styles from './styles';
import { WarehouseAutoCompleteField } from './WarehouseAutoCompleteField';

export const NovaPoshtaFields = () => {
  const { data: payersTypes } = useGetPayersTypesNP();

  const intl = useIntl();

  const { values, setFieldValue } = useFormikContext();

  const { novaPoshtaShipping } = values;

  return (
    <>
      <FormRow>
        <FormRow.Left>
          <CityAutoCompleteField
            name='novaPoshtaShipping.cityRef'
            cityRef={novaPoshtaShipping?.cityRef}
          />
        </FormRow.Left>
        <FormRow.Right>
          <TextField
            name='deliveryInformation.ttn'
            label='app.ttn'
            keyboardType='numeric'
          />
        </FormRow.Right>
      </FormRow>
      <FormRow>
        <FormRow.Left>
          <Radio.Group
            defaultValue={novaPoshtaShipping?.serviceType}
            name='novaPoshtaShipping.serviceType'
            label={intl.formatMessage({ id: 'app.serviceType' })}
            onChange={(value) => setFieldValue('novaPoshtaShipping.serviceType', value)}
          >
            <View style={styles.serviceType}>
              <Radio value='WarehouseWarehouse'>
                <Text style={styles.radioLabel}>
                  {intl.formatMessage({ id: 'app.warehouse' })}
                </Text>
              </Radio>
              <Radio value='WarehouseDoors' style={styles.serviceTypeRadio}>
                <Text style={styles.radioLabel}>
                  {intl.formatMessage({ id: 'app.courier' })}
                </Text>
              </Radio>
            </View>
          </Radio.Group>
          <Select
            name='novaPoshtaShipping.payerType'
            label='app.payerType'
            options={transformSelectOptions({
              data: payersTypes?.getTypesOfPayersNovaPoshta,
              label: 'description',
              value: 'ref',
            })}
          />
        </FormRow.Left>
        <FormRow.Right>
          {novaPoshtaShipping?.serviceType === 'WarehouseWarehouse' &&
            novaPoshtaShipping?.cityRef && (
              <WarehouseAutoCompleteField
                cityRef={novaPoshtaShipping?.cityRef}
                warehouseRef={novaPoshtaShipping?.addressRef}
                name='novaPoshtaShipping.addressRef'
              />
            )}
        </FormRow.Right>
      </FormRow>
      {novaPoshtaShipping?.serviceType === 'WarehouseDoors' &&
        novaPoshtaShipping?.cityRef && (
          <FormRow>
            <FormRow.Left>
              <StreetAutoCompleteField
                cityRef={novaPoshtaShipping?.cityRef}
                streetRef={novaPoshtaShipping?.streetRef}
                name='novaPoshtaShipping.streetRef'
              />
            </FormRow.Left>
            <FormRow.Right>
              <Flex justifyContent='space-between'>
                <View width='40%'>
                  <TextField
                    name='novaPoshtaShipping.buildingNumber'
                    label='app.building'
                  />
                </View>
                <View width='50%'>
                  <TextField name='novaPoshtaShipping.flatNumber' label='app.flat' />
                </View>
              </Flex>
            </FormRow.Right>
          </FormRow>
        )}
    </>
  );
};
