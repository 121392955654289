/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/sort-styles */
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  picker: {
    height: 36,
    transform: [{ scaleX: 0.9 }, { scaleY: 0.9 }],
    width: 100,
  },
  view: {
    backgroundColor: 'transparent',
    borderBottomWidth: 1,
    borderBottomColor: '#333333',
    color: '#4F4F4F',
  },
});
