import React from 'react';
import Svg from 'react-native-svg';

export const CloseIcon = (props) => {
  return (
    <Svg width={14} height={14} viewBox='0 0 14 14' fill='none' {...props}>
      <g clipPath='url(#clip0_9431_4992)'>
        <line
          y1='-0.5'
          x2='18.1042'
          y2='-0.5'
          transform='matrix(0.695973 0.718068 -0.695973 0.718068 0.700195 1)'
          stroke='#4F4F4F'
        />
        <line
          y1='-0.5'
          x2='18.1042'
          y2='-0.5'
          transform='matrix(-0.695973 0.718068 -0.695973 -0.718068 13.2998 0)'
          stroke='#4F4F4F'
        />
      </g>
      <defs>
        <clipPath id='clip0_9431_4992'>
          <rect width='14' height='14' fill='white' />
        </clipPath>
      </defs>
    </Svg>
  );
};
