import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const LeftLowerCloud = (props) => {
  return (
    <Svg
      width='636'
      height='268'
      viewBox='0 0 636 268'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <Path
        d='M0 0C0 0 123.052 71.3207 245.588 56.2619C326.812 46.2799 400.373 71.991 443.284 107.986C485.298 143.229 492.708 199.639 562.438 203.874C632.169 208.108 636 268 636 268H0V0Z'
        fill='#47C8FF'
        fillOpacity='0.1'
      />
    </Svg>
  );
};
