import { Input } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { FieldWrapper } from '../../../components';
import { IS_MOBILE_SCREEN } from '../../../constants';
import { getPhoneOrEmail, getValueFromPath } from '../../../utils';

export const CustomerContactHookForm = (props) => {
  const { control, formState, getValues } = useFormContext();

  const errorMessageText = getValueFromPath(formState.errors, props.name)?.message;
  const hasError = Boolean(errorMessageText);
  const label = getPhoneOrEmail(getValues(props.name)) || ' ';

  return (
    <CustomerContactHookFormNested
      {...props}
      label={label}
      errorMessageText={errorMessageText}
      hasError={hasError}
      control={control}
    />
  );
};

const CustomerContactHookFormNested = memo(
  ({
    name,
    width,
    onFieldChanged,
    index,
    errorMessageText,
    hasError,
    label,
    control,
  }) => {
    const intl = useIntl();

    return (
      <FieldWrapper
        isInvalid={hasError}
        label={label}
        errorMessageText={errorMessageText}
        width={width || (IS_MOBILE_SCREEN ? '100%' : '50%')}
        pr='12px'
        pl='12px'
      >
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => {
            return (
              <Input
                value={value}
                onChangeText={(value) => {
                  onFieldChanged(value, index);
                  onChange(value);
                }}
                placeholder={intl.formatMessage({ id: 'app.phoneOrEmail' })}
                height={'33px'}
              />
            );
          }}
        />
      </FieldWrapper>
    );
  },
);

CustomerContactHookFormNested.displayName = 'CustomerContactHookForm';

CustomerContactHookFormNested.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  onFieldChanged: PropTypes.func,
  index: PropTypes.number,
  hasError: PropTypes.bool,
  errorMessageText: PropTypes.string,
  label: PropTypes.string,
  control: PropTypes.object,
};

CustomerContactHookForm.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  onFieldChanged: PropTypes.func,
  index: PropTypes.number,
};
