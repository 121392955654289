/* eslint-disable react-native/no-color-literals */
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  disabled: {
    borderColor: '#828282',
    color: '#828282',
  },
  field: {
    marginBottom: 5,
  },
  hasError: {
    borderColor: 'tomato',
  },
  hidden: {
    display: 'none',
  },
  input: {
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderColor: '#333333',
    color: '#4F4F4F',
    flex: 1,
    fontSize: 14,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 2,
    zIndex: 0,
  },
  inputLogin: {
    borderColor: '#fff',
    borderRadius: 3,
    borderWidth: 1,
    height: 48,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
  },
});
