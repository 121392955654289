import { StyleSheet, Dimensions, Platform } from 'react-native';
import { displayWidth } from '../../../constants/index';
const width = Dimensions.get('window').width >= displayWidth;

export default StyleSheet.create({
  container: {
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    flex: 1,
    justifyContent: 'space-between',
    width: !width ? '100%' : 'calc(100% - 70px)',
    height: '10%',
    bottom: 85,
    zIndex: 11,
    marginLeft: 0,
  },
  success: {
    backgroundColor: '#6FCF97',
  },
  error: {
    backgroundColor: '#EB5757',
  },
  info: {
    backgroundColor: '#47C8FF',
  },
  warning: {
    backgroundColor: '#F2994A',
  },
});
