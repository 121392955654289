import { Flex } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { BoxWrapper, Typography } from '../../../../components';

const ReportsMobileItem = ({ title, income, exchange, spend, total }) => {
  return (
    <BoxWrapper>
      <Typography fontSize='lg'>{title}</Typography>
      <Flex
        justify='space-between'
        paddingY='md'
        borderBottomWidth='1px'
        borderColor='gray.default'
      >
        <Typography intlId='app.parishes' />
        {income}
      </Flex>
      <Flex
        justify='space-between'
        paddingY='md'
        borderBottomWidth='1px'
        borderColor='gray.default'
      >
        <Typography intlId='app.conversions' />
        {exchange}
      </Flex>
      <Flex
        justify='space-between'
        paddingY='md'
        borderBottomWidth='1px'
        borderColor='gray.default'
      >
        <Typography intlId='app.costs' />
        {spend}
      </Flex>
      <Flex justify='space-between' paddingY='md'>
        <Typography intlId='app.total' color='dark' />
        <Typography fontWeight='700'>{total}</Typography>
      </Flex>
    </BoxWrapper>
  );
};

export default memo(ReportsMobileItem);

ReportsMobileItem.propTypes = {
  title: PropTypes.string.isRequired,
  income: PropTypes.string.isRequired,
  exchange: PropTypes.string.isRequired,
  spend: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
};
