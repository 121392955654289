import React from 'react';

import { Button, Layout, RequestHandler } from '../../../components';
import { useUpdateAllProductsToMarketplaces } from '../../../hooks';

const ServiceSettings = () => {
  const [updateAllProducts, { loading }] = useUpdateAllProductsToMarketplaces();

  const handleUpdateAllProducts = () => {
    updateAllProducts();
  };

  return (
    <Layout>
      <RequestHandler loading={loading}>
        <Button
          type='button'
          intlId='service.servicesettings'
          size='sm'
          onPress={handleUpdateAllProducts}
          w='136'
        />
      </RequestHandler>
    </Layout>
  );
};

export default ServiceSettings;
