import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const OrdersIcon = ({ size = '24', fill = 'white', style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' style={{ ...style }}>
      <Path
        d='M23.988 8.07076L22.9815 13.8447C22.8574 14.4825 22.3492 14.8703 21.7632 14.8776H7.09L6.79868 16.5197H20.7832C21.5196 16.5757 22.0191 17.0973 22.028 17.7645C21.9745 18.4994 21.4529 19.0002 20.7832 19.0094H5.31546C4.47892 18.9341 3.9801 18.2673 4.07062 17.5262L4.73276 13.9241L3.7263 3.80648L0.865838 2.90602C0.166818 2.62848 -0.118482 1.99612 0.0447779 1.34336C0.316238 0.663064 0.966698 0.352244 1.60744 0.522304L5.26248 1.68768C5.75488 1.87226 6.03814 2.27532 6.11002 2.74712L6.3219 4.76004L22.902 6.61404C23.6638 6.77906 24.0774 7.38248 23.988 8.07076ZM9.10544 21.6231C9.10544 22.6727 8.25464 23.5235 7.20508 23.5235C6.15554 23.5235 5.30474 22.6727 5.30474 21.6231C5.30474 20.5736 6.15556 19.7228 7.20508 19.7228C8.25462 19.7228 9.10544 20.5736 9.10544 21.6231ZM20.4505 21.6231C20.4505 22.6727 19.5997 23.5235 18.5501 23.5235C17.5006 23.5235 16.6498 22.6727 16.6498 21.6231C16.6498 20.5736 17.5006 19.7228 18.5501 19.7228C19.5997 19.7228 20.4505 20.5736 20.4505 21.6231Z'
        fill={fill}
      />
    </Svg>
  );
};

OrdersIcon.propTypes = {
  size: PropTypes.string,
  style: PropTypes.object,
  fill: PropTypes.string,
};
