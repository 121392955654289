import { MaterialIcons } from '@expo/vector-icons';
import React from 'react';

import { IS_DESKTOP_SCREEN, IS_MOBILE_SCREEN, ROUTES } from '../../../constants';
import { useDrawerMenuContext } from '../../../context';
import { useHistory, useRoute } from '../../../hooks';
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../../Breadcrumbs/BreadcrumbsItem';
import { styles } from './styles';

export const HeaderLeft = () => {
  const navigation = useHistory();
  const { path } = useRoute();
  const { setIsDrawerMenuOpen } = useDrawerMenuContext();

  if (ROUTES.isSingleScreen(path) && IS_MOBILE_SCREEN) {
    return (
      <>
        <MaterialIcons
          name='arrow-back'
          size={25}
          color='#fff'
          style={styles.backButton}
          onPress={navigation.goBack}
        />
        <BreadcrumbsItem />
      </>
    );
  }

  if (IS_DESKTOP_SCREEN) return <Breadcrumbs />;

  return (
    <MaterialIcons
      name='menu'
      size={25}
      color='#fff'
      onPress={() => setIsDrawerMenuOpen(true)}
    />
  );
};
