import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
  addButton: {
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    zIndex: 3,
    right: 10,
    bottom: 40,
    backgroundColor: '#47C8FF',
  },
  addButtonWeb: {
    top: 96,
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    zIndex: 10,
    right: 78,
    backgroundColor: '#47C8FF',
  },
});
