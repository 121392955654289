import PropTypes from 'prop-types';
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

export const BankIcon = ({ size, style }) => (
  <Svg
    width={size}
    height={size}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={style}
  >
    <Path
      d='M4 10h3v7H4v-7Zm6.5 0h3v7h-3v-7ZM2 19h20v3H2v-3Zm15-9h3v7h-3v-7Zm-5-9L2 6v2h20V6L12 1Z'
      fill='#fff'
    />
  </Svg>
);

BankIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
