import { Box, Flex } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { TouchableOpacity } from 'react-native';

import { Typography } from '../../../../components';
import {
  ArrowAgainstMobileIcon,
  CompletedMobileIcon,
} from '../../../../components/Icons';
import { formatDateTime } from '../../../../utils';

const PurchasesMobileItem = ({
  id,
  onItemPress,
  name,
  date,
  source,
  parcel,
  forwarding,
  price,
  deliveryPrice,
  quantity,
}) => {
  const intl = useIntl();
  return (
    <TouchableOpacity onPress={() => onItemPress(id)}>
      <Box
        borderBottomWidth='1px'
        borderColor='gray.light'
        backgroundColor='white'
        borderRadius={'5px'}
        px={'md'}
        py={'18px'}
        mb={'8px'}
      >
        <Flex direction='row' align={'center'} mb='17px'>
          <CompletedMobileIcon />
          <Typography
            color={'primary.default'}
            fontSize={15}
            fontFamily={'medium'}
            ml={'12px'}
          >
            {name}
          </Typography>
        </Flex>

        <Flex direction='row' align={'center'} mb='12px' justify='space-between'>
          <Typography fontSize={'15px'}>
            {`№${id} ${intl.formatMessage({ id: 'app.from' })} ${formatDateTime(
              +date,
              'dd.mm.yyyy',
            )}`}
          </Typography>
          <Typography color={'pink'} fontSize={16} fontFamily={'medium'}>
            {source}
          </Typography>
        </Flex>

        <Flex direction='row' align={'center'} justify='space-between' mb={'10px'}>
          <Typography fontSize={'14px'}>{parcel}</Typography>
          <ArrowAgainstMobileIcon />
          <Typography fontSize={'14px'}>{forwarding}</Typography>
        </Flex>

        <Flex direction='row' align={'center'} justify='space-between'>
          <Box>
            <Typography intlId='app.cost' color='gray.main' mb={'4px'} />
            <Typography>{`${price} $`}</Typography>
          </Box>
          <Box>
            <Typography intlId='app.shippingUnit' color='gray.main' mb={'4px'} />
            <Typography>{`${deliveryPrice} $`}</Typography>
          </Box>
          <Box>
            <Typography intlId='app.quantity' color='gray.main' mb={'4px'} />
            <Typography>{`${quantity} ${intl.formatMessage({
              id: 'app.pcs',
            })}.`}</Typography>
          </Box>
        </Flex>
      </Box>
    </TouchableOpacity>
  );
};

export default memo(PurchasesMobileItem);

PurchasesMobileItem.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  onItemPress: PropTypes.func,
  date: PropTypes.string,
  source: PropTypes.string,
  parcel: PropTypes.string,
  forwarding: PropTypes.string,
  price: PropTypes.string,
  deliveryPrice: PropTypes.string,
  quantity: PropTypes.number,
};
