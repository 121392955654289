import { FORWARDING_STATUS_IDS, PARCEL_STATUS_IDS } from '../constants';

export const getStatus = (item, intl) => {
  if (item.source?.isInternational) {
    if (item.forwarding) {
      if (item.forwarding?.status?.id === FORWARDING_STATUS_IDS.onTheWay)
        return intl.formatMessage({ id: 'app.deliveryukraine' });
      if (item.forwarding?.status?.id === FORWARDING_STATUS_IDS.inWarehouse)
        return intl.formatMessage({ id: 'app.deliveryArrived' });
    } else {
      if (item.parcel?.status?.id === PARCEL_STATUS_IDS.onTheWay)
        return intl.formatMessage({ id: 'app.usshipping' });
      if (item.parcel?.status?.id === PARCEL_STATUS_IDS.inWarehouse)
        return intl.formatMessage({ id: 'app.deliveryInCarrierWarehouse' });
    }
  } else {
    if (item.parcel?.status?.id === PARCEL_STATUS_IDS.onTheWay)
      return intl.formatMessage({ id: 'app.deliveryInUkraine' });
    if (item.parcel?.status?.id === PARCEL_STATUS_IDS.inWarehouse)
      return intl.formatMessage({ id: 'app.deliveryInCarrierWarehouse' });
  }
};
