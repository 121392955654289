import { StyleSheet } from 'react-native';

import { DEVICE_WIDTH, IS_MOBILE_SCREEN, theme } from '../../../constants';

// IS_MOBILE_SCREEN

export default StyleSheet.create({
  buttonCancel: {
    backgroundColor: theme.colors.mediumGray,
    marginBottom: IS_MOBILE_SCREEN ? 24 : 0,
    marginRight: IS_MOBILE_SCREEN ? 0 : 24,
  },
  modalDescription: {
    color: theme.colors.gray,
    fontSize: 12,
    marginBottom: IS_MOBILE_SCREEN ? 50 : 32,
  },
  modalTitle: {
    color: theme.colors.dark,
    fontSize: 16,
    fontWeight: '500',
    marginBottom: IS_MOBILE_SCREEN ? 8 : 16,
  },
  modalWrapper: {
    backgroundColor: theme.colors.white,
    borderRadius: 5,
    padding: 24,
    width: IS_MOBILE_SCREEN ? DEVICE_WIDTH - 16 : 528,
  },
});
