import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { useMultiSelect } from '../../hooks';
import { MultiSelectOptions } from '../MultiSelectOptions/MultiSelectOptions';
import { Select } from '../Select/Select';

export const MultiSelect = ({
  name,
  label,
  placeholder = 'Выберите',
  options,
  selectedItemsOptions,
  ...props
}) => {
  const { onChange, onRemove } = useMultiSelect(name, options);
  const [field] = useField(name);

  return (
    <>
      <Select
        name={name}
        label={label}
        placeholder={placeholder}
        options={options}
        onChange={onChange}
        {...props}
      />
      <MultiSelectOptions
        value={field?.value}
        onRemove={onRemove}
        {...selectedItemsOptions}
      />
    </>
  );
};

MultiSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isSearchable: PropTypes.bool,
  options: PropTypes.array,
  selectedItemsOptions: PropTypes.object,
};
