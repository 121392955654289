import React from 'react';

import { BackToMainPageButton, Typography } from '../../components';
import { AccessClosedCenter, ErrorPageWrapper } from '../../components/ErrorPages';
import { useAccessContext } from '../../context';

const AccessClosed = () => {
  const { isAccessAllowed, setIsAccessAllowed } = useAccessContext();

  return (
    <ErrorPageWrapper>
      <AccessClosedCenter />
      <Typography mt={10} intlId='errors.sorry' fontFamily='light' fontSize='4xl' />
      <Typography
        maxWidth={400}
        mt={4}
        textAlign={'center'}
        intlId='errors.accessClosed'
        fontSize='xl'
      />
      <BackToMainPageButton
        onPress={() => !isAccessAllowed && setIsAccessAllowed(true)}
      />
    </ErrorPageWrapper>
  );
};

export default AccessClosed;
