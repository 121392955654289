import { Formik } from 'formik';
import { View } from 'native-base';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import {
  BoxWrapper,
  FormRow,
  Layout,
  MobileTable,
  RequestHandler,
} from '../../../components';
import { IS_DESKTOP_SCREEN } from '../../../constants';
import { useAddStockLocation, useStockLocationsQuery } from '../../../hooks';
import { addLocationSchema } from '../../../services/validation';
import AccordionItem from './AccordionItem/AccordionItem';
import AccordionMobileItem from './AccordionItem/AccordionMobileItem';
import styles from './styles';

const BoxesArrangement = () => {
  const [isAddLocation, setIsAddLocation] = useState(false);
  const [nestedStructureData, setNestedStructureData] = useState([]);
  const [textFieldId, setTextFieldId] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const initValues = useMemo(() => ({ parentId: '', name: '', typeId: '' }), []);

  const { data, ...queryProps } = useStockLocationsQuery();

  const [addStockLocationMutation, { loading }] = useAddStockLocation();

  const buildNestedStructure = useCallback((flatData) => {
    const map = new Map();
    const nestedData = [];

    flatData.forEach((item) => {
      const itemId = Number(item.id);
      map.set(itemId, { ...item, children: [] });
    });

    flatData.forEach((item) => {
      const itemId = Number(item.id);
      const itemParentId = item.parentId !== null ? Number(item.parentId) : null;
      const node = map.get(itemId);

      if (itemParentId === null) {
        nestedData.push(node);
      } else {
        const parent = map.get(itemParentId);
        if (parent) {
          parent.children.push(node);
        }
      }
    });

    return nestedData;
  }, []);

  useEffect(() => {
    if (!data) return;
    setIsLoading(true);
    const newNestedStructure = buildNestedStructure(data?.stockLocations);
    setIsLoading(false);
    setNestedStructureData([...newNestedStructure]);
  }, [data?.stockLocations, buildNestedStructure]);

  const handleFormSubmit = (values, { resetForm }) => {
    addStockLocationMutation({
      variables: {
        input: {
          name: values.name,
          parentId: values.parentId,
          typeId: values.typeId,
        },
      },
      onCompleted: () => {
        resetForm({ values: initValues });
      },
    });
    setIsAddLocation(false);
  };

  if (IS_DESKTOP_SCREEN) {
    return (
      <Layout>
        <RequestHandler {...queryProps}>
          <FormRow>
            <FormRow.Left>
              <View style={styles.container}>
                {nestedStructureData?.map((item, index) => (
                  <AccordionItem
                    key={item.id}
                    location={item}
                    isLast={index === nestedStructureData?.length - 1}
                  />
                ))}
              </View>
            </FormRow.Left>
            <Formik
              initialValues={initValues}
              onSubmit={handleFormSubmit}
              validationSchema={addLocationSchema}
            >
              {({ values, handleSubmit, setFieldValue }) => {
                return (
                  <FormRow.Right>
                    <View style={styles.editContainer}>
                      {nestedStructureData?.map((item, index) => (
                        <AccordionItem
                          key={item.id}
                          location={item}
                          isLast={index === nestedStructureData?.length - 1}
                          isEdit
                          loading={loading}
                          handleSubmit={handleSubmit}
                          values={values}
                          setFieldValue={setFieldValue}
                          isAddLocation={isAddLocation}
                          setIsAddLocation={setIsAddLocation}
                          textFieldId={textFieldId}
                          setTextFieldId={setTextFieldId}
                        />
                      ))}
                    </View>
                  </FormRow.Right>
                );
              }}
            </Formik>
          </FormRow>
        </RequestHandler>
      </Layout>
    );
  }
  return (
    <Layout>
      <RequestHandler {...queryProps} loading={isLoading}>
        <BoxWrapper>
          <View style={styles.container}>
            <MobileTable
              data={nestedStructureData}
              renderItem={(item, index) => (
                <AccordionMobileItem
                  key={item.id}
                  location={item}
                  isLast={index === nestedStructureData?.length - 1}
                />
              )}
              fetchMoreData={(resolve) => resolve()}
            />
          </View>
        </BoxWrapper>
        <Formik
          initialValues={initValues}
          onSubmit={handleFormSubmit}
          validationSchema={addLocationSchema}
        >
          {({ values, handleSubmit, setFieldValue }) => {
            return (
              <BoxWrapper>
                <View style={styles.editContainer}>
                  <MobileTable
                    data={nestedStructureData}
                    renderItem={(item, index) => (
                      <AccordionMobileItem
                        key={item.id}
                        location={item}
                        isLast={index === nestedStructureData?.length - 1}
                        isEdit
                        loading={loading}
                        handleSubmit={handleSubmit}
                        values={values}
                        setFieldValue={setFieldValue}
                        isAddLocation={isAddLocation}
                        setIsAddLocation={setIsAddLocation}
                        textFieldId={textFieldId}
                        setTextFieldId={setTextFieldId}
                      />
                    )}
                    fetchMoreData={(resolve) => resolve()}
                  />
                </View>
              </BoxWrapper>
            );
          }}
        </Formik>
      </RequestHandler>
    </Layout>
  );
};

export default memo(BoxesArrangement);
