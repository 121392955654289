import { Formik } from 'formik';
import React from 'react';

import {
  BoxWrapper,
  Checkbox,
  FieldRow,
  FieldWrapper,
  FormRow,
  FormWrapper,
  Layout,
  RequestHandler,
  SelectWithSearch,
  TextField,
  Typography,
} from '../../../components';
import { IS_DESKTOP_SCREEN } from '../../../constants';
import {
  useHasScopesQuery,
  useParams,
  useStockLocationsOptions,
  useStockPlaceMutation,
  useStockPlaceQuery,
} from '../../../hooks';
import { addBoxSchema } from '../../../services/validation';
import { BoxAvailability } from './BoxAvailability/BoxAvailability';

const Box = () => {
  const params = useParams();
  const { data: placeData, ...stockPlaceQueryProps } = useStockPlaceQuery();
  const [addPlace, { loading: isAddPlaceLoading }] = useStockPlaceMutation();
  const { getRoomsOptions, getShelvingOptions, stockLocationsQueryProps } =
    useStockLocationsOptions();
  const { data: hasScopesData } = useHasScopesQuery(['Section:Purchase:Access']);

  const onSubmit = (values) => {
    addPlace({
      variables: {
        input: {
          name: values.name,
          archival: values.archival,
          locationId: +values.shelving,
          ...(params?.id ? { id: Number(placeData?.stockPlace?.id) } : {}),
        },
      },
    });
  };

  const initialValues = {
    room: placeData?.stockPlace?.location[1]?.id || '',
    shelving: placeData?.stockPlace?.location[0]?.id || '',
    name: placeData?.stockPlace?.name || '',
    archival: params?.id ? placeData?.stockPlace?.archival : false,
  };

  return (
    <RequestHandler {...stockPlaceQueryProps}>
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={addBoxSchema}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <Layout
              onActionButtonPress={handleSubmit}
              actionButtonIsLoading={isAddPlaceLoading}
              actionButtonType='save'
            >
              <FormRow>
                <FormRow.Left>
                  <BoxWrapper>
                    <Typography intlId='app.box' variant='heading' />
                    <FieldRow>
                      <TextField name='name' label='app.title' />
                      <FieldWrapper
                        mb={'auto'}
                        mt={'auto'}
                        width={IS_DESKTOP_SCREEN ? '15%' : 'auto'}
                      >
                        <Checkbox
                          justify={'center'}
                          checked={values.archival}
                          onChange={() => setFieldValue('archival', !values.archival)}
                          label='app.archival'
                        />
                      </FieldWrapper>
                    </FieldRow>
                  </BoxWrapper>
                </FormRow.Left>
                <FormRow.Right>
                  <BoxWrapper>
                    <Typography intlId='app.location' variant='heading' />
                    <RequestHandler {...stockLocationsQueryProps}>
                      <FieldRow>
                        <SelectWithSearch
                          name='room'
                          label='app.room'
                          options={getRoomsOptions()}
                          onChange={() => {
                            setFieldValue('shelving', '');
                          }}
                        />
                        <SelectWithSearch
                          name='shelving'
                          label='app.shelving'
                          options={getShelvingOptions(values.room)}
                        />
                      </FieldRow>
                    </RequestHandler>
                  </BoxWrapper>
                </FormRow.Right>
              </FormRow>
              <BoxAvailability
                availabilities={placeData?.stockPlace?.availabilities}
                withPrice={hasScopesData?.hasScopes[0]?.isAllowed}
              />
            </Layout>
          )}
        </Formik>
      </FormWrapper>
    </RequestHandler>
  );
};

export default Box;
