import { MaterialIcons } from '@expo/vector-icons';
import { Box, Icon } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { Pressable, StyleSheet } from 'react-native';

import { colors } from '../../theme';

const EditButton = ({ onClick }) => {
  return (
    <Box position='relative'>
      <Pressable onPress={onClick} style={styles.button}>
        <Icon name='edit' as={MaterialIcons} color={colors.primary.default} size='26px' />
      </Pressable>
    </Box>
  );
};

EditButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default EditButton;

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    borderRadius: 40,
    height: 40,
    justifyContent: 'center',
    position: 'absolute',
    right: 5,
    top: -20,
    width: 40,
  },
});
