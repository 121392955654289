import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { SelectWithSearch } from '../../../../components';
import { useDebounce, useGetStreetsNovaPoshta } from '../../../../hooks';
import { transformSelectOptions } from '../../../../utils';

export const StreetAutoCompleteField = ({ name, cityRef, streetRef }) => {
  const [streetSearchValue, setStreetSearchValue] = useState('');

  const { debouncedValue: debouncedSearchValue, isDebouncing } =
    useDebounce(streetSearchValue);

  const [getStreets, { data: streetsData, loading }] = useGetStreetsNovaPoshta({
    variables: {
      streetName: debouncedSearchValue,
      cityRef: cityRef,
    },
  });

  useEffect(() => {
    cityRef &&
      streetRef &&
      getStreets({
        variables: {
          cityRef: cityRef,
        },
      });
  }, []);

  const getStreetsHandler = () => {
    !streetsData?.getStreetNovaPoshta && getStreets();
  };

  const setSearchHandler = (val) => {
    return val ? setStreetSearchValue(val) : setStreetSearchValue('');
  };

  return (
    <SelectWithSearch
      width='100%'
      pl={0}
      pr={0}
      setSearchValue={setSearchHandler}
      isLoading={loading || isDebouncing}
      onMenuOpen={getStreetsHandler}
      options={transformSelectOptions({
        data: streetsData?.getStreetNovaPoshta.slice(0, 50),
        value: 'ref',
        label: 'description',
      })}
      name={name}
      label='app.street'
    />
  );
};

StreetAutoCompleteField.propTypes = {
  name: PropTypes.string.isRequired,
  cityRef: PropTypes.string.isRequired,
  streetRef: PropTypes.string,
};
