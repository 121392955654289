/* eslint-disable react-native/no-inline-styles */
import { Spinner } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { FAB, Portal } from 'react-native-paper';

import { useMediaQuery } from '../../../hooks';
import styles from './styles';

const FloatingActionButton = ({ Icon, onPress, isLoading }) => {
  const isDesktop = useMediaQuery('isDesktop');

  const renderIcon = () => {
    if (isLoading)
      return <Spinner size='sm' mb={-2} style={{ bottom: -2 }} color={'white'} />;
    return <Icon />;
  };

  return (
    <Portal>
      <FAB
        icon={renderIcon}
        onPress={onPress}
        style={isDesktop ? styles.addButtonWeb : styles.addButton}
        disabled={isLoading}
      />
    </Portal>
  );
};

export default FloatingActionButton;

FloatingActionButton.propTypes = {
  Icon: PropTypes.any,
  onPress: PropTypes.func,
  isLoading: PropTypes.bool,
};
