import { FlatList } from 'native-base';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { SafeAreaView, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import HandlerClickOutside from '../HandlerClickOutside/HandlerClickOutside';
import styles from './styles';

export const Dropdown = ({
  data,
  renderItem,
  renderInput,
  setValue,
  changeCondition,
  isOpen,
}) => {
  const DropdownButton = useRef();

  const toggleDropdown = () => {
    changeCondition(isOpen ? false : true);
  };

  const handleChange = (value) => {
    setValue(value);
  };

  return (
    <View>
      <HandlerClickOutside onClickOutside={() => changeCondition(false)}>
        <SafeAreaView ref={DropdownButton}>
          <TouchableOpacity activeOpacity={1} onPress={toggleDropdown}>
            {renderInput()}
          </TouchableOpacity>
        </SafeAreaView>

        <View
          style={
            isOpen && !!data?.length ? styles.dropdownWrapper : styles.dropdownWrapperHide
          }
        >
          <View style={styles.dropdown}>
            <FlatList
              data={data}
              renderItem={({ item }) => (
                <TouchableOpacity
                  activeOpacity={1}
                  key={item}
                  onPress={() => handleChange(item)}
                >
                  {renderItem(item)}
                </TouchableOpacity>
              )}
            />
          </View>
        </View>
      </HandlerClickOutside>
    </View>
  );
};

Dropdown.propTypes = {
  renderItem: PropTypes.func,
  renderInput: PropTypes.func,
  data: PropTypes.array,
  setValue: PropTypes.func,
  changeCondition: PropTypes.func,
  isOpen: PropTypes.bool,
};
