import { MaterialIcons } from '@expo/vector-icons';
import { FieldArray, useFormikContext } from 'formik';
import { Box, Flex, Icon } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { TouchableOpacity } from 'react-native';

import { QuerySelectWithSearch, TextField } from '../../../../components';
import { FIND_PURCHASE_PARSELS_QUERY } from '../../../../graphql';

const ForwardingsMobileItems = ({ arrayHelpers }) => {
  const { values } = useFormikContext();

  const pickedParcelsId = useMemo(
    () =>
      values.trackingForwardingCodes[0].parcelIds.map((parcelId) => ({ id: parcelId })),
    [values.trackingForwardingCodes],
  );

  values?.trackingForwardingCodes[values?.trackingForwardingCodes.length - 1]
    ?.forwardingCode &&
    arrayHelpers.push({
      forwardingCode: '',
      parcelIds: [],
    });

  return values?.trackingForwardingCodes.map((forwardingRow, index) => {
    const isHasForwardingCode = forwardingRow.forwardingCode;

    return (
      <Box
        borderBottomWidth='1px'
        borderColor='gray.light'
        backgroundColor='white'
        borderRadius={'5px'}
        px={'md'}
        py={'18px'}
        mb={'8px'}
        key={index}
      >
        <TextField
          label='app.trackingForwardingCode'
          name={`trackingForwardingCodes.${index}.forwardingCode`}
          mb={'16px'}
        />

        <FieldArray
          name={`trackingForwardingCodes.${index}.parcelIds`}
          render={() => {
            return [
              ...(values?.trackingForwardingCodes?.[index]?.parcelIds || []),
              {},
            ].map((parcelsRow, i) => {
              return (
                <Box key={i}>
                  <QuerySelectWithSearch
                    alreadyPickedItems={pickedParcelsId}
                    fieldName={`trackingForwardingCodes.${index}.parcelIds.${i}`}
                    graphql={FIND_PURCHASE_PARSELS_QUERY}
                    graphqlName='purchaseParcels'
                    rowsInResponse
                    labelProperty={'trackingCode'}
                    onChange={(option) => {
                      if (!option?.label) {
                        arrayHelpers.remove(i);
                      }
                    }}
                    label={'app.trackingParcelCode'}
                    pl={'0px'}
                    pr={'0px'}
                    mb={'0px'}
                    key={i}
                  />
                </Box>
              );
            });
          }}
        />

        {isHasForwardingCode && (
          <Box mt={'27px'} mb={'9px'}>
            <TouchableOpacity onPress={() => arrayHelpers.remove(index)}>
              <Flex align={'center'} justify={'center'}>
                <Icon
                  size={'sm'}
                  color={'gray.dark'}
                  as={<MaterialIcons name={'delete'} />}
                />
              </Flex>
            </TouchableOpacity>
          </Box>
        )}
      </Box>
    );
  });
};

export default memo(ForwardingsMobileItems);

ForwardingsMobileItems.propTypes = {
  arrayHelpers: PropTypes.object,
};
