import { getPropFromObj } from './getPropFromObj';

export const getLabel = (item, label) => {
  let result = '';

  if (Array.isArray(label)) {
    label.forEach((el) => {
      result += getPropFromObj(item, el) ? `${getPropFromObj(item, el)} ` : '';
    });
  } else {
    result = item?.[label] || '----';
  }

  return result;
};
