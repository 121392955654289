import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const OrderStatusesIcon = ({ size = 24, style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' style={{ ...style }}>
      <Path
        d='M21 3.99998H3C2.73478 3.99998 2.48043 4.10534 2.29289 4.29288C2.10536 4.48041 2 4.73477 2 4.99998V19C2 19.5304 2.21071 20.0391 2.58579 20.4142C2.96086 20.7893 3.46957 21 4 21H20C20.5304 21 21.0391 20.7893 21.4142 20.4142C21.7893 20.0391 22 19.5304 22 19V4.99998C22 4.73477 21.8946 4.48041 21.7071 4.29288C21.5196 4.10534 21.2652 3.99998 21 3.99998ZM12 13C8.691 13 6 10.309 6 6.99998H8C8 9.20599 9.794 11 12 11C14.206 11 16 9.20599 16 6.99998H18C18 10.309 15.309 13 12 13Z'
        fill='white'
      />
    </Svg>
  );
};

OrderStatusesIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
