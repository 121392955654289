import { Flex } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import { BoxWrapper, TextLink, Typography } from '../../../../components';

const BoxAvailabilityMobileItem = ({
  withPrice,
  name,
  dateWithNumber,
  purchaseId,
  condition,
  deliveryPrice,
  price,
  quantity,
  reservedQuantity,
}) => {
  const intl = useIntl();
  return (
    <BoxWrapper>
      <Flex mb='md' justify='space-between'>
        <Typography intlId='app.title' />
        <Typography color='primary.default'>{name}</Typography>
      </Flex>
      <Flex mb='md' justify='space-between'>
        <Typography intlId='app.purchase' />
        <Flex>
          {withPrice ? (
            <TextLink link={`/purchase/purchase/${purchaseId}`}>
              {dateWithNumber()}
            </TextLink>
          ) : (
            <Typography>{dateWithNumber()}</Typography>
          )}
        </Flex>
      </Flex>
      <Flex mb='md' justify='space-between'>
        <Typography intlId='app.condition' />
        <Typography>{condition}</Typography>
      </Flex>
      {withPrice ? (
        <Flex mb='md' justify='space-between'>
          <Typography intlId='app.deliveryCost' />
          {deliveryPrice}
        </Flex>
      ) : null}
      {withPrice ? (
        <Flex mb='md' justify='space-between'>
          <Typography intlId='app.costPrice' />
          {price}
        </Flex>
      ) : null}
      <Flex justify='space-between'>
        <Typography intlId='app.quantity' />
        {withPrice && reservedQuantity ? (
          <>
            <Flex alignItems={'flex-end'}>
              <Typography>{`${quantity}/ `}</Typography>
              <Flex direction={'column'} alignItems={'flex-start'}>
                <Typography fontSize={'xs'} color={'danger'}>
                  {intl.formatMessage({ id: 'app.reservation' })}
                </Typography>
                <Typography color={'danger'} fontWeight={'600'}>
                  {`+ ${reservedQuantity}`}
                </Typography>
              </Flex>
            </Flex>
          </>
        ) : (
          <Typography>{quantity}</Typography>
        )}
      </Flex>
    </BoxWrapper>
  );
};

export default memo(BoxAvailabilityMobileItem);

BoxAvailabilityMobileItem.propTypes = {
  withPrice: PropTypes.bool,
  name: PropTypes.string.isRequired,
  dateWithNumber: PropTypes.string,
  purchaseId: PropTypes.string,
  condition: PropTypes.string,
  deliveryPrice: PropTypes.node,
  price: PropTypes.node,
  quantity: PropTypes.number,
  reservedQuantity: PropTypes.number,
};
