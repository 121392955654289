import React from 'react';
import { TextInput, View, Text } from 'react-native';
import WebSorting from '../../../../components/WebSorting/WebSorting';
import { Button, Modal, DatePicker } from '../../../../components/UI';
import { PhoneField, EmailField } from '../filterFields/';
import stylesGlobal from '../../../../globalStyles';
import stylesInput from '../../../../components/UI/Input/styles';
import styles from '../Style';
import columnData from '../columnData';
import { isWeb } from '../../../../constants';
import { useIntl } from '../../../../hooks';

const FilterFieldsMobile = ({
  data,
  setFieldValue,
  values,
  handleSubmit,
  sorting,
  setSorting,
  onClose,
}) => {
  const intl = useIntl();
  return (
    <Modal
      name={intl.formatMessage({
        id: 'app.filters',
      })}
      onClose={onClose}
    >
      <View>
        <Text style={stylesGlobal.label}>
          {intl.formatMessage({ id: 'customer.name' })}
        </Text>
        <TextInput
          style={[stylesInput.input, styles.inputFilter, isWeb && { outline: 'none' }]}
          value={values.name}
          onChangeText={(text) => setFieldValue('name', text)}
        />
      </View>
      <View>
        <EmailField
          name={'email'}
          label={intl.formatMessage({ id: 'app.email' })}
          handleChange={setFieldValue}
          value={values.email}
          emails={data.emails}
        />
      </View>
      <View>
        <PhoneField
          name={'phone'}
          label={intl.formatMessage({ id: 'app.phone' })}
          handleChange={setFieldValue}
          value={values.phone}
          phones={data.phones}
        />
      </View>
      <View
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}
      >
        <DatePicker
          name='dateFrom'
          value={values.dateFrom || new Date()}
          label={intl.formatMessage({ id: 'date.from' })}
          setFieldValue={setFieldValue}
        />
        <DatePicker
          name='dateTo'
          value={values.dateTo || new Date()}
          label={intl.formatMessage({ id: 'date.to' })}
          setFieldValue={setFieldValue}
        />
      </View>
      <WebSorting columnData={columnData} sorting={sorting} setSorting={setSorting} />
      <View style={stylesGlobal.listButton}>
        <Button
          mode='thin'
          onPress={() => {
            setFieldValue('name', '');
            setFieldValue('email', '');
            setFieldValue('phone', '');
            setFieldValue('dateFrom', '');
            setFieldValue('dateTo', '');
          }}
        >
          {intl.formatMessage({
            id: 'app.clear',
          })}
        </Button>
        <Button mode='thin' onPress={onClose}>
          {intl.formatMessage({
            id: 'app.cancel',
          })}
        </Button>
        <Button
          color='#47C8FF'
          mode='thin'
          onPress={() => {
            handleSubmit();
            onClose();
          }}
        >
          OK
        </Button>
      </View>
    </Modal>
  );
};

export default FilterFieldsMobile;
