import { Formik } from 'formik';
import { Box, Flex } from 'native-base';
import React from 'react';

import {
  BoxWrapper,
  DatePicker,
  FieldRow,
  FormRow,
  FormWrapper,
  Layout,
  MultiSelect,
  RequestHandler,
  Select,
  TextField,
  Typography,
} from '../../../components';
import {
  useGetRolesQuery,
  useGetUserByIdQuery,
  useIntl,
  useSexOptions,
  useUserMutation,
} from '../../../hooks';
import { userSchema } from '../../../services/validation';
import { getFormattedDate, transformSelectOptions } from '../../../utils';

const User = () => {
  const intl = useIntl();
  const { data, ...queryProps } = useGetUserByIdQuery();
  const { data: roles, ...rolesQueryProps } = useGetRolesQuery();
  const sexOptions = useSexOptions();
  const [userMutation, { loading: mutationLoading }] = useUserMutation();

  const error = queryProps?.error || rolesQueryProps?.error;
  const loading = queryProps?.loading || rolesQueryProps?.loading;

  const onSubmit = (values) => {
    const input = {
      ...(values.id && { id: parseInt(values.id) }),
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName,
      sex: parseInt(values.sex),
      phone: values.phone,
      email: values.email,
      skype: values.skype,
      roles: values.roles.map(({ value }) => parseInt(value)),
      status: parseInt(values.status),
    };
    if (values.birthday) {
      input.birthday = Number(values.birthday)
        ? getFormattedDate(values.birthday, 'yyyy-MM-dd')
        : values.birthday;
    }

    userMutation({
      variables: {
        input,
      },
    });
  };

  const rolesOptions = transformSelectOptions({
    data: roles?.userRoles,
    value: 'id',
    label: 'name',
  });

  const statusOptions = [
    {
      value: '0',
      label: intl.formatMessage({ id: 'app.inactive' }),
    },
    {
      value: '1',
      label: intl.formatMessage({ id: 'app.active' }),
    },
    {
      value: '2',
      label: intl.formatMessage({ id: 'app.banned' }),
    },
  ];

  const initialValues = {
    id: data?.user?.id || undefined,
    firstName: data?.user?.firstName || '',
    lastName: data?.user?.lastName || '',
    middleName: data?.user?.middleName || '',
    birthday: parseInt(data?.user?.birthday) || '',
    sex: data?.user?.sex?.toString() || '-1',
    phone: data?.user?.phone || '',
    email: data?.user?.email || '',
    skype: data?.user?.skype || '',
    roles:
      transformSelectOptions({ data: data?.user?.roles, value: 'id', label: 'name' }) ||
      '',
    status: data?.user?.status?.toString() || '0',
  };

  return (
    <RequestHandler loading={loading} error={error}>
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={userSchema}
        >
          {({ handleSubmit }) => (
            <Layout
              onActionButtonPress={handleSubmit}
              actionButtonType='save'
              actionButtonIsLoading={mutationLoading}
            >
              <FormRow>
                <FormRow.Left>
                  <BoxWrapper>
                    <Flex>
                      <Box mr='md' mb='lg' flex={1}>
                        <Typography intlId='app.personalInfo' variant='heading' mb='sm' />
                        <Typography
                          intlId='user.description'
                          fontSize='sm'
                          color='gray.main'
                        />
                      </Box>
                    </Flex>
                    <FieldRow withNegativeBottomMargin={false}>
                      <TextField name='firstName' label='app.name' />
                      <TextField name='lastName' label='customer.lastName' />
                    </FieldRow>
                    <FieldRow withNegativeBottomMargin={false}>
                      <TextField name='middleName' label='customer.middleName' />
                      <Select name='sex' label='app.sex' options={sexOptions} />
                    </FieldRow>
                    <FieldRow withNegativeBottomMargin={false}>
                      <DatePicker name='birthday' label='app.birthday' />
                      <Select name='status' label='app.status' options={statusOptions} />
                    </FieldRow>
                    <MultiSelect
                      name='roles'
                      label='app.role'
                      options={rolesOptions}
                      selectedItemsOptions={{
                        mb: 0,
                      }}
                    />
                  </BoxWrapper>
                </FormRow.Left>
                <FormRow.Right>
                  <BoxWrapper>
                    <Typography intlId='customer.contacts' variant='heading' mb='sm' />
                    <Flex align='flex-start' direction={{ base: 'column', lg: 'row' }}>
                      <Typography
                        fontSize='sm'
                        color='gray.main'
                        width={{ base: '100%', lg: '50%' }}
                        intlId='app.contactsDisclaimer'
                      />
                      <Box
                        mt={{ base: 'md', lg: 0 }}
                        ml={{ base: 0, lg: 'lg' }}
                        width={{ base: '100%', lg: '50%' }}
                      >
                        <TextField name='email' label='app.email' />
                        <TextField name='phone' label='app.phone' />
                        <TextField name='skype' label='app.skype' />
                      </Box>
                    </Flex>
                  </BoxWrapper>
                </FormRow.Right>
              </FormRow>
            </Layout>
          )}
        </Formik>
      </FormWrapper>
    </RequestHandler>
  );
};

export default User;
