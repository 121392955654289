import {
  BoxesArrangementIcon,
  DeliveryMethodsIcon,
  PackagingTemplatesIcon,
  PaymentMethodsIcon,
  ProductsIcon,
  SettingsIconWhite,
  SettingsMainIcon,
  SettingsOrdersStatusesIcon,
  SettingsUsersIcon,
  SourcesOrdersIcon,
} from '../../components/Icons';

export default [
  { name: 'Home', icon: SettingsMainIcon, path: '/home' },
  { name: 'Users', icon: SettingsUsersIcon, path: '/users', scopes: ['User:Read'] },
  {
    name: 'OrdersStatuses',
    icon: SettingsOrdersStatusesIcon,
    path: '/ordersstatuses',
  },
  {
    name: 'PaymentMethods',
    icon: PaymentMethodsIcon,
    path: '/paymentmethods',
  },
  {
    name: 'DeliveryMethods',
    icon: DeliveryMethodsIcon,
    path: '/deliverymethods',
  },
  {
    name: 'SourcesOrders',
    icon: SourcesOrdersIcon,
    path: '/sourcesorders',
  },
  { name: 'BoxesArrangement', icon: BoxesArrangementIcon, path: '/boxesarrangement' },
  {
    name: 'PackagingTemplates',
    icon: PackagingTemplatesIcon,
    path: '/packagingtemplates',
    scopes: ['PackagingTemplate:Read'],
  },
  {
    name: 'CustomParams',
    icon: ProductsIcon,
    path: '/customparams',
  },
  {
    name: 'ServiceSettings',
    icon: SettingsIconWhite,
    path: '/servicesettings',
  },
];
