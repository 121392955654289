import { useCallback, useState } from 'react';

import { LocalStorage } from '../services';

const useLocalStorage = (key, initialState) => {
  let [isLoading, setIsLoading] = useState(false);
  let [value, setValue] = useState(initialState);

  const setValueHandler = useCallback(
    async (value) => {
      setIsLoading(true);

      await LocalStorage.setValue(key, value);

      setValue(value);
      setIsLoading(false);
    },
    [key],
  );

  return {
    isLoading,
    value,
    setValue,
    setValueHandler,
  };
};

export default useLocalStorage;
