import {
  AddForwardingIcon,
  DeliveryUkraineIcon,
  DeliveryUSAIcon,
  PurchaseMainIcon,
} from '../../components/Icons';

export default [
  { name: 'Home', icon: PurchaseMainIcon, path: '/purchases', scopes: ['Purchase:Read'] },
  {
    name: 'DeliveryUsa',
    icon: DeliveryUSAIcon,
    path: '/parcels',
    scopes: ['Purchase:Read'],
  },
  {
    name: 'DeliveryUkraine',
    icon: DeliveryUkraineIcon,
    path: '/forwardings',
    scopes: ['Purchase:Read'],
  },
  {
    name: 'AddForwarding',
    icon: AddForwardingIcon,
    path: '/addforwarding',
    scopes: ['Purchase:Write'],
  },
];
