/* eslint-disable react-native/no-color-literals */
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  center: {
    marginRight: 25,
  },
  icon: {
    alignItems: 'center',
    borderRadius: 40 / 2,
    height: 40,
    justifyContent: 'center',
    width: 40,
  },
  info: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  left: {
    marginRight: 25,
  },
  right: {
    alignSelf: 'flex-end',
    marginLeft: 'auto',
  },
  statusCanceled: {
    backgroundColor: '#E0E0E0',
  },
  statusDelivered: {
    backgroundColor: '#fbbc04',
  },
  statusDraft: {
    backgroundColor: '#E0E0E0',
  },

  statusPaid: {
    backgroundColor: '#666',
  },

  statusPending: {
    backgroundColor: '#666',
  },

  statusReceived: {
    backgroundColor: '#38761d',
  },

  statusSent: {
    backgroundColor: '#4a86e8',
  },

  statusStore: {
    backgroundColor: '#b45f06',
  },

  statusWaiting: {
    backgroundColor: '#cc0000',
  },

  top: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 10,
  },

  wrapper: {
    backgroundColor: '#fff',
    borderRadius: 5,
    marginBottom: 8,
    paddingHorizontal: 24,
    paddingVertical: 24,
  },
});
