import { getVolumeWeight } from './getVolumeWeight';

export const getVolumeWeightProduct = (values, intl) => {
  if (values?.product) {
    return `${(
      getVolumeWeight(
        values.product.width,
        values.product.height,
        values.product.length,
      ) * values?.quantity
    ).toFixed(1)} ${intl?.formatMessage({ id: 'app.kg' })}`;
  } else {
    return '';
  }
};
