import { Box, Spinner } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { useAccessContext } from '../../context';
import { useHasScopesQuery } from '../../hooks';

export const AccessHandler = ({ scopesArray, children }) => {
  const { data, ...queryProps } = useHasScopesQuery(scopesArray);
  const { isAccessAllowed, setIsAccessAllowed } = useAccessContext();

  if (!scopesArray) {
    return children;
  }

  if (queryProps?.loading) {
    return (
      <Box paddingY='lg'>
        <Spinner />
      </Box>
    );
  }

  if (data?.hasScopes?.find((el) => el?.isAllowed === false)) {
    isAccessAllowed && setIsAccessAllowed(false);
    return null;
  }

  return children;
};

AccessHandler.propTypes = {
  scopesArray: PropTypes.array,
  children: PropTypes.node,
};
