import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

import { colors } from '../../theme';

export const ShippingOnUkraineIcon = ({ size = 40, style = {} }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ ...style }}
    >
      <Circle cx='20' cy='20' r='20' fill={colors.purple.dark} />
      <Path
        d='M24.1016 25.0781C24.3359 25.3125 24.6354 25.4297 25 25.4297C25.3646 25.4297 25.6641 25.3125 25.8984 25.0781C26.1328 24.8177 26.25 24.5182 26.25 24.1797C26.25 23.8411 26.1328 23.5547 25.8984 23.3203C25.6641 23.0599 25.3646 22.9297 25 22.9297C24.6354 22.9297 24.3359 23.0599 24.1016 23.3203C23.8672 23.5547 23.75 23.8411 23.75 24.1797C23.75 24.5182 23.8672 24.8177 24.1016 25.0781ZM26.25 17.9297H24.1797V20H27.8906L26.25 17.9297ZM14.1016 25.0781C14.3359 25.3125 14.6354 25.4297 15 25.4297C15.3646 25.4297 15.6641 25.3125 15.8984 25.0781C16.1328 24.8177 16.25 24.5182 16.25 24.1797C16.25 23.8411 16.1328 23.5547 15.8984 23.3203C15.6641 23.0599 15.3646 22.9297 15 22.9297C14.6354 22.9297 14.3359 23.0599 14.1016 23.3203C13.8672 23.5547 13.75 23.8411 13.75 24.1797C13.75 24.5182 13.8672 24.8177 14.1016 25.0781ZM26.6797 16.6797L29.1797 20V24.1797H27.5C27.5 24.8568 27.2526 25.4427 26.7578 25.9375C26.263 26.4323 25.6771 26.6797 25 26.6797C24.3229 26.6797 23.737 26.4323 23.2422 25.9375C22.7474 25.4427 22.5 24.8568 22.5 24.1797H17.5C17.5 24.8568 17.2526 25.4427 16.7578 25.9375C16.263 26.4323 15.6771 26.6797 15 26.6797C14.3229 26.6797 13.737 26.4323 13.2422 25.9375C12.7474 25.4427 12.5 24.8568 12.5 24.1797H10.8203V15C10.8203 14.5573 10.9896 14.1667 11.3281 13.8281C11.6667 13.4896 12.0573 13.3203 12.5 13.3203H24.1797V16.6797H26.6797Z'
        fill='white'
      />
    </Svg>
  );
};

ShippingOnUkraineIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
