import { getPropFromObj } from './getPropFromObj';

export const getInfo = (item, info) => {
  let result = '';

  if (Array.isArray(info)) {
    info.forEach((el) => {
      result += getPropFromObj(item, el) ? `${getPropFromObj(item, el)} ` : '';
    });
  } else {
    result = item?.[info] || '';
  }

  return result;
};
