import { MaterialIcons } from '@expo/vector-icons';
import { Box, Center, Flex, Icon } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { TouchableOpacity } from 'react-native';

import { Typography } from '../../../../components';
import { ExchangeIcon } from '../../../../components/Icons';

const CurrenciesMobileItem = ({ name, code, date, ratio, symbol, onItemPress }) => {
  return (
    <TouchableOpacity onPress={onItemPress}>
      <Box
        paddingY='20px'
        paddingX='20px'
        borderColor='gray.light'
        backgroundColor='#fff'
        marginBottom={'8px'}
        borderRadius={'5px'}
        shadow={2}
      >
        <Flex justify='space-between' alignItems='flex-start'>
          <Box>
            <Typography mb='xs'>{`${name}, ${code}`}</Typography>
            {date ? (
              <Flex>
                <Icon
                  as={<MaterialIcons name='calendar-today' />}
                  size='xs'
                  color='gray.main'
                />
                <Typography ml='sm' fontSize='13px'>
                  {date}
                </Typography>
              </Flex>
            ) : null}
            {ratio ? (
              <Flex mt='md'>
                <ExchangeIcon />
                <Typography fontSize='24px' ml='md'>
                  {ratio}
                </Typography>
              </Flex>
            ) : null}
          </Box>
          <Center bgColor='#CE5DAE' width={'40px'} height={'40px'} borderRadius={100}>
            <Typography color='white' fontSize='xl'>
              {symbol}
            </Typography>
          </Center>
        </Flex>
      </Box>
    </TouchableOpacity>
  );
};

export default memo(CurrenciesMobileItem);

CurrenciesMobileItem.propTypes = {
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired,
  date: PropTypes.node,
  ratio: PropTypes.string,
  onItemPress: PropTypes.func,
};
