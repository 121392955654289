import React, { useState, useEffect, useRef, Children, cloneElement } from 'react';
import ReactDOM from 'react-dom';
import { View, Text, TouchableOpacity } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import styles from './styles';
import { useMediaQuery } from '../../../hooks';
import { isWeb } from '../../../constants';

function MultiSelect({ name, handleChange, placeholder, children, value = '' }) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const onClick = (id, typeAct) => {
    const filter = value ? value.split(',') : [];
    if (typeAct) {
      filter.push(id);
    } else {
      filter.splice(filter.indexOf(id), 1);
    }
    handleChange(name, filter.join(','));
    setIsOpen(false);
  };
  if (isWeb)
    useEffect(() => {
      document.addEventListener('click', handleClickOutside, false);
      return () => document.removeEventListener('click', handleClickOutside, false);
    }, []);

  const handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(ref.current);
    if (!domNode || !domNode.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    children?.every((item) => value.includes(item.key)) && setIsOpen(false);
  }, [value]);

  const isDesktop = useMediaQuery('isDesktop');

  const renderFields = () => {
    return Children.map(children, (child) => {
      return (
        <TouchableOpacity
          key={child.key}
          style={styles.field}
          onPress={() => onClick(child.key, true)}
        >
          {child}
        </TouchableOpacity>
      );
    });
  };

  const renderSelecteds = () => {
    return Children.map(children, (child) => {
      if (value.split(',').includes(child.key))
        return (
          <TouchableOpacity key={child.key} style={styles.selected}>
            {child}
            <MaterialIcons
              onPress={() => onClick(child.key, false)}
              name='delete-forever'
              color='#333333'
              size={18}
            />
          </TouchableOpacity>
        );
    });
  };

  return (
    <View ref={ref} style={styles.wrapper}>
      <View style={styles.multiSelect}>
        <View style={styles.multiSelectHead}>
          <Text style={styles.name}>{placeholder}</Text>
          <View style={styles.icons}>
            {children?.some((item) => value.includes(item.key)) ? (
              <MaterialIcons
                onPress={() => handleChange(name, '')}
                name='close'
                color='#333333'
                size={25}
                style={styles.closeIcon}
              />
            ) : null}
            <MaterialIcons
              onPress={() => setIsOpen((prevState) => !prevState)}
              name={`arrow-drop-${isOpen ? 'up' : 'down'}`}
              color='#333333'
              size={25}
            />
          </View>
        </View>
        <View style={styles.selecteds}>{children && renderSelecteds()}</View>
      </View>
      {isOpen && (
        <View style={[styles.fields, isDesktop && styles.fieldsWeb]}>
          {children && renderFields()}
        </View>
      )}
    </View>
  );
}

export default MultiSelect;
