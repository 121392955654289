import React from 'react';

import {
  BoxWrapper,
  DatePicker,
  FieldRow,
  Select,
  TextField,
  Typography,
} from '../../../components';
import { useSexOptions } from '../../../hooks';

export const CustomerInfo = () => {
  const sexOptions = useSexOptions();

  return (
    <BoxWrapper>
      <Typography intlId='app.personalInfo' variant='heading' />
      <FieldRow withNegativeBottomMargin={false}>
        <TextField name='firstName' label='customer.name' />
        <TextField name='lastName' label='customer.lastName' />
      </FieldRow>
      <FieldRow withNegativeBottomMargin={false}>
        <TextField
          name='middleName'
          label='customer.middleName'
          width={'calc(50% - 24px)'}
        />
        <Select
          name='sex'
          label='app.sex'
          options={sexOptions}
          width={'calc(50% - 24px)'}
        />
      </FieldRow>
      <FieldRow>
        <DatePicker name='birthday' label='app.birthday' />
      </FieldRow>
    </BoxWrapper>
  );
};
