import { FieldArray, Formik } from 'formik';
import React from 'react';

import {
  BoxWrapper,
  DesktopTable,
  FormWrapper,
  Layout,
  Typography,
} from '../../../components';
import { IS_DESKTOP_SCREEN } from '../../../constants';
import { useAddPurchaseForwardingMutation } from '../../../hooks';
import ForwardingsMobileItems from './ForwardingsMobileItems/ForwardingsMobileItems';
import { ForwardingTableRows } from './ForwardingTableRows/ForwardingTableRows';

const AddForwarding = () => {
  const [addForwarding, { ...addForwardingProps }] = useAddPurchaseForwardingMutation();

  const initialValues = {
    trackingForwardingCodes: [
      {
        forwardingCode: '',
        parcelIds: [],
      },
    ],
  };

  const onSubmit = async (values, { resetForm }) => {
    let forwardingNumber = 0;
    const addForward = async () => {
      const forwarding = values.trackingForwardingCodes?.[forwardingNumber];
      if (forwarding?.forwardingCode) {
        await addForwarding({
          variables: {
            input: {
              id: null,
              date: new Date(),
              trackingCode: forwarding.forwardingCode || null,
              status: 1,
              parcelIds: forwarding?.parcelIds?.map((el) => +el) || null,
            },
          },
        });
      }
      if (forwardingNumber <= values.trackingForwardingCodes?.length - 1) {
        forwardingNumber++;
        addForward();
      } else {
        resetForm();
      }
    };

    await addForward();
  };

  return (
    <FormWrapper>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <Layout
            isScrollable={false}
            actionButtonType='save'
            onActionButtonPress={handleSubmit}
            actionButtonIsLoading={addForwardingProps.loading}
          >
            {IS_DESKTOP_SCREEN ? (
              <BoxWrapper mb={0}>
                <Typography intlId='app.listForwarding' variant='heading' mb='xs' />

                <DesktopTable editable={false}>
                  <DesktopTable.Head>
                    <DesktopTable.Title intlId='app.trackingForwardingCode' />
                    <DesktopTable.Title intlId='app.trackingParcelCode' />
                    <DesktopTable.Title />
                  </DesktopTable.Head>
                  <DesktopTable.Body>
                    <FieldArray
                      name={'trackingForwardingCodes'}
                      render={(arrayHelpers) => (
                        <ForwardingTableRows arrayHelpers={arrayHelpers} />
                      )}
                    />
                  </DesktopTable.Body>
                </DesktopTable>
              </BoxWrapper>
            ) : (
              <>
                <Typography
                  ml={'md'}
                  mb='lg'
                  color='dark'
                  fontSize='lg'
                  intlId='app.listForwarding'
                />
                <FieldArray
                  name={'trackingForwardingCodes'}
                  render={(arrayHelpers) => (
                    <ForwardingsMobileItems arrayHelpers={arrayHelpers} />
                  )}
                />
              </>
            )}
          </Layout>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default AddForwarding;
