import { Box, Flex, Row } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { ScrollView } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { BoxWrapper } from '../../components';
import { IS_DESKTOP_SCREEN, IS_LAPTOP_SCREEN, IS_MOBILE_SCREEN } from '../../constants';
import { theme } from '../../theme';

export const FormWrapper = ({ children }) => {
  return (
    <ScrollView>
      <KeyboardAwareScrollView extraScrollHeight={100} enableOnAndroid={true}>
        {children}
      </KeyboardAwareScrollView>
    </ScrollView>
  );
};

export const FormCard = ({ children, paperProps, ...props }) => {
  return (
    <Flex p={15} w={IS_DESKTOP_SCREEN ? '50%' : '100%'} {...props}>
      <BoxWrapper flexGrow={1} mb={0} {...paperProps}>
        {children}
      </BoxWrapper>
    </Flex>
  );
};

FormCard.propTypes = {
  children: PropTypes.node,
  paperProps: PropTypes.instanceOf(Object),
};

export const FormRow = ({ children, style = {}, ...props }) => {
  return (
    <Row
      direction={{ base: 'column', lg: 'row' }}
      wrap={IS_LAPTOP_SCREEN ? 'nowrap' : 'wrap'}
      justify='space-evenly'
      align='flex-start'
      style={style}
      {...props}
    >
      {children}
    </Row>
  );
};

FormRow.Left = function Left({ children }) {
  return (
    <Box
      flex={1}
      flexBasis={{ base: 'auto', lg: 0, xl: 0 }}
      mr={{ base: 0, lg: 'md' }}
      width='100%'
    >
      {children}
    </Box>
  );
};

FormRow.Right = function Right({ children }) {
  return (
    <Box
      flex={1}
      flexBasis={{ base: 'auto', lg: 0, xl: 0 }}
      ml={{ base: 0, lg: 'md' }}
      width='100%'
    >
      {children}
    </Box>
  );
};

export const FieldRow = ({
  position = 'space-between',
  withNegativeBottomMargin = true,
  children,
}) => {
  const getMarginBottom = () => {
    if (withNegativeBottomMargin && !IS_MOBILE_SCREEN) return `${-theme.space.lg}px`;
    return '0px';
  };

  return (
    <Flex
      direction={{ base: 'column', sm: 'row' }}
      align='stretch'
      wrap={{ base: 'nowrap', sm: 'wrap' }}
      justify={position}
      ml={`${-theme.space.lg / 2}px`}
      mr={`${-theme.space.lg / 2}px`}
      mb={getMarginBottom()}
    >
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          style: {
            ...child.props.style,
            marginRight: theme.space.lg / 2,
            marginLeft: theme.space.lg / 2,
            flexGrow: 1,
          },
        }),
      )}
    </Flex>
  );
};

FieldRow.propTypes = {
  position: PropTypes.string,
  withNegativeBottomMargin: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
FormWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
FormRow.Left.propTypes = {
  children: PropTypes.node.isRequired,
};
FormRow.Right.propTypes = {
  children: PropTypes.node,
};
FormRow.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.number,
};
