import gql from 'graphql-tag';

export default gql`
  query customers($ids: [ID]) {
    customers(ids: $ids) {
      id
      firstName
      lastName
      middleName
      createdAt
      emails {
        id
        email
      }
      phones {
        id
        phone
      }
    }
  }
`;
