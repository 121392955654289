import { Box, Flex, ScrollView } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-native';

import { IS_DESKTOP_SCREEN } from '../../constants';
import { useAuthContext } from '../../context';
import { Button } from '../Button/Button';
import { Typography } from '../Typography/Typography';

export const LogOutModal = ({ user, setIsModalOpen }) => {
  const { onLogout } = useAuthContext();

  return (
    <Modal animationType='none' visible={true} transparent={IS_DESKTOP_SCREEN}>
      <Box
        flex={1}
        alignItems='center'
        justifyContent='center'
        bgColor={IS_DESKTOP_SCREEN ? 'rgba(0,0,0, 0.4)' : 'gray.light'}
      >
        <Box
          bgColor={IS_DESKTOP_SCREEN ? 'white' : 'gray.light'}
          elevation={5}
          width='100%'
          height='100%'
          maxWidth={{ base: '100%', lg: '25%' }}
          maxHeight={{ base: '100%', lg: '35%' }}
          borderRadius={{ base: 0, lg: '5' }}
        >
          <ScrollView p={3}>
            <Typography pb={5}>{`${user?.firstName} ${user?.lastName}`}</Typography>
            <Typography pb={2} intlId={'app.roles'} />
            {user?.roles?.map((role, i) => (
              <Typography intlId={role?.name} key={i} />
            ))}
          </ScrollView>
          <Flex justify='space-between' padding='md'>
            <Button
              intlId={'app.logOut'}
              onPress={() => onLogout()}
              colorScheme={'rose'}
              variant='ghost'
            />
            <Button
              intlId='app.close'
              onPress={() => setIsModalOpen(false)}
              variant='ghost'
            />
          </Flex>
        </Box>
      </Box>
    </Modal>
  );
};

LogOutModal.propTypes = {
  user: PropTypes.object.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
};
