import React, { useRef } from 'react';
import { Icon, Image } from 'native-base';
import { useDrag, useDrop } from 'react-dnd';
import { useIntl } from 'react-intl';
import { API_HOST, theme } from '../../../../../constants';
import { MaterialIcons } from '@expo/vector-icons';
import { colors } from '../../../../../theme';
import { Button } from '../../../../../components';

const DraggableItem = ({ photo, index, isNotLastItem, moveForm, onHandleOpen }) => {
  const intl = useIntl();
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      const droppedItem = monitor.getItem();
      const targetItem = photo;

      if (dragIndex === hoverIndex) {
        return;
      }
      moveForm(dragIndex, hoverIndex, droppedItem.id, targetItem.id);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { id: photo.id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Button
      onPress={() => {
        onHandleOpen(photo.id);
      }}
      data-handler-id={handlerId}
      bgColor={theme.colors.bgLightGrayOpacity}
      borderWidth={1}
      borderColor={theme.colors.bgLightGray}
      borderStyle='solid'
      borderRadius='3px'
      h={82}
      w={82}
      _hover={{
        bgColor: theme.colors.primary,
        borderColor: theme.colors.primary,
        borderWidth: '1px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Image
        ref={ref}
        data-handler-id={handlerId}
        color={'blue'}
        width={82}
        height={82}
        borderRadius={3}
        source={`${API_HOST}/${
          photo?.formats?.find((el) => el.format === 'smallCrop').pathWithTime
        }`}
        alt={photo.alt || intl.formatMessage({ id: 'app.productPhoto' })}
      />
      <Icon
        position={'absolute'}
        left={'31px'}
        top={'31px'}
        name='edit'
        as={MaterialIcons}
        size={'20px'}
        color={colors.primary.default}
      />
    </Button>
  );
};

export default DraggableItem;
