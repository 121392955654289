/* eslint-disable react-native/no-color-literals */
import { Dimensions, StatusBar, StyleSheet } from 'react-native';

import { displayWidth, isAndroid, isWeb } from './constants';

const width = Dimensions.get('window').width;

export default StyleSheet.create({
  error: {
    backgroundColor: '#f44336',
  },
  filterWeb: {
    alignItems: 'center',
    backgroundColor: '#f2f2f2',
    borderBottomWidth: 1,
    borderColor: 'rgb(189, 189, 189)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    left: 0,
    minHeight: 83,
    position: 'absolute',
    top: 104,
    width: '100%',
    zIndex: 10,
  },
  form: {
    marginBottom: 40,
    ...(isWeb ? { boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)' } : null),
  },
  formWrapper: {
    alignItems: width >= displayWidth ? 'flex-start' : 'stretch',
    display: 'flex',
    flexDirection: width >= displayWidth ? 'row' : 'column',
    justifyContent: 'space-between',
  },
  header: {
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: '#d6d6d6',
    display: 'flex',
    flexDirection: 'row',
    height: 49,
  },
  label: {
    color: '#828282',
    fontSize: 10,
    marginBottom: 5,
  },
  listButton: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: 32,
    justifyContent: 'space-between',
    marginBottom: 16,
    marginHorizontal: 10,
  },
  listButtonWeb: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 83,
    justifyContent: 'space-evenly',
  },
  listForm: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },
  safeAreaView: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    paddingTop: isAndroid ? StatusBar.currentHeight : 0,
  },
  sectionForm: {
    width: width >= 1000 ? '47%' : '100%',
  },
  submitButton: {
    backgroundColor: '#47C8FF',
    bottom: 40,
    position: 'absolute',
    right: 10,
    zIndex: 3,
  },

  success: {
    backgroundColor: '#4caf50',
  },
  title: {
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  wrapper: {
    marginLeft: 10,
    marginRight: 10,
    position: 'relative',
  },
});
