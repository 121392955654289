import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { DatePicker } from '../../../../components/UI';

class DateField extends Component {
  handleChange = (property) => (e) => {
    this.props.handleChange(property, e.target.value);
    this.props.handleSubmit();
  };

  render() {
    const { dateTo, dateFrom } = this.props;

    return (
      <>
        <DatePicker
          value={dateTo}
          label={intl.formatMessage({ id: 'date.to' })}
          onChangeDate={this.handleChange('dateTo')}
        />
        <DatePicker
          value={dateFrom}
          label={intl.formatMessage({ id: 'date.from' })}
          onChangeDate={this.handleChange('dateFrom')}
        />
      </>
    );
  }
}

DateField.propTypes = {
  classes: PropTypes.object.isRequired,
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default injectIntl(DateField);
