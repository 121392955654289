import { Formik } from 'formik';
import { Box } from 'native-base';
import React, { useMemo } from 'react';

import {
  BoxWrapper,
  Checkbox,
  FieldRow,
  FormRow,
  FormWrapper,
  Layout,
  RequestHandler,
  Select,
  TextField,
  Typography,
} from '../../../../../components';
import { IS_MOBILE_SCREEN } from '../../../../../constants';
import {
  useCreateStrapiOrderStatusMap,
  useDeleteStrapiOrderStatusMap,
  useGetStatusesQuery,
  useGetStrapiOrderStatusMapQuery,
  useParams,
  useUpdateStrapiOrderStatusMap,
} from '../../../../../hooks';
import { createStrapiOrderStatusSchema } from '../../../../../services/validation';
import { transformSelectOptions } from '../../../../../utils';

const StrapiOrderStatus = () => {
  const { id: strapiStatusName, statusId } = useParams();

  const isEdit = strapiStatusName && statusId;

  const { data, ...queryProps } = useGetStrapiOrderStatusMapQuery({
    variables: { args: { strapiStatusName, statusId: Number(statusId) } },
    skip: !isEdit,
    fetchPolicy: 'no-cache',
  });

  const { data: statusesData } = useGetStatusesQuery();

  const [createStrapiOrderStatusMapMutation, { loading }] =
    useCreateStrapiOrderStatusMap();
  const [
    updateStrapiOrderStatusMapMutation,
    { loading: updateStrapiOrderStatusMapLoading },
  ] = useUpdateStrapiOrderStatusMap();
  const [
    deleteStrapiOrderStatusMapMutation,
    { loading: deleteStrapiOrderStatusMapLoading },
  ] = useDeleteStrapiOrderStatusMap();

  const initialValues = useMemo(() => {
    return {
      strapiStatusName: data?.strapiOrderStatusMap?.strapiStatusName || '',
      statusId: data?.strapiOrderStatusMap?.statusId?.toString() || '',
      isStrapiStatusDefault: data?.strapiOrderStatusMap?.isStrapiStatusDefault || false,
      isStatusDefault: data?.promOrderStatusMap?.isStatusDefault || false,
      strapiStatusDesc: data?.strapiOrderStatusMap?.strapiStatusDesc || '',
    };
  }, [data]);

  const handleFormSubmit = (values) => {
    if (isEdit) {
      updateStrapiOrderStatusMapMutation({
        variables: {
          input: {
            strapiStatusName,
            statusId: Number(statusId),
            isStrapiStatusDefault: values.isStrapiStatusDefault,
            isStatusDefault: values.isStatusDefault,
            strapiStatusDesc: values.strapiStatusDesc,
          },
        },
      });
    } else {
      createStrapiOrderStatusMapMutation({
        variables: {
          input: {
            strapiStatusName: values.strapiStatusName,
            statusId: Number(values.statusId),
            isStrapiStatusDefault: values.isStrapiStatusDefault,
            isStatusDefault: values.isStatusDefault,
            strapiStatusDesc: values.strapiStatusDesc,
          },
        },
      });
    }
  };

  const handleDeleteStrapiOrderStatusMap = () => {
    deleteStrapiOrderStatusMapMutation({
      variables: {
        params: { strapiStatusName, statusId: Number(statusId) },
      },
    });
  };

  return (
    <RequestHandler {...queryProps}>
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={createStrapiOrderStatusSchema}
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <Layout
              onActionButtonPress={handleSubmit}
              actionButtonType='save'
              actionButtonIsLoading={
                loading ||
                updateStrapiOrderStatusMapLoading ||
                deleteStrapiOrderStatusMapLoading
              }
              onRemoveButtonPress={handleDeleteStrapiOrderStatusMap}
            >
              <FormRow>
                <FormRow.Left>
                  <BoxWrapper>
                    <Typography intlId='strapi.status' variant='heading' />
                    {!isEdit ? (
                      <FieldRow withNegativeBottomMargin={false}>
                        <TextField name='strapiStatusName' label='strapi.status' />
                      </FieldRow>
                    ) : null}
                    <FieldRow withNegativeBottomMargin={false}>
                      <TextField
                        name='strapiStatusDesc'
                        label='strapi.statusDescription'
                      />
                    </FieldRow>
                    {!isEdit ? (
                      <FieldRow withNegativeBottomMargin={false}>
                        <Select
                          name='statusId'
                          label='orderStatuses.statusCRM'
                          options={transformSelectOptions({
                            data: statusesData?.statuses,
                          })}
                        />
                      </FieldRow>
                    ) : null}
                    <FieldRow withNegativeBottomMargin={false}>
                      <Box ml={2} pb={3}>
                        <Checkbox
                          mt={!IS_MOBILE_SCREEN ? '16px' : 0}
                          justify={!IS_MOBILE_SCREEN ? 'flex-start' : 'center'}
                          label={'orderStatuses.statusDefaultCRM'}
                          onChange={() =>
                            setFieldValue('isStatusDefault', !values.isStatusDefault)
                          }
                          checked={values.isStatusDefault}
                        />
                      </Box>
                    </FieldRow>
                    <FieldRow withNegativeBottomMargin={false}>
                      <Box ml={2} pb={3}>
                        <Checkbox
                          mt={!IS_MOBILE_SCREEN ? '16px' : 0}
                          justify={!IS_MOBILE_SCREEN ? 'flex-start' : 'center'}
                          label={'strapi.statusDefault'}
                          onChange={() =>
                            setFieldValue(
                              'isStrapiStatusDefault',
                              !values.isStrapiStatusDefault,
                            )
                          }
                          checked={values.isStrapiStatusDefault}
                        />
                      </Box>
                    </FieldRow>
                  </BoxWrapper>
                </FormRow.Left>
              </FormRow>
            </Layout>
          )}
        </Formik>
      </FormWrapper>
    </RequestHandler>
  );
};

export default StrapiOrderStatus;
