import { View } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { Typography } from '../../../../components';
import { theme } from '../../../../constants';
import styles from './styles';

export const ConditionNumber = ({ condition, style = {}, fontSize = 9 }) => (
  <View
    style={[
      styles.conditionSelectItem,
      {
        backgroundColor: theme.colors.conditions[condition?.id],
      },
      style,
    ]}
  >
    <Typography style={[styles.conditionSelectItemText, { fontSize }]}>
      {condition?.id}
    </Typography>
  </View>
);

ConditionNumber.propTypes = {
  condition: PropTypes.shape({
    id: PropTypes.string,
  }),
  style: PropTypes.object,
  fontSize: PropTypes.number,
};
