import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const BoxIcon = () => {
  return (
    <Svg
      width='18'
      height='13'
      viewBox='0 0 18 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <Path
        d='M11.973 6.44997C11.4977 6.44997 11.0505 6.19684 10.8087 5.79184L9.00021 2.79372L7.19459 5.79184C6.9499 6.19965 6.50271 6.45278 6.0274 6.45278C5.90084 6.45278 5.77428 6.4359 5.65334 6.39934L1.80021 5.29684V10.3031C1.80021 10.7165 2.08146 11.0765 2.48084 11.175L8.56146 12.6965C8.84834 12.7668 9.14928 12.7668 9.43334 12.6965L15.5196 11.175C15.919 11.0737 16.2002 10.7137 16.2002 10.3031V5.29684L12.3471 6.39653C12.2262 6.43309 12.0996 6.44997 11.973 6.44997ZM17.9524 3.29434L16.504 0.40309C16.4168 0.228715 16.2283 0.127465 16.0343 0.152778L9.00021 1.04997L11.5793 5.32778C11.6862 5.50497 11.8999 5.58934 12.0996 5.53309L17.6655 3.94403C17.944 3.86247 18.079 3.55309 17.9524 3.29434ZM1.49646 0.40309L0.0480263 3.29434C-0.0813487 3.55309 0.0564639 3.86247 0.332089 3.94122L5.89803 5.53028C6.09771 5.58653 6.31146 5.50215 6.41834 5.32497L9.00021 1.04997L1.96334 0.152778C1.76928 0.130278 1.58365 0.228715 1.49646 0.40309Z'
        fill='#47C8FF'
      />
    </Svg>
  );
};

BoxIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
