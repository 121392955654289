/* eslint-disable react-native/no-raw-text */
import { Flex } from 'native-base';
import React from 'react';

import {
  DesktopTable,
  Layout,
  MobileTable,
  RequestHandler,
  Typography,
} from '../../../components';
import { IS_DESKTOP_SCREEN } from '../../../constants';
import { useCurrencyContext } from '../../../context';
import {
  useFilter,
  useGetCurrenciesQuery,
  useGetReportsQuery,
  useHistory,
} from '../../../hooks';
import { ReportsFilters } from './ReportsFilters';
import ReportsMobileItem from './ReportsMobileItem/ReportsMobileItem';

const Reports = () => {
  const navigation = useHistory();
  const { filters } = useFilter();
  const { data: currenciesData, ...currenciesQueryProps } = useGetCurrenciesQuery();
  const { data: reportsData, ...reportsQueryProps } = useGetReportsQuery();
  const { currentCurrency } = useCurrencyContext();

  const isFilters = Boolean(Object.keys(filters).length);

  const handleClickAddButton = () => {
    navigation.push('/bookkeeping/account/');
  };

  const handleEditButtonClick = (item) => {
    navigation.push(`/bookkeeping/account/${item?.id}`);
  };

  const getTotalItem = (item) => {
    const total = Number(item.income) + Number(item.exchange) - Number(item.spend);
    const initialBalance = Number(item.bankAccount.initialBalance);
    return isFilters ? total.toFixed(2) : (total + initialBalance).toFixed(2);
  };

  const getTotal = () => {
    return reportsData?.reports
      ?.reduce((acc, cur) => {
        return acc + getTotalItem(cur) * Number(cur?.currencyRate || 1);
      }, 0)
      .toFixed(2);
  };

  const getNumberWithCurrencySymbol = (number, currencySymbol) => {
    return `${Number(number)} ${currencySymbol || ''}`;
  };

  const getTotalCurrencies = () => {
    if (currentCurrency === 'default') {
      let defaultCurrency = currenciesData?.currencies.find(
        (item) => item.code === 'USD',
      );
      return `${Number(
        getTotal() * (1 / Number(defaultCurrency?.lastRate?.ratio)),
      ).toFixed(2)} ${defaultCurrency?.symbol}`;
    } else {
      return `${Number(
        getTotal() * (1 / Number(currentCurrency?.lastRate?.ratio)),
      ).toFixed(2)} ${currentCurrency?.symbol}`;
    }
  };

  return (
    <Layout
      isScrollable={false}
      filters={<ReportsFilters />}
      onActionButtonPress={handleClickAddButton}
    >
      <RequestHandler {...currenciesQueryProps}>
        <RequestHandler {...reportsQueryProps}>
          {IS_DESKTOP_SCREEN ? (
            <DesktopTable editable={false}>
              <DesktopTable.Head>
                <DesktopTable.Title intlId='app.account' />
                {!isFilters ? <DesktopTable.Title intlId='app.initialBalance' /> : null}
                <DesktopTable.Title intlId='app.parishes' />
                <DesktopTable.Title intlId='app.conversions' />
                <DesktopTable.Title intlId='app.costs' />
                <DesktopTable.Title intlId='app.total' />
              </DesktopTable.Head>
              <DesktopTable.Body>
                {reportsData?.reports?.map((item, index) => {
                  const currencySymbol = item?.bankAccount?.currency?.symbol;

                  return (
                    <DesktopTable.Row
                      key={index}
                      onEditButtonClick={() => handleEditButtonClick(item?.bankAccount)}
                    >
                      <DesktopTable.Cell>
                        <Typography fontWeight='700'>
                          {item?.bankAccount?.title}
                        </Typography>
                      </DesktopTable.Cell>
                      {!isFilters ? (
                        <DesktopTable.Cell>
                          <Typography>{item?.bankAccount?.initialBalance}</Typography>
                        </DesktopTable.Cell>
                      ) : null}
                      <DesktopTable.Cell>
                        {getNumberWithCurrencySymbol(item.income, currencySymbol)}
                      </DesktopTable.Cell>
                      <DesktopTable.Cell>
                        {getNumberWithCurrencySymbol(item.exchange, currencySymbol)}
                      </DesktopTable.Cell>
                      <DesktopTable.Cell>
                        {getNumberWithCurrencySymbol(item.spend, currencySymbol)}
                      </DesktopTable.Cell>
                      <DesktopTable.Cell>
                        {getNumberWithCurrencySymbol(getTotalItem(item), currencySymbol)}
                      </DesktopTable.Cell>
                    </DesktopTable.Row>
                  );
                })}

                <DesktopTable.Row>
                  <DesktopTable.Cell> </DesktopTable.Cell>
                  <DesktopTable.Cell> </DesktopTable.Cell>
                  <DesktopTable.Cell> </DesktopTable.Cell>
                  <DesktopTable.Cell> </DesktopTable.Cell>
                  {!isFilters ? <DesktopTable.Cell> </DesktopTable.Cell> : null}
                  <DesktopTable.Cell>
                    <Typography fontWeight='700' fontSize='14px'>
                      {getTotalCurrencies()}
                    </Typography>
                  </DesktopTable.Cell>
                </DesktopTable.Row>
              </DesktopTable.Body>
            </DesktopTable>
          ) : (
            <MobileTable
              data={reportsData?.reports}
              renderItem={(item, i) => {
                const currencySymbol = item?.bankAccount?.currency?.symbol;

                return (
                  <>
                    <ReportsMobileItem
                      key={i}
                      title={item?.bankAccount?.title}
                      symbol={currencySymbol}
                      income={getNumberWithCurrencySymbol(item.income, currencySymbol)}
                      exchange={getNumberWithCurrencySymbol(
                        item.exchange,
                        currencySymbol,
                      )}
                      spend={getNumberWithCurrencySymbol(item.spend, currencySymbol)}
                      total={getNumberWithCurrencySymbol(
                        getTotalItem(item).toFixed(2),
                        currencySymbol,
                      )}
                      onItemPress={() => handleEditButtonClick(item?.bankAccount)}
                    />

                    <Flex justify='flex-end' mt='sm'>
                      <Typography fontWeight='700' fontSize='14px'>
                        {getTotalCurrencies()}
                      </Typography>
                    </Flex>
                  </>
                );
              }}
            />
          )}
        </RequestHandler>
      </RequestHandler>
    </Layout>
  );
};

export default Reports;
