import { Flex } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { BoxWrapper, Typography } from '../../../../components';

const CurrencyHistoryMobileItem = ({ ratio, date, removeButton }) => {
  return (
    <BoxWrapper paddingY='md' borderBottomWidth='1px' borderColor='gray.light'>
      <Flex mb='md' justify='space-between'>
        <Typography intlId='app.ratio' />
        <Typography>{ratio}</Typography>
      </Flex>
      <Flex mb='md' justify='space-between'>
        <Typography intlId='date.fromStart' />
        <Typography>{date}</Typography>
      </Flex>
      {removeButton}
    </BoxWrapper>
  );
};

export default memo(CurrencyHistoryMobileItem);

CurrencyHistoryMobileItem.propTypes = {
  ratio: PropTypes.string.isRequired,
  date: PropTypes.any.isRequired,
  removeButton: PropTypes.any,
};
