import { DocumentationAddIcon, DocumentationMainIcon } from '../../components/Icons';

export default [
  { name: 'DocumentationSections', icon: DocumentationMainIcon, path: '/home' },
  {
    name: 'AddDocumentationSection',
    icon: DocumentationAddIcon,
    path: '/adddocumentationsection',
  },
];
