import { FieldArray, useFormikContext } from 'formik';
import { Box, Flex } from 'native-base';
import React, { useMemo } from 'react';

import { DesktopTable, QuerySelectWithSearch, TextField } from '../../../../components';
import { FIND_PURCHASE_PARSELS_QUERY } from '../../../../graphql';

export const ForwardingTableRows = ({ arrayHelpers }) => {
  const { values } = useFormikContext();

  const pickedParcelsId = useMemo(
    () =>
      values.trackingForwardingCodes[0].parcelIds.map((parcelId) => ({ id: parcelId })),
    [values.trackingForwardingCodes],
  );

  values?.trackingForwardingCodes[values?.trackingForwardingCodes.length - 1]
    ?.forwardingCode &&
    arrayHelpers.push({
      forwardingCode: '',
      parcelIds: [],
    });

  return values?.trackingForwardingCodes.map((forwardingRow, index) => {
    const isHasForwardingCode = forwardingRow.forwardingCode;

    const removeForwarding = () => {
      arrayHelpers.remove(index);
    };

    return (
      <DesktopTable.Row
        iconName={'delete'}
        iconColor={'#BDBDBD'}
        key={index}
        onChange={isHasForwardingCode ? removeForwarding : undefined}
      >
        <DesktopTable.Cell hasContent display='table-caption'>
          <TextField
            name={`trackingForwardingCodes.${index}.forwardingCode`}
            width={'300px'}
            mb={'0px'}
          />
        </DesktopTable.Cell>
        <DesktopTable.Cell width={`55%`}>
          <Flex direction='row' wrap='wrap'>
            <FieldArray
              name={`trackingForwardingCodes.${index}.parcelIds`}
              render={(arrayHelpers) =>
                [...(values?.trackingForwardingCodes?.[index]?.parcelIds || []), {}].map(
                  (parcelsRow, i) => {
                    return (
                      <Box key={i} marginRight={'30px'}>
                        <QuerySelectWithSearch
                          alreadyPickedItems={pickedParcelsId}
                          fieldName={`trackingForwardingCodes.${index}.parcelIds.${i}`}
                          graphql={FIND_PURCHASE_PARSELS_QUERY}
                          graphqlName='purchaseParcels'
                          rowsInResponse
                          onChange={(option) => {
                            if (!option?.label) {
                              arrayHelpers.remove(i);
                            }
                          }}
                          labelProperty={'trackingCode'}
                          pl={0}
                          pr={0}
                          mb={0}
                          width={'300px'}
                        />
                      </Box>
                    );
                  },
                )
              }
            />
          </Flex>
        </DesktopTable.Cell>
      </DesktopTable.Row>
    );
  });
};
