import { StyleSheet } from 'react-native';
import { isWeb } from '../../constants';

export const styles = StyleSheet.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.4)', 
    borderRadius:'5px'
  },
  modalWrapper: {
    ...(isWeb ? { boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)' } : null),
  }
});
