import { Formik } from 'formik';
import React, { useMemo } from 'react';

import {
  BoxWrapper,
  FieldRow,
  FormRow,
  FormWrapper,
  Layout,
  RequestHandler, Select,
  TextField,
  Typography
} from "../../../components";
import {
  useCreatePaymentType,
  useDeletePaymentType,
  useGetPaymentTypeQuery, useIntl,
  useParams
} from "../../../hooks";
import { paymentTypeSchema } from '../../../services/validation';

const PaymentMethod = () => {
  const { id } = useParams();
  const intl = useIntl();

  const { data, ...queryProps } = useGetPaymentTypeQuery(id);

  const [createPaymentTypeMutation, { loading }] = useCreatePaymentType();

  const [deletePaymentTypeMutation, { loading: deletePaymentTypeLoading }] =
    useDeletePaymentType();

  const initialValues = useMemo(() => {
    return {
      name: data?.paymentType?.name || '',
      type: data?.paymentType?.type || 'prepayment',
    };
  }, [data]);

  const handleFormSubmit = (values) => {
    createPaymentTypeMutation({
      variables: {
        input: {
          ...(id && { id: Number(id) }),
          name: values.name,
          type: values.type,
        },
      },
    });
  };

  const handleDeletePaymentType = () => {
    deletePaymentTypeMutation({
      variables: {
        id: Number(id),
      },
    });
  };

  return (
    <RequestHandler {...queryProps}>
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={paymentTypeSchema}
        >
          {({ handleSubmit }) => (
            <Layout
              onActionButtonPress={handleSubmit}
              actionButtonType='save'
              actionButtonIsLoading={loading || deletePaymentTypeLoading}
              onRemoveButtonPress={handleDeletePaymentType}
            >
              <FormRow>
                <FormRow.Left>
                  <BoxWrapper>
                    <Typography intlId='app.paymentType' variant='heading' />
                    <FieldRow withNegativeBottomMargin={false}>
                      <TextField name='name' label='app.name' />
                    </FieldRow>
                    <FieldRow withNegativeBottomMargin={false}>
                      <Select name='type' label='app.paymentTypeType' options={[
                        {
                          value: 'prepayment',
                          label: intl.formatMessage({
                            id: 'app.paymentTypePrepayment',
                          }),
                        },
                        {
                          value: 'payOnDelivery',
                          label: intl.formatMessage({
                            id: 'app.paymentTypePayOnDelivery',
                          }),
                        },
                      ]} />
                    </FieldRow>
                  </BoxWrapper>
                </FormRow.Left>
              </FormRow>
            </Layout>
          )}
        </Formik>
      </FormWrapper>
    </RequestHandler>
  );
};

export default PaymentMethod;
