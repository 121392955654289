import React, { useEffect, useState } from 'react';

import {
  DesktopTable,
  Layout,
  MobileTable,
  RequestHandler,
} from '../../../../../components';
import { IS_DESKTOP_SCREEN } from '../../../../../constants';
import {
  useGetFlexibleshopOrderStatusMapsQuery,
  useGetStatusesQuery,
  useHistory,
} from '../../../../../hooks';
import { getOrderStatusName } from '../../../../../utils/getOrderStatusName';
import FlexibleshopOrderStatusMobileItem from './FlexibleshopOrderStatusMobileItem';

const COLUMNS = [
  { intlId: 'flexibleshop.id' },
  { intlId: 'orderStatuses.description' },
  { intlId: 'app.status' },
];

const FlexibleshopOrderStatuses = () => {
  const navigation = useHistory();
  const [statuses, setStatuses] = useState([]);

  const { data: statusesData } = useGetStatusesQuery();

  const {
    data: flexibleshopOrderStatusMapsData,
    ...flexibleshopOrderStatusMapsQueryProps
  } = useGetFlexibleshopOrderStatusMapsQuery();

  useEffect(() => {
    if (!statusesData) return;
    setStatuses(statusesData?.statuses);
  }, [statusesData]);

  const handleEditButtonClick = (flexibleshopStatusId, statusId) => {
    navigation.push(
      '/settings/orderstatuses/flexibleshop/' + flexibleshopStatusId + '/' + statusId,
    );
  };

  const handleClickAddButton = () => {
    navigation.push('/settings/orderstatuses/flexibleshop/add');
  };

  if (IS_DESKTOP_SCREEN) {
    return (
      <Layout isScrollable={false} onActionButtonPress={handleClickAddButton}>
        <RequestHandler {...flexibleshopOrderStatusMapsQueryProps}>
          <DesktopTable>
            <DesktopTable.Head>
              {COLUMNS?.map(({ intlId, sortId }) => (
                <DesktopTable.Title key={intlId} intlId={intlId} sortId={sortId} />
              ))}
            </DesktopTable.Head>
            <DesktopTable.Body>
              {flexibleshopOrderStatusMapsData?.flexibleshopOrderStatusMaps?.map(
                (item) => (
                  <DesktopTable.Row
                    key={item?.id}
                    onEditButtonClick={() =>
                      handleEditButtonClick(item.flexibleshopStatusId, item.statusId)
                    }
                  >
                    <DesktopTable.Cell>{item.flexibleshopStatusId}</DesktopTable.Cell>
                    <DesktopTable.Cell>{item.flexibleshopStatusDesc}</DesktopTable.Cell>
                    <DesktopTable.Cell>
                      {getOrderStatusName(statuses, item.statusId)}
                    </DesktopTable.Cell>
                  </DesktopTable.Row>
                ),
              )}
            </DesktopTable.Body>
          </DesktopTable>
        </RequestHandler>
      </Layout>
    );
  }
  return (
    <Layout isScrollable={false} onActionButtonPress={handleClickAddButton}>
      <RequestHandler {...flexibleshopOrderStatusMapsQueryProps}>
        <MobileTable
          data={flexibleshopOrderStatusMapsData?.flexibleshopOrderStatusMaps}
          renderItem={(item) => (
            <FlexibleshopOrderStatusMobileItem
              onItemPress={() =>
                handleEditButtonClick(item.flexibleshopStatusId, item.statusId)
              }
              id={item.flexibleshopStatusId}
              description={item.flexibleshopStatusDesc}
              status={getOrderStatusName(statuses, item.statusId)}
            />
          )}
        />
      </RequestHandler>
    </Layout>
  );
};

export default FlexibleshopOrderStatuses;
