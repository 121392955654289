import { StyleSheet } from 'react-native';

import { theme } from '../../constants';

export default function createStyles(hasError, inputHeight) {
  return StyleSheet.create({
    textInput: {
      borderBottomColor: hasError ? theme.colors.danger : theme.colors.black,
      borderBottomWidth: 1,
      color: theme.colors.darkGray,
      height: inputHeight,
      overflow: 'hidden',
      paddingBottom: 8,
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 8,
      textAlignVertical: 'top',
      width: '100%',
    },
    textInputFocus: {
      borderBottomColor: theme.colors.primary,
      borderBottomWidth: 1,
      outlineWidth: 0,
    },
    textInputHover: {
      borderBottomColor: theme.colors.primary,
    },
  });
}
