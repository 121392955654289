import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';

export const RightArrowIcon = ({ size = 28, style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 28 28' style={{ ...style }}>
      <Rect width='28' height='28' rx='5' fill='white' fillOpacity='0.1' />
      <Path
        d='M16.7812 13.3334L13.2052 9.75736L14.1478 8.8147L19.3332 14L14.1478 19.1854L13.2052 18.2427L16.7812 14.6667H8.6665V13.3334H16.7812Z'
        fill='white'
      />
    </Svg>
  );
};

RightArrowIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
