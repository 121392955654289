import {
  AcceptanceIcon,
  AnnulIcon,
  BoxesIcon,
  DashboardIcon,
  DisplacementsIcon,
  ProductsIcon,
} from '../../components/Icons';

export default [
  { name: 'Home', icon: DashboardIcon, path: '/home' },
  {
    name: 'Products',
    icon: ProductsIcon,
    path: '/products',
    scopes: ['Product:Read', 'Currency:Read'],
  },
  { name: 'Boxes', icon: BoxesIcon, path: '/boxes', scopes: ['Stock:Read'] },
  {
    name: 'Acceptance',
    icon: AcceptanceIcon,
    path: '/acceptance',
    scopes: [
      'Stock:Read',
      'Stock:Write',
      'Availability:Write',
      'Condition:Read',
      'Product:Read',
      'Purchase:Read',
    ],
  },
  {
    name: 'Displacements',
    icon: DisplacementsIcon,
    path: '/displacements',
    scopes: ['Stock:Read', 'Availability:Write', 'Condition:Read', 'Product:Read'],
  },
  {
    name: 'Annul',
    icon: AnnulIcon,
    path: '/annul',
    scopes: ['Stock:Read', 'Availability:Write', 'Condition:Read', 'Product:Read'],
  },
];
