import { MaterialIcons } from '@expo/vector-icons';
import { Flex, Icon, Input, View } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { AddModal, RequestHandler, Typography } from '../index';
import styles from './style';

export const SelectWithSearchMobile = ({
  options,
  setSearchValue,
  isLoading = false,
  onValueChange,
  selectedOption,
  placeholderText,
  searchValue,
  onSelectScreenOpen,
  label,
}) => {
  const [isSelectScreenOpen, setIsSelectScreenOpen] = useState(false);

  const [inputValue, setInputValue] = useState(selectedOption?.label || null);

  useEffect(() => {
    setInputValue(selectedOption?.label || '');
  }, [selectedOption]);

  const setSelectScreenOpen = () => {
    typeof onSelectScreenOpen === 'function' && setSearchValue('');
    setIsSelectScreenOpen(true);
    typeof onSelectScreenOpen === 'function' && onSelectScreenOpen();
  };

  const setOption = (option) => {
    onValueChange(option);
    setIsSelectScreenOpen(false);
  };

  return (
    <>
      <TouchableOpacity onPress={setSelectScreenOpen}>
        <Input
          onClick={setSelectScreenOpen}
          isDisabled
          value={inputValue}
          placeholder={placeholderText}
          InputRightElement={
            <Flex alignItems='center' justifyContent={'center'}>
              {selectedOption?.label && (
                <View h='34px'>
                  <Icon
                    as={
                      <MaterialIcons
                        name={'close'}
                        size={16}
                        color='black'
                        style={styles.iconBack}
                        onPress={() => setOption(null)}
                      />
                    }
                  />
                </View>
              )}
              <View h='42px' marginLeft={'30px'}>
                <MaterialIcons
                  name={'keyboard-arrow-down'}
                  size={24}
                  color={'#737373'}
                  style={styles.iconBack}
                  onPress={setSelectScreenOpen}
                />
              </View>
            </Flex>
          }
        />
      </TouchableOpacity>

      <AddModal
        isOpen={isSelectScreenOpen}
        onClose={() => setIsSelectScreenOpen(false)}
        searchValue={searchValue}
        withScan={false}
        onChangeSearchValue={setSearchValue}
        title={label}
      >
        <RequestHandler loading={isLoading}>
          {options.map((option, index) => (
            <TouchableOpacity key={index} onPress={() => setOption(option)}>
              <Flex p='md' mb='sm' shadow={2} bgColor='white' overflow='hidden'>
                <Typography>{option?.label}</Typography>
              </Flex>
            </TouchableOpacity>
          ))}
        </RequestHandler>
      </AddModal>
    </>
  );
};

SelectWithSearchMobile.propTypes = {
  placeholderText: PropTypes.string,
  options: PropTypes.array.isRequired,
  onSelectScreenOpen: PropTypes.func,
  onValueChange: PropTypes.func,
  setSearchValue: PropTypes.func,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  searchValue: PropTypes.string,
  selectedOption: PropTypes.object,
};
