import { Box, Spinner } from 'native-base';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FlatList } from 'react-native';

import { IS_APP } from '../../constants';
import { useFetchMore } from '../../hooks';
import { Typography } from '../Typography/Typography';
import MobileSorting from './MobileSorting';

export const MobileTable = ({
  data,
  sortableColumns,
  fetchMoreData,
  renderItem,
  autoHeight = false,
}) => {
  const noData = !data || data?.length === 0;

  const onEndReached = () =>
    new Promise((resolve, reject) => {
      fetchMoreData(resolve, reject);
    });

  if (noData) {
    return (
      <Box paddingX='md'>
        <Typography intlId='app.noData' />
      </Box>
    );
  }

  if (IS_APP) {
    return (
      <MobileTableApp
        data={data}
        sortableColumns={sortableColumns}
        onEndReached={onEndReached}
        renderItem={renderItem}
        autoHeight={autoHeight}
      />
    );
  }

  return (
    <MobileTableWeb
      data={data}
      sortableColumns={sortableColumns}
      fetchMore={fetchMoreData}
      renderItem={renderItem}
      autoHeight={autoHeight}
    />
  );
};

const MobileTableApp = ({
  data,
  sortableColumns,
  onEndReached,
  renderItem,
  autoHeight,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleEndReached = () => {
    if (typeof onEndReached === 'function') {
      setIsLoading(true);
      onEndReached().then(() => setIsLoading(false));
    }
  };

  return (
    <MobileTableView
      data={data}
      sortableColumns={sortableColumns}
      renderItem={renderItem}
      showPreloader={isLoading}
      handleEndReached={handleEndReached}
      autoHeight={autoHeight}
    />
  );
};

const MobileTableWeb = ({ data, sortableColumns, fetchMore, renderItem, autoHeight }) => {
  const [node, isLoading] = useFetchMore(fetchMore);

  return (
    <MobileTableView
      data={data}
      sortableColumns={sortableColumns}
      renderItem={renderItem}
      showPreloader={isLoading}
      node={node}
      autoHeight={autoHeight}
      onEndReached={() => {}}
    />
  );
};

const MobileTableView = ({
  data,
  sortableColumns,
  renderItem,
  showPreloader,
  node = null,
  autoHeight,
  handleEndReached,
}) => {
  const hasSort = sortableColumns && sortableColumns?.length > 0;

  return (
    <Box position='relative' flex={autoHeight ? null : 1}>
      {hasSort ? <MobileSorting sortableColumns={sortableColumns} /> : null}
      <FlatList
        data={data}
        onEndReached={handleEndReached}
        onEndReachedThreshold={1}
        renderItem={({ item, index }) => renderItem(item, index)}
        keyExtractor={(item, index) => item?.id?.toString() || index}
        nestedScrollEnabled
      />
      {node ? <Box ref={node} /> : null}
      {showPreloader ? (
        <Box top={IS_APP ? 3 : 'auto'} justifyContent={'center'} textAlign={'center'}>
          <Spinner />
        </Box>
      ) : null}
    </Box>
  );
};

MobileTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  sortableColumns: PropTypes.arrayOf(PropTypes.object),
  renderItem: PropTypes.func.isRequired,
  fetchMoreData: PropTypes.func,
  autoHeight: PropTypes.bool,
};

MobileTableApp.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  sortableColumns: PropTypes.arrayOf(PropTypes.object),
  renderItem: PropTypes.func.isRequired,
  onEndReached: PropTypes.func,
  autoHeight: PropTypes.bool,
};

MobileTableWeb.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  sortableColumns: PropTypes.arrayOf(PropTypes.object),
  renderItem: PropTypes.func.isRequired,
  fetchMore: PropTypes.func,
  autoHeight: PropTypes.bool,
  onEndReached: PropTypes.func,
};

MobileTableView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  sortableColumns: PropTypes.arrayOf(PropTypes.object),
  renderItem: PropTypes.func.isRequired,
  showPreloader: PropTypes.bool.isRequired,
  handleEndReached: PropTypes.func,
  node: PropTypes.func,
  autoHeight: PropTypes.bool,
};
