import PropTypes from 'prop-types';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const BookkeepingExchangesIcon = ({ size = 24, style = {} }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' style={{ ...style }}>
      <Path
        d='M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 7H6V9H15L10 4V7ZM5 11L10 16V13H14V11H5Z'
        fill='white'
      />
    </Svg>
  );
};

BookkeepingExchangesIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};
